import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { IconButton, Popper } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import './ShippingWindow.scss';
import { formatDateByMonthDayYear } from '../PreOrders/Utils';
import { dataFormatting } from '../../_lib/lib';
import { ICellRendererParams } from 'ag-grid-community';

interface IItemSetupShipping extends ICellRendererParams {
  getShippingWindow: any;
}

interface IPoClearingShipping {
  getShippingWindow: any;
  data: any;
}

interface IAwardsData {
  awardedPrice: number;
  awardedQuantity: number;
  quantityFulfilled: number;
}
interface IShippingData {
  avgPrice: number;
  quantityAvailable?: number;
  totalAwarded: number;
  totalFulfilled: number;
  available: string;
  startDate?: string;
  endDate?: string;
  itemNumberWarehouse?: string;
  awards?: IAwardsData[];
}

const TextBold = (props: ICellRendererParams) => <b>{props.value}</b>;

const ShippingWindow = (props: IItemSetupShipping | IPoClearingShipping) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [formatDates, setFormatDate] = useState<String>('');
  const [shippingData, setShippingData] = useState<IShippingData[]>([
    {
      avgPrice: 0,
      totalAwarded: 0,
      totalFulfilled: 0,
      available: '',
    },
  ]);
  const [awardsData, setAwardsData] = useState<IAwardsData[]>([
    {
      awardedPrice: 0,
      awardedQuantity: 0,
      quantityFulfilled: 0,
    },
  ]);

  const handleClick = async (event: any) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
    if (formatDates === '') {
      const res = await props.getShippingWindow(props.data.itemElectId);
      if (res) {
        setAwardsData(res.awards);
        setShippingData([res]);
        const formatOnlyDates = formatDateByMonthDayYear(res.startDate, res.endDate);
        setFormatDate(formatOnlyDates);
      } else {
        setAnchorEl(null);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const addingCurrencySymbolToText = (params: any) => {
    const currency = dataFormatting('currency', params.value);
    return currency;
  };

  const columnDef1: Array<ColDef | ColGroupDef> = [
    {
      headerName: dataFormatting('currency-with-no-0', 'Avg Price'),
      field: 'avgPrice',
      width: 100,
      cellRendererFramework: TextBold,
      cellRendererParams: (params: any) => {
        return {
          value: addingCurrencySymbolToText(params),
        };
      },
    },
    {
      headerName: 'Total Awarded',
      field: 'totalAwarded',
      width: 102,
      cellRendererFramework: TextBold,
      cellRendererParams: (params: any) => {
        return {
          value: params.value,
        };
      },
    },
    {
      headerName: 'Total Fulfilled',
      field: 'totalFulfilled',
      width: 100,
      cellRendererFramework: TextBold,
      cellRendererParams: (params: any) => {
        return {
          value: params.value,
        };
      },
    },
    {
      headerName: 'Available',
      field: 'quantityAvailable',
      width: 100,
      cellRendererFramework: TextBold,
      cellRendererParams: (params: any) => {
        return {
          value: params.value,
        };
      },
    },
  ];

  const onGridReady = (params: GridOptions) => {
    if (params.api) {
      if (formatDates === '') {
        params.api.showLoadingOverlay();
      } else {
        params.api.hideOverlay();
      }
    }
  };

  const gridOptions1: GridOptions = {
    headerHeight: 32,
    columnDefs: columnDef1,
    suppressContextMenu: true,
    suppressCellSelection: true,
    suppressHorizontalScroll: true,
    suppressRowHoverHighlight: true,
    onGridReady,
    defaultColDef: {
      wrapText: true,
      menuTabs: [],
      cellStyle: {
        wordBreak: 'break-word',
        paddingRight: '16px',
      },
    },
  };

  const columnDef2: Array<ColDef | ColGroupDef> = [
    {
      headerName: dataFormatting('currency-with-no-0', 'Price'),
      field: 'awardedPrice',
      width: 100,
      valueFormatter: addingCurrencySymbolToText,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      width: 100,
    },
    {
      headerName: 'Fulfilled',
      field: 'quantityFulfilled',
      width: 100,
    },
  ];

  const gridOptions2: GridOptions = {
    headerHeight: 32,
    columnDefs: columnDef2,
    suppressContextMenu: true,
    suppressCellSelection: true,
    suppressHorizontalScroll: true,
    suppressRowHoverHighlight: true,
    onGridReady,
    defaultColDef: {
      wrapText: true,
      menuTabs: [],
      cellStyle: {
        wordBreak: 'break-word',
        paddingRight: '16px',
      },
    },
  };

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick} onBlur={handleClose}>
        <ShowChartIcon />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ zIndex: 5, position: 'absolute' }}
        disablePortal={true}
        modifiers={{
          flip: {
            enabled: false,
          },
        }}>
        <div className="padding-1">
          <div className="popover-title margin-bottom-1">
            <ShowChartIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
            <span>
              <Typography>Shipping Window : {formatDates}</Typography>
            </span>
          </div>
          <div>
            <div className="ag-theme-alpine" style={{ height: '80px', width: '400px' }}>
              <AgGridReact gridOptions={gridOptions1} rowData={shippingData}></AgGridReact>
            </div>
            <div className="ag-theme-alpine" style={{ height: '120px', width: '400px' }}>
              <AgGridReact gridOptions={gridOptions2} rowData={awardsData}></AgGridReact>
            </div>
          </div>
        </div>
      </Popper>
    </div>
  );
};

export default React.memo(ShippingWindow);
