import React from 'react';
function SectionProgress({ ...rest }) {

  return (
    <div className="px-grid-loader" {...rest}>
      <div className="px-circular-loader"></div>
      <h3 className="text-center margin-top-1">Loading...</h3>
    </div>
  );
}

export default SectionProgress;
