import * as types from '../actions/actionTypes';

export default function stockGroupReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.STOCK_SET:
      const newStock = {
        ...state,
        results: Object.assign({}, action.stock),
        isLoaded: true,
        isComplete: action.isComplete,
      };
      console.log('Redux store', newStock);
      return newStock;
    case types.STOCKLIST_SETTINGS_SET:
      return { ...state, settings: action.settings };
    case types.STOCKLIST_OFFERS_SET:
      return { ...state, offers: action.offers };
    default:
      return state;
  }
}
