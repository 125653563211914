import { cloneDeep, isEmpty } from 'lodash';
import { awards } from '../../types/IPreOrders';
import * as types from '../actions/actionTypes';

const getFilterSpecificData = (data: awards[]) => {
  const uniqueBuyers = new Map(),
    uniqueItems = new Map(),
    buyerFilterLabel = new Map(),
    itemFilterLabel = new Map();

  if (isEmpty(data))
    return {
      uniqueBuyers: {},
      uniqueItems: {},
      buyerFilterLabel: {},
      itemFilterLabel: {},
    };

  data.forEach((award: awards) => {
    buyerFilterLabel.set(award.buyerNumber, award.buyerName);
    itemFilterLabel.set(award.itemNumberWarehouse, award.phonexItemDescription);
    uniqueBuyers.set(award.buyerNumber, true);
    uniqueItems.set(award.itemNumberWarehouse, true);
  });

  return {
    uniqueBuyers: Object.fromEntries(uniqueBuyers),
    uniqueItems: Object.fromEntries(uniqueItems),
    buyerFilterLabel: Object.fromEntries(buyerFilterLabel),
    itemFilterLabel: Object.fromEntries(itemFilterLabel),
  };
};

export default function preorderReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.AWARDS_SET: {
      const {
        uniqueBuyers,
        uniqueItems,
        buyerFilterLabel,
        itemFilterLabel,
      } = getFilterSpecificData(action.awards);
      return {
        ...state,
        awards: action.awards,
        uniqueBuyers: { ...state.uniqueBuyers, ...uniqueBuyers },
        uniqueItems: { ...state.uniqueItems, ...uniqueItems },
        buyerFilterLabel: { ...state.buyerFilterLabel, ...buyerFilterLabel },
        itemFilterLabel: { ...state.itemFilterLabel, ...itemFilterLabel },
      };
    }
    case types.AWARDS_CLOSED_SET: {
      const {
        uniqueBuyers,
        uniqueItems,
        buyerFilterLabel,
        itemFilterLabel,
      } = getFilterSpecificData(action.awardsClosed);
      return {
        ...state,
        awardsClosed: action.awardsClosed,
        uniqueBuyers: { ...state.uniqueBuyers, ...uniqueBuyers },
        uniqueItems: { ...state.uniqueItems, ...uniqueItems },
        buyerFilterLabel: { ...state.buyerFilterLabel, ...buyerFilterLabel },
        itemFilterLabel: { ...state.itemFilterLabel, ...itemFilterLabel },
      };
    }
    case types.AWARDS_PENDING_SET: {
      const {
        uniqueBuyers,
        uniqueItems,
        buyerFilterLabel,
        itemFilterLabel,
      } = getFilterSpecificData(action.awardsPending);
      return {
        ...state,
        awardsPending: action.awardsPending,
        uniqueBuyers: { ...state.uniqueBuyers, ...uniqueBuyers },
        uniqueItems: { ...state.uniqueItems, ...uniqueItems },
        buyerFilterLabel: { ...state.buyerFilterLabel, ...buyerFilterLabel },
        itemFilterLabel: { ...state.itemFilterLabel, ...itemFilterLabel },
      };
    }
    case types.UPDATE_AWARDS: {
      const { status, values, field } = action.payload;
      const awards = cloneDeep(status === 1 ? state.awards : state.awardsPending);
      if (!awards) return { ...state };
      if (field !== 'shippingEndDate') {
        values?.forEach((value: any) => {
          const currentItemIndex = awards.findIndex(
            (item: awards) =>
              item.itemElectId === value.itemElectId && item.buyerGrantId === value.buyerGrantId
          );
          let currentItem = awards[currentItemIndex];
          currentItem = {
            ...currentItem,
            ...value,
          };
          awards[currentItemIndex] = currentItem;
        });
      } else {
        values?.forEach((value: any) => {
          awards.forEach((item: awards, index: number) => {
            if (item.itemElectId === value.itemElectId) {
              let currentItem = awards[index];
              let shippingEndDate = value.shippingEndDate;
              currentItem = {
                ...currentItem,
                shippingEndDate,
              };
              awards[index] = currentItem;
            }
          });
        });
      }
      return { ...state, [status === 1 ? 'awards' : 'awardsPending']: awards };
    }
    default:
      return state;
  }
}
