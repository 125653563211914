import React from 'react';
import ReactDOM from 'react-dom';
import PhonexRouter from './PhonexRouter';
import ErrorBoundary from './ErrorBoundaries';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import configureStore from './redux/configureStore';
import { InitialState } from './redux/reducers';
import { Provider as ReduxProvider } from 'react-redux';
import I18nWrapper from './I18n';
import { ErrorHandler } from './ErrorHandler';
import { getConfig, getToken } from './_lib/lib';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

export const store = configureStore(InitialState());

const clearLocalStorageBasedOnToken = () => {
  const newJWTToken = getToken();
  const oldJWTToken = localStorage.getItem('jwtToken');
  if (oldJWTToken === null || newJWTToken !== oldJWTToken) {
    localStorage.removeItem('PO-Open-Selected-State');
    localStorage.setItem('jwtToken', newJWTToken ?? '');
  }
};

const tagManagerArgs = {
  gtmId: 'GTM-M8QPQZZ',
};

ReactGA.initialize('UA-182166620-1');
TagManager.initialize(tagManagerArgs);

async function fetchConfigAndUser() {
  try {
    const config = await getConfig();
    localStorage.setItem('PxConfig', JSON.stringify(config));
    // clear selectedState on new Login
    clearLocalStorageBasedOnToken();
    return ReactDOM.render(
      <ErrorBoundary>
        <ReduxProvider store={store}>
          <I18nWrapper>
            <ErrorHandler>
              <PhonexRouter />
            </ErrorHandler>
          </I18nWrapper>
        </ReduxProvider>
      </ErrorBoundary>,
      document.getElementById('root')
    );
  } catch (error: any) {
    console.error(error);
    return ReactDOM.render(<h2>Something Went Wrong</h2>, document.getElementById('root'));
  }
}

fetchConfigAndUser();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
