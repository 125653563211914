import { Dispatch } from 'react';

import { awards, IInitialState, IStateHandler } from '../../../types/IPreOrders';
import { checkValidTakeAll } from '../TableCellComponents';

import { errorMessages } from '../Utils';
import { updateAwardsActionsList } from './UpdateAwardsActions';
import {
  checkIfEndingFulfilledLessThanAwarded,
  checkIfMemorizeNeeded,
  checkIfNativeItemStatus,
  getAggregatedNewFulfilledValidation,
  getNewStateHandler,
  getValueToUpdateHandler,
  initialState,
  resetAllStates,
  getValueRespectiveToIncrementSize,
  validateAwarded,
  checkIfItemHasFunds,
} from './UpdateAwardsHandler';

const getValueToUpdateAndStateHandler = (
  data: any,
  changes: any,
  colId: any,
  newValueToUpdate: awards[],
  newStateHandler: IStateHandler[],
  message: string,
  setError: Dispatch<string>,
  setValue: Dispatch<string | boolean | number>,
  isAutoRounded: boolean
) => {
  let value;
  if (colId === 'isTakeAll') value = changes[colId] === true ? 'Y' : 'N';
  const valueToUpdate = getValueToUpdateHandler(newValueToUpdate, data, changes);
  const stateHandler = getNewStateHandler(
    newStateHandler,
    colId,
    typeof value !== 'undefined' ? value : data[colId],
    data.buyerNumber,
    data.itemElectId,
    message,
    setError,
    setValue,
    isAutoRounded
  );
  return { valueToUpdate, stateHandler };
};

const updateAwardsReducerFn = (state: IInitialState = initialState, action: any) => {
  switch (action.type) {
    case 'removeFromStateHandler': {
      let { data } = action.payload;
      const newStateHandler = state.stateHandler.filter(
        (st) => st.buyerNumber !== data.buyerNumber && st.itemElectId !== data.itemElectId
      );
      return {
        ...state,
        stateHandler: newStateHandler,
      };
    }
    case 'noValidationNeeded': {
      let { data, colId, processedValue: value, setError, setValue, message } = action.payload;
      let isValid = true,
        isAutoRounded = false,
        updatedValue = 0,
        newStateHandler = state.stateHandler;
      if (colId !== state.lastModifiedField) {
        resetAllStates(state.stateHandler);
        newStateHandler = [];
      }
      if (colId === 'awardedQuantity') {
        if (value !== 0 && !value) isValid = false;
        else {
          let { isAutoRounded: isRounded, value: roundedValue } = getValueRespectiveToIncrementSize(
            value,
            data,
            colId
          );
          isValid = validateAwarded(data, roundedValue);
          isAutoRounded = isRounded;
          updatedValue = roundedValue;
        }
      }
      if (isValid && colId === 'awardedQuantity') {
        setValue(updatedValue);
        value = updatedValue;
      } else {
        if (value !== 0) message = errorMessages.updateAwards.awardedValidation;
        else message = errorMessages.updateAwards.negativeNumber;
      }
      const { valueToUpdate, stateHandler } = getValueToUpdateAndStateHandler(
        data,
        { [colId]: value },
        colId,
        [],
        newStateHandler,
        message,
        setError,
        setValue,
        isAutoRounded
      );
      return {
        ...state,
        valueToUpdate,
        stateHandler,
        validationStatus: isValid ? 'valid' : 'pending',
        lastModifiedField: colId,
      };
    }
    case 'shippingDatesValidation': {
      const { data, colId, processedValue: value, message, setError, setValue } = action.payload;
      const memorize = checkIfMemorizeNeeded(colId, state.lastModifiedField, action.type);
      const isNative = checkIfNativeItemStatus(state.valueToUpdate, data.itemElectId);
      if (!memorize) resetAllStates(state.stateHandler);
      const newValueToUpdate = isNative && memorize ? state.valueToUpdate : [];
      const newStateHandler = isNative && memorize ? state.stateHandler : [];
      const { valueToUpdate, stateHandler } = getValueToUpdateAndStateHandler(
        data,
        { [colId]: value },
        colId,
        newValueToUpdate,
        newStateHandler,
        message,
        setError,
        setValue,
        false
      );
      return {
        ...state,
        valueToUpdate,
        stateHandler,
        validationStatus: 'valid',
        lastModifiedField: colId,
      };
    }
    case 'quantityReadyToFulFillValidation': {
      let { data, colId, processedValue: value, setError, setValue, message } = action.payload;
      let validationStatus = 'pending';
      const memorize = checkIfMemorizeNeeded(colId, state.lastModifiedField, action.type);
      const isNative = checkIfNativeItemStatus(state.valueToUpdate, data.itemElectId);
      if (!memorize) resetAllStates(state.stateHandler);
      const newValueToUpdate = isNative && memorize ? state.valueToUpdate : [];
      const newStateHandler = isNative && memorize ? state.stateHandler : [];
      let { isAutoRounded, value: updatedValue } = getValueRespectiveToIncrementSize(
        value,
        data,
        colId
      );

      let hasValidFunding = checkIfItemHasFunds(data, state.valueToUpdate, updatedValue);
      const isAggregatedValid = getAggregatedNewFulfilledValidation(
        state.valueToUpdate ?? [],
        data,
        value
      );

      setValue(updatedValue);
      if (!isAggregatedValid) message = errorMessages.updateAwards.newFulfilledValidation;
      if (!hasValidFunding) message = errorMessages.updateAwards.newFulfilledFundingValidation;

      let { valueToUpdate, stateHandler } = getValueToUpdateAndStateHandler(
        data,
        { [colId]: updatedValue },
        colId,
        newValueToUpdate,
        newStateHandler,
        message,
        setError,
        setValue,
        isAutoRounded
      );
      if (isAggregatedValid) {
        let { newValueToUpdate, newStateHandler, isValid } = checkIfEndingFulfilledLessThanAwarded(
          valueToUpdate,
          stateHandler
        );
        valueToUpdate = newValueToUpdate;
        stateHandler = isAggregatedValid && isValid ? stateHandler : newStateHandler;
        validationStatus = !isValid ? 'pending' : 'valid';
      }

      if (!hasValidFunding) validationStatus = 'pending';

      return {
        ...state,
        valueToUpdate,
        stateHandler,
        validationStatus,
        lastModifiedField: colId,
      };
    }
    case 'isTakeAllValidation': {
      let { data, colId, message, processedValue: value, setError, setValue } = action.payload;
      let newChanges,
        isValid = true,
        newStateHandler = state.stateHandler,
        newValueToUpdate = state.valueToUpdate;
      const memorize = checkIfMemorizeNeeded(colId, state.lastModifiedField, action.type);
      const isNative = checkIfNativeItemStatus(state.valueToUpdate, data.itemElectId);
      if (!memorize) resetAllStates(state.stateHandler);
      newValueToUpdate = isNative && memorize ? state.valueToUpdate : [];
      if (colId === 'takeAllLimitPercentage' && !value) {
        return data.setTakeLimitDisabled(false, errorMessages.updateAwards.takeAllBlankValidation);
      } else if (colId === 'isTakeAll') {
        message = value
          ? errorMessages.updateAwards.takeAllLimitValidation
          : errorMessages.updateAwards.default;
        newValueToUpdate = [];
        newStateHandler = [];
        newChanges = {
          [colId]: value,
          takeAllLimitPercentage: value ? data.takeAllLimitPercentage : 0,
        };
        if (value) isValid = false;
      } else {
        const { isValid: newIsValid, message: newMessage } = checkValidTakeAll(value, colId, data);
        if (!newIsValid) {
          data.setTakeLimitDisabled(false, message);
          message = newMessage;
        }
        isValid = newIsValid;
        newChanges = {
          takeAllLimitPercentage: value,
        };
      }
      const { valueToUpdate, stateHandler } = getValueToUpdateAndStateHandler(
        data,
        newChanges,
        colId,
        newValueToUpdate,
        newStateHandler,
        message,
        setError,
        setValue,
        false
      );
      return {
        ...state,
        valueToUpdate,
        stateHandler,
        validationStatus: !isValid ? 'pending' : 'valid',
        lastModifiedField: colId,
      };
    }
    case 'errorStateSetter': {
      const { colId, data, setError, setValue, message, processedValue: value } = action.payload;
      let actionType = updateAwardsActionsList[colId];
      const memorize = checkIfMemorizeNeeded(colId, state.lastModifiedField, actionType);
      const isNative = checkIfNativeItemStatus(state.valueToUpdate, data.itemElectId);
      if (!memorize && !isNative) resetAllStates(state.stateHandler);
      const newValueToUpdate = isNative && memorize ? state.valueToUpdate : [];
      const newStateHandler = isNative && memorize ? state.stateHandler : [];
      const { valueToUpdate, stateHandler } = getValueToUpdateAndStateHandler(
        data,
        { [colId]: value },
        colId,
        newValueToUpdate,
        newStateHandler,
        message,
        setError,
        setValue,
        false
      );
      return {
        ...state,
        stateHandler,
        valueToUpdate,
        validationStatus: 'pending',
        lastModifiedField: colId,
      };
    }
    case 'customStateSetter': {
      return { ...state, ...action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export { updateAwardsReducerFn };
