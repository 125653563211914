import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IDaysToSellFilterStategy, IFilterDialog } from '../../../types/IFilterEngine';
import { PrimaryButton, PrimaryIconButton, TertiaryButton, TextField } from '../../AtomComponents';
import { ArrowBack } from '@material-ui/icons';
import { dataFormatting } from '../../../_lib/lib';

const FilterDaysToSell = ({
  customOptionObj,
  title,
  attribute,
  filterState,
  clearFilter,
  saveFilter,
  closeDialog,
  openDialog,
  getLabel,
}: IFilterDialog) => {
  const [filterOptions, setFilterOptions] = useState<IDaysToSellFilterStategy>({
    from: 10,
    to: Infinity,
  });
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    if (customOptionObj) {
      const updatedObj = {
        ...customOptionObj,
      };
      if (updatedObj.from === 0) updatedObj.from = '0';
      if (updatedObj.to === 0) updatedObj.to = '0';
      if (updatedObj.to === Infinity) updatedObj.to = dataFormatting('number', updatedObj.to);
      setFilterOptions(updatedObj);
    }
  }, [customOptionObj]);

  const handleChange = (e: any) => {
    setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const errObj: any = {};
    let to = +filterOptions.to,
      from = +filterOptions.from;
    if (filterOptions.from) {
      const stringFrom = `${filterOptions.from}`;
      if (isNaN(from) || stringFrom.includes('.') || stringFrom.includes('e'))
        errObj.from = 'Must be a valid whole number between 0 and 100';
    } else {
      from = 1;
    }

    if (filterOptions.to) {
      if (isNaN(to) || `${filterOptions.to}`.includes('.') || `${filterOptions.to}`.includes('e'))
        errObj.to = `Must be a valid whole number`;
    } else {
      if (!isNaN(from) && from !== 1) {
        to = from + 5;
      } else {
        to = 5;
      }
    }

    if (from > 100) errObj.from = 'Must be a whole number between 0 and 100';
    if (from < 0) errObj.from = 'Must be a whole number between 0 and 100';

    if (to < from) {
      errObj.from = `Must be a whole number less than to date (${to})`;
      errObj.to = `Must be a whole number more than from date (${from})`;
    }
    // if (to === 0 && from > 0) errObj.from = undefined;

    setErrorObj(errObj);
    if (Object.keys(errObj).length > 0) return;

    saveFilter({
      title: title,
      attribute: attribute,
      selectedFilters: {
        from: from,
        to: to,
      },
    });
  };

  const handleClear = () => {
    clearFilter({
      title: title,
      attribute: attribute,
    });
  };

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!customOptionObj}>
      <DialogTitle>
        {!filterState.isApplied && (
          <PrimaryIconButton
            onClick={() =>
              openDialog({ title: 'New Filter', attribute: '+ NEW', from: attribute })
            }>
            <ArrowBack />
          </PrimaryIconButton>
        )}
        <span className="px-chips-filter-title">{getLabel('attribute', attribute)}</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          <div className="cell small-12 margin-bottom-1">
            Show Items where Estimated Days to Sell is...
          </div>
          <div className="cell small-12 px-one-line-data margin-bottom-1">
            <TextField
              label="From (Days)"
              name="from"
              placeholder="1"
              className="margin-1 px-helper-text-margin-top-1"
              value={filterOptions.from || ''}
              error={!!errorObj.from}
              helperText={errorObj.from || ''}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            —
            <TextField
              label="To (Days)"
              name="to"
              placeholder="5"
              className="margin-1 px-helper-text-margin-top-1"
              value={filterOptions.to || ''}
              error={!!errorObj.to}
              helperText={errorObj.to || ''}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {/* <div className="cell small-12 margin-top-2 px-text-description">
            *Note: Default value of to is 5, if from date is empty or from date + 5
          </div> */}
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>Clear</TertiaryButton>
        <PrimaryButton onClick={handleSave}>APPLY</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDaysToSell;
