import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import {
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isFirstDayOfMonth,
  isThisMonth,
  isToday,
} from 'date-fns';
import { endOfDay, startOfDay } from 'date-fns/esm';
import parse from 'date-fns/parse';
import React, { useEffect, useRef, useState } from 'react';
import {
  IAdvanceFilterFn,
  IDateFilterStrategy,
  IFilterDialog,
  IFiltersApplied,
  IFilterSaveParams,
} from '../../../types/IFilterEngine';
import { IDateRange } from '../../../types/IPricing';
import { getDateFormat } from '../../../_lib/lib';
import { PrimaryButton, TertiaryButton, TextField } from '../../AtomComponents';
import DateField from '../../DateField/DateField';
import { dateFormats, manipulateDates, prefilledDateRangeOptions } from '../../PreOrders/Utils';
import './FilterDate.scss';

interface IFilterDate extends IFilterDialog {
  applyButtonLabel?: any;
  clearButtonLabel?: any;
  disableStartBefore?: string;
  disableBothDates?: {
    endDate?: { after?: string };
  };
  disableCustomDateSelection?: boolean;
}

const commonDateFormat = 'MM/dd/yyyy';

function FilterDate({
  customOptionObj,
  title,
  attribute,
  clearFilter,
  saveFilter,
  closeDialog,
  getLabel,
  getCount,
  disableStartBefore,
  applyButtonLabel = 'APPLY',
  clearButtonLabel = 'Clear',
  disableBothDates,
  disableCustomDateSelection,
}: IFilterDate) {
  // initial filter set
  const [prefilledRange, setPrefilledRange] = useState<any>('');
  const [disabledCustomDateSelection, setDisableCustomDateSelection] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<IDateFilterStrategy>();
  const [prefilledDateRangeLocal, setPrefilledDateRangeLocal] = useState<any>(
    prefilledDateRangeOptions
  );

  useEffect(() => {
    const preFilledDateRange = [...prefilledDateRangeOptions];
    if (disableCustomDateSelection) {
      setPrefilledDateRangeLocal(
        preFilledDateRange.filter((option: any) => !['custom', "month-to-date"].includes(option.id) )
      );
    }
    // setPrefilledDateRangeLocal(preFilledDateRange);
  }, [disableCustomDateSelection]);

  useEffect(() => {
    if (customOptionObj) {
      try {
        const startDate = customOptionObj.startDate ? new Date(customOptionObj.startDate) : '';
        const endDate = customOptionObj.endDate ? new Date(customOptionObj.endDate) : '';
        // set the prefilledRange,
        const dateDifference = startDate && endDate ? differenceInDays(endDate, startDate) : -1;
        let prefilledDateRangeState = '';
        switch (dateDifference) {
          case 7:
            prefilledDateRangeState = 'seven-days-incl-today';
            break;
          case 14:
            prefilledDateRangeState = 'fourteen-days-incl-today';
            break;
          case 28:
            prefilledDateRangeState = 'twentyeight-days-incl-today';
            break;
          default:
            if (dateDifference >= 0 && !disableCustomDateSelection) {
              prefilledDateRangeState = 'custom';
            }
            break;
        }

        if (
          startDate &&
          endDate &&
          isFirstDayOfMonth(startDate) &&
          isThisMonth(startDate) &&
          isToday(endDate)
        ) {
          prefilledDateRangeState = 'month-to-date';
        }

        // set disabledCustomDateSelection,
        setPrefilledRange(prefilledDateRangeState || '');
        if (prefilledDateRangeState === 'custom') {
          setDisableCustomDateSelection(false);
        }

        if (startDate && endDate)
          // set disabled dates
          setDisabledDates({
            // before: addDays(startDate, 1),
            before: format(startDate, commonDateFormat),
            after: format(endDate, commonDateFormat),
          });

        // set rangeDates
        setFilterOptions({
          startDate: startDate ? format(startDate, commonDateFormat) : '',
          endDate: endDate ? format(endDate, commonDateFormat) : '',
        });
      } catch (error: any) {
        setFilterOptions({
          startDate: '',
          endDate: '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customOptionObj]);

  const setStartDate = (val: any) => {
    setFilterOptions({
      startDate: val,
      endDate: filterOptions?.endDate || '',
    });
    setDisabledDates({
      ...disabledDates,
      // before: add(Date.parse(val), { days: 1 }),
      before: val,
    });
  };

  const setEndDate = (val: any) => {
    setFilterOptions({
      startDate: filterOptions?.startDate || '',
      endDate: val,
    });
    setDisabledDates({
      ...disabledDates,
      after: val,
    });
  };

  const [disabledDates, setDisabledDates] = useState<any>({
    before: format(new Date(), commonDateFormat),
    after: format(new Date(), commonDateFormat),
  });
  const startDateRef = useRef<any>();
  const endDateRef = useRef<any>();

  const setPrefilledDateRange = (e: any) => {
    let startDate: Date = new Date();
    let endDate: string = format(new Date(), commonDateFormat);
    if (customOptionObj && customOptionObj?.type === 'fulfillment') {
      startDate = new Date(
        manipulateDates({
          date: startDate.toString(),
          currentFormat: dateFormats.timestamp,
          manipulate: { days: -2 },
          formatTo: dateFormats.timestamp,
        })
      );
      endDate = manipulateDates({
        date: endDate,
        currentFormat: dateFormats.monthDateYear,
        manipulate: { days: -1 },
        formatTo: dateFormats.monthDateYear,
      });
    }
    let startDay: any;
    if (e.target.value !== 'custom' && !disabledCustomDateSelection) {
      setDisableCustomDateSelection(true);
    }
    switch (e.target.value) {
      case 'seven-days-incl-today':
        startDay = startDate.getDate() - 7;
        break;
      case 'fourteen-days-incl-today':
        startDay = startDate.getDate() - 14;
        break;
      case 'twentyeight-days-incl-today':
        startDay = startDate.getDate() - 28;
        break;
      case 'month-to-date':
        startDay = 1;
        break;
      default:
        // enable the custom field edit
        startDay = startDate.getDate();
        setDisableCustomDateSelection(false);
        break;
    }

    startDate.setDate(startDay);
    setDisabledDates({
      ...disabledDates,
      before: format(startDate, commonDateFormat),
      after: endDate,
    });
    setPrefilledRange(e.target.value);
    setFilterOptions({
      ...filterOptions,
      startDate: format(startDate, commonDateFormat),
      endDate: endDate,
    });
  };

  const showInvalidDatesError = () => {
    // show error
    if (startDateRef?.current?.showError) {
      startDateRef.current.showError(`Invalid Dates`);
    }

    if (endDateRef?.current?.showError) {
      endDateRef.current.showError(`Invalid Dates`);
    }
  };
  const handleApply = () => {
    const dates: any = {
      startDate: '',
      endDate: '',
    };

    const startDate = filterOptions?.startDate || '',
      endDate = filterOptions?.endDate || '';

    const startDateFormat = getDateFormat(startDate);
    const endDateFormat = getDateFormat(endDate);
    if (prefilledRange && (!startDateFormat || !endDateFormat || !startDate || !endDate))
      return showInvalidDatesError();

    dates.startDate = startOfDay(parse(startDate, startDateFormat, new Date()));
    dates.endDate = endOfDay(parse(endDate, endDateFormat, new Date()));

    // check if start date < end date
    if (dates.startDate && dates.endDate && isAfter(dates.startDate, dates.endDate)) {
      // show error
      if (startDateRef?.current?.showError)
        startDateRef.current.showError(`The start date cannot be before ${endDate}`);
      if (endDateRef?.current?.showError)
        endDateRef.current.showError(`The end date cannot be before ${startDate}`);
      return;
    }

    let label = '';
    switch (prefilledRange) {
      case 'seven-days-incl-today':
        label = 'Last 7 Days';
        break;
      case 'fourteen-days-incl-today':
        label = 'Last 14 Days';
        break;
      case 'twentyeight-days-incl-today':
        label = 'Last 28 Days';
        break;
      case 'month-to-date':
        label = 'Month to Date';
        break;
      case 'custom':
        label = `${format(dates.startDate || 0, 'MMM dd')} -  ${format(
          dates.endDate || 0,
          'MMM dd'
        )}`;
        break;
    }

    const updatedFilter: IFilterSaveParams = {
      title: title,
      attribute: attribute,
      selectedFilters:
        filterOptions?.endDate && filterOptions?.startDate
          ? {
              ...dates,
              label,
            }
          : {
              label: 'All',
            },
    };

    // handle filter
    saveFilter(updatedFilter);
  };

  const handleClear = () => {
    clearFilter({ title, attribute });
  };

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!customOptionObj}>
      <DialogTitle>
        <span className="px-chips-filter-title">{getLabel('attribute', attribute)}</span>{' '}
      </DialogTitle>

      <DialogContent>
        <div className="grid-x margin-top-1">
          <TextField
            className="cell small-12"
            onFocus={() => startDateRef.current?.removeError()}
            select
            label="Select Date Range"
            value={prefilledRange}
            onChange={setPrefilledDateRange}>
            {prefilledDateRangeLocal.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <div className="small-12 margin-top-3 px-custom-date-range-filter-selector-container">
            <DateField
              textfieldProps={{
                variant: 'outlined',
                label: 'Start Date',
                disabled: disabledCustomDateSelection,
                InputLabelProps: { shrink: true },
              }}
              disabled={{
                before: disableStartBefore,
                after: manipulateDates({
                  date: disabledDates?.after.toString(),
                  currentFormat: dateFormats.monthDateYear,
                  formatTo: dateFormats.timestamp,
                  manipulate: { days: customOptionObj?.type === 'fulfillment' ? -1 : 0 },
                }),
              }}
              date={filterOptions?.startDate}
              setDate={setStartDate}
              ref={startDateRef}
            />
            <DateField
              textfieldProps={{
                variant: 'outlined',
                label: 'End Date',
                disabled: disabledCustomDateSelection,
                InputLabelProps: { shrink: true },
              }}
              disabled={{
                before: manipulateDates({
                  date: disabledDates?.before,
                  currentFormat: dateFormats.monthDateYear,
                  manipulate: { days: customOptionObj?.type === 'fulfillment' ? 1 : 0 },
                  formatTo: dateFormats.timestamp,
                }),
                after: manipulateDates({
                  date:
                    disableBothDates?.endDate?.after ??
                    format(new Date(), dateFormats.monthDateYear),
                  currentFormat: dateFormats.monthDateYear,
                  manipulate: { days: 0 },
                  formatTo: dateFormats.timestamp,
                }),
              }}
              date={filterOptions?.endDate}
              setDate={setEndDate}
              ref={endDateRef}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>{clearButtonLabel}</TertiaryButton>
        <PrimaryButton onClick={handleApply}>{applyButtonLabel}</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export const createDateFilter: IAdvanceFilterFn = (item: any, filtersApplied: IFiltersApplied) => {
  const { createDate } = item;
  if (!createDate || typeof createDate !== 'string') {
    return false;
  }

  const filteredDates: IDateRange = filtersApplied.createDate;

  if (!filteredDates.startDate || !filteredDates.endDate) {
    return true;
  }
  const createdDate = new Date(createDate);
  const startDate = startOfDay(new Date(filteredDates.startDate));
  const endDate = endOfDay(new Date(filteredDates.endDate));

  if (isBefore(createdDate, startDate) || isAfter(createdDate, endDate)) {
    return false;
  }

  return true;
};

export const updateDateFilter: IAdvanceFilterFn = (item: any, filtersApplied: IFiltersApplied) => {
  const { updateDate } = item;
  if (!updateDate || typeof updateDate !== 'string') {
    return false;
  }

  const filteredDates: IDateRange = filtersApplied.updateDate;

  if (!filteredDates.startDate || !filteredDates.endDate) {
    return true;
  }

  const updatedDate = new Date(updateDate);
  const startDate = startOfDay(new Date(filteredDates.startDate));
  const endDate = endOfDay(new Date(filteredDates.endDate));

  if (isBefore(updatedDate, startDate) || isAfter(updatedDate, endDate)) {
    return false;
  }

  return true;
};

export default FilterDate;
