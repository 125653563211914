import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import GlobalButtonWrapper from './GlobalButtonWrapper';

function PrimaryButton({ children, ...props }: ButtonProps) {
  return (
    <GlobalButtonWrapper props={props}>
      <Button variant="contained" color="primary" {...props}>
        {children}
      </Button>
    </GlobalButtonWrapper>
  );
}

export default PrimaryButton;
