import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { dataFormatting } from '../../_lib/lib';

interface IOrderDetailsGrid {
  order?: any;
  warehouseLabels?: any;
  setCancelDialog?: any;
  fetchAndSaveOrder?: any;
  canDeleteOrder: boolean;
  canEditOrder: boolean;
  canUpdateOrderQty: boolean;
  canUpdateCanceledAndShippedQuantity: boolean;
  canUpdateUnitPrice?: boolean;
  validateBillingShippingError?: any;
  saveRow?: any;
  deleteRow?: any;
  bulkCancelItems?: any;
  doNotDisableEditorField?: any;
  isOrderDraft?: any;
}

function OrderDetailsGrid({ order }: IOrderDetailsGrid) {
  //#region >> grid options >>
  const [gridAPI, setGridAPI] = useState<GridApi>();

  // on grid ready
  const onGridReady = (params: any): any => {
    setGridAPI(params.api);
  };

  useEffect(() => {
    gridAPI?.setRowData(order.lineItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridAPI]);

  const CurrencyValueRenderer = (params: ICellRendererParams) => {
    if (params.node.isRowPinned() || !params.colDef?.field) {
      return '';
    }

    return params.colDef.field
      ? dataFormatting('currency', params.data[params.colDef.field])
      : '--';
  };
  const colDef = [
    {
      headerName: '',
      minWidth: 300,
      cellRendererFramework: (params: ICellRendererParams) => {
        if (params.node.isRowPinned()) {
          return (
            <h5>
              <b>{params.data.itemDescription}</b>
            </h5>
          );
        }

        return (
          <div className="px-flex-row">
            <div className="px-flex-column padding-right-1">
              <b>{params.data.itemDescription}</b>
              <span>Item #{params.data.itemNumber}</span>
            </div>
          </div>
        );
      },
    },
    {
      headerName: 'Ordered Quantity',
      colId: 'quantityRequested',
      field: 'quantityRequested',
      type: 'numericColumn',
      wrapText: true,
    },
    {
      headerName: 'Cancelled Quantity',
      field: 'cancelledQuantity',
      colId: 'cancelledQuantity',
      type: 'numericColumn',
      wrapText: true,
    },
    {
      headerName: 'Backorder Quantity',
      field: 'backOrderQuantity',
      colId: 'backOrderQuantity',
      wrapText: true,
      type: 'numericColumn',
    },
    {
      headerName: 'Shipped Quantity',
      field: 'shippedQuantity',
      colId: 'shippedQuantity',
      wrapText: true,
      type: 'numericColumn',
    },
    {
      headerName: 'Unit Price',
      field: 'unitPrice',
      colId: 'unitPrice',
      wrapText: true,
      cellRendererFramework: CurrencyValueRenderer,
    },
    {
      headerName: 'Price',
      field: 'extendedPrice',
      sortable: true,
      wrapText: true,
      cellRendererFramework: CurrencyValueRenderer,
    },
  ];

  // AG grid options
  let gridOptions: GridOptions = {
    columnDefs: colDef,
    headerHeight: 32,
    getRowHeight: (params: any) => {
      if (params.node.isRowPinned()) {
        return 32;
      }
      if (!params.data.itemDescription) {
        return 32;
      }
      const lineHeight = 18;
      const characterWidth = 12;
      const colWidth = 250;
      const numLines = Math.ceil(
        (characterWidth *
          (params.data.itemDescription.length + `Item #{params.data.itemNumber}`.length)) /
          colWidth
      );
      return numLines * lineHeight;
    },
    defaultColDef: {
      flex: 1,
      sortable: false,
      resizable: true,
      filter: true,
      menuTabs: [],
      minWidth: 100,
      autoHeight: true,
      wrapText: true,
      valueGetter: (params: ValueGetterParams) => {
        if (params.node.isRowPinned()) {
          return '';
        }
        return params.colDef.field && params.data[params.colDef.field]
          ? params.data[params.colDef.field]
          : '--';
      },
    },
    getRowNodeId: (data) => {
      return data.itemNumberWarehouse;
    },
    domLayout: 'autoHeight',
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    stopEditingWhenGridLosesFocus: false,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    animateRows: false,
    suppressRowHoverHighlight: true,
    suppressAggFuncInHeader: true,
    undoRedoCellEditing: true,
    suppressClickEdit: true,
    singleClickEdit: false,
    groupDefaultExpanded: -1,
    onRowClicked: () => {},
    //onRowEditingStopped: func,
    loadingOverlayComponentFramework: () => (
      <div className="px-grid-loader">
        <div className="px-circular-loader"></div>
        <h3 className="text-center margin-top-1">Loading...</h3>
      </div>
    ),
  };
  //#endregion << grid options <<

  return (
    <>
      <div className="cell small-12 margin-" id="pxOrderGrid">
        <div className="ag-theme-alpine">
          <AgGridReact gridOptions={gridOptions} onGridReady={onGridReady} />
        </div>
      </div>
    </>
  );
}

export default OrderDetailsGrid;
