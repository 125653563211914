import { eOfferClearingTabs } from '../../types/IOfferClearing';
import * as types from '../actions/actionTypes';
const initState = {
  results: [],
  isLoaded: false,
  uniqueBuyers: {},
  stats: {
    [eOfferClearingTabs.accepted]: 0,
    [eOfferClearingTabs.countered]: 0,
    [eOfferClearingTabs.offered]: 0,
  },
};

export default function offerClearingReducer(state: any = initState, action: any) {
  switch (action.type) {
    case types.OFFER_CLEARING_SET:
      const uniqueBuyers: any = {};
      if (!action.payload) {
        action.payload = [];
      }
      action.payload.forEach((val: any) => {
        val.offers.forEach((offerVal: any) => {
          if (!uniqueBuyers[offerVal.buyerId]) {
            uniqueBuyers[offerVal.buyerId] = true;
          }
        });
      });
      return {
        ...state,
        results: action.payload,
        uniqueBuyers: uniqueBuyers,
        isLoaded: true,
      };
    case types.OFFER_CLEARING_UPDATE:
      const results = state.results.map((val: any) => {
        if (val.itemNumberWarehouse === action.payload.itemWarehouseNumber) {
          return {
            ...val,
            offers: val.offers.map((offerVal: any) => {
              if (
                offerVal.offerId === action.payload.offer.offerId &&
                offerVal.buyerId === action.payload.offer.buyerId
              ) {
                return action.payload.offer;
              }
              return offerVal;
            }),
          };
        }
        return val;
      });

      return {
        ...state,
        results: results,
      };
    case types.OFFER_CLEARING_STATS_SET:
      return {
        ...state,
        stats: {
          ...action.payload,
        },
      };
    case types.OFFER_CLEARING_STATS_UPDATE:
      return {
        ...state,
        stats: {
          ...state.stats,
          [action.payload.oldTab]: (state.stats[action.payload.oldTab] || 1) - 1,
          [action.payload.newTab]: (state.stats[action.payload.newTab] || 0) + 1,
        },
      };
    default:
      return state;
  }
}
