import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import './DraftOrder.scss';
//custom components
import OrderTotals from '../../components/DraftOrderComponents/OrderTotals';
import { BackButton } from '../../components/AtomComponents';
import BillingShippingComponent from '../../components/DraftOrderComponents/BillingShippingComponent';
import OrderDetailsGrid from '../../components/DraftOrderComponents/OrderDetailsGrid';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
//api and hooks
import apiToUrlMap from '../../ApiMapping';
import useDataService from '../../hooks/useDataService';
const DraftOrder = (props: any) => {
  const { order } = props;
  const { openSnackBar, exportData } = useDataService();
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);
  const exportPdf = async () => {
    setLoadingDialog(true);
    try {
      const fileName = `DraftOrder-${order.buyerNumber}-${order.warehouse}.pdf`;
      await exportData({
        method: 'POST',
        url: apiToUrlMap.draftExportPdf,
        fileName: fileName,
        body: { orders: [order] },
      });
    } catch (error: any) {
      openSnackBar('Unable to export Draft Order PDF', 'error');
    }
    setLoadingDialog(false);
  };
  const closeDraftOrder = () => {
    props.setShowDraftOrder();
  };
  return (
    <div
      className={`grid-x px-order-detail-management`}
      style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto' }}>
      <div className="grid-x cell margin-bottom-2">
        <LoadingDialog isDialogOpen={!!loadingDialog} />
        <BackButton goBack={closeDraftOrder} />
        <h3 className="cell auto"> PO Draft Order</h3>
        <div className="cell shrink">
          <Button
            onClick={() => {
              exportPdf();
            }}
            className="px-link-btn margin-right-1"
            startIcon={<GetApp />}>
            EXPORT AS PDF
          </Button>
        </div>
      </div>
      <div className={`cell small-12 px-order-details-meta-data-container align-top`}>
        <div className="px-order-detail-meta-data px-order-draft-detail ">
          <div className="padding-bottom-1">
            <BillingShippingComponent
              payload={order}
              defaults={{
                shippingMethod: order?.shippingMethod,
                paymentMethod: order?.paymentMethod,
                shippingAddress: order?.shippingAddress,
                billingAddress: order?.billingAddress,
              }}
              saveEvents={{}}
              validation={{}}
              defaultOpen={{}}
              setWorkGoingOn={() => {}}
            />
          </div>
        </div>
      </div>
      <div className="cell small-12 margin-top-2 px-text-description">
        <h5 className="margin-right-1">Items in Cart</h5>
      </div>
      <OrderDetailsGrid
        order={order}
        canDeleteOrder={true}
        canEditOrder={true}
        canUpdateOrderQty={true}
        canUpdateCanceledAndShippedQuantity={false}
        canUpdateUnitPrice={true}
        doNotDisableEditorField={true}
        isOrderDraft={true}
      />
      <div className="grid-x cell small-12 px-bottom-subtotal-text">
        <OrderTotals
          order={order}
          isOrderDraft={false}
          extraProps={{ minimumSpace: true }}
          containerClass="px-bottom-subtotal-text px-bold-fonts"
          dataClass="small-4 px-bottom-subtotal-data margin-right-2"
        />
      </div>
    </div>
  );
};
export { DraftOrder };
