import '../../views/PreOrders/PreOrders.scss';
import '../ImportReview/ImportReview.scss';
import React, { useEffect, useState } from 'react';
import { CheckCircle, ErrorOutline, Error, Warning } from '@material-ui/icons';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import useDataService from '../../hooks/useDataService';
import './PoOffersItemImport.scss';
import { ColDef, ColGroupDef, GridApi, GridOptions, ValueGetterParams } from 'ag-grid-community';
import { dataFormatting, messages } from '../../_lib/lib';
import { Badge, Tab, Tabs } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import { eMessageType } from '../../types/IMessageType';
import { LoadingComponent, NoRowsMatchedComponent } from '../PreOrders/TableCellComponents';
import { ITableData } from '../../types/IPoOffers';

interface IPoOffersItemsImport {
  importData: any;
  settings?: any;
  screen: string;
  closeImport: () => void;
  saveImport: (draftId?: any) => Promise<boolean>;
  keyGroup: any;
  fileName: string;
  setPoOffersTab?: any;
}

interface IPoTabStats {
  fileName?: number;
  errors?: number;
  warnings?: number;
  changes?: number;
}

enum eImportTabs {
  errors = 'errors',
  changes = 'changes',
  warnings = 'warnings',
}

let itemGroupGetterKey: any = '';

function PoOffersItemImport({
  importData,
  settings,
  closeImport,
  keyGroup,
  saveImport,
  screen,
  fileName,
  setPoOffersTab,
}: IPoOffersItemsImport) {
  const [tab, setTab] = useState<eImportTabs>(eImportTabs.errors);
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [tabStats, setTabStats] = useState<IPoTabStats>({});
  const [tableData, setTableData] = useState<ITableData>({
    changes: [],
    errors: [],
    fileName: [],
    warnings: [],
  });
  const { openSnackBar } = useDataService();

  let validErrorLength: number = 0;
  let validChangesLength: number = 0;
  let validWarningsLength: number = 0;

  Object.keys(importData['errors']).forEach(
    (key: string) => (validErrorLength += importData.errors[key].length)
  );
  Object.keys(importData['changes']).forEach(
    (key: string) => (validChangesLength += importData.changes[key].length)
  );

  Object.keys(importData['warnings']).forEach(
    (key: string) => (validWarningsLength += importData.warnings[key].length)
  );

  useEffect(() => {
    if (validErrorLength > 0) {
      setTab(eImportTabs.errors);
    } else if (validWarningsLength > 0) {
      setTab(eImportTabs.warnings);
    } else {
      setTab(eImportTabs.changes);
    }
  }, [validErrorLength, validWarningsLength]);

  useEffect(() => {
    if (Object.keys(tabStats).length <= 0 && importData && keyGroup) {
      if (validErrorLength > 0) {
        itemGroupGetterKey = keyGroup.errors;
      } else if (validWarningsLength > 0) {
        itemGroupGetterKey = keyGroup.warnings;
      } else {
        itemGroupGetterKey = keyGroup.changes;
      }

      const updatedTabStats: any = {};
      const updatedTableData: any = {};

      Object.keys(importData).forEach((val) => {
        let tableStatsData: any = {};
        updatedTableData[val] = [];
        Object.keys(importData[val]).forEach((resVal: string) => {
          const importItemCategory = resVal;
          if (Array.isArray(importData[val][resVal])) {
            importData[val][resVal].forEach((errorVal: any) => {
              tableStatsData[errorVal] = true;
              updatedTableData[val].push({
                ...errorVal,
                importItemCategory,
              });
            });
          }
        });

        updatedTabStats[val] = Object.keys(tableStatsData).length;
      });

      if (!updatedTabStats.changes) {
        openSnackBar('No changes found in import file', eMessageType.error);
      }
      setTableData(updatedTableData);
      setTabStats(updatedTabStats);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabStats, importData, keyGroup]);

  useEffect(() => {
    if (gridAPI && tableData[tab] && tab) {
      gridAPI.showLoadingOverlay();
      setTimeout(() => {
        gridAPI.setRowData(tableData[tab]);
        if (tableData[tab].length <= 0) {
          gridAPI.showNoRowsOverlay();
        }
      }, 200);
    }
  }, [tableData, gridAPI, tab]);

  const saveImportData = async () => {
    // show loading
    if (gridAPI) gridAPI.showLoadingOverlay();
    try {
      const res = await saveImport(importData.changes);
      if (res) {
        closeImport();
        if (setPoOffersTab) setPoOffersTab();
        openSnackBar(`Changes saved`, 'success');
      } else {
        openSnackBar(`Changes could not be saved. Try Again`, 'error');
      }
    } catch (error: any) {
      openSnackBar(`Changes could not be saved. Try Again`, 'error');
    }
    if (gridAPI) gridAPI.hideOverlay();
  };

  const handleTabChange = (e: any, tabVal: eImportTabs) => {
    switch (tabVal) {
      case eImportTabs.errors: {
        itemGroupGetterKey = keyGroup.errors;
        break;
      }
      case eImportTabs.warnings: {
        itemGroupGetterKey = keyGroup.warnings;
        break;
      }
      case eImportTabs.changes: {
        itemGroupGetterKey = keyGroup.changes;
        break;
      }
    }
    setTab(tabVal);
  };

  const itemGroupGetter = (params: ValueGetterParams) => {
    return messages[itemGroupGetterKey][params.data.importItemCategory]?.label;
  };

  const onGridReady = (gridOptions: GridOptions) => {
    if (gridOptions.api) {
      setGridAPI(gridOptions.api);
    }
  };

  const expandIcon = () => {
    const key = itemGroupGetterKey.toLowerCase();
    if (key.includes('error')) {
      return '<svg class="MuiSvgIcon-root px-review-icon margin-right-1 px-color-error" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>';
    }

    if (key.includes('warning')) {
      return '<svg class="MuiSvgIcon-root px-review-icon margin-right-1 px-color-warning" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg>';
    }

    if (key.includes('change')) {
      return '<svg class="MuiSvgIcon-root px-review-icon margin-right-1 px-color-change" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>';
    }

    return '<svg class="MuiSvgIcon-root px-review-icon margin-right-1 px-color-change" onclick="(e)=>{e.preventDefault();}" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>';
  };

  const collapseIcons = () => {
    const key = itemGroupGetterKey.toLowerCase();
    if (key.includes('error')) {
      return '<svg class="MuiSvgIcon-root px-collapsed-icon margin-right-1 px-color-error" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>';
    }

    if (key.includes('warning')) {
      return '<svg class="MuiSvgIcon-root px-collapsed-icon margin-right-1 px-color-warning" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg>';
    }

    if (key.includes('change')) {
      return '<svg class="MuiSvgIcon-root px-collapsed-icon margin-right-1 px-color-change" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>';
    }

    return '<svg class="MuiSvgIcon-root px-collapsed-icon margin-right-1 px-change" onclick="(e)=>{e.preventDefault();}" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>';
  };

  let columnDefs: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Row',
      headerClass: 'px-import-review-header',
      children: [
        {
          valueGetter: itemGroupGetter,
          rowGroup: true,
          hide: true,
        },
        {
          headerName: '',
          field: 'rowNumber',
          minWidth: 50,
          maxWidth: 80,
        },
      ],
    },
    {
      headerName: 'Draft Items',
      headerClass: 'px-import-review-header',
      children: [
        {
          headerName: 'Item#',
          colId: 'itemNumber',
          field: 'itemNumber',
          valueGetter: (params: ValueGetterParams) => {
            return params?.data?.itemNumber ?? '--';
          },
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: 'Warehouse',
          field: 'warehouse',
          colId: 'warehouse',
          cellClass: 'po-import-longtext',
          wrapText: true,
          valueGetter: (params: ValueGetterParams) => {
            return params?.data?.warehouse ?? '--';
          },
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: 'Quantity Forecast',
          field: 'quantityForecast',
          colId: 'quantityForecast',
          wrapText: true,
          valueGetter: (params: ValueGetterParams) => {
            return params?.data?.['Forecast Quantity'] ?? '--';
          },
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: 'New Offer Quantity',
          colId: 'offerQuantity',
          field: 'offerQuantity',
          wrapText: true,
          valueGetter: (params: ValueGetterParams) => {
            return dataFormatting('integer', params?.data?.offerQuantity);
          },
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: 'New Offer Price',
          colId: 'offerPrice',
          field: 'offerPrice',
          wrapText: true,
          valueGetter: (params: ValueGetterParams) => {
            return dataFormatting('currency', params?.data?.offerPrice);
          },
          minWidth: 100,
          maxWidth: 200,
        },
        {
          headerName: 'New Take All',
          colId: 'takeAll',
          field: 'takeAll',
          wrapText: true,
          valueGetter: (params: ValueGetterParams) => {
            return params?.data?.takeAll ?? '--';
          },
          minWidth: 100,
          maxWidth: 200,
        },
      ],
    },
  ];

  let gridOptions: GridOptions = {
    headerHeight: 32,
    groupHeaderHeight: 32,
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: false,
      autoHeight: true,
      menuTabs: [],
    },
    groupDefaultExpanded: -1,
    columnDefs: columnDefs,
    icons: {
      groupExpanded: expandIcon,
      groupContracted: collapseIcons,
    },
    enableCellTextSelection: true,
    groupUseEntireRow: true,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
  };

  return (
    <>
      <div className="grid-x px-po-review margin-left-1 margin-right-1">
        <div className="cell small-6">
          <h3 data-id="reviewFundChanges">Review PO Offer Changes</h3>
          <div className="px-po-meta-data-container" data-id="fileName">
            <CheckCircle className="px-review-icon margin-right-1 px-color-success" />
            <div className="px-po-meta-data">
              <span className="px-bold-fonts">File uploaded</span>
              {fileName}
            </div>
          </div>
        </div>
        <div className="cell small-6 text-right">
          <TertiaryButton data-id="cancelChanges" className="margin-right-1" onClick={closeImport}>
            CANCEL CHANGES
          </TertiaryButton>
          <PrimaryButton
            data-id="saveChanges"
            disabled={!tabStats.changes}
            onClick={saveImportData}>
            SAVE CHANGES
          </PrimaryButton>
        </div>
        <div className="grid-x small-12 margin-top-1">
          {validErrorLength > 0 && (
            <div className="small-4">
              <div className="px-po-meta-data-container" data-id="errorDesc">
                <ErrorOutline className="px-review-icon margin-right-1 px-color-error" />
                <div className="px-po-meta-data">
                  <span className="px-bold-fonts">
                    {`
                      ${messages[keyGroup.errors]?.label || 'Error'}
                      (will be skipped):
                      ${validErrorLength}
                  `}
                  </span>
                  {Object.keys(importData.errors).map((val) => {
                    return (
                      importData.errors[val].length > 0 && (
                        <span key={val} className="px-import-meta-data-vertical-margin">
                          {`${messages[keyGroup.errors][val]?.label}: ` || ''}
                          {importData.errors[val].length}
                        </span>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {validWarningsLength > 0 && (
            <div className="small-4">
              <div className="px-po-meta-data-container" data-id="warningDesc">
                <Warning className="px-review-icon margin-right-1 px-color-warning" />
                <div className="px-po-meta-data">
                  <span className="px-bold-fonts">
                    {`
                      ${messages[keyGroup.warnings]?.label || 'Warning'}:
                      ${validWarningsLength}
                  `}
                  </span>
                  {Object.keys(importData.warnings).map((val) => {
                    return (
                      importData.warnings[val].length > 0 && (
                        <span key={val} className="px-import-meta-data-vertical-margin">
                          {`${messages[keyGroup.warnings][val]?.label}: ` || ''}
                          {importData.warnings[val].length}
                        </span>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {validChangesLength > 0 && (
            <div className="small-4">
              <div className="px-po-meta-data-container" data-id="changesDesc">
                <Error className="px-review-icon margin-right-1 px-color-change" />
                <div className="px-po-meta-data">
                  <span className="px-bold-fonts ">
                    {`
                    ${messages[keyGroup.changes]?.label || 'Changes'}:
                    ${validChangesLength}
                   `}
                  </span>
                  {Object.keys(importData.changes).map((val) => {
                    return (
                      importData.changes[val].length > 0 && (
                        <span key={val} className="px-import-meta-data-vertical-margin">
                          {`${messages[keyGroup.changes][val]?.label}: ` || ''}
                          {importData.changes[val].length}
                        </span>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        <Tabs
          value={tab}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={handleTabChange}
          className="margin-top-1 px-po-tabs"
          aria-label="po tab change">
          <Tab
            className="px-error-tab px-bold-fonts"
            label={
              <Badge
                badgeContent={validErrorLength || 0}
                style={{
                  padding: '4px',
                }}
                max={Infinity}
                invisible={false}>
                Errors
              </Badge>
            }
            value={eImportTabs.errors}
            key="errorTab"
          />
          <Tab
            className="px-warning-tab px-bold-fonts"
            label={
              <Badge
                badgeContent={validWarningsLength || 0}
                style={{
                  padding: '4px',
                }}
                max={Infinity}
                invisible={false}>
                Warnings
              </Badge>
            }
            value={eImportTabs.warnings}
            key="warningsTab"
          />
          <Tab
            className="px-change-tab px-bold-fonts"
            label={
              <Badge
                badgeContent={validChangesLength || 0}
                style={{
                  padding: '4px',
                }}
                max={Infinity}
                invisible={false}>
                Changes
              </Badge>
            }
            value={eImportTabs.changes}
            key="changesTab"
          />
        </Tabs>
        <div className="ag-theme-alpine margin-top-1" style={{ height: '100vh', width: '100vw' }}>
          <AgGridReact gridOptions={gridOptions} onGridReady={onGridReady} />
        </div>
      </div>
    </>
  );
}

export default PoOffersItemImport;
