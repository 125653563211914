import { Today } from '@material-ui/icons';
import { differenceInDays, format } from 'date-fns';
import React from 'react';
import { IDateRange } from '../../types/IPricing';

interface IDateSelectorComponent {
  dateRange: IDateRange;
  openDateRangeSelector: Function;
  type?:string;
}

function DateSelectorComponent({ dateRange, openDateRangeSelector, type }: IDateSelectorComponent) {
  const startDate = new Date(dateRange.startDate);
  const endDate = new Date(dateRange.endDate);
  const dateFormats = {
    startDate: 'd LLL yyyy',
    endDate: 'd LLL yyyy',
  };
  if (startDate.getFullYear() !== endDate.getFullYear()) {
    dateFormats.startDate = 'd LLL yyyy';
  }
  return (
    <span
      onClick={() => openDateRangeSelector()}
      data-id="closedDateRangeSelector"
      className="px-recent-days-header-date pointer-mouse margin-bottom-2">
      <Today style={{ marginRight: '4px' }} />
      {type ? (
        <>{`Last ${differenceInDays(endDate, startDate)} Days + Today`}</>
      ) : (
        <>
          {format(startDate, dateFormats.startDate)}
          {' - '}
          {format(endDate, dateFormats.endDate)}
        </>
      )}
    </span>
  );
}

export default DateSelectorComponent;
