import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { I18nContext } from '../../I18n';
import { TopMenuBuyer } from './TopMenuBuyer';
import { TopMenuTenant } from './TopMenuTenant';
import { PrimaryButton, TertiaryButton } from '../AtomComponents';
import Dialog from '../Dialog/Dialog';
import './TopMenuBuyer.scss';
import './TopMenuTenant.scss';

function TopMenu(props: any) {
  const I18n = useContext(I18nContext);
  const [logoutMenu, setLogoutMenu] = React.useState<any>();
  const [logoutDialog, setLogoutDialog] = React.useState<any>();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const firstName = props.userState?.firstName;
        const lastName = props.userState?.lastName;
        // calculate user initial color
        const elem: any = document.querySelector('nav.px-c-TopMenu');
        if (elem) {
          const colorNumber =
            Math.abs(
              ((firstName[0] || 'N').charCodeAt(0) + (lastName[0] || 'A').charCodeAt(0)) % 6
            ) + 1;
          elem.style.setProperty('--px_user_data_color', `var(--px_color_badge_${colorNumber})`);
        }
      } catch (error: any) {
        console.error('error while setting initials', error);
      }
    };

    if (props.userState?.isSignedIn) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userState?.isSignedIn]);

  const openMenu = (e: any) => {
    setLogoutMenu(e.currentTarget);
  };
  const openLogoutDialog = (e: any) => {
    closeMenu();
    setLogoutDialog(!!e);
  };

  const closeLogoutDialog = () => {
    closeMenu();
    setLogoutDialog(undefined);
  };

  const closeMenu = async () => {
    setLogoutMenu(undefined);
  };

  function clearListCookies() {
    const cookies = document.cookie.split(';');
    const domainParts: Array<string> = props.configState.business_tenant_site.split('.');
    domainParts.shift();
    const domain = domainParts.join('.');

    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    for (var i = 0; i < cookies.length; i++)
      document.cookie = cookies[i] + '=;expires=' + new Date(0).toUTCString();
    if (cookies[i]?.indexOf('jwtToken') > -1) {
      document.cookie = `${cookies[i]}=;expires=${new Date(0).toUTCString()};Domain=.${domain}"`;
    }
  }

  const logout = async () => {
    closeLogoutDialog();
    try {
      clearListCookies();
      setTimeout(() => {
        localStorage.clear();
        window.location.href = (props.configState?.business_tenant_site || '') + '/logout';
      }, 0);
    } catch (error: any) {
      console.error('error signing out: ', error);
    }
  };

  return (
    <>
      {props.userState.userType === 'BUYER' && (
        <TopMenuBuyer openLogoutDialog={openLogoutDialog} {...props} />
      )}
      {props.userState.userType === 'ADMIN' && (
        <TopMenuTenant
          openMenu={openMenu}
          logoutMenu={logoutMenu}
          closeMenu={closeMenu}
          openLogoutDialog={openLogoutDialog}
          {...props}
        />
      )}
      <Dialog
        isDialogOpen={!!logoutDialog}
        closeDialog={closeLogoutDialog}
        label={I18n?.logout?.i18n_value || 'Logout'}
        title={I18n?.logout?.i18n_value || 'Logout'}
        content={I18n?.logoutConfirmationMessage?.i18n_value || 'Are you sure you want to log out?'}
        actions={
          <>
            <TertiaryButton onClick={closeLogoutDialog}>
              {I18n?.cancel?.i18n_value || 'Cancel'}
            </TertiaryButton>
            <PrimaryButton onClick={logout}>{I18n?.logout?.i18n_value || 'Logout'}</PrimaryButton>
          </>
        }
      />
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    userState: state.userState,
    urlState: state.urlState,
    configState: state.configState,
  };
}

export default connect(mapStateToProps)(TopMenu);
