import { Button } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface IBackButton {
  url?: string;
  goBack?: () => void;
  textToDisplay?: string;
}

function BackButton({ url, goBack, textToDisplay }: IBackButton) {
  const history = useHistory();
  const goBackFn = () => {
    if (goBack) {
      return goBack();
    }
    if (!url) {
      return history.goBack();
    }
    return history.push(url);
  };
  return (
    <Button data-id="goBack" onClick={goBackFn} className="margin-0 padding-0">
      <ArrowBackIos />
      {textToDisplay}
    </Button>
  );
}

export default BackButton;
