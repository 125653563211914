import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import {
  IFilterDialog,
  IOfferRangeFilterState,
  priceOfOfferClearingFilterStrategy,
} from '../../../types/IFilterEngine';
import { checkNullOrUndefined, dataFormatting } from '../../../_lib/lib';
import { PrimaryButton, PrimaryIconButton, TertiaryButton, TextField } from '../../AtomComponents';
import { ArrowBack } from '@material-ui/icons';

const FilterOfferRange = ({
  customOptionObj,
  title,
  attribute,
  filterState,
  clearFilter,
  saveFilter,
  closeDialog,
  openDialog,
  getLabel,
}: IFilterDialog) => {
  const [filterOptions, setFilterOptions] = useState<IOfferRangeFilterState>({
    type: 'percent',
    price: 'belowList',
    start: 0.01,
    end: 5,
  });
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    if (customOptionObj) {
      setFilterOptions({ ...customOptionObj });
    }
  }, [customOptionObj]);

  const handleChange = (e: any) =>
    setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });

  const handleSave = () => {
    const errObj: any = {};
    let start = +filterOptions?.start,
      end = +filterOptions?.end;
    if (filterOptions.start) {
      const stringStart = `${filterOptions.start}`;
      if (isNaN(start) || stringStart.includes('e'))
        errObj.start = 'Must be a valid number between 0 and 100';
    } else {
      start = 0.01;
    }

    if (filterOptions.end) {
      const stringEnd = `${filterOptions.end}`;
      if (isNaN(end) || stringEnd.includes('e'))
        errObj.end = 'Must be a valid number between 0 and 100';
    } else {
      end = 5;
    }

    if (start < 0 || start > 100) {
      errObj.start = `Must be a number between 0 and 100`;
    }

    if (end < 0 || end > 100) {
      errObj.end = `Must be a number between 0 and 100`;
    }

    if (!errObj.start && !errObj.end && start > end) {
      errObj.start = `Must be a number less than end date (${end})`;
      errObj.end = `Must be a number less than start date (${start})`;
    }

    if (!filterOptions.price) {
      errObj.price = 'Required';
    }
    setErrorObj(errObj);
    if (Object.keys(errObj).length > 0) return;

    let priceSelected = '';
    let priceType = '';
    switch (filterOptions.price) {
      case 'belowList':
        priceSelected = 'list';
        priceType = 'below';
        break;
      case 'aboveLow':
        priceSelected = 'low';
        priceType = 'above';
        break;
      case 'belowLow':
        priceSelected = 'low';
        priceType = 'below';
        break;
    }

    saveFilter({
      title: title,
      attribute: attribute,
      selectedFilters: {
        start,
        end,
        type: filterOptions.type,
        price: filterOptions.price,
        priceType,
        priceSelected,
      },
    });
  };

  const handleClear = () => {
    clearFilter({
      title: title,
      attribute: attribute,
    });
  };

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!customOptionObj}>
      <DialogTitle>
        {!filterState.isApplied && (
          <PrimaryIconButton
            onClick={() =>
              openDialog({ title: 'New Filter', attribute: '+ NEW', from: attribute })
            }>
            <ArrowBack />
          </PrimaryIconButton>
        )}
        <span className="px-chips-filter-title">{getLabel('attribute', attribute)}</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          <div className="cell small-12">Show the offers that are...</div>
          <div className="grid-x cell small-12 padding-1 px-one-line-data">
            <TextField
              label="Start"
              placeholder="0.01"
              name="start"
              className="cell small-5 px-helper-text-margin-top-1"
              value={checkNullOrUndefined(filterOptions.start) ? '' : filterOptions.start}
              error={!!errorObj.start}
              helperText={errorObj.start || ''}
              onChange={handleChange}
              InputProps={{
                endAdornment: filterOptions.type === 'cost' ? dataFormatting('currency', ' ') : '%',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className="cell small-1 text-center">—</div>
            <TextField
              label="End"
              name="end"
              placeholder="5"
              className="cell small-5 px-helper-text-margin-top-1"
              value={checkNullOrUndefined(filterOptions.end) ? '' : filterOptions.end}
              error={!!errorObj.end}
              helperText={errorObj.end || ''}
              onChange={handleChange}
              InputProps={{
                endAdornment: filterOptions.type === 'cost' ? dataFormatting('currency', ' ') : '%',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="grid-x cell small-12 padding-horizontal-1">
            <TextField
              select
              value={filterOptions.price || ''}
              error={!!errorObj.price}
              helperText={errorObj.price || ''}
              className="margin-vertical-2 cell small-5"
              label="In Range of"
              onChange={(e) =>
                setFilterOptions({
                  ...filterOptions,
                  price: e.target.value as priceOfOfferClearingFilterStrategy,
                })
              }
              placeholder="Select either percent or cost">
              {[
                {
                  id: 'belowList',
                  label: `Below ${dataFormatting('currency', ' List')}`,
                },
                {
                  id: 'aboveLow',
                  label: `Above ${dataFormatting('currency', ' Low')}`,
                },
                {
                  id: 'belowLow',
                  label: `Below ${dataFormatting('currency', ' Low')}`,
                },
              ].map((option: any, index: number) => (
                <MenuItem value={option.id} key={index}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>Clear</TertiaryButton>
        <PrimaryButton onClick={handleSave}>APPLY</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default FilterOfferRange;
