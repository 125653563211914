import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { IFilterDialog, ITopOffersFilterStategy } from '../../../types/IFilterEngine';
import { PrimaryButton, PrimaryIconButton, TertiaryButton, TextField } from '../../AtomComponents';
import { ArrowBack } from '@material-ui/icons';
import { dataFormatting } from '../../../_lib/lib';

const FilterTopOffers = ({
  customOptionObj,
  title,
  attribute,
  filterState,
  clearFilter,
  saveFilter,
  closeDialog,
  openDialog,
  getLabel,
}: IFilterDialog) => {
  const [filterOptions, setFilterOptions] = useState<ITopOffersFilterStategy>({
    qty: 0,
  });
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    if (customOptionObj) {
      const updatedObj = {
        ...customOptionObj,
      };
      if (updatedObj.qty === Infinity) updatedObj.qty = dataFormatting('number', updatedObj.qty);
      setFilterOptions(updatedObj);
    }
  }, [customOptionObj]);

  const handleChange = (e: any) => {
    // if (isNaN(+e.target.value)) return;
    setFilterOptions({ ...filterOptions, qty: e.target.value });
  };

  const handleSave = () => {
    const errObj: any = {};
    let qty = +filterOptions.qty;

    if (filterOptions.qty) {
      const stringStart = `${filterOptions.qty}`;
      if (isNaN(qty) || stringStart.includes('e') || stringStart.includes('.'))
        errObj.qty = 'Must be a valid whole number';
    } else {
      qty = 3;
    }

    if (qty < 1) errObj.qty = 'Must be a whole number more than 1';

    setErrorObj(errObj);
    if (Object.keys(errObj).length > 0) return;

    saveFilter({
      title: title,
      attribute: attribute,
      selectedFilters: {
        qty: qty,
      },
    });
  };

  const handleClear = () => {
    clearFilter({
      title: title,
      attribute: attribute,
    });
  };

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!customOptionObj}>
      <DialogTitle>
        {!filterState.isApplied && (
          <PrimaryIconButton
            onClick={() =>
              openDialog({ title: 'New Filter', attribute: '+ NEW', from: attribute })
            }>
            <ArrowBack />
          </PrimaryIconButton>
        )}
        <span className="px-chips-filter-title">{getLabel('attribute', attribute)}</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          <div className="cell small-12 margin-vertical-1">
            Show only the top offers in "Offered" status per item
          </div>
          <TextField
            label="Max number"
            name="qty"
            placeholder="3"
            className="cell small-12 margin-vertical-1"
            value={filterOptions.qty || ''}
            error={!!errorObj.qty}
            helperText={errorObj.qty || ''}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>Clear</TertiaryButton>
        <PrimaryButton onClick={handleSave}>APPLY</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default FilterTopOffers;
