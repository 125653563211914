export type PricingCategory =
  | 'import'
  | 'changes'
  | 'manual'
  | 'manualnochange'
  | 'unpriced'
  | 'all';

export enum ePricingCategory {
  import = 'import',
  changes = 'changes',
  manual = 'manual',
  manualNoChange = 'manualnochange',
  unpriced = 'unpriced',
  all = 'all',
}

export interface IDateRange {
  startDate: string;
  endDate: string;
}
export interface ICreateDateRange {
  offerDates: IDateRange | null;
  shippingDates: IDateRange | null;
}
export interface IDateRangeUpdate {
  dateRange: 'custom' | '28' | '14' | '7';
  demandLabel: string;
  from: string;
  inventoryLabel: string;
  to: string;
}
