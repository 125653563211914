import { cloneDeep, isEmpty } from 'lodash';
import * as types from '../actions/actionTypes';

const initState = {
  offersList: null,
  uniqueBuyers: {},
  buyerFilterLabel: null,
  inventoryMetricsForAllGroups: null,
  salesMetricsForAllGroups: null,
  uniqueCategories: null,
};
const getFilterSpecificData = (data: any) => {
  const uniqueBuyers = new Map(),
    buyerFilterLabel = new Map(),
    uniqueCategories = new Set(),
    uniqueWarehouses = new Set();

  if (isEmpty(data))
    return {
      uniqueBuyers: {},
      buyerFilterLabel: {},
      uniqueCategories: [],
      uniqueWarehouses: [],
    };

  data.forEach((itemList: any) => {
    uniqueCategories.add(itemList.category);
    uniqueWarehouses.add(itemList.warehouse);
    itemList.offers.forEach((offerVal: any) => {
      buyerFilterLabel.set(offerVal.buyerId, offerVal.buyerName);
      uniqueBuyers.set(offerVal.buyerId, true);
    });
  });

  return {
    uniqueBuyers: Object.fromEntries(uniqueBuyers),
    buyerFilterLabel: Object.fromEntries(buyerFilterLabel),
    uniqueCategories,
    uniqueWarehouses,
  };
};

export default function poClearingReducer(state: any = initState, action: any) {
  switch (action.type) {
    case types.PO_OFFER_CLEARING_SET:
      if (!action.payload) return { ...state, ...initState };
      const {
        uniqueBuyers,
        buyerFilterLabel,
        uniqueCategories,
        uniqueWarehouses,
      } = getFilterSpecificData(action.payload.items);
      return {
        ...state,
        offersList: action.payload.items,
        uniqueBuyers: uniqueBuyers,
        buyerFilterLabel: buyerFilterLabel,
        inventoryMetricsForAllGroups: action.payload.inventoryMetricsForAllGroups,
        salesMetricsForAllGroups: action.payload.salesMetricsForAllGroups,
        uniqueCategories,
        uniqueWarehouses,
      };

    case types.PO_OFFER_CLEARING_OFFER_UPDATE: {
      const values = action.payload.values;
      const offerList = cloneDeep(state.offersList);
      let currentOfferList: any = {};
      let currentOfferListIndex = 0;
      const offersList = cloneDeep(state.offersList);
      values?.forEach((value: any) => {
        currentOfferListIndex = offerList.findIndex(
          (offer: any) => offer.itemElectId === value.itemElectId
        );
        currentOfferList = offerList[currentOfferListIndex];
        // });
        const offers = currentOfferList.offers;
        if (!offers) {
          return { ...state };
        }
        // values?.forEach((value: any) => {
        const currentOfferIndex = offers.findIndex((offer: any) => offer.offerId === value.offerId);

        let currentOffer = offers[currentOfferIndex];
        currentOffer = {
          ...currentOffer,
          ...value,
        };
        offers[currentOfferIndex] = currentOffer;
        console.log(offers);
        // });
        offersList[currentOfferListIndex].offers = offers;
      });
      return { ...state, offersList };
      // return state;
    }
    default:
      return state;
  }
}
