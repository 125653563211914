import React from 'react';

interface IProps {
  className?: string;
}

const MultipleSelected = (props: IProps) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      fill="white"
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/multiple selected</title>
      <defs>
        <path
          d="M20,3 L4,3 C2.9,3 2,3.9 2,5 L2,19 C2,20.1 2.9,21 4,21 L20,21 C21.1,21 22,20.1 22,19 L22,5 C22,3.9 21.1,3 20,3 Z M10,17 L5,17 L5,15 L10,15 L10,17 Z M10,13 L5,13 L5,11 L10,11 L10,13 Z M10,9 L5,9 L5,7 L10,7 L10,9 Z M14.82,15 L12,12.16 L13.41,10.75 L14.82,12.17 L17.99,9 L19.41,10.42 L14.82,15 Z"
          id="path-1"></path>
      </defs>
      <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/multiple-selected">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use id="Shape" fill="#000000" fillRule="nonzero" xlinkHref="#path-1"></use>
          <g id="Group" mask="url(#mask-2)" fill="#757575">
            <g id="Color/Icon/Primary">
              <rect id="Color" x="0" y="0" width="24" height="24"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MultipleSelected;
