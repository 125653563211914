import { IAggFuncParams, RowNode } from 'ag-grid-community';
import { add, format } from 'date-fns';
import { filter, orderBy, sumBy } from 'lodash';
import moment from 'moment-timezone';
import { IItemSchedulingTab } from '../../types/IItemSetup';
import { offerStatusValues } from '../../types/IPoOffers';

import { awards } from '../../types/IPreOrders';
import { dataFormatting } from '../../_lib/lib';

interface IgetAggregatedSumOfFields {
  dataSet: awards[];
  field?: string;
  middlewareFn?: (item: awards) => number;
}

export interface ImanipulateDates {
  date: string;
  currentFormat?: string;
  formatTo: string;
  manipulate: Duration;
}

interface IgetLatestDate {
  dataSet: awards[];
  formatTo?: string;
}

interface filterData {
  data: any[];
  filterToApply: {
    [key: string]: string | boolean | number;
  };
}

interface GroupData {
  [key: string]: awards;
}

export enum dateFormats {
  yearMonthDate = 'yyyy-MM-dd',
  monthIn3Letters = 'PP',
  dateMonthIn3Letters = 'd LLL yyyy',
  timestamp = 'ccc LLL d y HH:mm:ss zzzz',
  monthDateYear = 'MM/dd/yyyy',
}

export interface FormatDatesByMonthDay {
  leftStartDate?: string;
  leftEndDate?: string;
  rightStartDate?: string;
  rightEndDate?: string;
}

export const monthNumbers: { [key: string]: number } = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
};

export const filterDataImpl = ({ data, filterToApply }: filterData) =>
  filter(data, filterToApply) as any[];

export const formatDateByMonthDay = (startDate?: string, endDate?: string) => {
  if (!!startDate && !!endDate) {
    const startDateObj = moment(startDate);
    const endDateObj = moment(endDate);
    if (startDateObj.month() === endDateObj.month()) {
      return `${startDateObj.format('MMM')} ${startDateObj.date()} - ${endDateObj.date()}`;
    } else {
      return `${startDateObj.format('MMM')} ${startDateObj.date()} - ${endDateObj.format(
        'MMM'
      )} ${endDateObj.date()}`;
    }
  }

  if (!!startDate) {
    const startDateObj = moment(startDate);
    return `${startDateObj.format('MMM')} ${startDateObj.date()}`;
  }

  if (!!endDate) {
    const endDateObj = moment(endDate);
    return `${endDateObj.format('MMM')} ${endDateObj.date()}`;
  }
  return '';
};

export const formatDateByMonthDayYear = (startDate?: string, endDate?: string) => {
  if (!!startDate && !!endDate) {
    const startDateObj = moment(startDate);
    const endDateObj = moment(endDate);
    if (startDateObj.month() === endDateObj.month() && startDateObj.year() === endDateObj.year()) {
      return `${startDateObj.format(
        'MMM'
      )} ${startDateObj.date()} - ${endDateObj.date()}, ${endDateObj.year()}`;
    } else if (
      startDateObj.month() !== endDateObj.month() &&
      startDateObj.year() === endDateObj.year()
    ) {
      return `${startDateObj.format('MMM')} ${startDateObj.date()} - ${endDateObj.format(
        'MMM'
      )} ${endDateObj.date()}, ${endDateObj.year()}`;
    } else if (
      startDateObj.month() === endDateObj.month() &&
      startDateObj.year() !== endDateObj.year()
    ) {
      return `${startDateObj.format(
        'MMM'
      )} ${startDateObj.date()}, ${startDateObj.year()} - ${endDateObj.date()}, ${endDateObj.year()}`;
    } else {
      return `${startDateObj.format(
        'MMM'
      )} ${startDateObj.date()}, ${startDateObj.year()} - ${endDateObj.format(
        'MMM'
      )} ${endDateObj.date()}, ${endDateObj.year()}`;
    }
  }
  if (!!startDate) {
    const startDateObj = moment(startDate);
    return `${startDateObj.format('MMM')} ${startDateObj.date()}, ${startDateObj.year()}`;
  }

  if (!!endDate) {
    const endDateObj = moment(endDate);
    return `${endDateObj.format('MMM')} ${endDateObj.date()}, ${endDateObj.year()}`;
  }
  return '--';
};

export const formatDatesByMonthDay = (dates: FormatDatesByMonthDay) => {
  const { leftStartDate, leftEndDate, rightStartDate, rightEndDate } = dates;
  if (Object.keys(dates).length === 0) return '--';
  let formattedDate = formatDateByMonthDay(leftStartDate, leftEndDate);
  if (!formattedDate) formattedDate = '--\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  formattedDate += '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0';
  formattedDate += formatDateByMonthDay(rightStartDate, rightEndDate);
  return formattedDate;
};

export const getAggregatedSumOfFields = ({
  dataSet,
  field,
  middlewareFn,
}: IgetAggregatedSumOfFields) => {
  let data = dataSet;
  if (!!field) {
    return sumBy(data, field) ?? 0.0;
  }
  return sumBy(data, middlewareFn) ?? 0.0;
};

const getDateRespectToFormat = (date: string, currentFormat: string) => {
  switch (currentFormat) {
    case dateFormats.yearMonthDate: {
      if (date.includes('/')) {
        const [year, month, day] = date.split('/');
        return { year: +year, month: +month - 1, day: +day };
      }
      let [year, month, day] = date.split('-');
      return { year: +year, month: +month - 1, day: +day };
    }
    case dateFormats.timestamp: {
      const splittedDate = date.split(' ');
      splittedDate.splice(0, 1);
      const [monthStr, day, year] = splittedDate;
      const month = monthNumbers[monthStr];
      return { year: +year, month, day: +day };
    }
    case dateFormats.monthIn3Letters: {
      const [monthStr, day, year] = date.split(' ');
      const month = monthNumbers[monthStr];
      return { month, year: +year, day: +day.slice(0, day.length - 1) };
    }
    default: {
      const currentDate = new Date(date);
      return {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        day: currentDate.getDate(),
      };
    }
  }
};

export const getFirstDayOfMonth = (date: Date) => {
  date.setDate(1);
  return date;
};

export const manipulateDates = ({
  date,
  currentFormat,
  formatTo,
  manipulate,
}: ImanipulateDates) => {
  if (!currentFormat || currentFormat === '')
    return format(add(new Date(date), manipulate), formatTo);
  const { year, month, day } = getDateRespectToFormat(date, currentFormat ?? '');
  const currentDate = new Date(year, month, day);
  return format(add(currentDate, manipulate), formatTo);
};

export const getLatestDate = ({
  dataSet,
  formatTo = dateFormats.yearMonthDate,
}: IgetLatestDate) => {
  let data = dataSet;
  if (data.length === 0) return '--';
  const sortedData = orderBy(data, ['shippingEndDate'], ['desc']);
  return manipulateDates({
    date: sortedData[0].shippingEndDate,
    manipulate: { days: 0 },
    currentFormat: dateFormats.yearMonthDate,
    formatTo,
  });
};

export const getDataToRecalculate = (data: awards) => {
  const {
    checkStateFromDetail,
    checkStateFromMaster,
    childIdsSelected,
    subGrid,
    subGridByItem,
    subGridByBuyer,
    setTakeLimitDisabled,
    isSubGrid,
    quantityRecommendedForFulfill,
    errorHandlers,
    ...currentData
  } = data;
  return currentData;
};

export const getDataToUpdate = (data: awards, getAll?: boolean) => {
  if (!!getAll) return data;
  const currentData = {
    itemElectId: data.itemElectId,
    buyerGrantId: data.buyerGrantId,
    shippingStartDate: data.shippingStartDate,
    shippingEndDate: data.shippingEndDate,
    awardedQuantity: data.awardedQuantity,
    awardedPrice: data.awardedPrice,
    isTakeAll: data.isTakeAll,
    takeAllLimitPercentage: data.takeAllLimitPercentage,
    quantityReadyToFulfill: data.quantityReadyToFulfill,
  };
  return currentData;
};

export const errorMessages = {
  updateAwards: {
    notNumeric: 'Value must be numeric',
    negativeNumber: 'Value must be > 0',
    notWholeNumber: 'Value must be >= 0 ',
    awardedValidation: 'Awarded cannot be changed to be < Ending Fulfilled',
    dateValidation: 'Date overlaps another shipping period for this item',
    takeAllBlankValidation: 'Cannot be changed to be blank when Take All is Yes',
    awardedBlankValidation: 'Awarded cannot be changed to be blank or < Ending Fulfilled',
    newFulfilledValidation: 'Sum of New Fulfilled across buyers cannot be > Starting Available',
    newFulfilledAwardedValidation: 'Ending Fulfilled exceeds awarded maximum',
    newFulfilledFundingValidation: 'Insufficient Funding Available',
    takeAllLimitValidation: 'Must be > 0 when Take All is Yes',
    default: 'Invalid value',
  },
  itemCountError: 'Found Sum of New Fulfilled across buyers cannot be > Starting Available for',
};

export const sanitizeData = (data: any, filterBy: string[]) => {
  const groupData: GroupData = {};
  data.forEach((item: any) => {
    const filterToApply: any = {};
    let topFilterKey = '';
    filterBy.forEach((value: any) => {
      filterToApply[value] = item[value];
      topFilterKey += item[value];
    });
    const subGrid = filterDataImpl({
      filterToApply,
      data: data,
    });
    const subGridByBuyer = filterDataImpl({
      data,
      filterToApply: { buyerNumber: item.buyerNumber },
    });
    const subGridByItem = filterDataImpl({
      data,
      filterToApply: { itemElectId: item.itemElectId },
    });
    item.subGridByBuyer = subGridByBuyer;
    item.subGrid = subGrid;
    item.subGridByItem = subGridByItem;
    item.isSubGrid = true;
    groupData[topFilterKey] = item;
  });
  return Object.values(groupData);
};

export const prefilledDateRangeOptions = [
  {
    id: 'month-to-date',
    label: 'Month to Date',
  },
  {
    id: 'seven-days-incl-today',
    label: 'Last 7 days + Today',
  },
  {
    id: 'fourteen-days-incl-today',
    label: 'Last 14 days + Today',
  },
  {
    id: 'twentyeight-days-incl-today',
    label: 'Last 28 days + Today',
  },
  {
    id: 'custom',
    label: 'Custom',
  },
];

export const confirmDialogRecalSubLines = ['Buyers Selected', 'Item Selected', 'Rows Selected'];

export const confirmDialogCOSubLines = ['Total Orders', 'Total Quantity', 'Total Price'];

export const alertMessageRecal =
  ' All selected rows will be recalculated even if your applied filters have hidden them from your current view';
export const alertMessageCO = 'Orders will be visible to buyers as soon as they are created';

export const orderOfItemSchedulingTabs = [
  'DRAFT',
  'SCHEDULED',
  'OFFERING',
  'NEGOTIATING',
  'FINALIZING',
  'SHIPPING',
];

export const awardedRule = (status: string) => {
  return status === offerStatusValues.awarded;
};
export const reofferRule = (status: string) => {
  return status === offerStatusValues.reoffer;
};
export const rejectedRule = (status: string) => {
  return status === offerStatusValues.rejected;
};
export const awaitingRule = (status: string) => {
  return (
    status === offerStatusValues.awaitingReview || status === offerStatusValues.reofferAwaiting
  );
};

export const groupValueGetter = (offerData: any) => {
  return {
    inventorMatricsAggFn: (params: IAggFuncParams) => {
      const groupName = params.rowNode.allLeafChildren[0]?.data.groupName || undefined;
      if (groupName === undefined || !offerData) return '';
      const key = params.column.getColId();
      if (!offerData.inventoryMetricsForAllGroups[groupName]) return null;
      return offerData.inventoryMetricsForAllGroups[groupName][key];
    },
    salesMatricsAggFn: (params: any) => {
      const groupName = params.rowNode.allLeafChildren[0]?.data.groupName || undefined;
      if (groupName === undefined || !offerData) return '';
      const key = params.column.colId;
      if (!offerData.salesMetricsForAllGroups[groupName]) return null;
      return offerData.salesMetricsForAllGroups[groupName][key];
    },
    daysOfInventoryItemGroup: (params: IAggFuncParams) => {
      const groupName = params.rowNode.allLeafChildren[0]?.data.groupName || undefined;
      const days = params.rowNode.allLeafChildren[0]?.data.inventoryReportingDays || undefined;
      if (
        groupName === undefined ||
        !offerData ||
        !offerData.inventoryMetricsForAllGroups[groupName] ||
        days === undefined
      )
        return '--';
      return null;
    },
    itemGroupABP: (params: any) => {
      const groupName = params.rowNode.allLeafChildren[0]?.data.groupName || undefined;
      if (groupName === undefined || !offerData) return '';
      return offerData.salesMetricsForAllGroups[groupName]?.abpInPeriod;
    },
    itemGroupASP: (params: any) => {
      const groupName = params.rowNode.allLeafChildren[0]?.data.groupName || undefined;
      if (groupName === undefined || !offerData) return '';
      return offerData.salesMetricsForAllGroups[groupName]?.aspInPeriod;
    },
    itemGroupPricingABP: (params: any) => {
      const groupName = params.rowNode.allLeafChildren[0]?.data.groupName || undefined;
      if (groupName === undefined || !offerData) return '';
      return {
        abpInPeriod: offerData.salesMetricsForAllGroups[groupName]?.abpInPeriod,
        aggregatedSalesTotalDemand:
          offerData.salesMetricsForAllGroups[groupName].offersInPeriod +
          offerData.salesMetricsForAllGroups[groupName].bookedShippedInPeriod +
          offerData.salesMetricsForAllGroups[groupName].soldInPeriod,
      };
    },
  };
};
export const getOfferedItems = (offerArr: Array<any>) => {
  return offerArr.filter((offer) => awaitingRule(offer.status)).map((offer) => offer.offerId);
};
export const formattingNumber = (value: any) => {
  return `${
    value === undefined || value === null
      ? '--'
      : value?.toLocaleString('en', {
          useGrouping: true,
          minimumFractionDigits: 0,
        }) || value
  }`;
};

export const getBulkUpdateDataObj = (selectedNodes: any, checkItemState: any) => {
  let selectedQuantity: number = 0,
    availableQuantity: number = 0,
    offerRange = {
      offerLowPrice: Infinity,
      offerHighPrice: -Infinity,
    },
    buyers: any = {},
    skus: number = 0,
    reOfferDisabledText = '',
    isAwardDisabled = false,
    offers: any = [];
  const bulkUpdateObject: any = {};
  selectedNodes.forEach((itemData: any) => {
    availableQuantity += itemData.quantityAvailable || 0;
    skus += 1;
    if (itemData.itemStatus === IItemSchedulingTab.finalizing)
      reOfferDisabledText = 'must be single SKU';
    if (Array.isArray(itemData.offers) && Array.isArray(itemData.childIdsSelected)) {
      itemData.childIdsSelected.forEach((selectedOfferId: number) => {
        const selectedOffer = itemData.offers.find(
          (offer: any) => selectedOfferId === offer.offerId
        );
        if (!selectedOffer) return;
        bulkUpdateObject[selectedOfferId] = selectedOffer;
        buyers[selectedOfferId] = true;
        if (selectedOffer.offerQuantity > selectedOffer.quantityAvailable) isAwardDisabled = true;
        selectedQuantity += selectedOffer.offerQuantity || 0;
        const offerPrice = selectedOffer.offerPrice || 0;
        if (offerPrice <= offerRange.offerLowPrice) {
          offerRange.offerLowPrice = offerPrice;
        }
        if (offerPrice >= offerRange.offerHighPrice) {
          offerRange.offerHighPrice = offerPrice;
        }
        offers.push(selectedOffer);
      });
    }
  });
  if (skus > 1) reOfferDisabledText = 'must be single SKU';
  if (availableQuantity === 0) reOfferDisabledText = 'nothing Available';
  const difference = availableQuantity - selectedQuantity;
  if (difference < 0) isAwardDisabled = true;

  const offerRangeStr = `${
    offerRange.offerLowPrice === Infinity
      ? '--'
      : dataFormatting('currency', offerRange.offerLowPrice)
  } to ${
    offerRange.offerHighPrice === -Infinity
      ? '--'
      : dataFormatting('currency', offerRange.offerHighPrice)
  }`;

  const avgPriceStr = `${dataFormatting('currency', checkItemState.avg ?? '--')}`;

  return {
    selectedQuantity,
    availableQuantity,
    difference: difference ?? '--',
    avgPrice: avgPriceStr,
    offerRange: offerRangeStr,
    highestOfferPrice: offerRange.offerHighPrice === -Infinity ? 0 : offerRange.offerHighPrice,
    buyers: Object.keys(buyers).length,
    skus,
    reOfferDisabledText,
    isAwardDisabled,
    offers,
  };
};

export const revNumberComparator = (valA: number, valB: number, reverse?: boolean) => {
  if (valA === valB) return 0;
  const res = valA > valB ? 1 : -1;
  return reverse ? (res === 1 ? -1 : 1) : res;
};

export const offerPriceRevComparatorNew = (arr: Array<string>, ascending: boolean) => (
  dataA: any,
  dataB: any
) => {
  const priceCmp: any = (index: number) => {
    const val = arr[index];
    const res = revNumberComparator(dataA[val], dataB[val], ascending);
    if (typeof dataA[val] === 'string')
      return ascending
        ? stringCmpCollator.compare(dataA[val], dataB[val])
        : stringCmpCollator.compare(dataB[val], dataA[val]);
    if (index > arr.length - 1) return res;
    if (res === 0) return priceCmp(index + 1);
    return res;
  };
  return priceCmp(0);
};

export const stringCmpCollator = new Intl.Collator('en');

export const groupNameSort = (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode) => {
  let valA = valueA || '',
    valB = valueB || '';
  return stringCmpCollator.compare(valA, valB);
};

export const offerDateSort = (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode) => {
  let valA = valueA || '',
    valB = valueB || '';
  if (nodeA.group) valA = nodeA.childrenAfterFilter[0].data.offerStartDate;
  if (nodeB.group) valB = nodeB.childrenAfterFilter[0].data.offerStartDate;
  return moment(valA) > moment(valB) ? 1 : moment(valA) > moment(valB) ? -1 : 0;
};

export const shippingDateSort = (
  valueA: string,
  valueB: string,
  nodeA: RowNode,
  nodeB: RowNode
) => {
  let valA = valueA || '',
    valB = valueB || '';
  if (nodeA.group) valA = nodeA.childrenAfterFilter[0].data.offerEndDate;
  if (nodeB.group) valB = nodeB.childrenAfterFilter[0].data.offerEndDate;
  return moment(valA) > moment(valB) ? 1 : moment(valA) > moment(valB) ? -1 : 0;
};

export const convertSecondsToTime = (
  secondsToAdd: number,
  formatToConvert: string,
  date?: string,
  dateFormat?: string
) => {
  if (!!date && !!dateFormat)
    return moment(date, dateFormat).startOf('day').seconds(secondsToAdd).format(formatToConvert);
  return moment().startOf('day').seconds(secondsToAdd).format(formatToConvert);
};

export const getSecondsFromStartOfToday = (date: any, formatTo: string) => {
  return moment(date, formatTo).diff(moment().startOf('day'), 'seconds');
};
