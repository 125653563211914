import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as preorderBuyerActions from '../../redux/actions/preorderBuyerActions';

import { cloneDeep, isEmpty } from 'lodash';

import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';

import { InputAdornment, Tab, Tabs } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';

import { checkNullOrUndefined, dataFormatting, searchItems } from '../../_lib/lib';
import apiToUrlMap from '../../ApiMapping';
import useDebounce from '../../hooks/useDebounce';
import useDataService from '../../hooks/useDataService';

import { getTableProperties } from '../../components/PreOrders/TablePropertiesBuyer/TableProperties';
import { dateFormats, manipulateDates } from '../../components/PreOrders/Utils';
import DateRangeAnalyticsSelector from '../../components/PreOrders/DateRangeAnalyticsSelector';
import DateSelectorComponent from '../../components/PreOrders/DateSelectorComponent';
import { TextField } from '../../components/AtomComponents';
import { ColsDef } from '../../components/PreOrders/TablePropertiesBuyer/ColsDef';

import { tabIndexStates } from '../../types/IPreOrders';
import { IDateRange } from '../../types/IPricing';
import { IFilterSaveParams } from '../../types/IFilterEngine';

import './PreOrders.scss';
import { customSorting } from '../../components/PreOrders/TableCellComponents';
import ReactGA from 'react-ga';

LicenseManager.setLicenseKey(
  'CompanyName=Phonex Holdings Inc,LicensedApplication=PhoneX SaaS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=3,AssetReference=AG-012160,ExpiryDate=1_December_2021_[v2]_MTYzODMxNjgwMDAwMA==f1ae693cebbed365cb2597d53a5021d2'
);

const PreOrdersBuyer = ({ preorderBuyerState, preorderBuyerActions, userState }: any) => {
  // Ag Grid and Helper States
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColApi, setGridColApi] = useState<ColumnApi | null>(null);
  const { awardsOpenObj, awardsClosedObj } = preorderBuyerState;

  // Seach Bar States
  const [searchText, setSearchText] = useState('');
  const debounce = useDebounce(searchText, 900);

  const { fetchUrl } = useDataService();
  const [refetchFromRemote, setRefetchFromRemote] = useState<boolean>(false);

  // Tab Maintenance
  const [tabIndexState, setTabIndexState] = useState<tabIndexStates>(0);

  // ColDefs
  const { buyerOpenColsDef, buyerClosedColsDef } = ColsDef();

  //#region >> DateRangeSelector manager >>
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: manipulateDates({
      date: new Date().toString(),
      formatTo: dateFormats.monthIn3Letters,
      currentFormat: dateFormats.timestamp,
      manipulate: { days: -90 },
    }),
    endDate: manipulateDates({
      date: new Date().toString(),
      formatTo: dateFormats.monthIn3Letters,
      currentFormat: dateFormats.timestamp,
      manipulate: { days: 0 },
    }),
  });
  const [dateRangeDialog, setDateRangeDialog] = useState<any>(false);
  const closeDateRangeSelector = () => setDateRangeDialog(false);
  const clearDateRangeSelector = () => {
    setDateRange({
      startDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.monthIn3Letters,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: -90 },
      }),
      endDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.monthIn3Letters,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
    });
    setRefetchFromRemote(true);
    setDateRangeDialog(false);
  };
  const openDateRangeSelector = () => {
    setDateRangeDialog({
      attribute: 'date',
      title: 'Date Select',
    });
  };

  const updateCustomDate = ({ selectedFilters }: IFilterSaveParams) => {
    if (selectedFilters.startDate && selectedFilters.endDate) {
      setDateRange({
        startDate: manipulateDates({
          date: selectedFilters.startDate,
          formatTo: dateFormats.dateMonthIn3Letters,
          manipulate: { days: 0 },
        }),
        endDate: manipulateDates({
          date: selectedFilters.endDate,
          formatTo: dateFormats.dateMonthIn3Letters,
          manipulate: { days: 0 },
        }),
      });
    }
    setRefetchFromRemote(true);
    closeDateRangeSelector();
  };
  //#endregion << DateRangeSelector manager <<

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' | PO Awards';
  }, []);

  useEffect(() => {
    if (isEmpty(userState)) return;
    if (tabIndexState === 0 && (!awardsOpenObj || refetchFromRemote)) {
      const getAwards = async () => {
        const preorderAwards = await getPreorderBuyerAwards('open');
        preorderBuyerActions.awardsSet(cloneDeep(preorderAwards));
      };
      getAwards();
    }
    if (tabIndexState === 1 && (!awardsClosedObj || refetchFromRemote)) {
      const getAwardsClosed = async () => {
        const preorderAwardsClosed = await getPreorderBuyerAwards(
          'closed',
          manipulateDates({
            date: dateRange.startDate,
            formatTo: dateFormats.yearMonthDate,
            manipulate: { days: 0 },
          }),
          manipulateDates({
            date: dateRange.endDate,
            formatTo: dateFormats.yearMonthDate,
            manipulate: { days: 0 },
          })
        );
        preorderBuyerActions.awardsClosedSet(cloneDeep(preorderAwardsClosed));
      };
      getAwardsClosed();
    }
    setRefetchFromRemote(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awardsOpenObj, awardsClosedObj, preorderBuyerActions, tabIndexState, dateRange]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    if (!gridApi) return;
    const sortOpen = [
      { colId: 'daysRemaining', sort: 'asc', sortIndex: 1 },
      { colId: 'phonexItemDescription', sort: 'asc', sortIndex: 2 },
    ];
    const sortClosed = [
      { colId: 'closedDate', sort: 'desc', sortIndex: 1, comparator: customSorting },
      { colId: 'phonexItemDescription', sort: 'asc', sortIndex: 2 },
    ];
    if (tabIndexState === 0 && awardsOpenObj) {
      gridApi.setColumnDefs(buyerOpenColsDef);
      gridApi.setRowData(awardsOpenObj.awards ?? []);
      gridColApi?.applyColumnState({
        state: sortOpen,
      });
    }
    if (tabIndexState === 1 && awardsClosedObj) {
      gridApi.setColumnDefs(buyerClosedColsDef);
      gridApi.setRowData(awardsClosedObj.awards ?? []);
      gridColApi?.applyColumnState({ state: sortClosed });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndexState, gridApi, awardsOpenObj, awardsClosedObj]);

  useEffect(() => {
    if (tabIndexState === 0 && (!awardsOpenObj || !awardsOpenObj.awards)) return;
    if (tabIndexState === 1 && (!awardsClosedObj || !awardsClosedObj.awards)) return;
    onStartSearchAgGrid({}, debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, tabIndexState, awardsClosedObj, awardsOpenObj]);

  const getPreorderBuyerAwards = async (
    status: string,
    shippingStartDate?: string,
    shippingEndDate?: string
  ) => {
    try {
      const { buyerId } = userState;
      gridApi?.showLoadingOverlay();
      const params =
        status === 'open'
          ? { buyerId, status }
          : {
              buyerId,
              status,
              startDate: shippingStartDate,
              endDate: shippingEndDate,
            };
      const res: any = await fetchUrl('get', apiToUrlMap.getAwardsBuyer, {
        params,
      });
      gridApi?.hideOverlay();
      return res;
    } catch (error) {
      gridApi?.hideOverlay();
      console.log('error while dummy auth call', error);
      return [];
    }
  };

  //#region >> SearchBar manager >>
  const onStartSearchAgGrid = async (e?: any, val?: string) => {
    if (checkNullOrUndefined(val)) return;
    if (e?.preventDefault) e.preventDefault();
    // search through the users
    // gridApi?.setQuickFilter(val);
    const searchItemList = tabIndexState === 0 ? awardsOpenObj.awards : awardsClosedObj.awards;
    const searchedCustomers = searchItems({
      searchTermState: debounce,
      view: 'preorderBuyer',
      itemsList: searchItemList,
      settings: {},
    });
    if (gridApi?.setRowData) gridApi.setRowData(searchedCustomers);
  };

  const onChangeSearchText = (e: any) => {
    e?.preventDefault();
    setSearchText(e.target?.value);
  };
  //#endregion << SearchBar manager <<

  const tabChange = (event: any, newValue: number) => {
    setTabIndexState(newValue);
  };

  // Set GridApi to Local State
  const onGridReady = (params: GridOptions) => {
    setGridApi(params.api ?? null);
    setGridColApi(params.columnApi ?? null);
  };

  const { gridOptions } = getTableProperties(onGridReady);
  const getContext = useMemo(
    () => ({ config: JSON.parse(localStorage.getItem('PxConfig') || '{}') }),
    []
  );

  return (
    <div className="grid-x po-main-buyer">
      <h2 className="cell small-12">PO Awards</h2>
      <div className="cell small-12 margin-bottom-1">
        <TextField
          // label="Search"
          size="small"
          style={{ width: '30%' }}
          data-id="searchBar"
          placeholder="Search Item Description"
          value={searchText}
          onChange={onChangeSearchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchText ? (
              <InputAdornment
                className="pointer-mouse"
                position="end"
                onClick={() => setSearchText('')}>
                <CloseIcon />
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
        />
      </div>
      <div className="cell small-12">
        <Tabs
          value={tabIndexState}
          onChange={tabChange}
          aria-label="simple tabs example"
          indicatorColor="secondary"
          textColor="secondary"
          color="primary"
          className="px-inventory-tabs">
          <Tab label="Open" color="primary" data-id="openTab" />
          <Tab label="Closed" color="primary" data-id="closedTab" />
        </Tabs>
      </div>
      <DateRangeAnalyticsSelector
        dateRangeDialog={dateRangeDialog}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        updateCustomDate={updateCustomDate}
        clearDateRangeSelector={clearDateRangeSelector}
        closeDateRangeSelector={closeDateRangeSelector}
        type="fulfillmentBuyer"
        disableDates={{
          endDate: {
            after: manipulateDates({
              date: new Date().toString(),
              manipulate: { days: 0 },
              formatTo: dateFormats.monthDateYear,
              currentFormat: dateFormats.timestamp,
            }),
          },
        }}
      />
      <div className="cell small-12 margin-top-3">
        {tabIndexState === 0 && awardsOpenObj && (
          <div className="grid-x cell small-5 margin-top-1 margin-bottom-2" id="calc-min-width">
            <span className="cell small-5 text-normal margin-top-1">
              Awards Pending Fulfillment:{' '}
            </span>
            <span className="cell small-7 margin-top-1">
              {dataFormatting('currency', awardsOpenObj.totalPendingFulfillmentPrice, true)}
            </span>
          </div>
        )}
        {tabIndexState === 1 && (
          <div className="margin-top-2">
            <DateSelectorComponent
              dateRange={dateRange}
              openDateRangeSelector={openDateRangeSelector}
            />
          </div>
        )}
        <div className="ag-theme-alpine px-fulfillment-table px-min-width-buyer">
          <AgGridReact gridOptions={gridOptions} context={getContext} />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  preorderBuyerState: state.preorderBuyerState,
  userState: state.userState,
});

const mapDispatchToProps = (dispatch: any) => ({
  preorderBuyerActions: bindActionCreators(preorderBuyerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreOrdersBuyer);
