import React, { useState } from 'react';

import Link from '@material-ui/core/Link';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

const uatViewLinkEndpoints: any = {
  stockList: 'stock-list',
  poOffers: 'po-offers',
  auction: 'auction',
  orders: 'orders',
  awardedBids: 'awarded-bids',
  poAwards: '/',
  accountBalance: 'customer-account',
  notificationPreference: 'notification/preference',
  shipBillPreference: 'preference/review',
};

const externalLinks = {
  gradingScale: 'https://hylaasp.hylamobile.com/grading-scale/',
  help: 'https://hyla.zendesk.com/hc/en-us',
};

const accountMenuLabels: any = {
  orders: 'Orders',
  awardedBids: 'Awarded Bids',
  // poAwards: 'PO Awards',
  accountBalance: 'Account Balance',
  notificationPreference: 'Notification Preference',
  shipBillPreference: 'Ship/Bill Preference',
};

const poMenuLabels: any = {
  poOffers: 'PO Offers',
  poAwards: 'PO Awards',
};

export const TopMenuBuyerNavItems = ({
  groupClassName,
  menuAnchorPosition,
  menuTransformOrigin,
  configState,
  openLogoutDialog,
  showPoOffers,
}: any) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openAccountMenu = (event: any) => setAnchorElement(event.target);
  const closeAccountMenu = () => setAnchorElement(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={`${groupClassName}-group1`}>
        <Link
          component="a"
          href={`${configState?.business_buyer_site}/${uatViewLinkEndpoints.stockList}`}
          underline="none"
          className="po-topnav-links">
          STOCK LIST
        </Link>
        <Link
          component="a"
          underline="none"
          href={`${configState?.business_buyer_site}/${uatViewLinkEndpoints.auction}`}
          className="po-topnav-links">
          AUCTION
        </Link>
        {/* {showPoOffers && (
          <Link
            component="a"
            underline="none"
            href={uatViewLinkEndpoints.poOffers}
            className="po-topnav-links">
            PO OFFERS
          </Link>
        )} */}
        <Button id="po-topnav-account" onClick={handleClick} disableRipple>
          PO
          <ArrowDropDown />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          keepMounted={false}
          getContentAnchorEl={null}
          anchorOrigin={menuAnchorPosition}
          transformOrigin={menuTransformOrigin}
          onClose={handleClose}>
          {Object.keys(poMenuLabels).map((accountKey: any) => {
            const redirectLink = `${uatViewLinkEndpoints[accountKey]}`;
            return (
              <MenuItem
                key={accountKey}
                href={redirectLink}
                onClick={() => {
                  setAnchorEl(null);
                  window.location.href = redirectLink;
                }}
                className="po-topnav-links">
                {poMenuLabels[accountKey]}
              </MenuItem>
            );
          })}
        </Menu>
        <Link
          component="a"
          underline="none"
          href={externalLinks.gradingScale}
          target="_blank"
          rel="noopener"
          className="po-topnav-links">
          GRADING SCALE
        </Link>
      </div>
      <div className={`${groupClassName}-group2`}>
        <Link
          component="a"
          underline="none"
          href={externalLinks.help}
          target="_blank"
          rel="noopener"
          className="po-topnav-links">
          HELP
        </Link>
        <Button id="po-topnav-account" onClick={openAccountMenu} disableRipple>
          Account
          <ArrowDropDown />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorElement}
          keepMounted={false}
          getContentAnchorEl={null}
          anchorOrigin={menuAnchorPosition}
          transformOrigin={menuTransformOrigin}
          open={!!anchorElement}
          onClose={closeAccountMenu}>
          {Object.keys(accountMenuLabels).map((accountKey: any) => {
            const redirectLink = `${configState?.business_buyer_site}/${uatViewLinkEndpoints[accountKey]}`;
            return (
              <MenuItem
                component="a"
                key={accountKey}
                href={redirectLink}
                onClick={closeAccountMenu}
                className="po-topnav-links">
                {accountMenuLabels[accountKey]}
              </MenuItem>
            );
          })}
        </Menu>
        <Link
          component="button"
          underline="none"
          className="po-topnav-links"
          onClick={openLogoutDialog}>
          LOGOUT
        </Link>
      </div>
    </>
  );
};
