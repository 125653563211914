import * as types from './actionTypes';

export function stockSet(stock: any, isComplete: Boolean) {
  return { type: types.STOCK_SET, stock, isComplete };
}

export function stockSettingsSet(settings: any) {
  return { type: types.STOCKLIST_SETTINGS_SET, settings };
}

export function stockOffersSet(offers: any) {
  return { type: types.STOCKLIST_OFFERS_SET, offers };
}
