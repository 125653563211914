import React from 'react';
import { ICloseFilterDialogParams, IFilterSaveParams } from '../../../types/IFilterEngine';
import { IOfferTimeRange } from '../../../types/IItemSetup';
import { ICreateDateRange } from '../../../types/IPricing';
import DateRangeSelector from './DateRangeSelector';
interface IDateRangeAnalyticsSelector extends ICreateDateRange {
  dateRangeDialog: boolean;
  updateCustomDate: (params: IFilterSaveParams) => void;
  closeDateRangeSelector: (params: ICloseFilterDialogParams) => void;
  clearDateRangeSelector: (params: ICloseFilterDialogParams) => void;
  disableStartBefore?: string;
  type?: string;
  disableDates?: {
    endDate?: {
      after?: string;
    };
  };
  setLoadingDialog: any;
  overLappingShippingDates: any;
  daysToFinalize: number;
  offeringTimeRange: IOfferTimeRange;
}
function CreateDateAnalyticsSelector({
  dateRangeDialog,
  offerDates,
  shippingDates,
  updateCustomDate,
  closeDateRangeSelector,
  clearDateRangeSelector,
  disableStartBefore,
  disableDates,
  type,
  setLoadingDialog,
  overLappingShippingDates,
  daysToFinalize,
  offeringTimeRange,
}: IDateRangeAnalyticsSelector) {
  let customObj: any = {
    type,
    offerDates,
    shippingDates,
  };
  if (!dateRangeDialog) {
    customObj = null;
  }

  return (
    <DateRangeSelector
      customOptionObj={customObj}
      saveFilter={updateCustomDate}
      closeDialog={closeDateRangeSelector}
      clearFilter={clearDateRangeSelector}
      disableStartBefore={disableStartBefore}
      disableBothDates={disableDates}
      setLoadingDialog={setLoadingDialog}
      overLappingShippingDates={overLappingShippingDates}
      daysToFinalize={daysToFinalize}
      offeringTimeRange={offeringTimeRange}
    />
  );
}

export default CreateDateAnalyticsSelector;
