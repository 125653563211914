import { FormControlLabel } from '@material-ui/core';
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IAggFuncParams,
  RowNode,
  RowSelectedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CustomHeader } from '../../views/PoClearing/CustomHeader';
import DetailGrid from '../../views/PoClearing/DetailGrid';
import PricingStats from '../../views/PoClearing/PricingStats';
import { dataFormatting, poClearingColWidth } from '../../_lib/lib';
import { CollapseIcon, ExpandIcon, PrimaryButton, PrimaryCheckbox } from '../AtomComponents';
import MultipleSelected from '../ItemSetup/MultipleSelected';
import ShippingWindow from '../ItemSetup/ShippingWindow';
import {
  awaitingRule,
  formatDateByMonthDay,
  formattingNumber,
  getBulkUpdateDataObj,
  getOfferedItems,
  groupValueGetter,
  offerDateSort,
  shippingDateSort,
  stringCmpCollator,
} from '../PreOrders/Utils';
import BulkUpdatesOffers from './BulkUpdatesOffers';
import PricingChart from './PricingChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { LoadingComponent } from '../PreOrders/TableCellComponents';
import { LastDateRangeSelector } from './LastDateRangeSelector';
import { differenceInDays } from 'date-fns';
import { IOffer, IOffersAndAnalytics, IPoClearingTabs } from '../../types/IPoClearing';
import { IBulkData, IDataRange, IItems } from '../../types/IOfferClearing';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

interface IClearingGrid {
  itemsState: IOffersAndAnalytics;
  dateRangeState: {
    dateRange: IDataRange;
    setDateRange: any;
  };
  getShippingWindow: (itemElectId: number) => {};
  offeredTabState: {
    current: number;
  };
  currentTab: IPoClearingTabs;
  updateOffer: (offer: IOffer[], refetchFromRemote?: boolean) => Promise<any>;
  setLoadingDialog: any;
  fetchOfferClearing: any;
  canEdit: boolean;
}
interface ICheckItems {
  avg: any;
  count: number;
  eventSource: string | null;
  qty: number;
  status: boolean;
}

interface ISelectedRows extends IItems {
  childIdsSelected: number[];
}
const initialCheckItemState = {
  count: 0,
  qty: 0,
  avg: 0,
  status: false,
  eventSource: null,
};

const PoClearingGrid = forwardRef(
  (
    {
      itemsState,
      dateRangeState,
      getShippingWindow,
      offeredTabState,
      currentTab,
      updateOffer,
      setLoadingDialog,
      canEdit,
    }: IClearingGrid,
    ref: any
  ) => {
    const [gridAPI, setGridAPI] = useState<GridApi>();
    const [checkItemsState, setCheckItemsState] = useState<ICheckItems>(initialCheckItemState);
    const [bulkUpdateOffersDialog, setBulkUpdateOffersDialog] = React.useState<boolean>(false);
    const [bulkUpdateData, setBulkUpdateData] = React.useState<IBulkData>({
      availableQuantity: 0,
      avgPrice: '',
      buyers: 0,
      difference: 0,
      highestOfferPrice: 0,
      reOfferDisabledText: '',
      isAwardDisabled: false,
      offerRange: '',
      offers: [],
      selectedQuantity: 0,
      skus: 0,
    });
    const openBulkUpdateOffersDialog = () => {
      const selectedRows: ISelectedRows[] = [];
      gridAPI?.forEachNode((node: any) => {
        if (!node.group && (node.selected === true || node.selected === undefined))
          selectedRows.push(node.data);
      });
      const bulkUpdateObj = getBulkUpdateDataObj(selectedRows, checkItemsState);
      setBulkUpdateData(bulkUpdateObj);
      setBulkUpdateOffersDialog(true);
    };
    const closeBulkUpdateOffersDialog = () => setBulkUpdateOffersDialog(false);

    const [menuOptionOpen, setMenuOptionOpen] = React.useState<any>();
    const [menuOptionDimensions, onMenuOptionDimensions] = React.useState<{
      top: number;
      left: number;
    }>({ top: 0, left: 0 });

    let itemsData = {
      inventoryMetricsForAllGroups: itemsState.inventoryMetricsForAllGroups,
      salesMetricsForAllGroups: itemsState.salesMetricsForAllGroups,
      items: itemsState.items,
    };

    const openPricingAvgGrid = (props: any) => {
      props.column.setColumnGroupOpened('Demand', false);
      let { left, bottom } = (props.event?.target! as Element).getBoundingClientRect();

      left = left + 100;
      let top = bottom + 10;

      onMenuOptionDimensions({ top, left });
      setTimeout(() => {
        const groupKey = props.data.groupName;
        setMenuOptionOpen({
          currentTarget: props.event!.target,
          data: { ...props.data },
          demandData: {
            ...itemsData?.salesMetricsForAllGroups[groupKey],
          },
        });
      }, 100);
    };

    const menuOptionClose = () => {
      setMenuOptionOpen(null);
    };

    useImperativeHandle(ref, () => {
      return {
        setBulkUpdateCount0() {
          setCheckItemsState(initialCheckItemState);
        },
        reDrawGrid() {
          gridAPI?.timeFullRedraw(2000);
        },
        showLoadingOverlay() {
          if (gridAPI) {
            gridAPI.showLoadingOverlay();
          }
        },
        hideLoadingOverlay() {
          if (gridAPI) {
            gridAPI.hideOverlay();
          }
        },
      };
    });

    const startDate = new Date(dateRangeState.dateRange.startDate);
    const endDate = new Date(dateRangeState.dateRange.endDate);
    const dateFormats = {
      startDate: 'd LLL yyyy',
      endDate: 'd LLL yyyy',
    };
    if (startDate.getFullYear() !== endDate.getFullYear()) {
      dateFormats.startDate = 'd LLL yyyy';
    }
    const {
      salesMatricsAggFn,
      inventorMatricsAggFn,
      itemGroupABP,
      itemGroupASP,
    } = groupValueGetter(itemsState);

    useEffect(() => {
      if (
        itemsState &&
        itemsState.inventoryMetricsForAllGroups &&
        itemsState.salesMetricsForAllGroups &&
        gridAPI
      ) {
        let columnDefs: Array<ColDef | ColGroupDef> = [
          {
            headerName: 'Group',
            field: 'groupName',
            checkboxSelection: (params: any) => {
              return params.node.group;
            },
            valueFormatter: (params: ValueFormatterParams) => {
              if (params.node.groupData && params.node.group) {
                return params.node.groupData['ag-Grid-AutoColumn'].replaceAll('__', ' / ');
              }
              return `-- ${params.value}`;
            },
            rowGroup: true,
            enableRowGroup: true,
            sortable: true,
            hide: true,
            cellStyle: { padding: '0 !important' },
          },
          {
            valueGetter: (params: ValueGetterParams) =>
              `${params?.data?.color} [${params?.data?.itemNumber}]`,
            hide: true,
            maxWidth: 10,
            sortable: true,
            sort: 'asc',
            sortIndex: 1,
            comparator: (a: string, b: string) => {
              return stringCmpCollator.compare(a, b);
            },
          },
          {
            headerName: '',
            minWidth: 60,
            maxWidth: 60,
            sortable: false,
            cellRendererFramework: (params: ICellRendererParams) => {
              if (params.node && !params.node.group && params.data.IsShipping) {
                return <ShippingWindow getShippingWindow={getShippingWindow} data={params.data} />;
              }
              return <></>;
            },
            cellStyle: { padding: '0 !important' },
          },
          {
            headerName: 'Available to Award',
            colId: 'availableToAward',
            field: 'quantityAvailable',
            minWidth: 420,
            maxWidth: 420,
            aggFunc: (params: IAggFuncParams) => {
              let sum = 0;
              params.values.forEach((value: any) => (sum += value));
              return sum;
            },
            valueFormatter: (params: ValueFormatterParams): any => {
              return params.value;
            },
            cellStyle: { padding: '0 !important' },
          },
          {
            headerName: 'Awarded',
            colId: 'awarded',
            field: 'awardedQuantity',
            minWidth: 140,
            maxWidth: 140,
            aggFunc: (params: IAggFuncParams) => {
              let sum = 0;
              params.values.forEach((value: any) => (sum += value));
              return sum;
            },
            valueFormatter: (params: ValueFormatterParams): any => {
              return params.value;
            },
            cellStyle: { padding: '0 !important' },
          },
          {
            headerName: '$ Average Award Price',
            field: 'awardedAveragePrice',
            aggFunc: 'avg',
            minWidth: 140,
            valueFormatter: (params: ValueFormatterParams): any => {
              return dataFormatting('currency', params.value);
            },
          },
          {
            headerName: 'Offer Dates',
            valueGetter: (params: ValueGetterParams) => {
              if (!params.data) {
                return '';
              }
              return formatDateByMonthDay(params.data.offerStartDate, params.data.offerEndDate);
            },
            valueFormatter: (params: ValueFormatterParams): any => {
              return params.value;
            },
            comparator: offerDateSort,
            cellStyle: { padding: '0 !important' },
            width: 150,
            minWidth: 150,
          },
          {
            headerName: 'Ship Dates',
            valueGetter: (params: ValueGetterParams) => {
              if (!params.data) {
                return '';
              }
              return formatDateByMonthDay(
                params.data.shippingStartDate,
                params.data.shippingEndDate
              );
            },
            valueFormatter: (params: ValueFormatterParams): any => {
              return params.value;
            },
            comparator: shippingDateSort,
            cellStyle: { padding: '0 !important' },
            width: 150,
            minWidth: 150,
          },
          {
            headerName: 'Last 7 Days + Today',
            headerGroupComponentFramework: LastDateRangeSelector,
            headerGroupComponentParams: {
              dateRangeState: dateRangeState,
            },
            headerClass: 'px-po-grid-border-left px-po-clearing-last-days clickable',
            children: [
              {
                headerName: 'Demand',
                groupId: 'Demand',
                openByDefault: true,
                headerClass: 'px-po-grid-border-left demandHeader',
                children: [
                  {
                    headerName: '',
                    headerClass: 'px-po-grid-border-left',
                    cellRendererFramework: PricingStats,
                    cellRendererParams: {
                      openPricingAvgGrid,
                    },
                    cellStyle: { borderLeft: '1px solid #aaa' },
                    maxWidth: 25,
                    width: 25,
                    minWidth: 25,
                  },
                  {
                    headerName: '$ Average Booked Price',
                    field: 'analytics.salesAbp',
                    colId: 'abpInPeriod',
                    aggFunc: itemGroupABP,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return dataFormatting('currency', params.value);
                    },
                    cellStyle: { padding: '0 0 0 10px !important' },
                    maxWidth: poClearingColWidth.ABP,
                    width: poClearingColWidth.ABP,
                    minWidth: poClearingColWidth.ABP,
                  },
                  {
                    headerName: '$ Average Shipped Price',
                    field: 'analytics.salesAsp',
                    colId: 'aspInPeriod',
                    columnGroupShow: 'closed',
                    aggFunc: itemGroupASP,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return dataFormatting('currency', params.value);
                    },
                    cellStyle: { padding: '0 10px !important' },
                    maxWidth: poClearingColWidth.ASP,
                    width: poClearingColWidth.ASP,
                    minWidth: poClearingColWidth.ASP,
                  },
                  {
                    headerName: 'Offered',
                    field: 'analytics.salesOffered',
                    colId: 'offersInPeriod',
                    columnGroupShow: 'closed',
                    aggFunc: salesMatricsAggFn,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return formattingNumber(params.value);
                    },
                    cellStyle: { padding: '0 10px !important' },
                    maxWidth: poClearingColWidth.OFFERED,
                    width: poClearingColWidth.OFFERED,
                    minWidth: poClearingColWidth.OFFERED,
                  },
                  {
                    headerName: 'Booked',
                    field: 'analytics.salesBooked',
                    colId: 'bookedShippedInPeriod',
                    aggFunc: salesMatricsAggFn,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return formattingNumber(params.value);
                    },
                    cellStyle: { padding: '0 !important' },
                    maxWidth: poClearingColWidth.BOOKED,
                    width: poClearingColWidth.BOOKED,
                    minWidth: poClearingColWidth.BOOKED,
                  },
                  {
                    headerName: 'Shipped',
                    field: 'analytics.salesSold',
                    colId: 'soldInPeriod',
                    columnGroupShow: 'closed',
                    aggFunc: salesMatricsAggFn,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return formattingNumber(params.value);
                    },
                    cellStyle: { padding: '0 10px !important' },
                    maxWidth: poClearingColWidth.SOLD,
                    width: poClearingColWidth.SOLD,
                    minWidth: poClearingColWidth.SOLD,
                  },
                ],
              },
            ],
          },

          {
            headerGroupComponentFramework: () => {
              return (
                <>
                  <CalendarTodayIcon />{' '}
                  <span className="vertical-middle">{`Last ${differenceInDays(
                    endDate,
                    startDate
                  )} Days`}</span>
                </>
              );
            },
            headerComponentParams: {},
            headerClass: 'px-po-grid-border-left px-po-clearing-last-days non-clickable',
            children: [
              {
                headerName: 'Inventory',
                headerClass: 'px-po-grid-border-left inventoryHeader',
                openByDefault: true,
                children: [
                  {
                    headerName: 'Days Inventory',
                    colId: 'inventoryDays',
                    field: 'analytics.inventoryDays',
                    headerClass: 'px-po-grid-border-left padding-left-2',
                    columnGroupShow: 'open',
                    width: 120,
                    minWidth: 120,
                    enableValue: true,
                    aggFunc: inventorMatricsAggFn,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return formattingNumber(params.value);
                    },
                    cellStyle: {
                      padding: '0 10px !important',
                      paddingLeft: '20px !important',
                      borderLeft: '1px solid #aaa',
                    },
                  },
                  {
                    headerName: 'Start Inv',
                    field: 'analytics.inventoryStarted',
                    colId: 'startingInventory',
                    headerClass: 'px-po-grid-border-left padding-left-2',
                    columnGroupShow: 'closed',
                    enableValue: true,
                    width: 80,
                    minWidth: 80,
                    aggFunc: inventorMatricsAggFn,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return formattingNumber(params.value);
                    },
                    cellStyle: {
                      padding: '0 10px !important',
                      paddingLeft: '20px !important',
                      borderLeft: '1px solid #aaa',
                    },
                  },
                  {
                    headerName: 'Added',
                    field: 'analytics.inventoryAdded',
                    colId: 'added',
                    columnGroupShow: 'closed',
                    enableValue: true,
                    aggFunc: inventorMatricsAggFn,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return formattingNumber(params.value);
                    },
                    width: 80,
                    minWidth: 80,
                    cellStyle: { padding: '0 10px !important' },
                  },
                  {
                    headerName: 'Sold',
                    field: 'analytics.inventorySold',
                    colId: 'sold',
                    columnGroupShow: 'closed',
                    width: 80,
                    minWidth: 80,
                    enableValue: true,
                    aggFunc: inventorMatricsAggFn,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return formattingNumber(params.value);
                    },
                    cellStyle: { padding: '0 10px !important' },
                  },
                  {
                    headerName: 'End Qty',
                    field: 'analytics.inventoryEnded',
                    colId: 'endingInventory',
                    columnGroupShow: 'closed',
                    width: 80,
                    minWidth: 80,
                    enableValue: true,
                    aggFunc: inventorMatricsAggFn,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return formattingNumber(params.value);
                    },
                    cellStyle: { padding: '0 10px !important' },
                  },
                  {
                    headerName: 'Days Inventory',
                    colId: 'inventoryDays',
                    field: 'analytics.inventoryDays',
                    columnGroupShow: 'closed',
                    width: 80,
                    minWidth: 80,
                    enableValue: true,
                    aggFunc: inventorMatricsAggFn,
                    valueFormatter: (params: ValueFormatterParams): any => {
                      return formattingNumber(params.value);
                    },
                    cellStyle: { padding: '0 10px !important' },
                  },
                ],
              },
            ],
          },
        ];
        gridAPI.setColumnDefs(columnDefs);
        gridAPI.setRowData(itemsState.items);
        gridAPI.hideOverlay();
        if (itemsState.items.length <= 0) {
          gridAPI?.showNoRowsOverlay();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridAPI, itemsState]);

    useEffect(() => {
      if (checkItemsState.eventSource === 'self' && gridAPI) {
        if (checkItemsState.status) {
          gridAPI.selectAll();
        } else {
          gridAPI.deselectAll();
        }
      }
    }, [checkItemsState, gridAPI]);

    const countChecked = (node: GridApi, checkedFrom: string) => {
      let items: any = { count: 0, qty: 0, avg: 0, status: false };
      node.forEachNode((val: any) => {
        if (val.group || val.selected === false) return;
        items.count += val.data.childIdsSelected?.length || 0;
        val.data.childIdsSelected?.forEach((index: any) => {
          const selectedOffer = val.data.offers.find((offer: any) => index === offer.offerId);
          if (!selectedOffer) return;
          items.qty += selectedOffer?.offerQuantity;
          items.avg += selectedOffer?.offerPrice;
        });
      });
      items.avg = items.avg / items.count;
      if (isNaN(items.avg)) {
        items.avg = 0;
      }
      if (items.count > 0) {
        items.status = items.count === offeredTabState.current ? true : undefined;
      }
      setCheckItemsState({ ...items, eventSource: 'inner' });
    };

    const onDetailRowsSelectionChanged = (node: any, masterGridApi: GridApi, idsSelected: any) => {
      node.data.childIdsSelected = idsSelected;
      node.data.updatingFromDetails = true;
      const selectionVal =
        idsSelected.length === getOfferedItems(node.data.offers).length
          ? true
          : idsSelected.length === 0
          ? false
          : undefined;
      node.selectThisNode(selectionVal);
      let parentUndetermined = false;
      let selectedNodesCount = 0;
      node.parent.allLeafChildren.forEach((node: any) => {
        if (node.selected || node.selected === undefined) parentUndetermined = true;
        if (node.selected) selectedNodesCount += 1;
      });
      node.parent.selectThisNode(
        node.parent.allChildrenCount === selectedNodesCount
          ? true
          : parentUndetermined
          ? undefined
          : false
      );
      setTimeout(() => (node.data.updatingFromDetails = false), 10);
      countChecked(masterGridApi, 'child');
    };

    const onGridReady = (params: GridReadyEvent) => {
      if (params && params.api) {
        setGridAPI(params.api);
      }
    };

    const onParentNodeSelected = (params: any) => {
      const node = params.node;
      const gridApi = params.api;
      if (node.data && gridApi) {
        node.data.childIdsSelected =
          node.selected && node.data.offers ? getOfferedItems(node.data.offers) : [];
        let detailGridId = 'detail_' + node.data.itemElectId;
        const detailGrid = gridApi.getDetailGridInfo(detailGridId);
        if (detailGrid && detailGrid.api) {
          detailGrid.api.forEachNode((detailNode: any) => {
            detailNode.data.updatingFromParent = true;
            detailNode.setSelected(node?.selected);
            setTimeout(() => (detailNode.data.updatingFromParent = false), 10);
          });
        }
        countChecked(gridApi, 'parent');
      }
    };

    const gridOptions: GridOptions = {
      onGridReady,
      sortingOrder: ['desc', 'asc'],
      suppressRowTransform: true,
      rowSelection: 'multiple',
      getRowHeight: (params: ICellRendererParams) => {
        if (params.node.group) {
          return 'auto';
        }
        return 40;
      },
      icons: {
        columnGroupOpened: CollapseIcon,
        columnGroupClosed: ExpandIcon,
      },
      groupDefaultExpanded: 2,
      groupSelectsChildren: true,
      suppressMakeColumnVisibleAfterUnGroup: true,
      autoGroupColumnDef: {
        headerName: 'Item',
        field: 'groupName',
        width: 340,
        minWidth: 340,
        maxWidth: 340,
        sortIndex: 0,
        sort: 'asc',
        // cellStyle: (params: any) => {
        //   if ((!params.node || params.node.group) && !params.node.selectable) {
        //     return { padding: '10px 0 10px 25px', wordBreak: 'break-word' };
        //   }
        //   return { padding: '10px 0', wordBreak: 'break-word' };
        // },
        cellClass: 'px-po-clearing-header-cell',
        cellRendererParams: {
          checkbox: (params: ICellRendererParams) => !params.node || params.node.group,
          suppressCount: true,
          suppressMakeColumnVisibleAfterUnGroup: true,
          suppressDoubleClickExpand: true,
          visible: false,
          hide: true,
        },
        valueFormatter: (params: any) => {
          if (params.node.groupData && params.node.group) {
            return params.node.groupData['ag-Grid-AutoColumn'].replaceAll('__', ' / ');
          } else if (params.column.colId === 'ag-Grid-AutoColumn') {
            return `${params.data.color} [${params.data.itemNumber}]`;
          }
          return params.value;
        },
        hide: true,
      },
      defaultColDef: {
        flex: 1,
        cellClass: 'cell-vertical-align-text-left px-po-break-word px-po-clearing-cell',
        sortable: true,
        resizable: true,
        filter: true,
        wrapText: true,
        autoHeight: true,
        suppressMenu: true,
      },
      rowBuffer: 10,
      suppressRowClickSelection: true,
      suppressMovableColumns: true,
      animateRows: false,
      suppressCellSelection: true,
      suppressAggFuncInHeader: true,
      stopEditingWhenGridLosesFocus: true,
      suppressContextMenu: true,
      enableCellTextSelection: true,
      keepDetailRows: true,
      applyColumnDefOrder: true,
      debounceVerticalScrollbar: true,
      loadingOverlayComponentFramework: LoadingComponent,
      overlayNoRowsTemplate: 'No Matching Items Found',
      getRowNodeId: (params: any) => params.itemElectId,
      masterDetail: true,
      detailRowAutoHeight: true,
      valueCache: true,
      isRowSelectable: (params: RowNode) => {
        if (!canEdit) return false;
        let isHeaderCheckboxPresent = false;
        isHeaderCheckboxPresent = params.data.offers?.some((val: any) => {
          return awaitingRule(val.status);
        });
        return isHeaderCheckboxPresent;
      },
      onRowSelected: (params: RowSelectedEvent) => {
        if (params.node?.data?.updatingFromDetails) return true;
        onParentNodeSelected(params);
      },
      frameworkComponents: {
        myDetailCellRenderer: DetailGrid,
        myCustomHeader: CustomHeader,
        LastDateRangeSelector: LastDateRangeSelector,
      },
      // DETAIL GRID
      detailCellRenderer: 'myDetailCellRenderer',
      detailCellRendererParams: (params: ICellRendererParams) => ({
        detailRowSelectedHandler: onDetailRowsSelectionChanged,
        initialIds: params.node.data.childIdsSelected || [],
        updateOffer: updateOffer,
        canEdit,
      }),
    };

    const MainCheck = () => {
      return (
        <div className="px-po-clearing-main-check">
          {[IPoClearingTabs.all, IPoClearingTabs.offered].includes(currentTab) && (
            <>
              <FormControlLabel
                control={
                  <PrimaryCheckbox
                    onChange={(e: any) => {
                      const mode = e.target.checked ? true : false;
                      setCheckItemsState({ ...checkItemsState, status: mode, eventSource: 'self' });
                    }}
                    checked={checkItemsState.status}
                    indeterminate={checkItemsState.status === undefined}
                    value="mainCheckbox"
                    name="mainCheckbox"
                    disabled={offeredTabState.current === 0 || !canEdit}
                    color="primary"
                  />
                }
                label={`${checkItemsState.count} Offers Selected (Qty: ${
                  checkItemsState.qty
                } @ Avg: $${parseFloat(checkItemsState.avg).toFixed(2)})`}
                aria-label={`${checkItemsState.count} Offers Selected (Qty: ${
                  checkItemsState.qty
                } @ Avg: $${parseFloat(checkItemsState.avg).toFixed(2)})`}
              />
              <PrimaryButton
                onClick={openBulkUpdateOffersDialog}
                disabled={checkItemsState.count === 0 || !canEdit}>
                <MultipleSelected className="multiple-selected" />
                BULK UPDATE OFFERS
              </PrimaryButton>
            </>
          )}
        </div>
      );
    };
    return (
      <>
        {bulkUpdateOffersDialog && (
          <BulkUpdatesOffers
            title={`Update ${checkItemsState.count} offers`}
            open={bulkUpdateOffersDialog}
            handleClose={closeBulkUpdateOffersDialog}
            bulkUpdateData={bulkUpdateData}
            updateOfferCall={updateOffer}
            setLoadingDialog={setLoadingDialog}
          />
        )}
        <div className="cell small-12 margin-top-1">
          <div className="ag-theme-alpine px-fulfillment-table px-min-width-buyer-table">
            <MainCheck />
            <AgGridReact gridOptions={gridOptions} />
            <div>
              {menuOptionOpen && (
                <PricingChart
                  menuDimensions={menuOptionDimensions}
                  menuOptionOpen={menuOptionOpen}
                  menuOptionClose={menuOptionClose}
                  heights={poClearingColWidth}
                  dateRange={dateRangeState.dateRange}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
);
export default PoClearingGrid;
