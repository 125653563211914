import * as types from '../actions/actionTypes';
// TODO:  remove customer user settings
const initState = {
  customerData: [],
  customerObj: null,
  isLoaded: false,
  isError: false,
  customerUserSettings: null,
};

let customerFields: any = {
  label: 'Add Customer Dialog',
  title: 'Customer Information',
  settings: [
    {
      id: 'buyerNumber',
      label: 'Customer Number',
      type: 'text',
      size: 'full',
      required: true,
      maxLength: null,
    },
    {
      id: 'buyerName',
      label: 'Customer Name',
      type: 'text',
      size: 'full',
      required: true,
      maxLength: null,
    },
    {
      id: 'salesRep.salesRepId',
      label: 'Sales Representative',
      type: 'text',
      size: 'full',
      required: true,
      maxLength: null,
    },
    {
      id: 'state',
      label: 'State',
      type: 'text',
      size: 'half',
      required: true,
      maxLength: null,
    },
    {
      id: 'country',
      label: 'Country',
      type: 'text',
      size: 'half',
      required: true,
      maxLength: null,
    },
    {
      id: 'phone',
      label: 'Phone',
      type: 'text',
      size: 'half',
      required: true,
      maxLength: null,
    },
    {
      id: 'whatsApp',
      label: 'WhatsApp',
      type: 'text',
      size: 'half',
      required: false,
      maxLength: null,
    },
    {
      id: 'email',
      label: 'Email',
      type: 'text',
      size: 'full',
      required: true,
      maxLength: null,
    },
  ],
  salesReps: {
    id: 'salesRepId',
    label: 'Sales Representative',
    type: 'select',
    required: true,
    maxLength: null,
  },
  additionalFields: [],
};

const customerPermissionFields = {
  paymentMethods: [],
  shoppingMethods: [
    {
      label: 'Stock List',
      id: 'STOCKLIST',
      disabled: true,
    },
  ],
  shippingMethods: [],
};

const customerFieldsConstantAttributes = {
  type: 'text',
  size: 'full',
  // required: true,
  maxLength: null,
};

const customerAddressFields = {
  label: 'Add Customer Address Dialog',
  title: 'Address Information',
  settings: [
    {
      id: 'addressCode',
      label: 'Address Code',
      type: 'text',
      size: 'full',
      required: true,
      maxLength: null,
    },
    {
      id: 'address1',
      label: 'Address 1',
      type: 'text',
      size: 'half',
      required: true,
      maxLength: null,
    },
    {
      id: 'address2',
      label: 'Address 2',
      type: 'text',
      size: 'half',
      required: true,
      maxLength: null,
    },
    {
      id: 'city',
      label: 'City',
      type: 'text',
      size: 'half',
      required: true,
      maxLength: null,
    },
    {
      id: 'state',
      label: 'State / Province',
      type: 'text',
      size: 'half',
      required: true,
      maxLength: null,
    },
    {
      id: 'country',
      label: 'Country',
      type: 'text',
      size: 'half',
      required: true,
      maxLength: null,
    },
    {
      id: 'zip',
      label: 'Zip',
      type: 'text',
      size: 'half',
      required: true,
      maxLength: null,
    },
    {
      id: 'shippingAddress',
      label: 'Shipping Address',
      type: 'switch',
      required: true,
      maxLength: null,
    },
    {
      id: 'billingAddress',
      label: 'Billing Address',
      type: 'switch',
      required: true,
      maxLength: null,
    },
    {
      id: 'defaultBillingAddress',
      label: 'Default Billing Address',
      type: 'switch',
      required: false,
      maxLength: null,
    },
    {
      id: 'defaultShippingAddress',
      label: 'Default Shipping Address',
      type: 'switch',
      required: false,
      maxLength: null,
    },
  ],
  additionalFields: [],
};

let customerUserFields: any = {
  label: 'Add User Dialog',
  title: 'User Information',
  settings: [
    {
      id: 'firstName',
      label: 'First Name',
      type: 'text',
      required: true,
      maxLength: null,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      type: 'text',
      required: true,
      maxLength: null,
    },
    {
      id: 'purchasing',
      label: 'Purchasing',
      type: 'switch',
    },
    {
      id: 'financing',
      label: 'Financing',
      type: 'switch',
    },
    {
      id: 'email',
      label: 'Email',
      type: 'text',
      required: true,
      maxLength: null,
    },
  ],
  buyerRoles: {
    label: 'Roles',
    settings: [
      {
        label: 'Finance: This role can view and pay invoices',
        id: 'FINANCE',
      },
      {
        label: 'Purchaser: This role can see stock list, make purchases, and view and pay invoices',
        id: 'PURCHASER',
      },
    ],
  },
  additionalFields: [],
};

const salesRepSettings = {
  id: 'salesRepId',
  label: 'Sales Rep',
  type: 'select',
  options: [],
};

const createCustomerObj = (customerData: Array<any>) => {
  const customerObj: any = {};
  customerData.forEach((val: any) => {
    customerObj[val.id] = {
      buyerName: val.buyerName,
      buyerNumber: val.buyerNumber,
      activeAndComplete: val.registrationStatus === 'Complete' && val.active,
      salesRep: val.salesRep.salesRepFullName,
    };
  });
  return customerObj;
};

export default function customerReducer(state: any = initState, action: any) {
  switch (action.type) {
    case types.CUSTOMER_SET:
      return {
        ...state,
        customerData: action.payload,
        customerObj: createCustomerObj(action.payload),
        isLoaded: true,
      };
    case types.CUSTOMER_ADD:
      return {
        ...state,
        customerData: [...state.customerData, action.payload],
        customerObj: {
          ...state.customerObj,
          ...createCustomerObj([action.payload]),
        },
        isLoaded: true,
      };
    case types.CUSTOMER_UPDATE:
      const customers = state.customerData.map((customer: any) => {
        if (customer.id === +action.payload.id) {
          return {
            ...action.payload,
            id: customer.id,
          };
        }
        return customer;
      });
      return {
        ...state,
        customerData: customers,
        customerObj: {
          ...state.customerObj,
          ...createCustomerObj([action.payload]),
        },
      };
    case types.CUSTOMER_STATUS_UPDATE:
      return {
        ...state,
        customerData: state.customerData.map((val: any) => {
          if (val.id === +action.payload.id) {
            val.registrationStatus = action.payload.status;
          }
          return val;
        }),
      };
    case types.CUSTOMER_SETTINGS:
      // console.log(action.settings);
      const settings: any = action?.payload?.settings;

      const salesRepObj: any = {};
      const salesRep = action?.payload?.salesRep?.map((rep: any) => {
        salesRepObj[rep.salesRepId] = {
          fullName: rep.salesRepFullName,
          email: rep.salesRepEmail,
        };
        return {
          key: rep.salesRepId,
          option: rep.salesRepFullName,
        };
      });
      salesRepSettings.options = salesRep;

      // add sales rep information
      customerFields.salesReps = salesRepSettings;

      // adding customer fields settings
      customerFields.settings = settings?.compulsorySettings?.map((column: any) => {
        if (column.id.indexOf('salesRep') > -1) {
          return salesRepSettings;
        }
        column = {
          ...column,
          ...customerFieldsConstantAttributes,
        };
        return column;
      });
      // adding customer field additional settings
      customerFields.additionalFields =
        // settings
        action?.payload?.settings?.customerAdditionalFields?.fields || [];

      // adding payment settings
      customerPermissionFields.paymentMethods =
        // settings
        action?.payload?.settings?.customerPaymentMethods?.paymentMethods;

      customerPermissionFields.shippingMethods =
        action?.payload?.settings?.customerShippingMethods?.shippingMethods;

      // adding shopping Channels
      if (!customerPermissionFields?.shoppingMethods) {
        customerPermissionFields.shoppingMethods = [];
      }

      if (!settings?.additionalAssignedChannels) {
        settings.additionalAssignedChannels = [];
      }

      customerPermissionFields.shoppingMethods = [
        ...customerPermissionFields.shoppingMethods,
        ...settings?.additionalAssignedChannels?.map((val: any) => {
          return {
            label: val[0] + val.slice(1).toLowerCase(),
            id: val,
          };
        }),
      ];
      // replacing additionalAssignedChannels to shoppingChannels, TODO: could change suggest in backend
      delete settings.additionalAssignedChannels;

      // adding address settings
      customerAddressFields.additionalFields =
        // settings
        action?.payload?.settings?.customerAddressAdditionalFields?.fields || [];

      // adding user settings
      // customerUserFields.additionalFields = action?.payload?.settings?.userAdditionalFields?.fields;

      // adding all the field data into the settings
      settings.customerFields = customerFields;
      settings.customerPermissionFields = customerPermissionFields;
      settings.customerAddressFields = customerAddressFields;
      settings.customerUserFields = customerUserFields;

      // removing additional field
      delete settings.customerAdditionalFields;
      delete settings.customerPaymentMethods;
      delete settings.customerAddressAdditionalFields;
      delete settings.compulsorySettings;
      delete settings.userAdditionalFields;
      delete settings.customerFields.settings;

      return {
        ...state,
        customerUserSettings: settings,
        salesRepObj: salesRepObj,
      };
    case types.CUSTOMER_LOADING_SET: {
      return {
        ...state,
        isLoaded: action.payload,
      };
    }
    default:
      return state;
  }
}
