import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep, filter, groupBy, isEmpty } from 'lodash';

//custom hooks
import useDataService from '../../hooks/useDataService';

//material-ui
import { Badge, Checkbox, Tab, Tabs } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// lib, api url template
import {
  advanceChipFilterDataState,
  itemSchedulingDraftRule,
  itemSchedulingFilterByTab,
  itemSchedulingScheduledRule,
} from '../../_lib/lib';

// styles
import './ItemSetup.scss';

// Helper Functions and Components
import { PrimaryButton, SecondaryButton } from '../../components/AtomComponents';
import Filters from '../../components/Filters/Filters';
import ImportExportDropdown from '../../components/ImportExportDropdown/ImportExportDropdown';
import ImportPopup from '../../components/ImportPopup/ImportPopup';
import ItemSetupGrid from '../../components/ItemSetup/ItemSetupGrid';
import MultipleSelected from '../../components/ItemSetup/MultipleSelected';
import SectionProgress from '../../components/Progress/SectionProgress';
import BulkUpdateDates from '../../components/ItemSetup/BulkUpdateDates';
import ItemImport from '../../components/ItemSetup/ItemImport';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';

//aggrid
import { GridApi, RowNode } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';

//types
import {
  IAllItemSchedules,
  IImportItems,
  IItems,
  IItemScheduling,
  IItemSchedulingTab,
  IOfferTimeRange,
  ISelectedRowsState,
  ITabStats,
  ItemImportChanges,
} from '../../types/IItemSetup';
import { eMessageType } from '../../types/IMessageType';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DeleteIcon from '@material-ui/icons/Delete';

import * as itemSchedulingActions from '../../redux/actions/itemSchedulingActions';
import useAdvanceFilterV1 from '../../hooks/useAdvanceFilterV1';
import SearchFieldV1 from '../../components/SearchField/SearchFieldV1';
import apiToUrlMap, { formatString } from '../../ApiMapping';
import moment from 'moment';
import { ConfirmDialog } from '../../components/Funding/ConfirmDialog';
import { IConfirmDialogProps, initialConfirmDialogProps } from '../../types/IPreOrders';
import { convertSecondsToTime } from '../../components/PreOrders/Utils';
import { DraftTimeSelectorDialog } from '../../components/ItemSetup/CreateTime/DraftTimeSelectorDialog';
import { DraftFinalizeDateDialog } from '../../components/ItemSetup/CreateTime/DraftFinalizeDateDialog';
import ReactGA from 'react-ga';

LicenseManager.setLicenseKey(
  'CompanyName=Phonex Holdings Inc,LicensedApplication=PhoneX SaaS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=3,AssetReference=AG-012160,ExpiryDate=1_December_2021_[v2]_MTYzODMxNjgwMDAwMA==f1ae693cebbed365cb2597d53a5021d2'
);

const InitialSelectedRowsState = {
  count: 0,
  totalCount: 0,
  rowIds: [],
  isChecked: false,
  isIndeterminate: false,
  selectedNodes: [],
  finalizingMax: 0,
  hasScheduled: false,
};

const initialBulkUpdateState = {
  selectedItems: {},
  nonOverlappingSchedules: [],
  overlappingOfferSchedules: [],
  overlappingShippingSchedules: [],
};

const ItemSetup = ({ itemsListState, itemSchedulingActions }: IItemScheduling) => {
  // use data service
  const { openSnackBar, fileUpload, fetchUrl, exportData } = useDataService();

  //Items
  const { itemsList, uniqueCategories, uniqueWarehouses } = itemsListState;
  const [tabStats, setTabStats] = useState<ITabStats>({
    [IItemSchedulingTab.draft]: 0,
    [IItemSchedulingTab.scheduled]: 0,
    [IItemSchedulingTab.offering]: 0,
    [IItemSchedulingTab.shipping]: 0,
    [IItemSchedulingTab.negotiating]: 0,
    [IItemSchedulingTab.finalizing]: 0,
  });
  const allItemSchedules = useRef<IAllItemSchedules[] | null>();

  //aggrid
  const pxAgGridRef = useRef<any>();
  const [selectedRowsState, setSelectedRowsState] = useState<ISelectedRowsState>(
    InitialSelectedRowsState
  );

  //notification state
  const [isScheduleDialog, setIsScheduleDialog] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState<IConfirmDialogProps>(
    initialConfirmDialogProps
  );

  const [draft, setDraft] = useState<any>([]);

  // Tab Maintenance
  const [tab, setTab] = useState<IItemSchedulingTab>(IItemSchedulingTab.all);
  const [filteredState, setFilteredState] = useState<any>();
  const tabIndexStateRef = useRef<IItemSchedulingTab>(tab);
  const [tableData, setTableData] = useState<any>();

  // Import Items
  const [importDialog, setImportDialog] = useState<boolean>(false);
  const [currentFileName, setCurrentFileName] = useState<string>('');
  const [importData, setImportData] = useState<IImportItems | null>();
  const pxImportRef: any = useRef();

  //Bulk Update State
  const [bulkUpdateDatesInfo, setBulkUpdateDatesInfo] = useState<any>(initialBulkUpdateState);
  const [bulkUpdateDatesDialog, setBulkUpdateDatesDialog] = useState<boolean>(false);

  //lastUpdated timestamp
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>('');
  //Schedule Draft items
  const [isDisableScheduleDrafts, setIsDisableScheduleDrafts] = useState(true);
  const [isDaysFinalize, setIsDaysFinalize] = useState<boolean>(false);

  //loading state
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);

  const [openTimeSelectorDialog, setOpenTimeSelectorDialog] = useState<boolean>(false);
  const [daysToFinalizeDialog, setDaysToFinalizeDialog] = useState<boolean>(false);
  const [offeringTimeRange, setOfferingTimeRange] = useState<IOfferTimeRange>();

  const [isSearchOn, setIsSearchOn] = useState<boolean>(false);

  // Function for clearing Local States
  const clearAllLocalStates = useCallback(() => {
    itemSchedulingActions.itemSchedulingSet(null);
  }, [itemSchedulingActions]);

  const settings = useMemo(() => {
    const optionalFilters = [
      'manufacturer',
      'model',
      'grade',
      'capacity',
      'color',
      'carrier',
      'protocol',
      'lockStatus',
      'ram',
      'bandMaterial',
      'casingMaterial',
      'casingSize',
      'cpuModel',
    ];
    return {
      topSearchLevelForAllTenants: [],
      optionalFilters,
    };
  }, []);

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' | Item Scheduling';

    return () => {
      clearAllLocalStates();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAllLocalStates]);

  const getItemsforItemSchedulingAndSchedules = async () => {
    try {
      const [itemsFetched, itemSchedules, timeSchedules] = await Promise.all([
        fetchUrl('GET', apiToUrlMap.itemScheduling),
        fetchUrl('GET', apiToUrlMap.getItemSchedules),
        fetchUrl('GET', apiToUrlMap.getTimeSchedules),
      ]);
      allItemSchedules.current = cloneDeep(itemSchedules.itemSchedules);
      itemSchedulingActions.itemSchedulingSet(cloneDeep(itemsFetched));
      setOfferingTimeRange(timeSchedules);
      getLastUpdatedTime(itemsFetched.lastUpdatedTs);
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
    }
  };

  const getAllItemSchedulesMultiple = async () => {
    setLoadingDialog(true);
    try {
      const itemSchedules = await fetchUrl('GET', apiToUrlMap.getItemSchedules);
      allItemSchedules.current = cloneDeep(itemSchedules.itemSchedules);
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
    }
    setLoadingDialog(false);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    getItemsforItemSchedulingAndSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateItem = async (
    items: any,
    refetchSchedules?: boolean,
    displaySuccessMessage?: boolean,
    reFetchFromRemote?: boolean
  ) => {
    pxAgGridRef.current?.showLoadingOverlay();
    try {
      const res = await fetchUrl('PATCH', apiToUrlMap.itemScheduling, { body: { items } });
      if (reFetchFromRemote) {
        itemSchedulingActions.itemSchedulingSet([]);
        await getItemsforItemSchedulingAndSchedules();
        return true;
      }
      if (refetchSchedules) {
        allItemSchedules.current = null;
        await getAllItemSchedulesMultiple();
      }
      itemSchedulingActions.updateItemsDraft(cloneDeep(items));
      getLastUpdatedTime(res.lastUpdatedTs);
      pxAgGridRef.current?.hideLoadingOverlay();
      if (!displaySuccessMessage) openSnackBar('Value updated successfully', eMessageType.success);
      return true;
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
      pxAgGridRef.current?.hideLoadingOverlay();
      if (displaySuccessMessage) {
        if (error.message.toUpperCase().includes('ERROR SCHEDULING'))
          openSnackBar(error.message, 'error');
        else if (error.message.includes('Invalid dates found'))
          openSnackBar(
            'Draft Items have dates earlier than allowed.  Please update or delete invalid items before Scheduling',
            eMessageType.error
          );
        else openSnackBar('Draft items could not be scheduled', eMessageType.error);
      }
      return false;
    }
  };

  const deleteItem = async (itemElectId: number) => {
    pxAgGridRef.current?.showLoadingOverlay();
    try {
      const res = await fetchUrl(
        'delete',
        formatString(apiToUrlMap.deleteItem, {
          itemElectId,
        })
      );
      allItemSchedules.current = null;
      await getAllItemSchedulesMultiple();
      getLastUpdatedTime(res.lastUpdatedTs);
      itemSchedulingActions.deleteItemDraftScheduled(itemElectId);
      setSelectedRowsState(InitialSelectedRowsState);
      pxAgGridRef.current?.hideLoadingOverlay();
      openSnackBar('Item is deleted sucessfully', eMessageType.success);
      return true;
    } catch (error) {
      openSnackBar('Could not delete item', eMessageType.error);
      pxAgGridRef.current?.hideLoadingOverlay();
      return false;
    }
  };
  const getLastUpdatedTime = (lastUpdated: any) => {
    const cst_time = moment.tz(lastUpdated, 'America/Chicago');
    const currentTimeZone = moment.tz.guess();
    const ist_time = cst_time.tz(currentTimeZone).format('MMM DD, yyyy hh:mm A');
    setLastUpdatedTime(ist_time);
  };
  useEffect(() => {
    if (!itemsList || !allItemSchedules.current) return;
    const filteredItems: any = [];
    const data = cloneDeep(itemsList.items ?? []);
    data.forEach((item: any) => {
      const sanitizedItem = {
        ...item,
        secondItemGroup: filter(data, {
          itemNumber: item.itemNumber,
          color: item.color,
          groupName: item.groupName,
        }),
      };
      filteredItems.push(sanitizedItem);
    });
    setTableData(filteredItems);
  }, [itemsList, allItemSchedules]);

  useEffect(() => {
    if (!itemsList || !itemsList.items) return;
    const draftItems = itemsList.items.filter((item: any) => {
      return item?.status === IItemSchedulingTab.draft;
    });
    const checkDates = draftItems.filter((data: IItems) => {
      return (
        data.offerStartDate !== null ||
        data.offerEndDate !== null ||
        data.shippingStartDate !== null ||
        data.shippingEndDate !== null
      );
    });
    if (checkDates.length !== 0) {
      setIsDaysFinalize(true);
    } else {
      setIsDaysFinalize(false);
    }
  }, [itemsList]);

  //#region >> filter code >>
  const {
    searchTerm,
    startSearch,
    // filters applied state
    setFiltersApplied,
    filtersApplied,
    // advance filters
    setAdvanceFilter,
    advanceFilters,
    // reset filter function
    resetFilter,
    // items
    itemsState,
    // event
    chipsEvent,
    // count values
    filterCountState,
  } = useAdvanceFilterV1({
    requiredFilters:
      uniqueCategories && uniqueWarehouses
        ? {
            category: [...uniqueCategories],
            warehouse: [...uniqueWarehouses],
          }
        : null,
    updateTabCount: (stats: any) => setTabStats(stats),
    outOfStockField: 'totalQuantityAvailable',
    advanceFilter: {},
    pxGridRef: pxAgGridRef,
    viewName: 'itemScheduling',
    settings: settings,
    items: tableData,
  });
  //#endregion >> Chip Filter

  useEffect(() => {
    if (isEmpty(filtersApplied) || isEmpty(uniqueCategories) || isEmpty(uniqueWarehouses)) return;
    const filterSetTimer = setTimeout(() => {
      setFiltersApplied({
        ...filtersApplied,
        category: uniqueCategories,
        warehouse: uniqueWarehouses,
      });
      resetFilter();
    }, 0);
    return () => clearTimeout(filterSetTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueCategories, uniqueWarehouses]);

  useEffect(() => {
    if (itemsState) {
      const draftItems = itemsState.filter((item: any) => {
        return item?.status === IItemSchedulingTab.draft;
      });
      const checkQuantityDates = draftItems.filter((data: IItems) => {
        return (
          data.offerStartDate === null ||
          data.offerEndDate === null ||
          data.shippingStartDate === null ||
          data.shippingEndDate === null ||
          data.quantityForecast === null ||
          data.quantityForecast === 0
        );
      });
      if (checkQuantityDates.length !== 0 || draftItems.length === 0) {
        setIsDisableScheduleDrafts(true);
      } else {
        setIsDisableScheduleDrafts(false);
      }
    }
  }, [itemsState]);
  useEffect(() => {
    setIsSearchOn(true);
  }, [searchTerm, itemsState]);

  useEffect(() => {
    let dataSettingTimer: NodeJS.Timeout;
    if (itemsState && tab && pxAgGridRef) {
      const filteredItems = itemsState.filter((item: any) => {
        return itemSchedulingFilterByTab(tab, item);
      });
      dataSettingTimer = setTimeout(() => {
        setFilteredState(filteredItems);
        if (!!filteredItems && filteredItems.length === 0) {
          pxAgGridRef?.current?.gridApi.showNoRowsOverlay();
          return;
        } else {
          pxAgGridRef?.current?.hideLoadingOverlay();
        }
      }, 100);
    }
    setIsSearchOn(false);
    return () => {
      clearTimeout(dataSettingTimer);
    };
    // setSelectedRowsState(InitialSelectedRowsState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState?.length, tab, isSearchOn]);

  //import draft items functions
  const handleUpload = async (file: File) => {
    try {
      const res = await fileUpload(file, apiToUrlMap.importItems);
      const dataToImport: any = cloneDeep(res);
      setImportData(dataToImport);
      setCurrentFileName(dataToImport.fileName);
      closeImportDialog();
      closeImportDialog();
    } catch (error: any) {
      pxImportRef?.current?.setImportError(error.message);
    }
  };

  const handleSave = async (updates: ItemImportChanges) => {
    try {
      itemSchedulingActions.itemSchedulingSet([]);
      let items = [...updates.newItems, ...updates.updatedItems];
      await fetchUrl('PUT', apiToUrlMap.itemScheduling, { body: { items } });
      allItemSchedules.current = null;
      await getItemsforItemSchedulingAndSchedules();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleFileExport = async () => {
    setLoadingDialog(true);
    try {
      let today = new Date();
      let fileName = `PO Item Schedule EXPORT-${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}.xlsx`;
      await exportData({
        url: apiToUrlMap.exportItems,
        fileName,
      });
      openSnackBar('File Exported', eMessageType.success);
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
    }
    setLoadingDialog(false);
  };
  const formatMonthDateYearTime = (startDate: any, endDate: any) => {
    return `${moment(startDate, 'yyyy-MM-DD HH:mm:ss').format('MMM DD, hh:mm a')} - ${moment(
      endDate,
      'yyyy-MM-DD HH:mm:ss'
    ).format('MMM DD, hh:mm a')}`;
  };

  const scheduleDialogContent = (draft: any) => {
    return draft.map((items: any) => (
      <div className="scheduleDialogContent margin-bottom-2" key={items.itemElectId}>
        <p>
          Number of Items: <span>{items.length}</span>
        </p>
        <p>
          {' '}
          Offer Dates:{' '}
          <span>{formatMonthDateYearTime(items.offerStartDate, items.offerEndDate)}</span>
        </p>
        <p>
          {' '}
          Negotiating Dates:{' '}
          <span>
            {formatMonthDateYearTime(items.negotiatingStartDate, items.negotiatingEndDate)}
          </span>
        </p>
        <p>
          {' '}
          Finalizing Dates:{' '}
          <span>{formatMonthDateYearTime(items.finalizingStartDate, items.finalizingEndDate)}</span>
        </p>
        <p>
          {' '}
          Shipping Dates:{' '}
          <span>
            {moment(items.shippingStartDate, 'yyyy-MM-DD HH:mm:ss').format('MMM DD, hh:mm a')} -
            {` ${moment(items.shippingEndDate, 'yyyy-MM-DD HH:mm:ss').format('MMM DD')}, 11:59 pm`}
          </span>
        </p>
      </div>
    ));
  };

  const scheduleDraftItems = async () => {
    const items = tableData
      .filter((item: any) => {
        return itemSchedulingDraftRule(item);
      })
      .map((item: any) => ({
        itemElectId: item.itemElectId,
        itemNumberWarehouse: item.itemNumberWarehouse,
        itemNumber: item.itemNumber,
        warehouse: item.warehouse,
        quantityForecast: item.quantityForecast,
        offerStartDate: item.offerStartDate,
        offerEndDate: item.offerEndDate,
        shippingStartDate: item.shippingStartDate,
        shippingEndDate: item.shippingEndDate,
        status: IItemSchedulingTab.scheduled,
      }));
    setIsScheduleDialog(false);
    const res = await updateItem(items, false, true, true);
    if (res) {
      openSnackBar('Value updated successfully', eMessageType.success);
      setSelectedRowsState(InitialSelectedRowsState);
    }
  };

  const shippingWindow = async (itemElectId: any) => {
    try {
      const res = await fetchUrl(
        'GET',
        formatString(apiToUrlMap.getInsights, {
          itemElectId: itemElectId,
        })
      );
      return res;
    } catch (error) {
      openSnackBar('Something has gone wrong.Try again', eMessageType.error);
      return;
    }
  };

  const bulkDeleteSelectedItems = async () => {
    const selectedItemIds = selectedRowsState.selectedNodes.map(
      (node: RowNode) => node.data.itemElectId
    );
    setLoadingDialog(true);
    try {
      const res = await fetchUrl('POST', apiToUrlMap.bulkDeleteItems, { body: selectedItemIds });
      await getItemsforItemSchedulingAndSchedules();
      getLastUpdatedTime(res.lastUpdatedTs);
      openSnackBar(
        `${
          selectedItemIds.length > 1
            ? `${selectedItemIds.length} Items Deleted`
            : `${selectedItemIds.length} Item Deleted`
        }`,
        eMessageType.success
      );
      setSelectedRowsState(InitialSelectedRowsState);
      pxAgGridRef.current?.hideLoadingOverlay();
    } catch (error: any) {
      openSnackBar('Failed to Delete', eMessageType.error);
    }
    setLoadingDialog(false);
    setIsDeleteDialogOpen(false);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogProps(initialConfirmDialogProps);
    setIsScheduleDialog(false);
    setIsDeleteDialogOpen(false);
  };

  const handleScheduleDraftItems = async () => {
    let draftItems: any = [];
    tableData.forEach((item: any) => {
      if (itemSchedulingDraftRule(item)) {
        // if (!minOfferDate || moment(new Date(minOfferDate)).isAfter(new Date(item.offerStartDate)))
        //   minOfferDate = item.offerStartDate;
        // if (!maxOfferDate || moment(new Date(maxOfferDate)).isBefore(new Date(item.offerEndDate)))
        //   maxOfferDate = item.offerEndDate;
        // if (
        //   !minShippingDate ||
        //   moment(new Date(minShippingDate)).isAfter(new Date(item.shippingStartDate))
        // )
        //   minShippingDate = item.shippingStartDate;
        // if (
        //   !maxShippingDate ||
        //   moment(new Date(maxShippingDate)).isBefore(new Date(item.shippingEndDate))
        // )
        //   maxShippingDate = item.shippingEndDate;
        draftItems.push(item);
      }
    });
    const scheduleDetails = Object.values(
      groupBy(
        draftItems,
        (item: any) =>
          `${item.offerStartDate}+${item.offerEndDate}+${item.shippingStartDate}+${item.shippingEndDate}`
      )
    ).map((item: any) => {
      return {
        itemElectId: item[0].itemElectId,
        offerStartDate: item[0].offerStartDate,
        offerEndDate: item[0].offerEndDate,
        shippingStartDate: item[0].shippingStartDate,
        shippingEndDate: item[0].shippingEndDate,
        negotiatingStartDate: item[0].negotiatingStartDate,
        negotiatingEndDate: item[0].negotiatingEndDate,
        finalizingStartDate: item[0].finalizingStartDate,
        finalizingEndDate: item[0].finalizingEndDate,
        length: item.length,
      };
    });

    setIsScheduleDialog(true);
    // setShippingDates({ minShippingDate, maxShippingDate });
    // setOfferDates({ minOfferDate, maxOfferDate });
    // setDraftLength(draftItems.length);
    setDraft(scheduleDetails);
  };

  useEffect(() => {
    setConfirmDialogProps({
      isDialogOpen: isScheduleDialog,
      title: 'Confirm Scheduling Draft Items',
      confirmDialogContent: () => scheduleDialogContent(draft),
      confirmActionProps: {
        confirmLabel: 'CONTINUE',
        confirmHandler: scheduleDraftItems,
        rejectLabel: 'CANCEL',
        rejectHandler: closeConfirmDialog,
      },
      backdropHandler: () => {
        setIsScheduleDialog(false);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScheduleDialog]);

  useEffect(() => {
    setConfirmDialogProps({
      isDialogOpen: isDeleteDialogOpen,
      title: 'Confirm Bulk Delete',
      confirmDialogContent: () => {
        const selectedItemIds = selectedRowsState.selectedNodes.map(
          (node: RowNode) => node.data.itemElectId
        );
        return <p>Number of Items to Delete: {selectedItemIds.length}</p>;
      },
      confirmActionProps: {
        confirmLabel: 'CONTINUE TO DELETE',
        confirmHandler: bulkDeleteSelectedItems,
        rejectLabel: 'CANCEL',
        rejectHandler: closeConfirmDialog,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteDialogOpen]);

  const exportTemplate = async () => {
    let fileName = `PO Draft Items Import Template.xlsx`;
    setLoadingDialog(true);
    try {
      await exportData({ url: apiToUrlMap.exportTemplate, fileName });
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
    }
    setLoadingDialog(false);
  };

  // Import Dialog Manager
  const openImportDialog = () => setImportDialog(true);
  const closeImportDialog = () => setImportDialog(false);

  // Bulk Update Dates Manger
  const getItemSchedulesMultiple = async (itemNumberWarehouses: string[]) => {
    setLoadingDialog(true);
    try {
      const itemSchedules = await fetchUrl('POST', apiToUrlMap.getItemSchedules, {
        body: { items: itemNumberWarehouses },
      });
      setBulkUpdateDatesInfo(cloneDeep(itemSchedules));
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
    }
    setLoadingDialog(false);
  };

  const openBulkUpdateDatesDialog = async () => {
    const itemNumberWarehouses = selectedRowsState.rowIds.map(
      (ele: any) => ele.itemNumberWarehouse
    );
    await getItemSchedulesMultiple(itemNumberWarehouses);
    setBulkUpdateDatesDialog(true);
  };
  const closeBulkUpdateDatesDialog = () => setBulkUpdateDatesDialog(false);

  const toggleSelectedRowsHandler = (e: any, checked: boolean) => {
    const gridApi = pxAgGridRef.current?.gridApi as GridApi;
    gridApi?.forEachNode((node: RowNode) => {
      if (node.data) {
        node.data.checkingFromDetail = true;
        if (checked) node.setSelected(true);
        else node.setSelected(false);
        setTimeout(() => (node.data.checkingFromDetail = false), 5);
      }
    });
    const selectedNodes = gridApi.getSelectedNodes();
    let finalizingMax = 0;
    const count = selectedNodes.length;
    const rowIds = selectedNodes.map((node: RowNode) => {
      if (node.data.daysToFinalize > finalizingMax) finalizingMax = node.data.daysToFinalize;
      return {
        nodeId: node.id,
        itemNumberWarehouse: node.data.itemNumberWarehouse,
      };
    });
    const isChecked = count === selectedRowsState.totalCount;
    const isIndeterminate = count > 0 && !isChecked;
    const hasScheduled = selectedNodes.some((node: RowNode) =>
      itemSchedulingScheduledRule(node.data)
    );

    setSelectedRowsState((prev: ISelectedRowsState) => ({
      ...prev,
      rowIds,
      count,
      isChecked,
      isIndeterminate,
      selectedNodes,
      finalizingMax,
      hasScheduled,
    }));
  };

  const tabChange = (e: any, tabVal: IItemSchedulingTab) => {
    if (tabVal === tab) {
      return;
    }
    chipsEvent({
      type: 'tab-change',
      payload: tabVal,
    });
    setTab(tabVal);
  };

  const reviewImportStateHandler = () => {
    setImportData(null);
  };

  const openTimeSelector = (e: any) => {
    e.preventDefault();
    setOpenTimeSelectorDialog(true);
  };
  const openFinalizeDialog = (e: any) => {
    e.preventDefault();
    setDaysToFinalizeDialog(true);
  };
  const closeFinalizeDialog = () => {
    setDaysToFinalizeDialog(false);
  };

  const closeTimeSelector = () => {
    setOpenTimeSelectorDialog(false);
  };

  const saveSelectedTimes = async (offerTimeRange: IOfferTimeRange) => {
    try {
      const { daysToFinalize: unused, ...rest } = offerTimeRange;
      const res = await fetchUrl('PUT', apiToUrlMap.getTimeSchedules, {
        body: { ...rest },
      });
      if (res) {
        allItemSchedules.current = null;
        await getItemsforItemSchedulingAndSchedules();
      }
      return true;
    } catch (error: any) {
      openSnackBar(error.message, 'error');
      return false;
    }
  };
  const saveSelectedFinalizeDates = async (dates: string) => {
    const requestBody = {
      daysToFinalize: parseInt(dates),
    };
    try {
      setLoadingDialog(true);
      await fetchUrl('POST', apiToUrlMap.daysToFinalize, {
        body: requestBody,
      });
      allItemSchedules.current = null;
      await getItemsforItemSchedulingAndSchedules();
    } catch (error: any) {
      openSnackBar(error.message, 'error');
    }
    setLoadingDialog(false);
    setDaysToFinalizeDialog(false);
  };

  const openBulkDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  if (importData) {
    return (
      <ItemImport
        importData={importData}
        fileName={currentFileName!}
        keyGroup={{
          errors: 'preorderItemError',
          changes: 'preorderItemChange',
          warnings: 'preorderItemWarning',
        }}
        closeImport={reviewImportStateHandler}
        saveImport={handleSave}
        screen="poDraftItem"
      />
    );
  }
  return (
    <div className="grid-x">
      {openTimeSelectorDialog && offeringTimeRange && (
        <DraftTimeSelectorDialog
          closeDialog={closeTimeSelector}
          isDialogOpen={openTimeSelectorDialog}
          title="Start and End Times for all Draft"
          confirmHandler={saveSelectedTimes}
          offerTimeRange={offeringTimeRange}
          setLoadingDialog={setLoadingDialog}
        />
      )}
      <DraftFinalizeDateDialog
        closeDialog={closeFinalizeDialog}
        isDialogOpen={daysToFinalizeDialog}
        title="Finalize window for all Draft"
        confirmHandler={saveSelectedFinalizeDates}
        offerTimeRange={offeringTimeRange}
        setLoadingDialog={setLoadingDialog}
        isDaysFinalize={isDaysFinalize}
      />
      <ConfirmDialog
        isDialogOpen={confirmDialogProps?.isDialogOpen}
        title={confirmDialogProps?.title}
        confirmDialogContent={confirmDialogProps?.confirmDialogContent}
        confirmActionProps={confirmDialogProps?.confirmActionProps}
        backdropHandler={confirmDialogProps?.backdropHandler}
        className="scheduleDialog"
      />
      <LoadingDialog isDialogOpen={loadingDialog} />
      <ImportPopup
        title="Import PO Draft Item"
        desc={
          <>
            Add Items that will be made available for PO Offer
            <br />
            <p className="px-color-link pointer-mouse" onClick={exportTemplate}>
              (download template).
            </p>
          </>
        }
        ref={pxImportRef}
        open={importDialog}
        handleClose={closeImportDialog}
        handleUpload={handleUpload}
        customError="File format must be .XLSX"
      />
      {bulkUpdateDatesDialog && (
        <BulkUpdateDates
          open={bulkUpdateDatesDialog}
          title={`Set Date for ${selectedRowsState.count} items`}
          handleClose={closeBulkUpdateDatesDialog}
          selectedItemsInfo={bulkUpdateDatesInfo}
          updateItem={updateItem}
          selectedNodes={selectedRowsState.selectedNodes}
          setLoadingDialog={setLoadingDialog}
          daysToFinalize={offeringTimeRange?.daysToFinalize ?? 0}
          offeringTimeRange={offeringTimeRange}
        />
      )}
      <div className="grid-x cell small-12 px-item-setup">
        <div className="cell small-5 margin-bottom-2">
          <h2>PO Item Scheduling</h2>
          {!!lastUpdatedTime && <span className="text-faded">Last Updated: {lastUpdatedTime}</span>}
        </div>
        <div className="cell small-7 text-right margin-bottom-1 px-po-import-bar">
          <ImportExportDropdown
            importExportOptions={{
              importLabel: 'IMPORT DRAFT ITEMS',
              importAction: openImportDialog,
              exportLabel: 'EXPORT',
              exportAction: handleFileExport,
              actionButtonParams: {
                import: {
                  'data-id': 'importDraftItems',
                },
                export: {
                  'data-id': 'exportDraftItems',
                },
              },
            }}
          />
          <PrimaryButton
            className="margin-left-1"
            data-id="scheduleDraftItems"
            disabled={isDisableScheduleDrafts}
            onClick={handleScheduleDraftItems}>
            SCHEDULE DRAFT ITEMS
          </PrimaryButton>
          <div className="grid-x cell small-12">
            <div className="cell small-7 margin-left-2">
              <div className="margin-top-2">
                <DateRangeIcon className="vertical-middle px-margin-right-5" />
                <a href=" " className="time-range-link" onClick={openFinalizeDialog}>
                  Finalize window for all Draft
                </a>
              </div>
              <div className="offering-times margin-top-half-1">
                <span className="px-text-description">Days for Finalize:</span>
                <span className="padding-left-1">{`${
                  offeringTimeRange?.daysToFinalize ?? 0
                }D`}</span>
              </div>
            </div>
            <div className="cell small-4 px-start-end-time">
              <div className="margin-top-2">
                <AccessTimeIcon className="vertical-middle px-margin-right-5" />
                <a href=" " className="time-range-link" onClick={openTimeSelector}>
                  Start and End Times for all Drafts
                </a>
              </div>
              <div className="offering-times margin-top-half-1">
                <span className="px-text-description">Offering start:</span>
                <span className="padding-left-1">
                  {convertSecondsToTime(offeringTimeRange?.offerStartTimeInSeconds ?? 0, 'hh:mm a')}{' '}
                  CT
                </span>
              </div>
              <div className="offering-times margin-top-half-1">
                <span className="px-text-description">Offering and Negotiating end:</span>
                <span className="padding-left-1 ">
                  {convertSecondsToTime(offeringTimeRange?.offerEndTimeInSeconds ?? 0, 'hh:mm a')}{' '}
                  CT
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cell small-12 margin-bottom-1">
        <SearchFieldV1
          startSearch={startSearch}
          view="itemScheduling"
          searchTerm={searchTerm.searchText}
        />
      </div>
      <div className="cell small-12">
        {!!filterCountState && !!settings && filtersApplied && tableData?.length ? (
          <Filters
            requiredFilters={['category', 'warehouse']}
            optionalFilters={settings.optionalFilters}
            // Settings used to configure chips
            chipsDataState={{
              ...settings,
              ...advanceChipFilterDataState(settings),
            }}
            // list of filters applied
            filtersApplied={filtersApplied}
            setFiltersApplied={setFiltersApplied}
            // advanced Filters
            advanceFilters={advanceFilters}
            setAdvanceFilter={setAdvanceFilter}
            // Menu (count of items per attribute)
            filterCountState={filterCountState}
            // Chips events
            chipsEvent={chipsEvent}
            // resetFilter
            resetFilter={resetFilter}
            excludeOutOfStock={true}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="cell small-12">
        <Tabs
          value={tab}
          onChange={tabChange}
          aria-label="simple tabs example"
          indicatorColor="secondary"
          textColor="secondary"
          color="primary"
          className="margin-top-1 px-inventory-tabs">
          <Tab
            label={IItemSchedulingTab.all}
            key={IItemSchedulingTab.all}
            value={IItemSchedulingTab.all}
            color="primary"
            data-id="allTab"
          />
          <Tab
            label={
              <Badge
                data-id="draftTab"
                badgeContent={tabStats[IItemSchedulingTab.draft] || 0}
                className="draft-badge"
                max={Infinity}
                invisible={false}>
                {IItemSchedulingTab.draft}
              </Badge>
            }
            key={IItemSchedulingTab.draft}
            value={IItemSchedulingTab.draft}
            color="primary"
            data-id="draftTab"
          />
          <Tab
            label={
              <Badge
                data-id="scheduledTab"
                badgeContent={tabStats[IItemSchedulingTab.scheduled] || 0}
                className="scheduled-badge"
                max={Infinity}
                invisible={false}>
                {IItemSchedulingTab.scheduled}
              </Badge>
            }
            key={IItemSchedulingTab.scheduled}
            value={IItemSchedulingTab.scheduled}
            color="primary"
            data-id="scheduledTab"
          />
          <Tab
            label={
              <Badge
                data-id="offeringTab"
                badgeContent={tabStats[IItemSchedulingTab.offering] || 0}
                className="offering-badge"
                max={Infinity}
                invisible={false}>
                {IItemSchedulingTab.offering}
              </Badge>
            }
            value={IItemSchedulingTab.offering}
            key={IItemSchedulingTab.offering}
            color="primary"
            data-id="offeringTab"
          />
          <Tab
            label={
              <Badge
                data-id="negotiatingTab"
                badgeContent={tabStats[IItemSchedulingTab.negotiating] || 0}
                className="phonex_theme_variant negotiating-badge"
                max={Infinity}
                invisible={false}>
                {IItemSchedulingTab.negotiating}
              </Badge>
            }
            key={IItemSchedulingTab.negotiating || 0}
            value={IItemSchedulingTab.negotiating || 0}
            color="primary"
            data-id="negotiatingTab"
          />
          <Tab
            label={
              <Badge
                data-id="finalizingTab"
                badgeContent={tabStats[IItemSchedulingTab.finalizing] || 0}
                className="phonex_theme_variant finalizing-badge"
                max={Infinity}
                invisible={false}>
                {IItemSchedulingTab.finalizing}
              </Badge>
            }
            key={IItemSchedulingTab.finalizing}
            value={IItemSchedulingTab.finalizing}
            color="primary"
            data-id="finalizingTab"
          />
          <Tab
            label={
              <Badge
                data-id="shippingTab"
                badgeContent={tabStats[IItemSchedulingTab.shipping] || 0}
                className="shipping-badge"
                max={Infinity}
                invisible={false}>
                {IItemSchedulingTab.shipping}
              </Badge>
            }
            key={IItemSchedulingTab.shipping}
            value={IItemSchedulingTab.shipping}
            color="primary"
            data-id="shippingTab"
          />
        </Tabs>
      </div>
      {[IItemSchedulingTab.all, IItemSchedulingTab.draft, IItemSchedulingTab.scheduled].includes(
        tab
      ) && (
        <div className="cell small-12 margin-top-2 px-itemsetup-select-panel">
          <Checkbox
            checked={selectedRowsState.isChecked}
            indeterminate={selectedRowsState.isIndeterminate}
            onChange={toggleSelectedRowsHandler}
            disabled={
              (!tabStats[IItemSchedulingTab.draft] && !tabStats[IItemSchedulingTab.scheduled]) ||
              (tabStats[IItemSchedulingTab.draft] === 0 &&
                tabStats[IItemSchedulingTab.scheduled] === 0) ||
              (tab === IItemSchedulingTab.draft && !tabStats[tab]) ||
              (tab === IItemSchedulingTab.scheduled && !tabStats[tab])
            }
            color="primary"
          />
          {selectedRowsState.count > 0 && (
            <span className="margin-right-2">{selectedRowsState.count} Items Selected</span>
          )}
          {[IItemSchedulingTab.all, IItemSchedulingTab.draft].includes(tab) && (
            <PrimaryButton
              disabled={
                filteredState?.length === 0 ||
                selectedRowsState.count < 1 ||
                selectedRowsState.hasScheduled
              }
              onClick={openBulkUpdateDatesDialog}>
              <MultipleSelected className="multiple-selected" />
              BULK UPDATE DATES
            </PrimaryButton>
          )}
          <SecondaryButton
            disabled={filteredState?.length === 0 || selectedRowsState.count < 1}
            className="margin-left-1"
            onClick={openBulkDeleteDialog}>
            <DeleteIcon className="multiple-selected" />
            BULK DELETE ITEMS
          </SecondaryButton>
        </div>
      )}
      <div className="cell small-12 margin-top-2 px-itemsetup-grid">
        {itemsState ? (
          <ItemSetupGrid
            ref={pxAgGridRef}
            setSelectedRowsState={setSelectedRowsState}
            selectedNodeIds={selectedRowsState.rowIds}
            items={filteredState}
            tabIndexState={tabIndexStateRef}
            updateItem={updateItem}
            deleteItem={deleteItem}
            setLoadingDialog={setLoadingDialog}
            getShippingWindow={shippingWindow}
            allItemSchedules={allItemSchedules}
            offeringTimeRange={offeringTimeRange}
          />
        ) : (
          <SectionProgress />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  itemsListState: state.itemSchedulingState,
});

const mapDispatchToProps = (dispatch: any) => ({
  itemSchedulingActions: bindActionCreators(itemSchedulingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemSetup);
