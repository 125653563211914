import React from 'react';
import { ICloseFilterDialogParams, IFilterSaveParams } from '../../types/IFilterEngine';
import { IDateRange } from '../../types/IPricing';
import FilterDate from '../Filters/FilterDate/FilterDate';
interface IDateRangeAnalyticsSelector extends IDateRange {
  dateRangeDialog: boolean;
  updateCustomDate: (params: IFilterSaveParams) => void;
  closeDateRangeSelector: (params: ICloseFilterDialogParams) => void;
  clearDateRangeSelector: (params: ICloseFilterDialogParams) => void;
  disableStartBefore?: string;
  type?: string;
  disableDates?: {
    endDate?: {
      after?: string;
    };
  };
  disableCustom?: any;
}
function DateRangeAnalyticsSelector({
  dateRangeDialog,
  startDate,
  endDate,
  updateCustomDate,
  closeDateRangeSelector,
  clearDateRangeSelector,
  disableStartBefore,
  disableDates,
  type,
  disableCustom,
}: IDateRangeAnalyticsSelector) {
  let customObj: any = {
    type,
    startDate,
    endDate,
  };
  if (!dateRangeDialog) {
    customObj = null;
  }

  return (
    <FilterDate
      customOptionObj={customObj}
      title={'Date Select'}
      attribute={'date'}
      saveFilter={updateCustomDate}
      closeDialog={closeDateRangeSelector}
      clearFilter={clearDateRangeSelector}
      filterState={{}}
      getCount={() => 1}
      getLabel={() => ''}
      openDialog={() => {}}
      presetOptionObj={{}}
      setAdvanceFilter={() => {}}
      disableStartBefore={disableStartBefore}
      disableBothDates={disableDates}
      disableCustomDateSelection={disableCustom}
    />
  );
}

export default DateRangeAnalyticsSelector;
