import * as types from './actionTypes';

export function customerSet(customer: any) {
  return { type: types.CUSTOMER_SET, payload: customer };
}

export function customerAdd(customer: any) {
  return { type: types.CUSTOMER_ADD, payload: customer };
}

export function customerDelete(customer: any) {
  return { type: types.CUSTOMER_DELETE, payload: customer };
}

export function customerUpdate(customer: any) {
  return { type: types.CUSTOMER_UPDATE, payload: customer };
}

export function customerStatusUpdate(id: any, status: any) {
  return {
    type: types.CUSTOMER_STATUS_UPDATE,
    payload: {
      id,
      status,
    },
  };
}

export function customerSetting(settings: any, salesRep: any) {
  return {
    type: types.CUSTOMER_SETTINGS,
    payload: {
      settings,
      salesRep,
    },
  };
}

export function customerSalesRep(salesRep: any) {
  return { type: types.CUSTOMER_SALES_REP, salesRep };
}

export function customerLoadedSet(loaded: boolean) {
  return { type: types.CUSTOMER_LOADING_SET, payload: loaded };
}
