import React from 'react';
import { IBillingShippingComponent } from '../../types/IBillingShippingTypes';
import './BillingShippingComponent.scss';
function BillingShippingComponent({ payload, defaults }: IBillingShippingComponent) {
  const generateAddressUI = (optionKey: 'shippingAddress' | 'billingAddress') => {
    let currentlySelectedData: any = {};
    switch (optionKey) {
      case 'shippingAddress':
        currentlySelectedData = defaults.shippingAddress ?? {};
        break;
      case 'billingAddress':
        currentlySelectedData = defaults.billingAddress ?? {};
        break;
    }
    if (typeof currentlySelectedData === 'object' && Object.keys(currentlySelectedData).length) {
      return (
        <p>
          {payload.buyerName}
          <br />
          {currentlySelectedData['address1']}
          {currentlySelectedData['address2'] && (
            <>
              <br /> {currentlySelectedData['address2']}
            </>
          )}
          <br />
          {['city', 'state', 'country', 'zip']
            .filter((key) => currentlySelectedData[key])
            .reduce((a, b) => a + currentlySelectedData[b] + ', ', '')
            .trim()
            .replace(/([,]*)$/g, '')}
          {currentlySelectedData['contact'] && (
            <>
              <br /> {currentlySelectedData['contact']}
            </>
          )}
        </p>
      );
    } else {
      return '--';
    }
  };
  const BillingShippingAddress = () => {
    return (
      <>
        <div className="px-flexbox-item">
          <h5 className="order-header">Billing Address:</h5>
          {generateAddressUI('billingAddress')}
        </div>
        <div className="px-flexbox-item">
          <h5 className="order-header">Shipping Address:</h5>
          {generateAddressUI('shippingAddress')}
        </div>
      </>
    );
  };
  return (
    <>
      <div className="grid-x px-address-container">
        <BillingShippingAddress />
      </div>
      <div className="px-order-container">
        <div className="px-flexbox-item">
          <h5 className="order-header">Customer#:</h5>
          <div className="cell small-12 px-customer-data">{payload?.buyerNumber}</div>
        </div>
        <div className="px-flexbox-item">
          <h5 className="order-header">Warehouse:</h5>
          <div className="cell small-12 px-customer-data">{payload?.warehouse}</div>
        </div>
        <div className="px-flexbox-item">
          <h5 className="order-header">Shipping Method:</h5>
          <div className="cell small-12 px-customer-data">
            {!!defaults.shippingMethod ? defaults.shippingMethod : '--'}
          </div>
        </div>
        <div className="px-flexbox-item">
          <h5 className="order-header">Payment Method:</h5>
          <div className="cell small-12 px-customer-data">
            {!!defaults.paymentMethod ? defaults.paymentMethod : '--'}
          </div>
        </div>
        {/* <div className = 'px-flexbox-item-5'>
        <h5 className="order-header">Order Date:</h5>
        <div className="cell small-12 px-customer-data">{!!defaults.paymentMethod ? defaults.paymentMethod : '--'}</div>
        </div> */}
      </div>
    </>
  );
}
export default BillingShippingComponent;
