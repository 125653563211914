import { InputAdornment, makeStyles, TextFieldProps, Tooltip } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { isAfter, isBefore, isValid, parse } from 'date-fns';
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import TextField from '../AtomComponents/TextField';
import DateDialog from './DateDialog';
import './DateField.scss';
import ErrorIcon from '@material-ui/icons/Error';
import { dateFormats, manipulateDates } from '../PreOrders/Utils';

interface IDateField {
  textfieldProps?: TextFieldProps;
  date: any;
  setDate: any;
  disabled?: {
    after?: any;
    before?: any;
  };
  dateFormat?: string;
  params?: any;
  updateHandler?: any;
  showNoDate?: any;
  disableEdit?: boolean;
}

const tooltipStyle = makeStyles({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'red',
    height: '5px',
  },
});

const DateField = forwardRef(
  (
    {
      textfieldProps,
      date,
      setDate,
      disabled,
      dateFormat,
      params,
      updateHandler,
      showNoDate,
      disableEdit,
    }: IDateField,
    ref: any
  ) => {
    const tooltipClass = tooltipStyle();
    const [currentDate, setCurrentDate] = useState<string>(date);
    const [dateError, setDateError] = useState<string>('');
    const [openCalendarState, setOpenCalendarState] = useState<boolean>(false);
    const commonDateFormat = dateFormat ? dateFormat : 'MM/dd/yyyy';

    useEffect(() => {
      setCurrentDate(date);
    }, [date]);

    const onChange = (e: any) => {
      setCurrentDate(e.target.value);
      setDate(e.target.value);
    };
    const changeDateFromCalendar = (calendarDate: Date) => {
      if (isValid(calendarDate)) {
        setCurrentDate(
          manipulateDates({
            date: calendarDate.toString(),
            formatTo: commonDateFormat,
            manipulate: { days: 0 },
          })
        );
        setDate(
          manipulateDates({
            date: calendarDate.toString(),
            formatTo: commonDateFormat,
            manipulate: { days: 0 },
          })
        );
        if (!!updateHandler && params) {
          setDateError('');
          updateHandler(
            params,
            manipulateDates({
              date: calendarDate.toString(),
              formatTo: commonDateFormat,
              manipulate: { days: 0 },
            }),
            setDateError,
            setCurrentDate
          );
        }
      }
    };
    const toggleCalendar = () => {
      if (textfieldProps?.disabled) {
        return;
      }
      setOpenCalendarState(!openCalendarState);
    };

    useImperativeHandle(
      ref,
      () => ({
        isCorrectDate: () => {
          // find the format
          const dateFormatsSupported = [
            'Mo-dd-yyyy',
            'MM-dd-yyyy',
            'MMM-dd-yyyy',
            'MMMM-dd-yyyy',
            'MMMMM-dd-yyyy',
            'Mo/dd/yyyy',
            'MM/dd/yyyy',
            'MMM/dd/yyyy',
            'MMMM/dd/yyyy',
            'MMMMM/dd/yyyy',
            'Mo dd yyyy',
            'MM dd yyyy',
            'MMM dd yyyy',
            'MMMM dd yyyy',
            'MMMMM dd yyyy',
            'Mo.dd.yyyy',
            'MM.dd.yyyy',
            'MMM.dd.yyyy',
            'MMMM.dd.yyyy',
            'MMMMM.dd.yyyy',
            'MM/dd',
            'MM.dd',
            'MM dd',
            'MMMM/dd',
            'MMMM.dd',
            'MMMM dd',

            'yyyy-dd-Mo',
            'yyyy-dd-MM',
            'yyyy-dd-MMM',
            'yyyy-dd-MMMM',
            'yyyy-dd-MMMMM',
            'yyyy/dd/Mo',
            'yyyy/dd/MM',
            'yyyy/dd/MMM',
            'yyyy/dd/MMMM',
            'yyyy/dd/MMMMM',
            'yyyy dd Mo',
            'yyyy dd MM',
            'yyyy dd MMM',
            'yyyy dd MMMM',
            'yyyy dd MMMMM',
            'yyyy.dd.Mo',
            'yyyy.dd.MM',
            'yyyy.dd.MMM',
            'yyyy.dd.MMMM',
            'yyyy.dd.MMMMM',

            'dd-Mo-yyyy',
            'dd-MM-yyyy',
            'dd-MMM-yyyy',
            'dd-MMMM-yyyy',
            'dd-MMMMM-yyyy',
            'dd/Mo/yyyy',
            'dd/MM/yyyy',
            'dd/MMM/yyyy',
            'dd/MMMM/yyyy',
            'dd/MMMMM/yyyy',
            'dd Mo yyyy',
            'dd MM yyyy',
            'dd MMM yyyy',
            'dd MMMM yyyy',
            'dd MMMMM yyyy',
            'dd.Mo.yyyy',
            'dd.MM.yyyy',
            'dd.MMM.yyyy',
            'dd.MMMM.yyyy',
            'dd.MMMMM.yyyy',
            'dd/MMMM',
            'dd.MMMM',
            'dd MMMM',
          ];

          for (let i = 0; i < dateFormatsSupported.length; i++) {
            const parsedDate = parse(date, dateFormatsSupported[i], new Date());
            if (isValid(parsedDate)) {
              console.log(
                'current date: ',
                parsedDate,
                'is before',
                disabled?.before,
                'is',
                isBefore(parsedDate, parse(disabled?.before, commonDateFormat, new Date())),
                'is after',
                disabled?.after,
                'is',
                isBefore(parsedDate, parse(disabled?.after, commonDateFormat, new Date())),
                'format',
                dateFormatsSupported[i]
              );
              if (
                disabled?.before &&
                isBefore(parsedDate, parse(disabled.before, commonDateFormat, new Date()))
              ) {
                // console.log(
                //   'current date: ',
                //   parsedDate,
                //   'is before',
                //   disabled.before,
                //   'is',
                //   isBefore(parsedDate, parse(disabled.before, commonDateFormat, new Date())),
                //   'format',
                //   dateFormatsSupported[i]
                // );
                // check the date is before
                setDateError(`The ${textfieldProps?.label} cannot be before ${disabled.before}`);
                return false;
              }
              if (
                disabled?.after &&
                isAfter(parsedDate, parse(disabled.after, commonDateFormat, new Date()))
              ) {
                // console.log(
                //   'current date: ',
                //   parsedDate,
                //   'is after',
                //   disabled.after,
                //   'is',
                //   isAfter(parsedDate, parse(disabled.after, commonDateFormat, new Date())),
                //   'format',
                //   dateFormatsSupported[i]
                // );
                // check the date is after
                setDateError(`The ${textfieldProps?.label} cannot be after ${disabled.after}`);
                return false;
              }
              setDateError('');
              setCurrentDate(
                manipulateDates({
                  date: parsedDate.toString(),
                  formatTo: dateFormats.yearMonthDate,
                  manipulate: { days: 0 },
                })
              );
              return parsedDate;
            }
          }

          setDateError(
            'Invalid Date! please enter the date in the format: MM/DD/YYYY or DD/MM/YYYY'
          );
          return false;
        },
        removeError: () => {
          onFocus();
        },
        showError: (error: string) => {
          setDateError(error);
        },
      }),
      // eslint-disable-next-line
      [date]
    );

    const onFocus = () => {
      if (!disableEdit) setDateError('');
    };
    const startAdornment = !!dateError ? (
      <Tooltip classes={tooltipClass} disableFocusListener disableTouchListener title={dateError}>
        <ErrorIcon className="errorMessage" color="error" />
      </Tooltip>
    ) : (
      <></>
    );

    return (
      <div className="px-datefield-container">
        <DateDialog
          onChange={changeDateFromCalendar}
          disabled={disabled}
          dateVal={currentDate}
          dateFormat={dateFormat}
          openDialog={openCalendarState}
          closeDialog={toggleCalendar}
        />
        <TextField
          onChange={onChange}
          onFocus={onFocus}
          {...textfieldProps}
          error={!!dateError}
          helperText={''}
          value={showNoDate ? '' : currentDate}
          InputProps={{
            startAdornment,
            endAdornment: (
              <InputAdornment className="pointer-mouse" position="end" onClick={toggleCalendar}>
                <CalendarToday />
              </InputAdornment>
            ),
            readOnly: disableEdit,
          }}
        />
      </div>
    );
  }
);

export default DateField;
