import React, { Dispatch } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  RowSelectedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { dataFormatting, detailGridRowHeight } from '../../_lib/lib';
import Chip from '@material-ui/core/Chip';
import { CustomInputButton } from './CustomInputButtom';
import GridInput from '../../components/GridInput/GridInput';
import { AllTypes, awardsKey } from '../../types/IPreOrders';
import { preprocessValue } from '../../components/PreOrders/UpdateAwards/UpdateAwardsHandler';
import {
  awaitingRule,
  awardedRule,
  offerPriceRevComparatorNew,
  reofferRule,
} from '../../components/PreOrders/Utils';
import './DetailGrid.scss';
import { IPoClearingTabs } from '../../types/IPoClearing';
import { IPooffersItems } from '../../types/IPoOffers';
import { GetRowNodeIdFunc, ICellRendererParams } from '@ag-grid-community/all-modules';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { PopoverWrapper } from './PopoverWrapper';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';

interface Ilabels {
  AWAITING_REVIEW: string;
  REOFFER_AWAITING_REVIEW: string;
  REOFFER_REQUEST: string;
  AWARDED: string;
  REJECTED: string;
}
const scheduleStatus = {
  offering: 'OFFERING',
  negotiating: 'NEGOTIATING',
  finalizing: 'FINALIZING',
  shipping: 'SHIPPING',
};

interface INode extends GetRowNodeIdFunc {
  offerId: string;
  id: number;
}

const DetailGrid: any = React.forwardRef((props: any, ref: any) => {
  let initialIds = props.initialIds,
    detailApi: any,
    masterGridApi = props.api,
    nodeId = `${props.data.itemElectId}`;
  const data = props.data;

  let offerData: any = {};
  let quantityValue: any = {};
  let setErrorFunc: React.Dispatch<string> = async () => {};
  let endAdornmentHandle: any = async () => {};
  let isErrorOccur = false;

  // const offerHistoryInfo = (
  //   <div className="info-details">
  //     <div className="history-items date-ongoing">
  //       <h6>Offer: </h6>
  //       <p>Jul 01, 5:00 am </p>
  //     </div>
  //     <div className="history-items">
  //       <h6>Re-Offer Request: </h6>
  //       <p>Jul 01, 5:00 am </p>
  //     </div>
  //     <div className="history-items">
  //       <h6>Offer: </h6>
  //       <p>Jul 01, 5:00 am </p>
  //     </div>
  //   </div>
  // );

  let dateStatus;
  switch (data.itemStatus) {
    case scheduleStatus.offering:
      dateStatus = 0;
      break;
    case scheduleStatus.negotiating:
      dateStatus = 1;
      break;
    case scheduleStatus.finalizing:
      dateStatus = 2;
      break;
    default:
      dateStatus = 3;
      break;
  }

  const scheduleInfo = (
    <div className="info-details">
      <div
        className={`info-items ${dateStatus > 0 && 'date-passed'} ${
          dateStatus === 0 && 'date-ongoing'
        }`}>
        <h6>Offering: </h6>
        <p>
          {`${moment(data.offerStartDate).format('MMM DD, hh:mm a')} - ${moment(
            data.offerEndDate
          ).format('MMM DD, hh:mm a')}`}
        </p>
        {data.itemStatus === scheduleStatus.offering && (
          <div style={{ display: 'inline-flex' }}>
            <AccessAlarmIcon />
            <p>{data.durationLeft}</p>
          </div>
        )}
      </div>
      <div
        className={`info-items ${dateStatus > 1 && 'date-passed'} ${
          dateStatus === 1 && 'date-ongoing'
        }`}>
        <h6>Negotiating: </h6>
        <p>
          {`${moment(data.negotiatingStartDate).format('MMM DD, hh:mm a')} - ${moment(
            data.negotiatingEndDate
          ).format('MMM DD, hh:mm a')}`}
        </p>
        {data.itemStatus === scheduleStatus.negotiating && (
          <div style={{ display: 'inline-flex' }}>
            <AccessAlarmIcon />
            <p>{data.durationLeft}</p>
          </div>
        )}
      </div>
      <div
        className={`info-items ${dateStatus > 2 && 'date-passed'} ${
          dateStatus === 2 && 'date-ongoing'
        }`}>
        <h6>Finalizing: </h6>
        <p>
          {`${moment(data.finalizingStartDate).format('MMM DD, hh:mm a')} - ${moment(
            data.finalizingEndDate
          ).format('MMM DD, hh:mm a')}`}
        </p>
        {data.itemStatus === scheduleStatus.finalizing && (
          <div style={{ display: 'inline-flex' }}>
            <AccessAlarmIcon />
            <p>{data.durationLeft}</p>
          </div>
        )}
      </div>
      <div
        className={`info-items ${dateStatus > 3 && 'date-passed'} ${
          dateStatus === 3 && 'date-ongoing'
        }`}>
        <h6>Shipping: </h6>
        <p>
          {`${moment(data.shippingStartDate).format('MMM DD, hh:mm a')} - ${moment(
            data.shippingEndDate
          ).format('MMM DD')}, 11:59 pm`}
        </p>
        {data.itemStatus === scheduleStatus.shipping && (
          <div style={{ display: 'inline-flex' }}>
            <AccessAlarmIcon />
            <p>{data.durationLeft}</p>
          </div>
        )}
      </div>
    </div>
  );
  const scheduleView = (
    <>
      <span>{data.durationLeft}</span>
      <span className="item-status">
        {awaitingRule(data.itemStatus)
          ? 'Offering'
          : data.itemStatus.charAt(0) + data.itemStatus.slice(1).toLowerCase()}
      </span>
    </>
  );

  const updateHandler = async (
    params: any,
    newValue: string,
    setError: Dispatch<string>,
    setValue: Dispatch<AllTypes>,
    endAdornmentHandler: any
  ) => {
    let data = params.data,
      colId: awardsKey = params.column.colId;
    endAdornmentHandler('default');
    setError('');
    const { processedValue } = preprocessValue(colId, newValue, data);
    const value = +processedValue;
    let newValueWithTakePercent;
    setErrorFunc = setError;
    endAdornmentHandle = endAdornmentHandler;
    if (data.incrementSize > 1) {
      endAdornmentHandler('increment');
    }

    if (newValue === '') {
      isErrorOccur = false;
      params.data.setAwardDisable(false);
      return;
    }
    if (props.node.parent.data?.quantityAvailable === 0) {
      setError(`Quantity is greater than Available Forecast Quantity = 0`);
      endAdornmentHandler('error');
      isErrorOccur = true;
      params.data.setAwardDisable(true);
      return;
    }
    if (value === data.offerQuantity) {
      setValue(value);
      quantityValue[data.offerId] = value;
      isErrorOccur = false;
      params.data.setAwardDisable(false);
      return;
    }

    if (+value <= 0) {
      setError('Quantity must be a number greater than 0');
      endAdornmentHandler('error');
      isErrorOccur = true;
      params.data.setAwardDisable(true);
      return;
    }
    if (isNaN(+newValue)) {
      setError('Quantity must be a number greater than 0');
      endAdornmentHandler('error');
      isErrorOccur = true;
      params.data.setAwardDisable(true);
      return;
    }
    if (value - Math.floor(value) !== 0 || +newValue !== Math.floor(+newValue)) {
      setError('Quantity must not be a decimal number');
      endAdornmentHandler('error');
      isErrorOccur = true;
      params.data.setAwardDisable(true);
      return;
    } else {
      setValue(value);
      quantityValue[data.offerId] = value;
    }
    if (data.incrementSize > 1) {
      if (+value % params.data.incrementSize === 0) {
        setValue(value);
        quantityValue[data.offerId] = value;
        endAdornmentHandler('increment');
      } else {
        newValueWithTakePercent = Math.floor(+value / data.incrementSize) * data.incrementSize;
        if (newValueWithTakePercent === 0) {
          setValue(params.data.incrementSize);
          quantityValue[data.offerId] = params.data.incrementSize;
        } else {
          setValue(newValueWithTakePercent);
          quantityValue[data.offerId] = newValueWithTakePercent;
        }
        endAdornmentHandler('roundOff');
      }
    }
    isErrorOccur = false;
    params.data.setAwardDisable(false);
    offerData[data.offerId] = params.data;
  };

  const onSendPrice = async (value: string, data: IPooffersItems, type: string) => {
    const selectedOffer = !offerData[data.offerId] ? data : offerData[data.offerId];
    const offerQuantity = !offerData[data.offerId]
      ? data.offerQuantity
      : quantityValue[data.offerId];
    let dataValue = {};
    type === 'award'
      ? (dataValue = {
          ...selectedOffer,
          awardedPrice: value === '' ? data.offerPrice : +value,
          awardedQuantity: offerQuantity,
          status: IPoClearingTabs.awarded,
          offerAction: IPoClearingTabs.awarded,
          isTakeAll: data.isTakeAll === 0 ? false : true,
        })
      : (dataValue = {
          ...selectedOffer,
          reofferRequestPrice: value === '' ? null : +value,
          reofferRequestQuantity: offerQuantity,
          status: IPoClearingTabs.reofferedRequest,
          offerAction: IPoClearingTabs.reofferedRequest,
          isTakeAll: data.isTakeAll === 0 ? false : true,
        });
    const newOfferofferData = dataValue;
    if (!isErrorOccur) {
      const res = await props.updateOffer([newOfferofferData]);
      if (typeof res === 'string' && res.includes('available forecast')) {
        setErrorFunc(res);
        endAdornmentHandle('error');
        return;
      } else {
        return res;
      }
    }
  };

  const labels: Ilabels = {
    AWAITING_REVIEW: 'Offered',
    REOFFER_AWAITING_REVIEW: 'Offered',
    REOFFER_REQUEST: 'Re-offer Request',
    AWARDED: 'Awarded',
    REJECTED: 'Rejected',
  };

  const colors: Ilabels = {
    AWAITING_REVIEW: 'RGB(var(--px_color_badge_3))',
    REOFFER_AWAITING_REVIEW: 'RGB(var(--px_color_badge_3))',
    REOFFER_REQUEST: 'RGB(var(--px_color_badge_1))',
    AWARDED: 'RGB(var(--px_color_badge_2))',
    REJECTED: 'RGB(var(--px_color_badge_6))',
  };

  const onGridReady = (params: GridOptions) => {
    if (params.api) {
      let detailGridId = 'detail_' + nodeId;
      let gridInfo = {
        id: detailGridId,
        api: params.api,
        columnApi: params.columnApi,
      };
      masterGridApi.addDetailGridInfo(detailGridId, gridInfo);
      detailApi = params.api;
      detailApi.setRowData(props.data.offers);
      initialIds.forEach((id: number | string) => detailApi.getRowNode(id).setSelected(true));
    }
  };

  const onRowSelected = (params: RowSelectedEvent) => {
    if (params.data.updatingFromParent) return true;
    let selectedNodes = detailApi.getSelectedNodes();
    props.detailRowSelectedHandler(
      props.node.parent,
      masterGridApi,
      selectedNodes.map((node: INode) => node.id)
    );
  };

  const comparator = offerPriceRevComparatorNew(
    ['offerPriceValue', 'offerQuantity', 'buyerName'],
    false
  );
  let colDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Buyer',
      field: 'buyerName',
      valueFormatter: (params: ValueFormatterParams) =>
        `${params.data.buyerName} - ${params.data.buyerNumber}`,
      headerClass: 'header-settings',
      //   const nodeOrOffer = !!params.api?.rowModel.rowsToDisplay?.length ? true : false;
      //   let isHeaderCheckboxPresent = false;
      //   if (!nodeOrOffer) {
      //     isHeaderCheckboxPresent = props.data.offers?.some((val: any) => {
      //       return awaitingRule(val.status);
      //     });
      //   } else {
      //     isHeaderCheckboxPresent = params.api.rowModel.rowsToDisplay?.some(
      //       (val: any) => val.selectable
      //     );
      //   }
      //   return isHeaderCheckboxPresent === false ? 'header-settings' : '';
      headerCheckboxSelection: (params: any) => {
        if (!props.canEdit) return false;
        const nodeOrOffer = !!params.api.rowModel.rowsToDisplay?.length ? true : false;
        let isHeaderCheckboxPresent = false;
        if (!nodeOrOffer) {
          isHeaderCheckboxPresent = props.data.offers?.some((val: any) => {
            return awaitingRule(val.status);
          });
        } else {
          isHeaderCheckboxPresent = params.api.rowModel.rowsToDisplay?.some(
            (val: any) => val.selectable
          );
        }
        return isHeaderCheckboxPresent;
      },
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: (params: ICellRendererParams) => awaitingRule(params.data.status),
      cellStyle: (params: ICellRendererParams) => {
        if (!props.canEdit || !awaitingRule(params.data.status)) {
          return { paddingLeft: '58px', wordBreak: 'break-word' };
        }
        return { paddingLeft: '30px', wordBreak: 'break-word' };
      },
      width: 380,
      minWidth: 380,
      maxWidth: 380,
      colId: 'checkboxSelection',
    },
    {
      headerName: 'Offer',
      field: 'offerPrice',
      sort: 'desc',
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return comparator(
          {
            ...nodeA.data,
            offerPriceValue: valueA,
          },
          {
            ...nodeB.data,
            offerPriceValue: valueB,
          }
        );
      },
      valueFormatter: (params: ValueFormatterParams) =>
        `${params.data.offerQuantity} @ ${dataFormatting('currency', params.data.offerPrice)}`,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      headerName: 'Take All',
      field: 'isTakeAll',
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      valueGetter: (params: ValueGetterParams) => (params.data.isTakeAll ? 'YES' : '--'),
    },
    {
      headerName: 'Status',
      field: 'status',
      colId: 'status',
      minWidth: 170,
      maxWidth: 170,
      cellRendererFramework: (params: ICellRendererParams) => {
        const status = labels[params.data.status as keyof Ilabels];
        return (
          <div className="status-container">
            <Chip
              label={status}
              size="small"
              className={`px-pointer margin-bottom-1 margin-top-1`}
              style={{
                backgroundColor: colors[params.data.status as keyof Ilabels],
                color: 'RGB(var(--px_color_text_on_badge))',
              }}
              onClick={() => {}}
            />
            {/* {status === 'Offered' && (
              <PopoverWrapper
                iconsNeeded={<HistoryIcon />}
                header={<Typography>Offer History</Typography>}
                infoDetails={offerHistoryInfo}
              />
            )} */}
          </div>
        );
      },
    },
    {
      headerName: 'Quantity',
      colId: 'awardedQuantity',
      minWidth: 150,
      maxWidth: 150,
      cellRendererParams: () => ({
        updateHandler,
        refreshCells: true,
        parentNode: props.node.parent,
        disabled: !props.canEdit,
      }),
      valueFormatter: (params: ValueFormatterParams) => {
        if (awaitingRule(params.data.status)) return '';
        let price;
        if (awardedRule(params.data.status)) price = params.data.awardedPrice;
        else if (reofferRule(params.data.status)) price = params.data.reofferRequestPrice;
        else price = params.data.offerPrice;
        return `${params.value} @ ${dataFormatting('currency', price)}`;
      },
      valueGetter: (params: ValueGetterParams) => {
        if (awardedRule(params.data.status)) return params.data.awardedQuantity;
        if (reofferRule(params.data.status)) return params.data.reofferRequestQuantity;
        return params.data.offerQuantity;
      },
      cellRendererSelector: (params: any) => {
        if (!params.data || !awaitingRule(params.data.status)) return { component: '' };
        return { component: 'gridInput' };
      },
    },
    {
      headerName: '$ Price',
      // field: 'awardedPrice',
      colId: 'awardedPrice',
      maxWidth: 400,
      // valueGetter: (params: ValueGetterParams) => {
      //   if (awardedRule(params.data.status)) return params.data.awardedPrice;
      //   if (reofferRule(params.data.status)) return params.data.reofferRequestPrice;
      //   return params.data.offerPrice;
      // },
      cellRendererSelector: (params: any) => {
        if (!params.data || !awaitingRule(params.data.status)) return { component: '' };
        return { component: 'priceInputButton' };
      },
      // valueFormatter: (params: ValueFormatterParams) => {
      //   return dataFormatting('currency', params.value);
      // },
      cellRendererParams: () => ({
        onSendPrice: onSendPrice,
        updateOffer: props.updateOffer,
        offerItem: props.data,
        parentNode: props.node.parent,
        canEdit: props.canEdit,
      }),
    },
    {
      headerName: '',
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      cellStyle: { padding: '0 !important' },
      cellRendererFramework: (params: ICellRendererParams) => {
        return (
          <PopoverWrapper
            infoDetails={scheduleInfo}
            iconsNeeded={<AccessAlarmIcon />}
            header={<Typography>Schedule</Typography>}
            content={scheduleView}
          />
        );
      },
    },
    {
      headerName: ' ',
      minWidth: 200,
      maxWidth: 200,
    },
  ];

  let detailGridOptions: GridOptions = {
    headerHeight: 32,
    rowHeight: detailGridRowHeight,
    columnDefs: colDef,
    rowSelection: 'multiple',
    getRowNodeId: (data: INode) => data.offerId,
    isRowSelectable: (params: any) => {
      if (!props.canEdit) return false;
      return awaitingRule(params.data.status);
    },
    frameworkComponents: {
      priceInputButton: CustomInputButton,
      gridInput: GridInput,
    },
    rowMultiSelectWithClick: false,
    suppressRowDrag: true,
    animateRows: false,
    suppressAnimationFrame: true,
    suppressMovableColumns: true,
    suppressAggFuncInHeader: true,
    stopEditingWhenGridLosesFocus: true,
    suppressCellSelection: true,
    suppressRowClickSelection: true,
    suppressRowDeselection: true,
    enableCellTextSelection: true,
    suppressRowTransform: true,
    suppressContextMenu: true,
    domLayout: 'autoHeight',
    onGridReady,
    onRowSelected: onRowSelected,
    defaultColDef: {
      flex: 1,
      sortable: false,
      resizable: false,
      filter: false,
      menuTabs: [],
      cellStyle: { paddingLeft: '20px' },
    },
    groupDefaultExpanded: 1,
  };

  return (
    <div className="full-width-panel">
      <div
        className={`ag-theme-alpine px-full-width-grid px-detail-grid ${
          props?.pinned !== 'left' ? 'px-detail-grid-right' : ''
        }`}>
        <AgGridReact gridOptions={detailGridOptions} rowData={props.data.offers}></AgGridReact>
      </div>
    </div>
  );
});

export default DetailGrid;
