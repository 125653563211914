import * as types from '../actions/actionTypes';

export default function userReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.USER_SET:
      return { ...state, ...action.user, isSignedIn: true, pass: '*' };
    case types.USER_STATE_SET:
      return { ...state, ...action.state };
    case types.USER_AUTHORITIES_SET:
      return { ...state, authorities: action.authorities };
    default:
      return state;
  }
}
