import React from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Dialog } from '@material-ui/core';

interface IDateDialog {
  onChange: any;
  dateVal: any;
  openDialog: any;
  closeDialog: any;
  disabled?: {
    before?: any;
    after?: any;
  };
  dateFormat?: any;
}

function DateDialog({
  onChange,
  dateVal,
  openDialog,
  closeDialog,
  disabled,
  dateFormat,
}: IDateDialog) {
  const changeDateValue = (date: any) => {
    onChange(date);
    closeDialog();
  };

  return (
    <Dialog className="px-date-dialog" open={openDialog} onClose={closeDialog}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          orientation="landscape"
          variant="static"
          openTo="date"
          value={dateVal}
          minDate={disabled?.before}
          maxDate={disabled?.after}
          onChange={changeDateValue}
        />
      </MuiPickersUtilsProvider>
    </Dialog>
  );
}

export default DateDialog;
