import * as types from '../actions/actionTypes';

export default function contractsReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.CONTRACTS_SET:
      //console.log('action.contracts', action.contracts);
      //return Object.assign({}, action.contracts);
      return {
        data: action.contracts,
      };
    default:
      return state;
  }
}
