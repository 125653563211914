import * as types from './actionTypes';

export function pricingSet(pricing: any) {
  return {
    type: types.PRICING_SET,
    payload: pricing,
  };
}

export function pricingPublish() {
  return {
    type: types.PRICING_PUBLISH,
  };
}

export function pricingCounterUpdate(oldTab: string, newTab: string, data: any) {
  return {
    type: types.PRICING_COUNT_UPDATE,
    payload: {
      oldTab,
      newTab,
      data,
    },
  };
}

export function pricingUpdate(
  itemNumberWarehouse: string,
  index: number,
  column: string,
  value: any
) {
  return {
    type: types.PRICING_UPDATE,
    payload: {
      itemNumberWarehouse,
      index,
      column,
      value,
    },
  };
}

export function pricingSettingsSet(settings: any) {
  return {
    type: types.PRICING_SETTINGS_SET,
    payload: settings,
  };
}

export function pricingLoadedChange(isLoaded: any) {
  return {
    type: types.PRICING_LOADING_CHANGE,
    payload: isLoaded,
  };
}

export function pricingStatsUpdate(payload: any) {
  return {
    type: types.PRICING_STATS_UPDATE,
    payload,
  };
}
