import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PrimaryButton from '../AtomComponents/PrimaryButton';
import TertiaryButton from '../AtomComponents/TertiaryButton';
import Dialog from '../Dialog/Dialog';
import FileWell from '../AtomComponents/FileWell';
import SectionProgress from '../Progress/SectionProgress';

interface IImportDialog {
  handleUpload: any;
  handleClose: any;
  open: boolean;
  title: any;
  desc?: any;
  customError?: string;
}

export default forwardRef(
  ({ handleUpload, handleClose, title, open, desc, customError }: IImportDialog, ref: any) => {
    // import file state
    const [importFile, setImportFile] = useState<any>(null);
    // error
    const [error, setError] = useState<string>('');
    // isLoading
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
      return () => {
        setImportFile(null);
        setError('');
        setIsLoading(false);
      };
    }, [open]);

    // handle file drop
    const handleDrop = (file: any) => {
      setImportFile(file[0]);
    };

    const resetError = () => setError('');

    const handlerImportUpload = async (e: any) => {
      if (importFile) {
        setIsLoading(true);
        await handleUpload(importFile);
      } else {
        // no file exists
        setError('Please upload the file to have a successful import');
      }
    };

    useImperativeHandle(ref, () => {
      return {
        setImportError(errorData: string) {
          setError(errorData || 'Internal server error');
          setIsLoading(false);
        },
      };
    });

    return (
      <Dialog
        isDialogOpen={open}
        closeDialog={handleClose}
        label={''}
        title={title}
        className="px-import-dialog-min-width-500px"
        content={
          <>
            <div id="alert-dialog-description">
              <div>{desc}</div>
            </div>
            <FileWell
              filesLimit={1}
              acceptedFiles={[
                '.xlsx',
                '.xls',
                'application/vnd.ms-excel',
                'application/msexcel',
                'application/x-msexcel',
                'application/x-ms-excel',
                'application/x-excel',
                'application/x-dos_ms_excel',
                'application/xls',
                'application/x-xls',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/excel',
              ]}
              handleDrop={handleDrop}
              customError={customError}
              resetError={resetError}
            />
            {isLoading && (
              <span className="margin-top-1">
                <SectionProgress />
              </span>
            )}
            {error && <div className="px-import-error margin-top-1">{error}</div>}
          </>
        }
        actions={
          <>
            <TertiaryButton data-id="cancelFile" onClick={handleClose}>
              Cancel
            </TertiaryButton>
            <PrimaryButton data-id="uploadFile" onClick={handlerImportUpload} autoFocus>
              Upload
            </PrimaryButton>
          </>
        }
      />
    );
  }
);
