import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import {
  Tooltip,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ErrorIcon from '@material-ui/icons/Error';
import { Info, Warning } from '@material-ui/icons';
import '../../views/PreOrders/PreOrders.scss';
import { checkValidTakeAll } from '../PreOrders/TableCellComponents';
import {
  getAggregatedNewFulfilledValidation,
  isFundingRequired,
} from '../PreOrders/UpdateAwards/UpdateAwardsHandler';
import { errorMessages } from '../PreOrders/Utils';

const tooltipStyleError = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'red',
    height: '5px',
    paddingTop: 0,
  },
}));
export const tooltipStyleMsg = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'black',
    height: '5px',
    paddingTop: 0,
  },
}));

const GridInput = forwardRef((props: any, ref) => {
  const tooltipClassMsg = tooltipStyleMsg();
  const tooltipClassError = tooltipStyleError();
  const [value, setValue] = useState(
    props.isCheckBox ? isFundingRequired[props.value] : props.disabled ? '' : props.valueFormatted
  );
  const [disabled, setDisabled] = useState<boolean>(props?.disabled ?? false);
  const [error, setError] = useState<any>('');
  const [recommendedMsg, setRecommendedMsg] = useState<string>('');
  const refInput: any = useRef(null);
  const [endAdornment, setEndAdornment] = useState<JSX.Element>(<></>);

  const disableHandler = (status: boolean, message: string) => {
    setDisabled(status);
    setError(message);
    setValue(status ? '' : props.data?.[props.column.colId]);
    setEndAdornment(getAdornment('default'));
  };

  const getAdornment = (endAdornmentStatus: string) => {
    switch (endAdornmentStatus) {
      case 'roundOff': {
        return (
          <Tooltip
            classes={tooltipClassMsg}
            disableFocusListener
            disableTouchListener
            title={incrementMsg}>
            <Warning className="px-message-warning" />
          </Tooltip>
        );
      }
      case 'error': {
        return (
          <Tooltip
            classes={tooltipClassError}
            disableFocusListener
            disableTouchListener
            title={error}>
            <ErrorIcon className="errorMessage" color="error" />
          </Tooltip>
        );
      }
      case 'increment': {
        return (
          <Tooltip
            classes={tooltipClassMsg}
            disableFocusListener
            disableTouchListener
            title={incrementMsg}>
            <Info className="px-message-info" />
          </Tooltip>
        );
      }
      case 'default': {
        return <></>;
      }
      default:
        return <></>;
    }
  };

  const incrementMsg = `Increments of ${props.data?.incrementSize}`;
  const endAdornmentHandler = (status: string) => setEndAdornment(getAdornment(status));

  useEffect(() => {
    if (error === '') {
      if (!(props.data?.incrementSize > 1 && helperTextCols.includes(props.column.colId)))
        setEndAdornment(getAdornment('default'));
      else setEndAdornment(getAdornment('increment'));
    }
    if (error !== incrementMsg && error !== '') setEndAdornment(getAdornment('error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (props.data?.incrementSize > 1 && helperTextCols.includes(props.column.colId)) {
      setEndAdornment(getAdornment('increment'));
    } else {
      setEndAdornment(getAdornment('default'));
    }
    if (!props.node.master && !!props.data) {
      const errorHandlers = {
        ...props.data?.errorHandlers,
        [props.column.colId]: { error: setError, endAdornment: endAdornmentHandler },
      };
      props.data.errorHandlers = errorHandlers;
    }

    if (props.column.colId === 'takeAllLimitPercentage') {
      props.data.setTakeLimitDisabled = disableHandler;
    }
    if (props.column.colId === 'isTakeAll') {
      props.data.setIsTakeAllError = setError;
    }
    if (props.column.colId === 'quantityReadyToFulfill' && !props.textOnly) {
      props.recommendedCal(props.data, value, setRecommendedMsg);
    }
    if (props.refreshCells) {
      if (props.parentNode.data.quantityAvailable === 0) {
        setDisabled(true);
      }
    }
    if (props.column.colId !== 'quantityReadyToFulfill') return;
    const isNewFulfilledLessThanAvailable = getAggregatedNewFulfilledValidation(
      [],
      props.data,
      props.data?.quantityReadyToFulfill.toString()
    );
    if (!isNewFulfilledLessThanAvailable) {
      setError(errorMessages.updateAwards.newFulfilledValidation);
      setEndAdornment(getAdornment('error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        // this simple editor doubles any value entered into the input
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any value greater than 1000
        return false;
      },
    };
  });

  const updateValueHandler = (event: any) => {
    if (!!props.updateHandler && props?.isCheckBox) {
      setValue(event.target.checked);
      props.updateHandler(props, event.target.checked ? 'Yes' : 'No', () => {}, setValue);
      return;
    }
    if (!!props.updateHandler && props?.takeAll) {
      const { isValid, message, oldValue: originalValue } = checkValidTakeAll(
        event.target.value,
        'isTakeAll',
        props.data
      );
      if (!isValid) {
        props.data?.setTakeLimitDisabled(false, message);
        props.data?.setIsTakeAllError(true);
        setValue(originalValue);
        return;
      }
      props.updateHandler(props, event.target.value, setError, setValue);
      return;
    }
    if (!!props.updateHandler) {
      props.updateHandler(props, value, setError, setValue, endAdornmentHandler);
    }
  };
  const helperTextCols = ['awardedQuantity', 'quantityReadyToFulfill', 'quantityForecast'];

  const { style, title } =
    parseInt(recommendedMsg) >= 0
      ? { style: { backgroundColor: '#E1F5FE' }, title: `Recommended:${recommendedMsg}` }
      : { style: {}, title: '' };

  if (!!props.textOnly) {
    return <>{value}</>;
  }

  if (!!props.isCheckBox) {
    let styles: React.CSSProperties = {
      wordWrap: 'break-word',
      width: '100px',
      pointerEvents: 'none',
      cursor: 'default',
    };
    // PO-225 ( Disable Retaining Funding Requied Flag in local )
    // let className;
    // if (props.disablePointerAction) {
    //   styles = { ...styles, pointerEvents: 'none', cursor: 'default' };
    //   className = 'disablePointerFunding';
    // }
    return (
      <>
        <span style={{ marginLeft: '10px' }}>{props.data?.buyer_id}</span>
        <FormControlLabel
          style={styles}
          disabled={disabled}
          control={
            // disabled ? (
            //   <Checkbox name="a" color="primary" data-id="fundingCheckbox" />
            // ) : (
            <Checkbox
              name="a"
              color="primary"
              className="disablePointerFunding"
              checked={value}
              data-id="fundingCheckbox"
            />
            // )
          }
          // onChange={updateValueHandler}
          label={props.checkBoxLabel}
        />
      </>
    );
  }

  return (
    <Tooltip classes={tooltipClassMsg} title={title}>
      <TextField
        ref={refInput}
        value={value}
        onChange={(event: any) => {
          setValue(event.target.value);
          setError('');
          if (props.refreshCells) {
            props.data.setAwardDisable(false);
          }
          if (props.data?.incrementSize > 1 && helperTextCols.includes(props.column.colId))
            setEndAdornment(getAdornment('increment'));
          else setEndAdornment(getAdornment('default'));
          if (props.takeAll) updateValueHandler(event);
        }}
        onBlur={(event: any) => {
          if (!props.takeAll) updateValueHandler(event);
        }}
        error={!!error}
        variant="outlined"
        disabled={disabled}
        style={{ minWidth: '80px', width: '100%' }}
        InputProps={{
          style,
          startAdornment: !!props.startAdornment && (
            <InputAdornment position="start">{props.startAdornment.symbol}</InputAdornment>
          ),
          endAdornment: props.takeAll ? null : endAdornment,
        }}
        size="small"
        select={!!props.takeAll}>
        <MenuItem value="Y">Y</MenuItem>
        <MenuItem value="N">N</MenuItem>
      </TextField>
    </Tooltip>
  );
});

export default GridInput;
