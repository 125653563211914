import React, { createElement, useEffect, useMemo, useState } from 'react';
import TertiaryButton from '../AtomComponents/TertiaryButton';
import './index.scss';
import FilterAttribute from './FilterAttribute/FilterAttribute';
import FilterBuyers from './FilterBuyers/FilterBuyers';
import FilterDate from './FilterDate/FilterDate';
import FilterDaysToSell from './FilterDaysToSell/FilterDaysToSell';
import FilterNew from './FilterNew/FilterNew';
import FiltersRequired from './FiltersRequired/FiltersRequired';
import FilterWarehouse from './FilterWarehouse/FilterWarehouse';
import {
  IClearFiltersParams,
  ICloseFilterDialogParams,
  IDeletePreviousFilterFn,
  IDialogInfo,
  IFilterDialog,
  IFilterDialogOpenFn,
  IFiltersUI,
  IGetFilterOptionCount,
  IFilterSaveParams,
  INewFilterTabType,
} from '../../types/IFilterEngine';
import FilterOfferRange from './FilterOfferRange/FilterOfferRange';
import { formatString } from '../../ApiMapping';
import FilterItemsAvailability from './FilterItemsAvailability/FilterItemsAvailability';
import FilterAddedToStock from './FilterAddedToStock/FilterAddedToStock';
import FilterTopOffers from './FilterTopOffers/FilterTopOffers';
import FilterChip from './FilterChip';
import FilterItems from './FilterItems/FilterItems';
import FilterCategory from './FilterCategory/FilterCategory';
import { cloneDeep, isEmpty, values } from 'lodash';

let previousFilteredCounts: any = undefined;

function Filters({
  filterCountState,
  chipsEvent,
  chipsDataState,
  requiredFilters,
  optionalFilters,
  customFilters,
  filtersApplied,
  excludeOutOfStock = false,
  setFiltersApplied,
  advanceFilters,
  setAdvanceFilter,
  resetFilter,
  children,
  isPreorder,
}: IFiltersUI) {
  // declare the component list
  const componentsList: any = {
    '+ NEW': FilterNew,
    // Not using FilterCategory Because Category is treated as attribute in PreOrders
    category: FilterCategory,
    generic: FilterAttribute,
    warehouse: FilterWarehouse,
    buyerFilter: FilterBuyers,
    itemFilter: FilterItems,
    // yet to update
    salesRepId: FiltersRequired,
    paymentStatus: FiltersRequired,
    createDate: FilterDate,
    updateDate: FilterDate,
    // advance filter
    offerRange: FilterOfferRange,
    availability: FilterItemsAvailability,
    daysToSell: FilterDaysToSell,
    addToStock: FilterAddedToStock,
    topOffers: FilterTopOffers,
  };
  // open dialog
  const [dialogState, setDialogState] = useState<IDialogInfo>();
  // set dialog initial state to the required filter state
  // dialog initial state => dialog initial items selection state
  const [filterCounts, setFilterCounts] = useState<any>(null);
  // used for maintaining sequence of the chips visible on the screen
  const [filterUISequence, setFilterUISequence] = useState<Array<string>>(requiredFilters);
  // requiredFilters
  useEffect(() => {
    if (!filterUISequence.every((item) => !!filtersApplied[item])) {
      setFilterUISequence(requiredFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersApplied]);

  useEffect(() => {
    if (!filterCountState || !Array.isArray(filterUISequence)) return;
    const updatedFilterCounts: any = {};
    const isPrevFilter = previousFilteredCounts && Object.keys(previousFilteredCounts).length > 0;
    const isSearched = !!filterCountState.itemSearched;
    const isIncludeOutOfStock = !!filterCountState.includeOutOfStock;

    filterUISequence.forEach((val: string) => {
      const isRequiredField = requiredFilters.includes(val);

      if (isRequiredField) {
        if (isSearched && isIncludeOutOfStock) {
          updatedFilterCounts[val] = filterCountState.searchedAbsolute[val] || 0;
        } else {
          if (
            isPrevFilter &&
            !isIncludeOutOfStock &&
            previousFilteredCounts[val] &&
            Object.keys(previousFilteredCounts[val]).length
          ) {
            updatedFilterCounts[val] = previousFilteredCounts[val] || 0;
          } else {
            updatedFilterCounts[val] = filterCountState.filtered[val] || 0;
          }
        }
      } else {
        // if include out of stock is there take absolute count
        if (isIncludeOutOfStock) {
          if (isSearched) {
            updatedFilterCounts[val] = filterCountState.searchedAbsolute[val] || 0;
          } else {
            updatedFilterCounts[val] = filterCountState.absolute[val] || 0;
          }
        } else {
          if (
            isPrevFilter &&
            previousFilteredCounts[val] &&
            typeof previousFilteredCounts[val] === 'object'
          ) {
            updatedFilterCounts[val] = previousFilteredCounts[val] || 0;
          } else {
            updatedFilterCounts[val] = filterCountState.filtered[val] || 0;
          }
        }
      }
      // SKU: 1604664 1606249 1606256 1606449 1606736 1606736 1606737 1606797 1606941 1606968
    });

    if (isPrevFilter && !isIncludeOutOfStock) previousFilteredCounts = undefined;
    setFilterCounts(updatedFilterCounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCountState?.includeOutOfStock]);

  useEffect(() => {
    if (!isPreorder) return;
    if (!filterCountState || !filterCounts) return;
    const updatedFilterCounts = { ...filterCounts };
    const isBuyerFilterApplied = values(filtersApplied.buyerFilter).includes(false);
    const isItemFilterApplied = values(filtersApplied.itemFilter).includes(false);
    requiredFilters.forEach(
      (filter: string) =>
        (updatedFilterCounts[filter] = cloneDeep(filterCountState?.absolute[filter]))
    );
    requiredFilters.forEach((filter: string) => {
      if (!(filter in filterCountState.filtered)) return;
      if (!isBuyerFilterApplied && isItemFilterApplied && filter === 'itemFilter') {
        const filteredCurrentFilter = Object.keys(filterCountState?.filtered.buyerFilter);
        for (let filterKey in filterCountState?.absolute.buyerFilter) {
          if (!filteredCurrentFilter.includes(filterKey)) {
            delete updatedFilterCounts.buyerFilter[filterKey];
          }
        }
      } else if (!isItemFilterApplied && isBuyerFilterApplied && filter === 'buyerFilter') {
        const filteredCurrentFilter = Object.keys(filterCountState?.filtered.itemFilter ?? {});
        for (let filterKey in filterCountState?.absolute.itemFilter) {
          if (!filteredCurrentFilter.includes(filterKey)) {
            updatedFilterCounts.itemFilter[filterKey] &&
              delete updatedFilterCounts.itemFilter[filterKey];
          }
        }
      } else if (
        !filterCountState.includeOutOfStock &&
        !isBuyerFilterApplied &&
        !isItemFilterApplied
      ) {
        const filteredCurrentFilter = Object.keys(filterCountState?.filtered[filter]);
        for (let filterKey in filterCountState?.absolute[filter]) {
          if (!filteredCurrentFilter.includes(filterKey)) {
            delete updatedFilterCounts[filter][filterKey];
          }
        }
      }
      for (let filterKey in filterCountState?.filtered[filter]) {
        updatedFilterCounts[filter][filterKey] = cloneDeep(
          filterCountState?.filtered[filter][filterKey]
        );
      }
    });
    setFilterCounts(updatedFilterCounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCountState]);

  useEffect(() => {
    initialFilterCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCountState?.itemSearched]);

  const initialFilterCounts = () => {
    if (!filterCountState || !Array.isArray(filterUISequence)) return;
    const updatedFilterCounts: any = {};
    filterUISequence.forEach((val: string) => {
      updatedFilterCounts[val] = !!filterCountState.includeOutOfStock
        ? filterCountState.searchedAbsolute[val] || 0
        : filterCountState.filtered[val] || 0;
    });
    setFilterCounts(updatedFilterCounts);
  };

  // get the lable for each chip [catefory, out of stock, customer]
  const getLabelForChips = (attr: string, chipsDataState: any) => {
    if (!filtersApplied || !attr || !filtersApplied[attr]) return;

    // for out of stock just return Out of stock
    if (attr === 'outOfStock') {
      return 'Out of Stock';
    }

    let attLabel = attr;
    // Get spelled out label from JSON => it is the main label i.e., Category: or Customer:
    if (chipsDataState.attributeLabels && chipsDataState.attributeLabels.hasOwnProperty(attr)) {
      attLabel = chipsDataState.attributeLabels[attr];
    }

    if (customFilters?.includes(attr) && chipsDataState[`${attr}Labels`]?.chipLabel) {
      return formatString(chipsDataState[`${attr}Labels`].chipLabel, {
        ...filtersApplied[attr],
      });
    }

    const selectedAttributes: string[] = [];
    if (attr === 'warehouse') {
      Object.keys(filtersApplied[attr]).forEach((val) => {
        if (filtersApplied[attr][val]) {
          selectedAttributes.push(val);
        }
      });
    } else {
      Object.keys(filtersApplied[attr]).forEach((val) => {
        if (filtersApplied[attr][val]) {
          selectedAttributes.push(
            chipsDataState[`${attr}Labels`] && chipsDataState[`${attr}Labels`][val]
              ? chipsDataState[`${attr}Labels`][val]
              : val
          );
        }
      });
    }
    // Otherwise get comma separated options
    if (selectedAttributes.length === Object.keys(filtersApplied[attr]).length) {
      return `${attLabel}: All`;
    }

    const labelContent = selectedAttributes.join(', ');
    return `${attLabel}: ${labelContent.slice(0, 5)}${labelContent.length > 5 ? '...' : ''}`;
  };

  // getting lower level filter labels from settings => if upper level filter is category, then lower level filter is given by this function
  const getOptionLabel = (attribute: string, option: string) => {
    let attLabel = option;

    let labelKey = `${attribute}Labels`;
    if (
      chipsDataState.hasOwnProperty(labelKey) &&
      chipsDataState[labelKey].hasOwnProperty(option)
    ) {
      attLabel = chipsDataState[labelKey][option];
    }

    if (attribute === '+ NEW') {
      attLabel = attLabel.replace('_', ' ');
    }

    return attLabel;
  };

  // delete chip filter
  const deleteChip = (payload: any) => {
    // TODO: update according to new Data structure
    if (requiredFilters.includes(payload.attribute) || !Array.isArray(filterUISequence)) {
      return;
    }
    // updating chip sequence > removing filters applied after this
    let updatedFilterSequence = [...filterUISequence];
    let chipIndex = updatedFilterSequence.indexOf(payload.attribute);
    updatedFilterSequence.splice(chipIndex);
    // updating applied filters
    let updatedFiltersApplied: any = { ...filtersApplied };
    let updatedInitialState = { ...filterCounts };
    const keys = Object.keys(updatedFiltersApplied);
    keys.forEach((key: string) => {
      // if updated filter sequence not includes the key
      // and key is not outofstock and it's not in required filter
      if (
        updatedFilterSequence.indexOf(key) === -1 &&
        key !== 'outOfStock' &&
        !requiredFilters.includes(key)
      ) {
        delete updatedFiltersApplied[key];
        delete updatedInitialState[key];
      }
    });
    // setting the data
    setFiltersApplied({ ...updatedFiltersApplied });
    setFilterUISequence([...updatedFilterSequence]);
    setFilterCounts({ ...updatedInitialState });
    if (chipsEvent) {
      // triggering event
      chipsEvent({
        type: 'delete-chip',
        payload,
      });
    }
  };

  // open filter dialog
  const openDialog: IFilterDialogOpenFn = (dialogInfo) => {
    setDialogState({ ...dialogInfo });
    if (!filterCounts?.hasOwnProperty(dialogInfo.attribute)) {
      // taking initial dialog state from the menu filter
      const updatedFilterCounts = filterCountState?.filtered[dialogInfo.attribute]
        ? { ...filterCountState?.filtered[dialogInfo.attribute] }
        : {};

      // setting initial state
      setFilterCounts({
        ...filterCounts,
        [dialogInfo.attribute]: updatedFilterCounts,
      });
    }

    if (chipsEvent)
      chipsEvent({
        type: 'open-dialog',
      });
  };

  // cancel filter dialog
  const closeDialog = ({ attribute }: ICloseFilterDialogParams) => {
    if (!filtersApplied[attribute] && filterCounts[attribute]) {
      const updatedFilterCounts = { ...filterCounts };
      delete updatedFilterCounts[attribute];
      setFilterCounts(updatedFilterCounts);
    }
    setDialogState(undefined);
    if (chipsEvent) {
      chipsEvent({
        type: 'cancel-dialog',
      });
    }
  };

  // save filter dialog
  const saveDialog = (payload: IFilterSaveParams) => {
    if (!Array.isArray(filterUISequence)) return;
    const selectedFilterAppliedState = {
      ...payload.selectedFilters,
    };

    // and all false
    if (requiredFilters.includes(payload.attribute) && payload.checkedFilters === 0) {
      Object.keys(selectedFilterAppliedState).forEach((optionVal) => {
        selectedFilterAppliedState[optionVal] = true;
      });
    }

    deletePreviousFilter({
      attribute: payload.attribute,
      updatedFiltersApplied: {
        ...filtersApplied,
        [payload.attribute]: selectedFilterAppliedState,
      },
      filterUISeqSet: new Set<any>([...filterUISequence, payload.attribute]),
      updatedFilterCounts: {
        ...filterCounts,
        [payload.attribute]: filterCounts[payload.attribute]
          ? filterCounts[payload.attribute]
          : {
              ...filterCountState?.filtered[payload.attribute],
            },
      },
    });

    setDialogState(undefined);
    if (chipsEvent) {
      chipsEvent({
        type: 'save-dialog',
        payload: payload,
      });
    }
  };

  const deletePreviousFilter: IDeletePreviousFilterFn = ({
    attribute,
    updatedFiltersApplied,
    filterUISeqSet,
    updatedFilterCounts,
  }) => {
    if (!Array.isArray(filterUISequence)) return;
    if (filterUISequence.indexOf(attribute) > -1) {
      for (
        let index = filterUISequence.indexOf(attribute) + 1;
        index < filterUISequence.length;
        index++
      ) {
        const val = filterUISequence[index];
        if (requiredFilters.indexOf(val) === -1) {
          // remove the chips above the current
          delete updatedFiltersApplied[val];
          // remove from sequence
          filterUISeqSet.delete(val);
        } else {
          // reset the required filters come beyond this
          Object.keys(updatedFiltersApplied[val]).forEach((optionVal) => {
            updatedFiltersApplied[val][optionVal] = true;
          });
        }
        // remove counts
        delete updatedFilterCounts[val];
      }
    }
    setFiltersApplied(updatedFiltersApplied);

    setFilterUISequence(Array.from(filterUISeqSet));

    setFilterCounts(updatedFilterCounts);
  };

  const clearFilter = (params: IClearFiltersParams) => {
    // close dialog
    setDialogState(undefined);
    if (!Array.isArray(filterUISequence)) return;
    if (!filtersApplied[params.attribute]) return;
    // do not remove if the filter is required
    if (requiredFilters.includes(params.attribute)) {
      const selectedFilters: { [key: string]: boolean } = {};
      let ifAllAlreadyApplied = true;
      Object.keys(filterCountState?.absolute[params.attribute]).forEach((val) => {
        selectedFilters[val] = true;
        if (!filtersApplied[params.attribute][val]) ifAllAlreadyApplied = false;
      });
      if (ifAllAlreadyApplied) return;
      // reset the filter
      deletePreviousFilter({
        attribute: params.attribute,
        updatedFiltersApplied: {
          ...filtersApplied,
          [params.attribute]: selectedFilters,
        },
        filterUISeqSet: new Set<any>([...filterUISequence, params.attribute]),
        updatedFilterCounts: { ...filterCounts },
      });

      setDialogState(undefined);
      if (chipsEvent) {
        chipsEvent({
          type: 'save-dialog',
          payload: params,
        });
      }
    } else {
      // remove the filter applied
      deleteChip(params);
    }
  };

  const getFilterOptionsCount: IGetFilterOptionCount = (attribute, attributeVal) => {
    return filterCounts?.[attribute]?.[attributeVal] || 0;
  };

  const getPresetFilters = useMemo(() => {
    return (
      optionalFilters?.filter((val: string) => {
        if (!filterCountState?.filtered[val] || isEmpty(filterCountState?.filtered[val]))
          return false;
        const keysOfFiltered = Object.keys(filterCountState.filtered[val]);
        if (keysOfFiltered.length <= 1 && keysOfFiltered.includes('(Blanks)')) return false;
        return !filtersApplied[val];
      }) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCountState?.filtered, filtersApplied, optionalFilters]);

  // creating the dialog component
  const DialogComponent = () => {
    try {
      const dialogComponentName =
        dialogState?.attribute && componentsList.hasOwnProperty(dialogState.attribute)
          ? dialogState.attribute
          : 'generic';
      if (componentsList.hasOwnProperty(dialogComponentName)) {
        let presetOptionObj = filtersApplied[dialogState?.attribute];
        let presetFilters: Array<any> = [];
        let allowedCustomFilter: Array<any> = [];
        if (dialogState?.attribute === '+ NEW') {
          presetFilters = getPresetFilters;

          if (customFilters) {
            allowedCustomFilter = customFilters.filter((val) => {
              return !filtersApplied[val];
            });
          }
        }

        if (!presetOptionObj) {
          if (
            dialogComponentName === 'generic' ||
            dialogComponentName === 'warehouse' ||
            dialogComponentName === 'buyerFilter' ||
            dialogComponentName === 'category'
          ) {
            // set option obj to dialog initial state
            presetOptionObj = {};
            if (dialogState?.attribute && filterCounts && filterCounts[dialogState.attribute]) {
              Object.keys(filterCounts[dialogState?.attribute]).forEach((val) => {
                if (val === '(Null)') return;
                presetOptionObj[val] = true;
              });
            }
          }
        }

        const filterLabel: any = {};
        if (presetOptionObj && dialogComponentName !== 'category') {
          Object.keys(presetOptionObj).forEach((option) => {
            const label = getOptionLabel(dialogState?.attribute, option);
            const count = getFilterOptionsCount(dialogState?.attribute, option);
            if (!count || label === '(Null)') return null;
            if (dialogComponentName === 'warehouse') {
              filterLabel[option] = `${option} (${count})`;
            }
            // else if (dialogState?.attribute === 'isTakeAll') {
            //   filterLabel[option] = `${label === 'true' ? 'YES' : 'NO'} (${count})`;
            // }
            else {
              filterLabel[option] = `${label} (${count})`;
            }
          });
        }

        let customOptionObj: any = filtersApplied[dialogState?.attribute] || {};
        if (
          customFilters?.includes(dialogComponentName) &&
          !filtersApplied[dialogState?.attribute]
        ) {
          switch (dialogComponentName) {
            case 'offerRange':
              customOptionObj = {
                type: 'percent',
                price: 'belowList',
              };
              break;
            case 'availability':
              customOptionObj = {
                type: 'below',
              };
              break;
            case 'daysToSell':
              customOptionObj = {};
              break;
            case 'addToStock':
              customOptionObj = {
                type: 'atLeast',
              };
              break;
            case 'topOffers':
              customOptionObj = {};
              break;
            case 'createDate':
              customOptionObj = {};
              break;
            case 'updateDate':
              customOptionObj = {};
              break;
          }
        }

        let defaultTab: INewFilterTabType = 'preset';
        if (dialogState?.from && customFilters?.includes(dialogState.from)) defaultTab = 'custom';
        const dialogParam: IFilterDialog = {
          presetOptionObj: presetOptionObj,
          customOptionObj: customOptionObj,
          title: dialogState?.title || '',
          attribute: dialogState?.attribute,
          filterState: {
            filterUISequence,
            optionalFilters,
            presetFilters,
            customFilters: allowedCustomFilter,
            newFilterTab: defaultTab,
            filterLabel: filterLabel,
            isApplied: dialogState?.from && dialogState.from === 'filterChip',
            // requiredFilters.indexOf(dialogState?.attribute) > -1,
          },
          openDialog: openDialog,
          clearFilter: clearFilter,
          closeDialog: closeDialog,
          saveFilter: saveDialog,
          getLabel: getOptionLabel,
          getCount: getFilterOptionsCount,
          setAdvanceFilter: (fn, attribute) =>
            setAdvanceFilter({
              ...advanceFilters,
              [attribute]: fn,
            }),
        };

        return createElement(componentsList[dialogComponentName], dialogParam, children);
      }
    } catch (error: any) {
      console.error(error, ':occured while creating dialog');
    }
    return <>Component not found! #Err 0001</>;
  };

  const toggle = ({ attribute }: IDialogInfo) => {
    if (!filtersApplied[attribute]) previousFilteredCounts = { ...filterCounts };
    setFiltersApplied({ ...filtersApplied, [attribute]: !filtersApplied[attribute] });
    if (chipsEvent) {
      chipsEvent({
        type: 'toggle',
        attr: attribute,
        payload: {
          newValue: !filtersApplied[attribute],
        },
      });
    }
  };

  const clearAllFilters = () => {
    setFilterCounts(undefined);
    setFilterUISequence(requiredFilters);
    resetFilter();
    if (chipsEvent) {
      chipsEvent({
        type: 'clear',
      });
    }
  };

  const ChipsBar = () => {
    return (
      <>
        <div className="px-advance-chip-filters">
          {filterUISequence?.map((mainAttr: any) => {
            let chipLabel: string = getLabelForChips(mainAttr, chipsDataState) || '';
            const isRequiredFilter = requiredFilters.includes(mainAttr);
            if (isRequiredFilter && !componentsList[mainAttr]) return null;
            return (
              <FilterChip
                label={chipLabel}
                attribute={mainAttr}
                openDialog={openDialog}
                key={`chip-${mainAttr}`}
                deleteFilter={deleteChip}
                isFilterOn={chipLabel.toLowerCase().indexOf('all') > -1 && isRequiredFilter}
                isRequiredFilter={isRequiredFilter}
              />
            );
          })}
          {!excludeOutOfStock && (
            <FilterChip
              attribute="outOfStock"
              deleteFilter={deleteChip}
              isRequiredFilter={true}
              isFilterOn={filtersApplied.outOfStock}
              label={`Include 0 Available (${
                filterCountState?.absolute?.quantityAvailable
                  ? filterCountState?.absolute?.quantityAvailable['0'] ?? 0
                  : 0
              })`}
              openDialog={toggle}
            />
          )}
          {optionalFilters && optionalFilters.length !== 0 && (
            <TertiaryButton
              data-id="newFilter"
              disabled={isEmpty(getPresetFilters)}
              className="px-chips-filter-toggle margin-bottom-1"
              onClick={(e: any) => openDialog({ title: 'New Filter', attribute: '+ NEW' })}>
              + NEW
            </TertiaryButton>
          )}
          <TertiaryButton
            data-id="clearFilter"
            className="margin-bottom-1"
            onClick={clearAllFilters}>
            CLEAR
          </TertiaryButton>
        </div>
        {!!dialogState ? <DialogComponent /> : null}
      </>
    );
  };

  return <>{!!chipsDataState ? <ChipsBar /> : null}</>;
}

export default Filters;
