import React, { useEffect, useState, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty, keys } from 'lodash';

//aggrid imports
import { useHistory } from 'react-router';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
  RowNode,
  SelectionChangedEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';

//Material UI components
import { Checkbox } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

//Custom components
import { CreateOrdersButton } from '../../components/Create Orders/CreateOrdersButton';
import { BackButton } from '../../components/AtomComponents';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';

import * as preorderActions from '../../redux/actions/preorderActions';
import './CreateOrders.scss';
//custom hooks
import useDataService from '../../hooks/useDataService';
import ReactGA from 'react-ga';

//helper functions
import {
  alertMessageCO,
  confirmDialogCOSubLines,
  getAggregatedSumOfFields,
  getDataToRecalculate,
  sanitizeData,
} from '../../components/PreOrders/Utils';
import {
  confirmDialogContent,
  LoadingComponent,
} from '../../components/PreOrders/TableCellComponents';

// lib, api url template
import { dataFormatting, messages } from '../../_lib/lib';
import apiToUrlMap from '../../ApiMapping';

//types
import {
  awards,
  IConfirmDialogProps,
  initialConfirmDialogProps,
  IPreOrders,
} from '../../types/IPreOrders';
import { DraftOrder } from '../DraftOrder/DraftOrder';
import { ConfirmDialog } from '../../components/Funding/ConfirmDialog';

LicenseManager.setLicenseKey(
  'CompanyName=Phonex Holdings Inc,LicensedApplication=PhoneX SaaS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=3,AssetReference=AG-012160,ExpiryDate=1_December_2021_[v2]_MTYzODMxNjgwMDAwMA==f1ae693cebbed365cb2597d53a5021d2'
);

const CreateOrders = ({ preorderActions, preorderState, canManagePoOrder }: IPreOrders) => {
  const history = useHistory();
  const awardsRef: any = useRef();

  //grid and loading states
  const [gridApi, setGridApi] = useState<GridApi>();
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);

  //usedata service
  const { fetchUrl, openSnackBar } = useDataService();

  //checkstate handling
  const [selectedNodes, setSelectedNodes] = useState<awards[]>([]);
  const [checkNodes, setCheckNodes] = useState<boolean | null>(false);
  const [selectedCount, setSelectedCount] = useState<number>(0);

  //API call
  const [refetchFromRemote, setRefetchFromRemote] = useState<boolean>(false);

  //draft order states
  const [showDraftOrder, setShowDraftOrder] = useState<boolean>(false);
  const [draftOrderData, setDraftOrderData] = useState<any>();

  const [confirmDialogProps, setConfirmDialogProps] = useState<IConfirmDialogProps>(
    initialConfirmDialogProps
  );

  //get awards API call
  const getPreorderAwards = async (
    status: string,
    shippingStartDate?: string,
    shippingEndDate?: string
  ) => {
    try {
      const params =
        status === ('open' || 'pending')
          ? { status }
          : { status, startDate: shippingStartDate, endDate: shippingEndDate };
      const res = await fetchUrl('get', apiToUrlMap.getAwards, {
        params,
      });
      return res.awards;
    } catch (error) {
      console.log('error while dummy auth call', error);
    }
  };

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' | Create Orders';
  }, []);

  useEffect(() => {
    const getAwards = async () => {
      const preorderAwards = await getPreorderAwards('open');
      awardsRef.current = preorderAwards;
      preorderActions.awardsSet(cloneDeep(preorderAwards));
      setRefetchFromRemote(false);
    };
    getAwards();
    return () => {
      preorderActions.awardsSet(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, refetchFromRemote]);

  useEffect(() => {
    if (!preorderState?.awards) return;
    const FilteredData = preorderState.awards.filter((item: awards) => {
      return item.quantityReadyToFulfill > 0;
    });
    gridApi?.setRowData(sanitizeData(cloneDeep(FilteredData ?? []), ['buyerNumber', 'warehouse']));
    gridApi?.forEachNode((node: RowNode) => {
      selectedNodes.forEach((item: awards) => {
        if (node.data.buyerNumber === item.buyerNumber && node.data.warehouse === item.warehouse)
          node.setSelected(true);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, preorderState]);

  useEffect(() => {
    if (!gridApi) return;
    const totalRowCount = gridApi?.getDisplayedRowCount();
    if (totalRowCount === selectedCount && selectedCount > 0) setCheckNodes(true);
    else if (totalRowCount !== selectedCount && selectedCount > 0) setCheckNodes(null);
    else setCheckNodes(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, selectedCount]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const draftHandler = async (params: ICellRendererParams) => {
    const draftErrorKey = 'preorderDraftOrderError';
    try {
      setLoadingDialog(true);
      const awards: awards[] = [];
      params.data.subGrid.forEach((subItem: awards) => {
        const data = getDataToRecalculate(subItem);
        awards.push(data);
      });
      const res = await fetchUrl('post', apiToUrlMap.createOrders, {
        body: { awards: awards },
      });
      if (res.errors) {
        keys(res.errors).forEach((val: any) => {
          if (res.errors[val].length > 0) {
            openSnackBar(messages[draftErrorKey][val]?.label, 'error');
          }
        });
      } else {
        setDraftOrderData(res.orders[0]);
        setShowDraftOrder(true);
      }
      setLoadingDialog(false);
    } catch (error: any) {
      openSnackBar('Unable To draft order', 'error');
      setLoadingDialog(false);
    }
    // history.push(`/preorders/create/${params.data.buyerNumber}-${params.data.warehouse}`);
  };

  const isVisible = (params: ICellRendererParams) => {
    return (
      <VisibilityIcon
        className="visibleIcon "
        color="primary"
        onClick={() => {
          draftHandler(params);
        }}
      />
    );
  };

  const colDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Buyer',
      valueGetter: (params: ValueGetterParams) => params.data.buyerName,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: { checkbox: true },
      headerClass: 'headerClass',
      cellClass: `fundHeaderClass ${canManagePoOrder ? '' : 'disable-field'}`,
      wrapText: true,
      minWidth: 300,
    },
    {
      headerName: 'Warehouse Name',
      valueGetter: (params: ValueGetterParams) => params.data.warehouse,
      wrapText: true,
      minWidth: 100,
    },
    // {
    //   headerName: 'Funds Remaining',
    //   colId: 'fundsRemaining',
    //   valueGetter: (params: ValueGetterParams) => {
    //     const endingFunds = getAggregatedSumOfFields({
    //       dataSet: filterDataImpl({
    //         data: awardsRef.current,
    //         filterToApply: { buyerNumber: params.data.buyerNumber },
    //       }),
    //       middlewareFn: (item: awards) => item.awardedPrice * item.quantityReadyToFulfill,
    //     });
    //     return dataFormatting('currency', params.data.startingFund - endingFunds, true);
    //   },
    //   cellStyle: (params: CellClassParams) => remainingCellRules(params),
    //   minWidth: 100,
    // },
    {
      headerName: 'Total Quantity',
      field: 'quantityReadyToFulfill',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Total Price',
      field: 'Total_Price',
      valueGetter: (params: ValueGetterParams) => {
        const totalPrice = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.awardedPrice * item.quantityReadyToFulfill,
        });
        return dataFormatting('currency', totalPrice, true);
      },
      minWidth: 100,
    },
    {
      headerName: 'Draft Order',
      field: 'Draft_Order',
      cellRendererFramework: isVisible,
      enableValue: true,
      minWidth: 100,
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: colDef,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    suppressContextMenu: true,
    rowHeight: 84,
    rowStyle: {
      background: '#eef5ff',
    },
    defaultColDef: {
      flex: 1,
      cellClass: 'cell-vertical-align-text-left',
      menuTabs: [],
      sortable: true,
    },
    getRowNodeId: (data: awards) => `${data.buyerNumber}-${data.warehouse}`,
    onSelectionChanged: (params: SelectionChangedEvent) => whatIsChecked(params),
    loadingOverlayComponentFramework: LoadingComponent,
  };

  const whatIsChecked = (params: SelectionChangedEvent) => {
    setSelectedCount(params.api.getSelectedNodes().length);
    const selectednode = params.api.getSelectedNodes();
    let selectedArray: awards[] = [];
    selectednode.forEach((item: RowNode) => {
      item.data.subGrid.forEach((subItem: awards) => {
        const data = getDataToRecalculate(subItem);
        selectedArray.push(data);
      });
    });
    setSelectedNodes(selectedArray);
  };

  const onGridReady = async (params: GridOptions) => {
    params.api && setGridApi(params.api);
  };

  const handleChange = () => {
    if (!!!checkNodes) gridApi?.selectAll();
    else gridApi?.deselectAll();
  };

  const confirmCreateOrders = async () => {
    setConfirmDialogProps(initialConfirmDialogProps);
    gridApi?.showLoadingOverlay();
    try {
      await fetchUrl(
        'put',
        apiToUrlMap.createOrders,
        {
          body: { awards: selectedNodes },
        },
        true
      );
      openSnackBar('Orders created successfully', 'success');
      setSelectedCount(0);
      setSelectedNodes([]);
      setRefetchFromRemote(true);
    } catch (error: any) {
      if (error.message.includes('API timeout')) {
        setConfirmDialogProps({
          isDialogOpen: true,
          title: '',
          confirmDialogContent: () => (
            <div className="px-error">
              Error: Your request failed to finish properly. <br></br>
              <br></br>
              Please get in touch with PhoneX IT (slack: #assurant-prod-support or email:
              hyla-it-dev@phonexinc.com). <br></br> <br></br>
              PhoneX IT will need to correct this issue before you continue.
            </div>
          ),
          backdropHandler: () => setConfirmDialogProps(initialConfirmDialogProps),
          confirmActionProps: {
            confirmLabel: 'OK',
            confirmHandler: () => setConfirmDialogProps(initialConfirmDialogProps),
          },
        });
      } else openSnackBar('Data value mismatch error when creating orders', 'error');
    }
    gridApi?.hideOverlay();
  };

  const closeConfrimRecalculateHandler = () => setConfirmDialogProps(initialConfirmDialogProps);

  const createOrdersHandler = async () => {
    // const negativeFundsWithFundingRequired: any = [];
    // const negativeFundwithoutFundingRequired: any = [];
    // selectedNodes.forEach((value: awards) => {
    //   if (value.isFundingRequired === 'Yes' && value.endingFund < 0)
    //     negativeFundsWithFundingRequired.push(value);
    //   if (value.isFundingRequired === 'No' && value.endingFund < 0)
    //     negativeFundwithoutFundingRequired.push(value);
    // });
    // if (!isEmpty(negativeFundwithoutFundingRequired))
    //   openSnackBar('Orders created will end in negative funds', 'warning');
    // if (!isEmpty(negativeFundsWithFundingRequired))
    //   openSnackBar(
    //     `${negativeFundsWithFundingRequired[0].buyerName} - ${negativeFundsWithFundingRequired[0].warehouse} ends in negative funds`,
    //     'error'
    //   );
    setConfirmDialogProps({
      isDialogOpen: true,
      title: 'Confirm Create Orders',
      // disabled: !isEmpty(negativeFundsWithFundingRequired),
      confirmDialogContent: () =>
        confirmDialogContent(
          selectedNodes,
          confirmDialogCOSubLines,
          'createOrders',
          alertMessageCO,
          '',
          selectedCount
        ),
      confirmActionProps: {
        confirmLabel: 'CONTINUE',
        confirmHandler: confirmCreateOrders,
        rejectLabel: 'CANCEL',
        rejectHandler: closeConfrimRecalculateHandler,
      },
    });
  };

  const closeDraftOrder = () => {
    setDraftOrderData(null);
    setShowDraftOrder(false);
  };

  return (
    <>
      <LoadingDialog isDialogOpen={!!loadingDialog} />
      {confirmDialogProps?.isDialogOpen && (
        <ConfirmDialog
          isDialogOpen={confirmDialogProps.isDialogOpen}
          title={confirmDialogProps.title}
          confirmDialogContent={confirmDialogProps.confirmDialogContent}
          confirmActionProps={confirmDialogProps.confirmActionProps}
          disabled={confirmDialogProps.disabled}
          backdropHandler={confirmDialogProps.backdropHandler}
        />
      )}
      {showDraftOrder && draftOrderData && (
        <DraftOrder setShowDraftOrder={closeDraftOrder} order={draftOrderData} />
      )}
      {!showDraftOrder && (
        <>
          <BackButton url="/" textToDisplay="Back" />
          <main className="grid-x funding">
            <h1 className="cell small-12 margin-bottom-3">Create Orders</h1>
            <div
              className="ag-theme-alpine"
              style={{ width: '100vw', height: '98vh', marginBottom: '60px' }}>
              <div>
                <Checkbox
                  className="margin-bottom-2"
                  style={{ marginLeft: '10px' }}
                  checked={!!checkNodes}
                  indeterminate={checkNodes === null}
                  onChange={handleChange}
                  color="primary"
                  disabled={!canManagePoOrder || false}
                />
                <CreateOrdersButton
                  disabled={!selectedCount || !canManagePoOrder}
                  onCreateOrdersButton={createOrdersHandler}
                  itemSelected={selectedCount}
                />
              </div>
              <AgGridReact onGridReady={onGridReady} gridOptions={gridOptions}></AgGridReact>
            </div>
          </main>
        </>
      )}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    preorderState: state.preorderState,
    canManagePoOrder: state.userState.authorities.includes('CAN_FULFILL_PREORDER'),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    preorderActions: bindActionCreators(preorderActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrders);
