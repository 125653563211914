import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import * as preorderActions from '../../redux/actions/preorderActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as fundingPreorderActions from '../../redux/actions/fundingPreorderActions';

//custom hooks
import useDataService from '../../hooks/useDataService';
// import useAdvanceFilter from '../../hooks/useAdvanceFilter';

//Material-UI Components
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';

// Custom Components
import FundingImport from '../../components/FundingImport/FundingImport';
import FundingGrid from '../../components/Funding/FundingGrid';
import ImportExportDropdown from '../../components/ImportExportDropdown/ImportExportDropdown';
import ImportPopup from '../../components/ImportPopup/ImportPopup';
import DateRangeAnalyticsSelector from '../../components/PreOrders/DateRangeAnalyticsSelector';
import DateSelectorComponent from '../../components/PreOrders/DateSelectorComponent';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';

// lib, api url template
import apiToUrlMap from '../../ApiMapping';

// Types
import { FundChanges, IFunding, IImportFunds, FundVal } from '../../types/IFunding';
import { dateFormats, getFirstDayOfMonth, manipulateDates } from '../../components/PreOrders/Utils';
import { IDateRange } from '../../types/IPricing';
import { IFilterSaveParams } from '../../types/IFilterEngine';
// import { ConfirmDialog } from '../../components/Funding/ConfirmDialog';

// styles
import './Funding.scss';
import '../PreOrders/PreOrders.scss';
import { TextField } from '../../components/AtomComponents';
import { isEmpty } from '@aws-amplify/core';
import ReactGA from 'react-ga';

const Funding = ({ fundingpreorderState, fundingPreorderActions, preorderActions }: IFunding) => {
  const history = useHistory();
  const firstDayUtc: any = useRef();
  const [goLiveDate, setGoLiveDate] = useState('2021/09/30');
  const [importDialog, setImportDialog] = useState(false);
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: manipulateDates({
      date: getFirstDayOfMonth(new Date()).toString(),
      formatTo: dateFormats.monthIn3Letters,
      currentFormat: dateFormats.timestamp,
      manipulate: { days: 0 },
    }),
    endDate: manipulateDates({
      date: new Date().toString(),
      formatTo: dateFormats.monthIn3Letters,
      currentFormat: dateFormats.timestamp,
      manipulate: { days: 0 },
    }),
  });

  // const [confirmDialogProps, setConfirmDialogProps] = useState<IConfirmDialogProps>(
  //   initialConfirmDialogProps
  // );
  // const [numberOfCustomers, setNumberOfCustomers] = useState<number>(0);

  //import funds
  const [importData, setImportData] = useState<IImportFunds | null>();
  const [currentFileName, setCurrentFileName] = useState<string>();

  const pxImportRef: any = useRef();
  const pxAgGridRef: any = useRef();
  // page loading states
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);

  // use data service
  const { openSnackBar, exportData, fetchUrl, fileUpload } = useDataService();
  // const pxAgGridRef: any = React.useRef();

  // Funds Data
  const [refetchFromRemote, setRefetchFromRemote] = useState<boolean>(false);

  //search text
  const [searchText, setSearchText] = useState('');

  // const [isConfirmFunds, setIsConfirmFunds] = useState<boolean>(false);
  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' | Funding';
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    setGoLiveDate((prev: string) => config?.goLiveDate ?? prev);
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    if (firstDay.toLocaleDateString('sv-SE') < config?.goLiveDate) {
      firstDayUtc.current = config?.goLiveDate;
    } else {
      firstDayUtc.current = new Date(
        firstDay.valueOf() + Math.abs(firstDay.getTimezoneOffset() * 60 * 1000)
      );
    }
    setDateRange({
      startDate: manipulateDates({
        date: getFirstDayOfMonth(new Date()).toString(),
        formatTo: dateFormats.dateMonthIn3Letters,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
      endDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.dateMonthIn3Letters,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
    });
    setRefetchFromRemote(true);
  }, []);

  // useEffect(() => {
  //   if (fundingpreorderState.funds === []) {
  //     return;
  //   }
  //   const fund = fundingpreorderState.funds?.[0];
  //   const lastUpdatedDate = fund?.updateDate;
  //   const currentDate = new Date().toLocaleDateString('sv-SE');
  //   // const currentDate = new Date().getTimezoneOffset();
  //   if (lastUpdatedDate === currentDate) {
  //     setIsConfirmFunds(true);
  //   }
  // }, [fundingpreorderState.funds]);

  useEffect(() => {
    return () => fundingPreorderActions.fundsSet(null);
  }, [history, fundingPreorderActions]);

  const getFunds = async () => {
    const params = {
      startDate: manipulateDates({
        date: dateRange.startDate,
        formatTo: dateFormats.yearMonthDate,
        manipulate: { days: 0 },
      }),
      endDate: manipulateDates({
        date: dateRange.endDate,
        formatTo: dateFormats.yearMonthDate,
        manipulate: { days: 0 },
      }),
    };
    try {
      const res = await fetchUrl('get', apiToUrlMap.preorderFundingGetFunds, {
        params,
      });
      // setNumberOfCustomers(res.funds.length);
      fundingPreorderActions.fundsSet(res.funds);
    } catch (error: any) {
      if (error.message === 'No funding data found in date range') {
        fundingPreorderActions.fundsSet([]);
        // setNumberOfCustomers(0);
      }
      openSnackBar(error.message, 'error');
    }
  };

  //#region >> DateRangeSelector manager >>
  const [dateRangeDialog, setDateRangeDialog] = useState<any>(false);
  const closeDateRangeSelector = () => setDateRangeDialog(false);
  const clearDateRangeSelector = () => {
    setDateRange({
      startDate: manipulateDates({
        date: getFirstDayOfMonth(new Date()).toString(),
        formatTo: dateFormats.monthIn3Letters,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
      endDate: manipulateDates({
        date: new Date().toString(),
        formatTo: dateFormats.monthIn3Letters,
        currentFormat: dateFormats.timestamp,
        manipulate: { days: 0 },
      }),
    });
    setRefetchFromRemote(true);
    setDateRangeDialog(false);
  };
  const openDateRangeSelector = () => {
    setDateRangeDialog({
      attribute: 'date',
      title: 'Date Select',
    });
  };

  const updateCustomDate = ({ selectedFilters }: IFilterSaveParams) => {
    if (selectedFilters.startDate && selectedFilters.endDate) {
      setDateRange({
        startDate: manipulateDates({
          date: selectedFilters.startDate.toString(),
          formatTo: dateFormats.dateMonthIn3Letters,
          currentFormat: dateFormats.timestamp,
          manipulate: { days: 0 },
        }),
        endDate: manipulateDates({
          date: selectedFilters.endDate.toString(),
          formatTo: dateFormats.dateMonthIn3Letters,
          currentFormat: dateFormats.timestamp,
          manipulate: { days: 0 },
        }),
      });
    }
    setRefetchFromRemote(true);
    closeDateRangeSelector();
  };
  //#endregion << DateRangeSelector manager <<

  // fetching of funding data
  useEffect(() => {
    if (!refetchFromRemote) {
      return;
    }
    pxAgGridRef?.current?.showLoadingDialog();
    getFunds();
    setRefetchFromRemote(false);
    pxAgGridRef?.current?.hideLoadingDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchFromRemote]);

  const onChangeSearchText = (e: any) => {
    e?.preventDefault();
    setSearchText(e.target?.value);
  };

  const handleSave = async (updates: FundChanges) => {
    try {
      let updatedFunds: FundVal[] = updates.funds_update;
      if (!isEmpty(updates.funds_update)) {
        updatedFunds = updates.funds_update.filter(
          (item: FundVal) => !item.isFundingRequiredModified
        );
      }
      let funds = [...updatedFunds, ...updates.is_funding_required_modified];
      await fetchUrl('put', apiToUrlMap.preordersFundingAccept, {
        body: { funds },
      });
      setSearchText('');
      setRefetchFromRemote(true);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleUpload = async (file: File) => {
    try {
      const res = (await fileUpload(file, apiToUrlMap.preordersFundingImport)) as IImportFunds;
      setImportData(res);
      console.log(res);
      setCurrentFileName(file.name);
      closeImportDialog();
    } catch (error: any) {
      if (pxImportRef?.current?.setImportError) {
        pxImportRef.current.setImportError(error.message);
      }
    }
  };

  //Funds Export
  const handleFileExport = async () => {
    setLoadingDialog(true);
    const startDate = manipulateDates({
      date: dateRange.startDate,
      formatTo: dateFormats.yearMonthDate,
      manipulate: { days: 0 },
    });
    const endDate = manipulateDates({
      date: dateRange.endDate,
      formatTo: dateFormats.yearMonthDate,
      manipulate: { days: 0 },
    });
    const params =
      searchText!.length > 0
        ? { startDate, endDate, buyerName: searchText }
        : { startDate, endDate };
    let fileName = `exportFunding_${startDate}_${endDate}`;
    await exportData({
      url: apiToUrlMap.preorderFundingExport,
      fileName,
      additionalParams: {
        params,
      },
    });
    setLoadingDialog(false);
  };

  //import dialog state
  const openImportDialog = () => {
    setImportDialog(true);
  };

  const closeImportDialog = () => {
    setImportDialog(false);
  };
  // const handleConfirmFundSave = async () => {
  //   setConfirmDialogProps(initialConfirmDialogProps);
  //   try {
  //     await fetchUrl('patch', apiToUrlMap.preorderFundingConfirm);
  //     setRefetchFromRemote(true);
  //     openSnackBar(`Funds confirmed successfully`, 'success');
  //   } catch (error: any) {
  //     openSnackBar('Error while confirming funds', 'error');
  //   }
  // };
  // const confirmFundingHandler = () => {
  //   setConfirmDialogProps({
  //     isDialogOpen: true,
  //     title: 'Confirm Funding',
  //     confirmDialogContent: confirmFundingContent,
  //     confirmActionProps: {
  //       rejectLabel: 'CANCEL',
  //       confirmLabel: 'SAVE',
  //       rejectHandler: closeConfirmFundingDialog,
  //       confirmHandler: handleConfirmFundSave,
  //     },
  //   });
  // };

  const reviewImportStateHandler = () => {
    setSearchText('');
    setImportData(null);
  };
  // const closeConfirmFundingDialog = () => {
  //   setConfirmDialogProps(initialConfirmDialogProps);
  // };
  // const confirmFundingContent = () => (
  //   <Alert severity="info" variant="filled">
  //     Funding will be confirmed for {numberOfCustomers} customers.
  //   </Alert>
  // );

  return (
    <>
      <LoadingDialog isDialogOpen={!!loadingDialog} />
      {importData && (
        <FundingImport
          importData={importData}
          fileName={currentFileName!}
          keyGroup={{
            errors: 'preorderFundingError',
            changes: 'preorderFundingChange',
            warnings: 'preorderFundingWarning',
          }}
          closeImport={reviewImportStateHandler}
          saveImport={handleSave}
          screen="funding"
        />
      )}
      {!importData && (
        <main className="grid-x funding">
          <ImportPopup
            title="PO: Import Funding"
            desc=""
            ref={pxImportRef}
            open={importDialog}
            handleClose={closeImportDialog}
            handleUpload={handleUpload}
          />
          <h2 className="cell small-5 margin-bottom-2">PO Funding</h2>
          <div className="cell small-7 text-right margin-bottom-1 px-po-import-bar">
            <ImportExportDropdown
              importExportOptions={{
                importLabel: 'IMPORT PO FUNDS',
                importAction: openImportDialog,
                exportLabel: 'EXPORT PO FUNDS',
                exportAction: handleFileExport,
                actionButtonParams: {
                  import: {
                    'data-id': 'import',
                  },
                  export: {
                    'data-id': 'export',
                  },
                },
              }}
            />
            {/* <ConfirmDialog
              isDialogOpen={confirmDialogProps?.isDialogOpen}
              title={confirmDialogProps?.title}
              confirmDialogContent={confirmDialogProps?.confirmDialogContent}
              confirmActionProps={confirmDialogProps?.confirmActionProps}
            /> */}
            {/* <PrimaryButton
              disabled={isConfirmFunds}
              onClick={confirmFundingHandler}
              data-id="confirmFunding">
              CONFIRM FUNDING
            </PrimaryButton> */}
          </div>
          <div className="cell small-12 margin-bottom-1">
            <TextField
              // label="Search"
              size="small"
              style={{ width: '30%' }}
              data-id="searchBar"
              placeholder="Search Buyer"
              value={searchText}
              onChange={onChangeSearchText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchText ? (
                  <InputAdornment
                    className="pointer-mouse"
                    position="end"
                    onClick={() => setSearchText('')}>
                    <CloseIcon />
                  </InputAdornment>
                ) : (
                  <></>
                ),
              }}
            />
          </div>
          <div className="cell small-12">
            <div className="cell small-12">
              <DateRangeAnalyticsSelector
                dateRangeDialog={dateRangeDialog}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                updateCustomDate={updateCustomDate}
                clearDateRangeSelector={clearDateRangeSelector}
                closeDateRangeSelector={closeDateRangeSelector}
                disableStartBefore={manipulateDates({
                  date: goLiveDate,
                  formatTo: dateFormats.monthIn3Letters,
                  currentFormat: dateFormats.yearMonthDate,
                  manipulate: { days: 0 },
                })}
              />
              <div className="margin-top-2 margin-bottom-2">
                <DateSelectorComponent
                  dateRange={dateRange}
                  openDateRangeSelector={openDateRangeSelector}
                />
              </div>
            </div>
            <div className="cell small-12">
              <FundingGrid searchText={searchText} ref={pxAgGridRef} />
            </div>
          </div>
        </main>
      )}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    fundingpreorderState: state.fundingpreorderState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    fundingPreorderActions: bindActionCreators(fundingPreorderActions, dispatch),
    preorderActions: bindActionCreators(preorderActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Funding);
