import * as types from '../actions/actionTypes';
const initState = {
    results: [],
    isLoaded: false,
    filters: {}
}

export default function inventoryReducer(state: any = initState, action: any) {
    switch (action.type) {
        case types.INVENTORY_SET:
            return {
                ...state,
                results: action.inventory.map((val: any, index: number) => {
                    return {
                        ...val,
                        index
                    }
                }),
                isLoaded: true
            };
        case types.INVENTORY_UPDATE:
            const results = [...state.results];
            results[+action.payload.index] = {
                ...results[+action.payload.index],
                ...action.payload.inventory
            };
            return {
                ...state,
                results: results,
            };
        case types.INVENTORY_SETTINGS_SET:
            return {
                ...state,
                settings: action.settings
            };
        default:
            return state;
    }
}