import axios from 'axios';
import { API, Auth } from 'aws-amplify';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ePricingCategory } from '../types/IPricing';
import { eOfferClearingTabs, offerClearingStatusValues } from '../types/IOfferClearing';
import { defaultChannelValueDisplayMap, IChannelTab } from '../types/IChannel';
import { parse, isValid, parseISO, intervalToDuration } from 'date-fns';
import { formatString } from '../ApiMapping';
import { validatorType } from '../types/IBillingShippingTypes';
import { ColDef, ColGroupDef, IAggFuncParams, ValueGetterParams } from 'ag-grid-community';
import ImportHighlighter from '../components/ImportReview/ImportHighlighter';
import {
  IAddedToStockFilterStategy,
  IAdvanceFilterEngine,
  IAdvanceFilterEngineRes,
  IAdvanceFilterEngineV1,
  IAdvanceFilterFn,
  IFiltersApplied,
  IScreenNames,
} from '../types/IFilterEngine';
import { IItemSchedulingTab } from '../types/IItemSetup';
import { awaitingRule, awardedRule, reofferRule } from '../components/PreOrders/Utils';
import { IPoClearingTabs } from '../types/IPoClearing';

export const apiName = 'tenantSpecificEndpoint';

const extraParams = {
  headers: {
    // "pxTestAPIHeader": "pxTestAPIHeaderVal"
  },
};

export const getConfig = () => {
  return new Promise((resolve: any) => {
    // const pxConfig = localStorage.getItem('PxConfig');
    // if (pxConfig) return resolve(pxConfig);
    // TODO: Update pxConfig
    const localConfig = {
      business_address: 'Park West 1 & 2, 1507 LBJ Fwy, Suite 500, Farmers Branch, TX 75234',
      business_buyer_site: 'https://hylauat.phonexinc.com',
      business_copyrights_text: '© 2020. All rights reserved.',
      business_phone: '+1 972-573-0300',
      business_tenant_site: 'https://hylauat.phonexinc.com',
      business_website: 'https://www.hylamobile.com/',
      'CSS::px_alpha_darker': '0.63',
      'CSS::px_alpha_darker::phonex_theme_variant': '0.63',
      'CSS::px_alpha_light': '0.1',
      'CSS::px_alpha_light::phonex_theme_variant': '0.1',
      'CSS::px_alpha_lighter': '0.03',
      'CSS::px_alpha_lighter::phonex_theme_variant': '0.03',
      'CSS::px_alpha_medium': '0.4',
      'CSS::px_alpha_medium::phonex_theme_variant': '0.4',
      'CSS::px_alpha_muted': '0.33',
      'CSS::px_alpha_muted::phonex_theme_variant': '0.33',
      'CSS::px_color_background': '#FFFFFF',
      'CSS::px_color_background::phonex_theme_variant': '#FFFFFF',
      'CSS::px_color_badge_1': '#DBA200',
      'CSS::px_color_badge_1::phonex_theme_variant': '#DBA200',
      'CSS::px_color_badge_1b': '#DBA200',
      'CSS::px_color_badge_1b::phonex_theme_variant': '#DBA200',
      'CSS::px_color_badge_2': '#5A8B30',
      'CSS::px_color_badge_2::phonex_theme_variant': '#5A8B30',
      'CSS::px_color_badge_2b': '#5A8B30',
      'CSS::px_color_badge_2b::phonex_theme_variant': '#5A8B30',
      'CSS::px_color_badge_3': '#2F50AC',
      'CSS::px_color_badge_3::phonex_theme_variant': '#2F50AC',
      'CSS::px_color_badge_3b': '#2F50AC',
      'CSS::px_color_badge_3b::phonex_theme_variant': '#2F50AC',
      'CSS::px_color_badge_4': '#8A3AE5',
      'CSS::px_color_badge_4::phonex_theme_variant': '#8A3AE5',
      'CSS::px_color_badge_5': '#D21EA9',
      'CSS::px_color_badge_5::phonex_theme_variant': '#D21EA9',
      'CSS::px_color_badge_6': '#816C6C',
      'CSS::px_color_badge_6::phonex_theme_variant': '#816C6C',
      'CSS::px_color_border': '#d3d3d3',
      'CSS::px_color_border::phonex_theme_variant': '#d3d3d3',
      'CSS::px_color_border_light': '#e5e5e5',
      'CSS::px_color_border_light::phonex_theme_variant': '#e5e5e5',
      'CSS::px_color_error': '#D32F2F',
      'CSS::px_color_error::phonex_theme_variant': '#D32F2F',
      'CSS::px_color_error_input': '#D32F2F',
      'CSS::px_color_error_input::phonex_theme_variant': '#D32F2F',
      'CSS::px_color_error_text': '#D32F2F',
      'CSS::px_color_error_text::phonex_theme_variant': '#D32F2F',
      'CSS::px_color_info': '#202020',
      'CSS::px_color_info::phonex_theme_variant': '#202020',
      'CSS::px_color_link': '#124191',
      'CSS::px_color_link::phonex_theme_variant': '#124191',
      'CSS::px_color_link_active': '#124191',
      'CSS::px_color_link_active::phonex_theme_variant': '#124191',
      'CSS::px_color_link_hover': '#113167',
      'CSS::px_color_link_hover::phonex_theme_variant': '#113167',
      'CSS::px_color_link_visited': '#124191',
      'CSS::px_color_link_visited::phonex_theme_variant': '#0070B9',
      'CSS::px_color_primary': '#0070B9',
      'CSS::px_color_primary::phonex_theme_variant': '#0070B9',
      'CSS::px_color_secondary': '#FFF492',
      'CSS::px_color_secondary::phonex_theme_variant': '#FFF492',
      'CSS::px_color_success': '#4CAF50',
      'CSS::px_color_success::phonex_theme_variant': '#4CAF50',
      'CSS::px_color_surface': '#FFFFFF',
      'CSS::px_color_surface::phonex_theme_variant': '#FFFFFF',
      'CSS::px_color_text_muted': '#BBBBBB',
      'CSS::px_color_text_muted::phonex_theme_variant': '#BBBBBB',
      'CSS::px_color_text_on_badge': '#FFFFFF',
      'CSS::px_color_text_on_badge::phonex_theme_variant': '#FFFFFF',
      'CSS::px_color_text_on_primary': '#FFFFFF',
      'CSS::px_color_text_on_primary::phonex_theme_variant': '#FFFFFF',
      'CSS::px_color_text_on_secondary': '#212121',
      'CSS::px_color_text_on_secondary::phonex_theme_variant': '#212121',
      'CSS::px_color_text_on_snackbar': '#FFFFFF',
      'CSS::px_color_text_on_snackbar::phonex_theme_variant': '#FFFFFF',
      'CSS::px_color_text_primary': '#212121',
      'CSS::px_color_text_primary::phonex_theme_variant': '#212121',
      'CSS::px_color_text_secondary': '#757575',
      'CSS::px_color_text_secondary::phonex_theme_variant': '#757575',
      'CSS::px_color_text_variant': '#2974CC',
      'CSS::px_color_text_variant::phonex_theme_variant': '#2974CC',
      'CSS::px_color_warning': '#F57C00',
      'CSS::px_color_warning::phonex_theme_variant': '#F57C00',
      'CSS::px_font_body': "'Roboto', sans-serif",
      'CSS::px_font_body::phonex_theme_variant': "'Roboto', sans-serif",
      'CSS::px_font_title': "'Roboto', sans-serif",
      'CSS::px_font_title::phonex_theme_variant': "'Roboto', sans-serif",
      'CSS::px_topLogoHeight': '40px',
      'CSS::px_topLogoHeight::phonex_theme_variant': '40px',
      'CSS::px_topLogoWidth': '159px',
      'CSS::px_topLogoWidth::phonex_theme_variant': '159px',
      descriptionParts:
        '{ "Accessories": "", "Hearables": "", "Other": "", "Phones": "warehouse,manufacturer,model,model_number,capacity,protocol,grade,lock_status", "Tablet": "", "Wearables": "" }',
      favicon: 'https://hylauat.phonexinc.com/static/favicons/Hyla_stamp_PMS368-300x300.png',
      gradeScaleUrl: null,
      goLiveDate: '2021-11-11',
      groupAttributes: 'manufacturer,model,warehouse,grade,capacity',
      groupDescription: 'manufacturer,model,capacity,grade',
      language: 'en-US',
      logoSmall: 'https://hylauat.phonexinc.com/static/img/logo.png',
      pusherSubscriptionKey: '5817e06a36e7ce5726e8',
      region: 'us-east-1',
      siteDescription: 'Hyla e-commerce app',
      siteTitle: 'Assurant',
      // tenantApiEndPoint: 'https://k6kyv5b46k.execute-api.us-east-1.amazonaws.com/dev',
      tenantApiEndPoint: process.env.REACT_APP_API_URL,
      tenantApiName: 'tenantSpecificEndpoint',
      tenantName: 'Hyla',
      tenantShortName: 'Hyla',
      tenant_id: 'Hyla',
      termsOfSaleUrl: 'https://hylaasp.hylamobile.com/terms-of-use/',
      userPoolId: 'us-east-1_vvh7pYi7n',
      userPoolLogo: 'https://hylauat.phonexinc.com/static/img/logo.png',
      userPoolWebClientId: '39e5vcmeerd9of4sam75v9b7nm',
    };
    if (process.env.REACT_APP_STAGE === 'dev') {
      localConfig.business_tenant_site = '#';
      localConfig.goLiveDate = '2021-09-30';
      localConfig.pusherSubscriptionKey = '801f737c760aff1371cc';
      // localConfig.tenantApiEndPoint = 'https://rr6blburb8.execute-api.us-east-1.amazonaws.com/dev';
    }
    if (process.env.REACT_APP_STAGE === 'sandbox') {
      // localConfig.business_tenant_site = '#';
      localConfig.business_tenant_site = 'https://hylasandbox.phonexinc.com';
      localConfig.tenantApiEndPoint =
        'https://d8z0yypo0i.execute-api.us-east-2.amazonaws.com/sandbox';
    }
    if (process.env.REACT_APP_STAGE === 'test') {
      // hyla uat is the default one
    }
    if (process.env.REACT_APP_STAGE === 'prod') {
      localConfig.business_tenant_site = 'https://buy.hylamobile.com';
      localConfig.goLiveDate = '2021-12-27';
      localConfig.pusherSubscriptionKey = '94be2206f9b1f3a1a656';
      // localConfig.tenantApiEndPoint = 'https://fs0c2f46kh.execute-api.us-east-1.amazonaws.com/prod';
      localConfig.logoSmall = 'https://buy.hylamobile.com/static/img/logo.png';
      localConfig.favicon =
        'https://buy.hylamobile.com/static/favicons/Hyla_stamp_PMS368-300x300.png';
      localConfig.business_buyer_site = 'https://buy.hylamobile.com';
      localConfig.business_website = 'https://www.hylamobile.com';
    }
    if (process.env.REACT_APP_STAGE === 'prodlike') {
      localConfig.business_tenant_site = 'https:/hyla-prodlike.phonexinc.com';
      localConfig.logoSmall = 'https://hyla-prodlike.phonexinc.com/static/img/logo.png';
      localConfig.favicon =
        'https://hyla-prodlike.phonexinc.com/static/favicons/Hyla_stamp_PMS368-300x300.png';
      localConfig.business_buyer_site = 'https://hyla-prodlike.phonexinc.com';
    }
    resolve(localConfig);
  });
};

export const getToken = () => {
  const cookies = document.cookie.split(';');
  for (const i in cookies) {
    if (cookies[i].includes('jwtToken=')) {
      return cookies[i].split('=')[1];
    }
  }
  return null;
};

export const mapPathToRedirecting: any = {
  Fulfillment: 'fulfillment',
  Funding: 'funding',
  ItemScheduling: 'item-scheduling',
  OfferClearing: 'po-offer-clearing',
};

export const reactStringReplace = (
  str: string,
  labelStringFormatter: any,
  ReactComponentFormatter: any
) => {
  var parts = str.split(/\{|\}/g);
  var fn = labelStringFormatter,
    output = [];
  for (var i = 0; i < parts.length; i++) {
    output[i] = fn(parts[i], `reactStringReplace-${i}`);
    // toggle between the two functions
    fn = fn === labelStringFormatter ? ReactComponentFormatter : labelStringFormatter;
  }
  return <div className="grid-x align-middle">{output}</div>;
};

export const getFilter = () => {
  return new Promise((resolve: any) => {
    const localFilter = localStorage.getItem('PxFilter');
    if (localFilter) {
      resolve(JSON.parse(localFilter));
    } else {
      const path = '/px-api-gateway/filter';
      API.get(apiName, path, extraParams).then(async (res: any) => {
        localStorage.setItem('PxFilter', JSON.stringify(res));
        resolve(res);
      });
    }
  });
};

export const downloadFile = async (file: any, fileName?: string) => {
  const name =
    file.headers && file.headers['content-disposition']
      ? file.headers['content-disposition'].split('=')[1].trim()
      : fileName;
  const href = window.URL.createObjectURL(file.data || file);
  const link = document.createElement('a');
  link.href = href;
  link.style.display = 'none';
  link.setAttribute('download', name); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(href);
  return true;
};

export const itemGroupAverageBookedPrice = (params: IAggFuncParams) => {
  if (params.values.length) {
    const sum = {
      totalBookedInPeriodExtendedPrice: 0,
      totalBookedInPeriod: 0,
    };
    params.rowNode.childrenAfterFilter.forEach((val) => {
      if (!val.data?.totalBookedInPeriod) return;
      sum.totalBookedInPeriodExtendedPrice += +(val.data?.totalBookedInPeriodExtendedPrice || 0);
      sum.totalBookedInPeriod += +(val.data?.totalBookedInPeriod || 0);
    });

    return averageBookedPriceFormula(sum.totalBookedInPeriod, sum.totalBookedInPeriodExtendedPrice);
  }
  return null;
  // return 50;
};

export const averageBookedPrice = ({ data }: any) => {
  // Extended Booked Price / Total Booked
  return averageBookedPriceFormula(data.totalBookedInPeriod, data.totalBookedInPeriodExtendedPrice);
};

const averageBookedPriceFormula = (
  totalBookedInPeriod: any,
  totalBookedInPeriodExtendedPrice: any
) => {
  if (!totalBookedInPeriod) return Infinity;
  return totalBookedInPeriodExtendedPrice / totalBookedInPeriod;
};

export const averageBookedCost = (params: any) => {
  if (params.node?.isRowPinned()) console.log(params.data);

  const { data } = params;
  // Extended Booked Cost / Total Booked
  return averageBookedCostFormula(data.totalBookedInPeriod, data.totalBookedInPeriodExtendedCost);
};

export const itemGroupAverageBookedCost = (params: IAggFuncParams) => {
  if (params.values.length) {
    const sum = {
      totalBookedInPeriodExtendedCost: 0,
      totalBookedInPeriod: 0,
    };
    params.rowNode.childrenAfterFilter.forEach((val) => {
      if (!val.data?.totalBookedInPeriod) return;
      sum.totalBookedInPeriodExtendedCost += +(val.data?.totalBookedInPeriodExtendedCost || 0);
      sum.totalBookedInPeriod += +(val.data?.totalBookedInPeriod || 0);
    });

    return averageBookedCostFormula(sum.totalBookedInPeriod, sum.totalBookedInPeriodExtendedCost);
  }
  return null;
};

const averageBookedCostFormula = (
  totalBookedInPeriod: any,
  totalBookedInPeriodExtendedCost: any
) => {
  if (!totalBookedInPeriod) return Infinity;
  if (!totalBookedInPeriodExtendedCost) return null;
  // checkNullOrUndefined(totalBookedInPeriodExtendedCost)
  return (+totalBookedInPeriodExtendedCost / totalBookedInPeriod).toFixed(2);
};

export const marginBookedPercent = ({ data }: any) => {
  return marginBookedPercentFormula(
    data.totalBookedInPeriodExtendedPrice,
    data.totalBookedInPeriodExtendedCost
  );
};

export const itemGroupMarginBookedPercent = (params: IAggFuncParams) => {
  if (params.values.length) {
    const sum = {
      totalBookedInPeriodExtendedPrice: 0,
      totalBookedInPeriodExtendedCost: 0,
    };
    params.rowNode.childrenAfterFilter.forEach((val) => {
      if (!val.data?.totalBookedInPeriodExtendedPrice) return;
      sum.totalBookedInPeriodExtendedPrice += +(val.data?.totalBookedInPeriodExtendedPrice || 0);
      sum.totalBookedInPeriodExtendedCost += +(val.data?.totalBookedInPeriodExtendedCost || 0);
    });

    return marginBookedPercentFormula(
      sum.totalBookedInPeriodExtendedPrice,
      sum.totalBookedInPeriodExtendedCost
    );
  }
  return null;
};

const marginBookedPercentFormula = (
  totalBookedInPeriodExtendedPrice: any,
  totalBookedInPeriodExtendedCost: any
) => {
  // (Extended Booked Price - Extended Booked Cost)/Extended Booked Price
  if (!totalBookedInPeriodExtendedPrice) return Infinity;
  if (!totalBookedInPeriodExtendedCost) return null;
  const diff = totalBookedInPeriodExtendedPrice - totalBookedInPeriodExtendedCost;
  return ((diff * 100) / totalBookedInPeriodExtendedPrice).toFixed(2);
};

export const estimatedDaysToSell = (days: number) => ({ data }: any) => {
  return estimatedDaysToSellFormula(data.totalBookedInPeriod, data.currentAvailableQuantity, days);
};

export const estimatedDaysToSellPrefetch = (days: number, data: any) => {
  return estimatedDaysToSellFormula(data.totalBookedInPeriod, data.currentAvailableQuantity, days);
};

export const itemGroupEstimatedDaysToSell = (days: number) => (params: IAggFuncParams) => {
  if (params.values.length) {
    const sum = {
      totalBookedInPeriod: 0,
      currentAvailableQuantity: 0,
    };
    params.rowNode.childrenAfterFilter.forEach((val) => {
      if (!val.data?.totalBookedInPeriod) {
        return;
      }
      sum.totalBookedInPeriod += +(val.data?.totalBookedInPeriod || 0);
      sum.currentAvailableQuantity += +(val.data?.currentAvailableQuantity || 0);
    });

    return estimatedDaysToSellFormula(sum.totalBookedInPeriod, sum.currentAvailableQuantity, days);
  }
  return null;
};

const estimatedDaysToSellFormula = (
  totalBookedInPeriod: any,
  currentAvailableQuantity: any,
  days: number
) => {
  if (!totalBookedInPeriod) return Infinity;

  if (!days || !currentAvailableQuantity) return 0;

  //  A = Current quantity available for sale
  // B = Total Booked in Period / Number of days in period
  // Estimated Days to Book = A / B
  return ((currentAvailableQuantity * days) / totalBookedInPeriod).toFixed(2);
};

export const dayOfInventory = (days: number) => (params: ValueGetterParams) => {
  if (!params.data) return null;
  return dayOfInventoryFormula(
    params.data.periodBeginningOnHand,
    params.data.periodEndingOnHand,
    params.data.soldInPeriod,
    days
  );
};

export const itemGroupDayOfInventory = (days: number) => (params: IAggFuncParams) => {
  if (params.values.length) {
    const sum = {
      periodBeginningOnHand: 0,
      periodEndingOnHand: 0,
      soldInPeriod: 0,
    };
    params.rowNode.childrenAfterFilter.forEach((val) => {
      if (!val.data?.soldInPeriod) return;
      sum.periodBeginningOnHand += +(val.data?.periodBeginningOnHand || 0);
      sum.periodEndingOnHand += +(val.data?.periodEndingOnHand || 0);
      sum.soldInPeriod += +(val.data?.soldInPeriod || 0);
    });

    return dayOfInventoryFormula(
      sum.periodBeginningOnHand,
      sum.periodEndingOnHand,
      sum.soldInPeriod,
      days
    );
  }
  return null;
};

const dayOfInventoryFormula = (startQty: any, endQty: any, soldInventory: any, days: number) => {
  // Days Inventory when Sold equals 0 is 9999
  if (!soldInventory) return Infinity;

  if ((checkNullOrUndefined(startQty) && checkNullOrUndefined(endQty)) || !days) return null;
  //   Average Inventory = (Start Qty + End Qty) / 2
  const averageInventory = (+startQty + +endQty) / 2;
  // Days Inventory when Sold is GT than 0 is
  // = (Average Inventory / Sold ) * Number of days in period
  return ((averageInventory * days) / soldInventory).toFixed(2);
};

export const getImportPricingSummary = async () => {
  return await commonService('get', `/px-api-gateway/stocklist/pricing/import-summary`, {
    body: {},
  });
};

export const acceptPricingImport = async (reqBody: any) => {
  return await commonService('post', `/px-api-gateway/stocklist/pricing/accept-import`, {
    responseType: 'text',
    body: reqBody,
  });
};

export const cancelPricingImport = async () => {
  return await commonService('post', `/px-api-gateway/stocklist/pricing/cancel-import`, {
    body: {},
  });
};

export const offerClearingTabRules = (tab: string, status: string) => {
  switch (tab.toLowerCase()) {
    case eOfferClearingTabs.offered:
      return offeredRule(status);
    case eOfferClearingTabs.countered:
      return counterRule(status);
    case eOfferClearingTabs.accepted:
      return acceptedRule(status);
    default:
      return true;
  }
};

export const offeredRule = (status: string) => {
  return status === offerClearingStatusValues.offered;
};

export const counterRule = (status: string) => {
  return status === offerClearingStatusValues.countered;
};

export const acceptedRule = (status: string) => {
  return status === offerClearingStatusValues.accepted;
};

export const pricingTabRules = (
  tab: string,
  draftListPrice: any,
  draftLowPrice: any,
  draftSource: any,
  listPrice: any,
  lowPrice: any
) => {
  const draftPrice = {
    listPrice: draftListPrice,
    lowPrice: draftLowPrice,
    source: draftSource,
  };
  // TODO: Change these values according to server
  switch (tab.toLowerCase()) {
    case ePricingCategory.changes:
      return (
        importRule(draftPrice) || manualRule(draftPrice, listPrice, lowPrice)
        // || manualNoChangeRule(draftPrice, listPrice, lowPrice)
      );
    case ePricingCategory.import:
      return importRule(draftPrice);
    case ePricingCategory.manual:
      return manualRule(draftPrice, listPrice, lowPrice);
    case ePricingCategory.manualNoChange:
      return manualNoChangeRule(draftPrice, listPrice, lowPrice);
    case ePricingCategory.unpriced:
      return unpricedRule(draftPrice, listPrice, lowPrice);
    default:
      // all
      return true;
  }
};

export const categorizePricing = (pricing: any) => {
  const draftPrice = {
    listPrice: pricing.draftListPrice,
    lowPrice: pricing.draftLowPrice,
    source: pricing.draftSource,
  };
  if (unpricedRule(draftPrice, pricing.listPrice, pricing.lowPrice)) {
    return ePricingCategory.unpriced;
  }

  if (manualRule(draftPrice, pricing.listPrice, pricing.lowPrice)) {
    return ePricingCategory.manual;
  }

  if (manualNoChangeRule(draftPrice, pricing.listPrice, pricing.lowPrice)) {
    return ePricingCategory.manualNoChange;
  }

  if (importRule(draftPrice)) {
    return ePricingCategory.import;
  }

  return ePricingCategory.all;
};

export const importRule = (draftPrice: any) => {
  return draftPrice && draftPrice.source && draftPrice.source.toLowerCase() === 'import';
};

export const manualRule = (draftPrice: any, listPrice: any, lowPrice: any) => {
  return (
    draftPrice.source &&
    draftPrice.source.toLowerCase() === 'manual' &&
    draftPrice.listPrice &&
    draftPrice.lowPrice &&
    (listPrice !== draftPrice.listPrice || lowPrice !== draftPrice.lowPrice)
  );
};

const manualNoChangeRule = (draftPrice: any, listPrice: any, lowPrice: any) => {
  return (
    draftPrice &&
    draftPrice.source &&
    draftPrice.source.toLowerCase() === 'manual' &&
    listPrice === draftPrice.listPrice &&
    lowPrice === draftPrice.lowPrice
  );
};

const unpricedRule = (draftPrice: any, listPrice: any, lowPrice: any) => {
  return (!listPrice || !lowPrice) && (!draftPrice.listPrice || !draftPrice.lowPrice);
};

const channelStocklistRule = (inventory: any) => {
  return inventory?.channel === IChannelTab.stocklist || inventory?.stockListQuantityAvailable > 0;
};

const channelAuctionRule = (inventory: any) => {
  return inventory.channel === IChannelTab.auction || inventory.auctionQuantityAvailable > 0;
};

const channelUnassignedRule = (inventory: any) => {
  return inventory.channel === IChannelTab.notAssign || inventory.unassignedQuantityAvailable > 0;
};

const channelPreOrderRule = (inventory: any) => {
  return inventory.channel === IChannelTab.preOrder || inventory.preOrderQuantityAvailable > 0;
};

const categorizeChannel = (inventory: any) => {
  const categoryArr = [];
  if (channelStocklistRule(inventory)) {
    categoryArr.push(IChannelTab.stocklist);
  }

  if (channelUnassignedRule(inventory)) {
    categoryArr.push(IChannelTab.notAssign);
  }

  if (channelPreOrderRule(inventory)) {
    categoryArr.push(IChannelTab.preOrder);
  }

  if (channelAuctionRule(inventory)) {
    categoryArr.push(IChannelTab.auction);
  }

  return categoryArr;
};

export const itemSchedulingDraftRule = (item: any) => {
  return item?.status === IItemSchedulingTab.draft;
};

export const itemSchedulingScheduledRule = (item: any) => {
  return item?.status === IItemSchedulingTab.scheduled;
};

const itemSchedulingOfferingRule = (item: any) => {
  return item?.status === IItemSchedulingTab.offering;
};

const itemSchedulingShippingRule = (item: any) => {
  return item?.status === IItemSchedulingTab.shipping;
};

const itemSchedulingNegotiatingRule = (item: any) => {
  return item?.status === IItemSchedulingTab.negotiating;
};
const itemSchedulingFinalizingRule = (item: any) => {
  return item?.status === IItemSchedulingTab.finalizing;
};

const categorizeItemScheduling = (item: any) => {
  const categoryArr = [];
  if (itemSchedulingDraftRule(item)) {
    categoryArr.push(IItemSchedulingTab.draft);
  }

  if (itemSchedulingScheduledRule(item)) {
    categoryArr.push(IItemSchedulingTab.scheduled);
  }

  if (itemSchedulingOfferingRule(item)) {
    categoryArr.push(IItemSchedulingTab.offering);
  }
  if (itemSchedulingNegotiatingRule(item)) {
    categoryArr.push(IItemSchedulingTab.negotiating);
  }
  if (itemSchedulingFinalizingRule(item)) {
    categoryArr.push(IItemSchedulingTab.finalizing);
  }
  if (itemSchedulingShippingRule(item)) {
    categoryArr.push(IItemSchedulingTab.shipping);
  }

  return categoryArr;
};

export const categorizePoClearing = (item: any) => {
  const categoryArr = [];
  if (awardedRule(item.status)) {
    categoryArr.push(IPoClearingTabs.awarded);
  }

  if (reofferRule(item.status)) {
    categoryArr.push(IPoClearingTabs.reofferedRequest);
  }

  if (awaitingRule(item.status)) {
    categoryArr.push(IPoClearingTabs.offered);
  }

  return categoryArr;
};

export const poClearingRules = (tab: string, status: string) => {
  switch (tab) {
    case IPoClearingTabs.awarded:
      return awardedRule(status);
    case IPoClearingTabs.reofferedRequest:
      return reofferRule(status);
    case IPoClearingTabs.offered:
      return awaitingRule(status);
    default:
      return true;
  }
};

export const itemSchedulingFilterByTab = (tab: IItemSchedulingTab, item: any) => {
  switch (tab) {
    case IItemSchedulingTab.draft:
      return itemSchedulingDraftRule(item);
    case IItemSchedulingTab.offering:
      return itemSchedulingOfferingRule(item);
    case IItemSchedulingTab.scheduled:
      return itemSchedulingScheduledRule(item);
    case IItemSchedulingTab.shipping:
      return itemSchedulingShippingRule(item);
    case IItemSchedulingTab.negotiating:
      return itemSchedulingNegotiatingRule(item);
    case IItemSchedulingTab.finalizing:
      return itemSchedulingFinalizingRule(item);
    default:
      return true;
  }
};

export const channelFilterByTab = (tab: IChannelTab, inventory: any) => {
  switch (tab) {
    case IChannelTab.stocklist:
      return channelStocklistRule(inventory);
    case IChannelTab.notAssign:
      return channelUnassignedRule(inventory);
    case IChannelTab.auction:
      return channelAuctionRule(inventory);
    case IChannelTab.preOrder:
      return channelPreOrderRule(inventory);
    default:
      return true;
  }
};

// get tab value
const getTabValue = (screen: string, data: any) => {
  switch (screen) {
    case 'pricing': {
      return [categorizePricing(data)];
    }
    case 'channel': {
      return categorizeChannel(data);
    }
    case 'itemScheduling': {
      return categorizeItemScheduling(data);
    }
    case 'poClearing': {
      return categorizePoClearing(data);
    }
    default: {
      return ['noTab'];
    }
  }
};

// order details rules
export const validateNewValueEmpty: validatorType = async ({ newValue }) => {
  if (!newValue) {
    return {
      error: true,
    };
  }

  return {
    success: true,
  };
};

export const validateShippingAddInfo: validatorType = async ({
  validationState,
  oldChoices,
  newValue,
  changedState,
}) => {
  if (validationState) {
    const shippingAddressId = oldChoices[changedState];
    if (shippingAddressId === newValue) {
      // no updates are done
      return {
        error: true,
      };
    }
    // if shipping method id is available in the new shipping method code
    return {
      success: true,
    };
  }
  return {
    error: true,
  };
};

//
export const orderDetailsMessages = {
  disabledFulfillmentStatus: {
    AWAITING_PAYMENT: 'To select Awaiting Payment status please change payment status',
    PARTIALLY_SHIPPED: 'Update some item qty as shipped first',
    SHIPPED: 'Update all item qty as shipped first',
    INVOICED: 'only enabled if status is “Shipped”',
  },
  disabledPaymentStatus: {
    PAID: 'Please update fulfillment status first',
  },
};

// order edit rules
interface IOrderQuantityEditRule {
  onhold?: boolean;
  currentEdit: any;
  prevData: any;
}
export const cancelQuantityEditRule = ({
  onhold,
  currentEdit,
  prevData,
}: IOrderQuantityEditRule) => {
  let error = '';
  if (onhold) {
    error = 'Cannot cancel on hold';
  } else {
    if (currentEdit.canceledQuantity < 0) {
      error = 'Must be 0 or more';
    }

    if (currentEdit.canceledQuantity > currentEdit.orderedQuantity - currentEdit.shippedQuantity) {
      error = `Cancel must be ${currentEdit.orderedQuantity - currentEdit.shippedQuantity} or less`;
    }

    if (
      currentEdit.canceledQuantity < prevData.canceledQuantity &&
      prevData.canceledQuantity - currentEdit.canceledQuantity > prevData.availableQuantity
    ) {
      error = `Cancel must be ${prevData.canceledQuantity - prevData.availableQuantity} or more`;
    }
  }
  return error;
};
export const orderedQuantityEditRule = ({
  onhold,
  currentEdit,
  prevData,
}: IOrderQuantityEditRule) => {
  let error = '';

  if (!onhold) {
    if (currentEdit.orderedQuantity < prevData.orderedQuantity) {
      error = 'Cannot decrease';
    }
  }

  if (+currentEdit.orderedQuantity <= 0) {
    error = 'Cannot be 0 or less';
    return error;
  }

  if (prevData.availableQuantity + prevData.orderedQuantity < currentEdit.orderedQuantity) {
    // allow increase checking availability
    error = `Must be ${prevData.availableQuantity + prevData.orderedQuantity} or less`;
  }
  return error;
};
export const shippedQuantityEditRule = ({
  onhold,
  currentEdit,
  prevData,
}: IOrderQuantityEditRule) => {
  let error = '';
  if (onhold) {
    error = 'Cannot Add Shipped Qty';
    // shipped qty must be validated by onhand
  } else {
    if (currentEdit.shippedQuantity > currentEdit.orderedQuantity - currentEdit.canceledQuantity) {
      error = `Must be ${prevData.orderedQuantity - prevData.canceledQuantity} or less`;
    }

    if (prevData.onhandQuantity < currentEdit.shippedQuantity - prevData.shippedQuantity) {
      error = `Must be ${prevData.shippedQuantity + prevData.onhandQuantity} or less`;
    }

    if (prevData.shippedQuantity > currentEdit.shippedQuantity) {
      error = 'Cannot decrease';
    }
  }
  return error;
};
export const currentEditedQuantity = ({ currentEdit, prevData }: IOrderQuantityEditRule) => {
  let updatedQuantity = '';
  if (prevData.orderedQuantity !== currentEdit.orderedQuantity) {
    updatedQuantity = 'orderedQuantity';
  }

  if (prevData.canceledQuantity !== currentEdit.canceledQuantity) {
    updatedQuantity = 'canceledQuantity';
  }

  if (prevData.shippedQuantity !== currentEdit.shippedQuantity) {
    updatedQuantity = 'shippedQuantity';
  }
  return updatedQuantity;
};
export const setOrderQuantityErrorObj = (
  orderQty: string,
  cancelQty: string,
  shipQty: string,
  unitPrice: string
) => {
  return {
    orderedQuantity: orderQty,
    canceledQuantity: cancelQty,
    shippedQuantity: shipQty,
    unitPrice,
  };
};

// field validation
export const fieldValidation = (value: string, type: string) => {
  let errorValRes = '';
  switch (type) {
    case 'alphanumeric':
      if (!value.match(/^([0-9]|[a-z])+([0-9a-z]+)$/i)) {
        errorValRes = 'Please enter alpha numeric value of buyer';
      }
      break;
    case 'atleast-one-alphabet':
      if (!value.match(/^[a-z]+[a-z0-9]*/i)) {
        errorValRes = 'Please start with atleast one alphabet';
      }
      break;
    case 'number':
      if (!value.match(/^[0-9]+$/i)) {
        errorValRes = 'Invalid number';
      }
      break;
    case 'internationalized-zip':
      // eslint-disable-next-line no-useless-escape
      if (
        value.match(/(^[-]+[\S \n]*[-]*$)|(^[-]*[\S \n]*[-]+$)/gi) ||
        // eslint-disable-next-line no-useless-escape
        !value.match(/^[a-z0-9\-]+[\s]?[a-z0-9\-]*$/i)
      ) {
        errorValRes = 'Invalid zip';
      }
      break;
    case 'required':
      if (!value) {
        errorValRes = 'Required field';
      }
      break;
    case 'email':
      // eslint-disable-next-line no-useless-escape
      if (!value.match(/^[\w-\.+]{1,64}@[\w-].{1,252}$/g)) {
        errorValRes = 'invalid email';
      }
      break;
    case 'tel': {
      // eslint-disable-next-line no-useless-escape
      if (!value.match(/^[+]?[0-9/() .\-\/]{10,15}/g) || value.match(/[a-z]/g)) {
        // telephone validation
        errorValRes = 'invalid telephone number';
      }
      break;
    }
    // atleast on alphabet and other can be numbers => country/state/city
    // numbers with special characters => zip
    case 'text':
      break;
  }
  return errorValRes;
};

export const commonService = async (
  method: string,
  path: string,
  additionalParams: any,
  permission?: string
) => {
  try {
    let result: any;
    const upperCaseMethod = method.toUpperCase();
    switch (upperCaseMethod) {
      case 'GET': {
        result = await API.get(apiName, path, additionalParams);
        break;
      }
      case 'POST': {
        result = await API.post(apiName, path, additionalParams);
        break;
      }
      case 'PUT': {
        result = await API.put(apiName, path, additionalParams);
        break;
      }
      case 'PATCH': {
        result = await API.patch(apiName, path, additionalParams);
        break;
      }
      case 'DELETE': {
        result = await API.del(apiName, path, additionalParams);
        break;
      }
      default: {
        result = {};
      }
    }

    if (
      upperCaseMethod === 'POST' &&
      ((additionalParams && additionalParams.responseType === 'text' && result) ||
        result?.id ||
        !result)
    ) {
      // you'll get id here
      return {
        ...result,
        success: true,
      };
    }

    if (upperCaseMethod === 'PUT' || upperCaseMethod === 'DELETE') {
      if (!result || (typeof result === 'object' && Object.keys(result).length === 0)) {
        return {
          success: true,
        };
      }
    }

    if (upperCaseMethod === 'GET' && (!result.status || result.status === 200)) {
      return result;
    }

    if (!result) {
      // throw new Error('Internal Service Error');

      return {
        status: 500,
        message: 'Internal Service Error',
        error: true,
      };
    }
    return {
      status: result.statusCode,
      message: result.message,
      error: true,
    };
  } catch (e: any) {
    // console.log(e, 'error occurred at common service lib');
    if (e.code === 'NotAuthorizedException') {
      // redirect
      <Redirect />;
      return;
    }
    return {
      timestamp: Date.now(),
      status: e.status,
      error: 'Connection Error',
      message: e.message,
      path: path,
    };
  }
};

const Redirect = () => {
  const history = useHistory();
  history.push('/customers');
  return <></>;
};

export const customerMessages = {
  updateCustomerSuccessMessage: 'Customer Updated successfully',
  addCustomerSuccessMessage: 'Customer Added successfully',
  activatedCustomerSuccessMessage: 'Customer Activated Successfully',
  deactivatedCustomerSuccessMessage: 'Customer Deactivated Successfully',
  compulsoryFields: [
    {
      id: 'buyerNumber',
      label: 'Customer Number',
      type: 'text',
      required: true,
      maxLength: null,
    },
    {
      id: 'buyerName',
      label: 'Customer Name',
      type: 'text',
      required: true,
      maxLength: null,
    },
    {
      id: 'salesRepId',
      label: 'Sales Representative',
      type: 'text',
      required: true,
      maxLength: null,
    },
    {
      id: 'state',
      label: 'State',
      type: 'text',
      required: true,
      maxLength: null,
    },
    {
      id: 'country',
      label: 'Country',
      type: 'text',
      required: true,
      maxLength: null,
    },
    {
      id: 'phone',
      label: 'Phone',
      type: 'text',
      required: true,
      maxLength: null,
    },
    {
      id: 'email',
      label: 'Email',
      type: 'text',
      required: true,
      maxLength: null,
    },
    {
      id: 'whatsApp',
      label: 'WhatsApp',
      type: 'text',
      required: true,
      maxLength: null,
    },
  ],
};

export const pricingGridColWidth = {
  ABP: 120,
  // ABPHeader: 'margin-left-minus-4px',
  ABC: 90,
  ABCHeader: 'margin-left-minus-4px',
  MARGINPERCENT: 90,
  MARGINPERCENTHeader: 'margin-left-minus-4px',
  BOOKED: 100,
  BOOKEDHeader: 'margin-left-minus-4px',
  OFFERED: 80,
  OFFEREDHeader: 'margin-left-minus-4px',
  SOLD: 70,
  SOLDHeader: 'margin-left-minus-4px',
};

export const offerClearingGridColWidth = {
  ABP: 120,
  // ABPHeader: 'px-left-60px',
  //     left: 60px;
  ABC: 90,
  ABCHeader: 'px-left-182px',
  // left: 194px
  MARGINPERCENT: 90,
  MARGINPERCENTHeader: 'px-left-272px',
  // left: 337px;
  BOOKED: 100,
  BOOKEDHeader: 'px-left-362px',
  // left: 455px;
  OFFERED: 80,
  OFFEREDHeader: 'px-left-463px',
  //    left: 554px;
  SOLD: 70,
  SOLDHeader: 'px-left-543px',
  // left: 641px;
};

export const poClearingColWidth = {
  ABP: 120,
  ASP: 120,
  OFFERED: 120,
  BOOKED: 108,
  SOLD: 100,
};

// TODO: review
const customerRowGroupGetter = (itemGroupGetterKey: string) => (params: any) => {
  if (
    params.data &&
    messages[itemGroupGetterKey] &&
    messages[itemGroupGetterKey][params.data.importItemCategory]?.label
  ) {
    return messages[itemGroupGetterKey][params.data.importItemCategory].label;
  }
  return '';
};

type AgGridReactColDefGetterFunction = (
  itemGroupGetterKey: string,
  settings: any
) => Array<ColDef | ColGroupDef>;

const customerImportReviewCols: AgGridReactColDefGetterFunction = (
  itemGroupGetterKey: string,
  settings: any
) => [
  {
    headerName: 'Row',
    headerClass: 'px-inventory-review-header',
    children: [
      {
        valueGetter: customerRowGroupGetter(itemGroupGetterKey),
        rowGroup: true,
        sortable: true,
        hide: true,
      },
      {
        headerName: '',
        field: 'rowNumber',
        maxWidth: 150,
      },
    ],
  },
  {
    headerName: 'Customer Information',
    headerClass: 'px-inventory-review-header',
    children: [
      {
        headerName: 'PhoneX Customer #',
        colId: 'px-customer-id',
        field: 'id',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Ref Customer #',
        field: 'buyerNumber',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Customer',
        field: 'buyerName',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Email',
        field: 'email',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Phone',
        field: 'phone',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'WhatsApp',
        field: 'whatsApp',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Sales Rep',
        field: 'salesRepId',
        valueGetter: (params: ValueGetterParams) => {
          if (!params.data) {
            return '';
          }
          return settings.salesRepObj &&
            params.data?.salesRepId &&
            settings.salesRepObj[params.data.salesRepId]?.fullName
            ? settings.salesRepObj[params.data.salesRepId].fullName
            : params.data?.salesRepId;
        },
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Country',
        field: 'country',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'State',
        field: 'state',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Status',
        field: 'active',
        cellRendererFramework: ImportHighlighter,
        valueGetter: (params) => {
          return params.data?.active ? 'Activated' : 'Deactivated';
        },
      },
    ],
  },
];

const addressImportReviewCols: AgGridReactColDefGetterFunction = (
  itemGroupGetterKey: string,
  settings: any
) => [
  {
    headerName: 'Row',
    headerClass: 'px-inventory-review-header',
    children: [
      {
        valueGetter: customerRowGroupGetter(itemGroupGetterKey),
        rowGroup: true,
        sortable: true,
        hide: true,
      },
      {
        headerName: '',
        field: 'rowNumber',
        maxWidth: 150,
      },
    ],
  },
  {
    headerName: 'Customer Information',
    headerClass: 'px-inventory-review-header',
    children: [
      {
        headerName: 'PhoneX Customer #',
        colId: 'px-customer-id',
        field: 'buyerId',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Ref Customer #',
        field: 'buyerNumber',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Address Code',
        field: 'addressCode',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Address 1',
        field: 'address1',
        cellRendererFramework: ImportHighlighter,
        width: 200,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        headerName: 'Address 2',
        field: 'address2',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'City',
        field: 'city',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'State',
        field: 'state',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Country',
        field: 'country',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'ZIP',
        field: 'zip',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Shipping/Default',
        cellRendererFramework: ImportHighlighter,
        valueGetter: (params) => {
          let label = params.data?.shippingAddress ? 'Yes' : '';
          if (params.data?.shippingAddress && params.data?.defaultShippingAddress) {
            label += ' [Default]';
          }
          return label;
        },
      },
      {
        headerName: 'Billing/Default',
        cellRendererFramework: ImportHighlighter,
        valueGetter: (params) => {
          let label = params.data?.billingAddress ? 'Yes' : '';
          if (params.data?.billingAddress && params.data?.defaultBillingAddress) {
            label += ' [Default]';
          }
          return label;
        },
      },
    ],
  },
];

const userImportReviewCols: AgGridReactColDefGetterFunction = (
  itemGroupGetterKey: string,
  settings: any
) => [
  {
    headerName: 'Row',
    headerClass: 'px-inventory-review-header',
    children: [
      {
        valueGetter: customerRowGroupGetter(itemGroupGetterKey),
        rowGroup: true,
        sortable: true,
        hide: true,
      },
      {
        headerName: '',
        field: 'rowNumber',
        maxWidth: 150,
      },
    ],
  },
  {
    headerName: 'Customer Information',
    headerClass: 'px-inventory-review-header',
    children: [
      {
        headerName: 'PhoneX Customer #',
        colId: 'px-customer-id',
        field: 'customerId',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'First Name',
        field: 'firstName',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Last Name',
        field: 'lastName',
        cellRendererFramework: ImportHighlighter,
        width: 200,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        headerName: 'Role',
        field: 'buyerRole',
        cellRendererFramework: ImportHighlighter,
      },
      {
        headerName: 'Status',
        field: 'enabled',
        valueGetter: (params) => {
          return params.data?.enabled ? 'Active' : 'Inactive';
        },
        cellRendererFramework: ImportHighlighter,
      },
    ],
  },
];

export const messages: any = {
  poOfferResponsesItemError: {
    label: 'Errors',
    OFFER_HAS_BEEN_UPDATED_SINCE_EXPORT: {
      label: 'Offer has been updated since export',
    },
    RESPONSE_PRICE_TOO_LOW: {
      label: 'Response Price too Low',
    },
    RESPONSE_QUANTITY_TOO_HIGH: {
      label: 'Response Quantity too High',
    },
    OFFER_ALREADY_AWARDED: {
      label: 'Offer already Awarded',
    },
    OFFER_ID_NOT_VALID_FOR_RESPONSE: {
      label: 'Offer ID not valid for response',
    },
    INVALID_DATES: {
      label: 'Offer dates not valid',
    },
    INVALID_OFFER_ID: {
      label: 'Offer ID not valid',
    },
    INVALID_OFFER: {
      label: 'Invalid Offer',
    },
    QUANTITY_IS_LESS_THAN_MIN_INCREMENT: {
      label: 'Quantity is less than min increment',
    },
    INVALID_RESPONSE_TYPE: {
      label: 'Response Type invalid',
    },
    INVALID_RESPONSE_QUANTITY: {
      label: 'Invalid Response Quantity',
    },
    INVALID_RESPONSE_PRICE: {
      label: 'Invalid Response Price',
    },
    DUPLICATE_OFFERS: {
      label: 'Duplicate Offers found',
    },
  },
  poOfferResponsesItemChange: {
    label: 'Changes',
    REOFFER_REQUEST: {
      label: 'Re-offer Request',
    },
    AWARDED: {
      label: 'Awarded',
    },
    REJECTED: {
      label: 'Rejected',
    },
  },
  poOfferResponsesItemWarning: {
    label: 'Warnings',
    REOFFER_REQUEST_CHANGED: {
      label: 'Re-Offer Request Changed',
    },
    TOTAL_AWARDS_FOR_ITEM_ARE_MORE_THAN_AVAILABLE_FORECAST: {
      label: 'Total Awards for item are more than available forecast',
    },
    RESPONSE_PRICE_FOR_AWARD_WILL_BE_IGNORED: {
      label: 'Response Price for Award will be ignored',
    },
    QUANTITY_HAS_BEEN_REDUCED_TO_MATCH_INCREMENT_SIZE: {
      label: 'Quantity has been reduced to match increment size',
    },
  },
  poOfferItemError: {
    label: 'Errors',
    ITEM_WAREHOUSE_DOESNOT_EXIST: {
      label: 'Item # and Warehouse not found in PO Items',
    },
    ITEM_NOT_IN_OFFERING: {
      label: 'Offer Item is no longer taking new offers',
    },
    'NEW OFFER PRICE_INVALID': {
      label: 'Invalid New Offer Price',
    },
    'NEW OFFER QUANTITY_INVALID': {
      label: 'Invalid New Offer Quantity',
    },
    'NEW TAKE ALL_INVALID': {
      label: 'Invalid New Take All (must be YES, NO, or blank)',
    },
    DUPLICATE_OFFERS: {
      label: 'More than one offer for the same Item',
    },
    ITEM_ALREADY_AWARDED: {
      label: 'Item already Awarded',
    },
    OFFER_HAS_BEEN_UPDATED_SINCE_EXPORT: {
      label: 'Offer has been updated since export',
    },
  },
  poOfferItemChange: {
    label: 'Changes',
    newOffers: {
      label: 'PO Offers Added',
    },
    updatedOffers: {
      label: 'PO Offers Updated',
    },
  },
  poOfferItemWarning: {
    label: 'Warnings',
    OFFER_QUANTITY_NOT_A_MULTIPLE_OF_INCREMENT_SIZE: {
      label: 'New Offer Quantity reduced to match Increment',
    },
    OFFER_QUANTITY_REDUCED_TO_FORECAST_QUANTITY: {
      label: 'New Offer Quantity reduced to Forecast',
    },
    NEW_OFFER_PRICE_ROUNDED_TO_THE_NEAREST_CENT: {
      label: 'New Offer Price rounded to the nearest cent',
    },
  },
  preorderItemError: {
    label: 'Errors',
    'ITEM#_INVALID': {
      label: 'Item # missing',
    },
    WAREHOUSE_INVALID: {
      label: 'Warehouse not found',
    },
    ITEM_WAREHOUSE_DOESNOT_EXIST: {
      label: 'Item # not found in Warehouse',
    },
    DUPLICATE_ITEMS: {
      label: 'Item # and Warehouse appears more than once',
    },
  },
  preorderItemChange: {
    label: 'Changes',
    newItems: {
      label: 'Draft items to be added',
    },
    updatedItems: {
      label: 'Draft items to be updated',
    },
  },
  preorderItemWarning: {
    label: 'Warnings',
    FORECAST_QUANTITY_NOT_A_MULTIPLE_OF_INCREMENT_SIZE: {
      label: 'Quantity Forecast does not match item increment (will be rounded down)',
    },
    FORECAST_QUANTITY_INVALID: {
      label: 'Quantity Forecast is not a valid quantity value (will be blank)',
    },
  },
  preorderDraftOrderError: {
    DATA_MISMATCH: {
      label: 'Data value mismatch error when drafting order',
    },
    INCREMENT_SIZE_MISMATCH: {
      label: 'Increment size mismatches when drafting order',
    },
  },
  preorderFundingError: {
    label: 'Errors found in the file',
    'BUYER#_INVALID': {
      label: 'Buyer# is not a PO Buyer',
    },
    ADD_OR_SUBTRACT_FUNDING_INVALID: {
      label: 'Add_Or_Subtract_Funding value invalid',
    },
    FUNDING_REQUIRED_INVALID: {
      label: 'Funding_Required value invalid',
    },
  },
  preorderFundingWarning: {
    label: 'Warnings found in the file',
    ADJUSTMENT_RESULTS_IN_NEGATIVE_FUNDS: {
      label: 'Add_Or_Subtract_Funding value would result in negative Ending_Balance',
    },
  },
  preorderFundingChange: {
    label: 'Changes Found in File',
    funds_update: {
      label: 'Updated Funds',
    },
    is_funding_required_modified: {
      label: 'Updated Funding Required',
    },
  },
  preorderImportError: {
    label: 'Errors Found in File',
    BUYERNUMBER_NOT_FOUND: {
      field: 'BuyerNumber',
      label: 'Buyer# not found',
    },
    WAREHOUSE_NOT_FOUND: {
      field: 'warehouse',
      label: 'Warehouse not found ',
    },
    ITEMNUMBER_NOT_FOUND: {
      field: 'SKU',
      label: 'Item# not found',
    },
    AWARDEDQUANTITY_NOT_FOUND: {
      field: 'Awarded_Quantity',
      label: 'Awarded_Quantity not found',
    },
    BUYER_NOT_PARTICIPATING: {
      label: 'Buyer# not a PO Buyer',
    },
    AWARDEDPRICE_NOT_FOUND: {
      field: 'Awarded_Price',
      label: 'Awarded_Price not found',
    },
    SHIPPINGSTARTDATE_NOT_FOUND: {
      field: 'shippingStartDate',
      label: 'Shipping_Start_Date not found',
    },
    SHIPPINGENDDATE_NOT_FOUND: {
      field: 'shippingEndDate',
      label: 'Shipping_End_Date not found',
    },
    ISTAKEALL_NOT_FOUND: {
      field: 'Take_All',
      label: 'Take_All not found',
    },
    TAKEALLLIMITPERCENTAGE_NOT_FOUND: {
      field: 'takeAllLimit',
      label: 'Take_All_Limit not found',
    },
    NEW_AWARD_DATES_OVERLAP_WITH_AN_EXISTING_AWARD: {
      field: ['shippingStartDate', 'shippingEndDate'],
      label: 'New Award Dates overlap an existing Award',
    },
    ITEM_WAREHOUSE_DOESNOT_EXIST: {
      field: 'itemWarehouse',
      label: 'Item# does not exist for Warehouse ',
    },
    DATE_OVERLAP_WITH_EXISTING: {
      field: ['shippingStartDate', 'shippingEndDate'],
      label: 'Shipping dates overlap with the existing schedule',
    },
    DUPLICATE_AWARDS: {
      label: 'Duplicate awards',
    },
    NEW_AWARD_WITH_ZERO_AWARDED_QUANTITY: {
      label: 'New awards with zero awarded quantity',
    },
    DATE_OVERLAP_IN_EXCEL: {
      label: 'Shipping dates overlap for the same item',
    },
  },
  preorderImportWarning: {
    label: 'Warnings Found in File',
    AWARDED_QUANTITY_NOT_A_MULTIPLE_OF_INCREMENT_SIZE: {
      label: 'Awarded_Quantity not a multiple of current Increment Size',
    },
    AWARDS_WILL_BE_DELETED: {
      label: 'Existing Award will be removed',
    },
  },
  preorderImportChange: {
    label: 'Changes Found in File',
    newAwards: {
      label: 'New Awards',
    },
    updateAwards: {
      label: 'Updated Awards',
    },
    deleteAwards: {
      label: 'Delete Awards',
    },
  },

  inventoryImportError: {
    label: 'Errors found (will be skipped)',
    DUPLICATE_SKU_FOR_WAREHOUSE: {
      label: 'Duplicate SKUs for Warehouse',
    },
    INVALID_WAREHOUSE: {
      label: 'Invalid SKU or Warehouse',
    },
    INVALID_CATEGORY: {
      label: 'Invalid Category',
    },
    INVALID_GRADE: {
      label: 'Invalid Grade',
    },
    MISSING_REQUIRED_ATTRIBUTE: {
      label: 'Missing required attribute',
    },
    MISSING_QUANTITY: {
      label: 'Missing Quantity',
    },
    INVALID_QUANTITY: {
      label: 'Invalid Quantity',
    },
  },
  inventoryImportWarning: {
    label: 'Warnings found',
    BACKORDER: {
      label: 'Quantity adjustments cause backorders',
    },
    INCREMENT_MISMATCH: {
      label: 'Quantity adjustments do not match Increments',
    },
    MISSING_COST: {
      label: 'Missing Cost',
    },
  },
  inventoryImportUpdate: {
    label: 'Changes found',
    NEW_SKU: {
      label: 'NEW SKUs will be added',
      id: ['itemNumber'],
    },
    DESC_UPDATED: {
      label: 'SKUs will have Description updated',
    },
    COST_UPDATED: {
      label: 'SKUs will have Cost updated',
      id: ['cost'],
    },
    QUANTITY_UPDATED: {
      label: 'SKUs will have Quantity updated',
      id: ['quantityOnHand'],
    },
    INCREMENT_SIZE_UPDATED: {
      label: 'SKUs will have Increments updated',
      id: ['incrementSize'],
    },
  },

  imeiImportError: {
    label: 'Errors found (will be skipped)',
    INVALID_SKU_WAREHOUSE: {
      label: 'Invalid SKUs for Warehouse',
    },
    INVALID_QUANTITY: {
      label: 'Invalid Quantity',
    },
    INVALID_ORDER_NUMBER: {
      label: 'Invalid Order Number',
    },
    IMEIS_EXCEEDS_SHIPPED_COUNTS: {
      label: 'IMEI Exceeds Shipped Counts',
    },
    DUPLICATE_IMEI: {
      label: 'Duplicate IMEI',
    },
  },
  imeiImportWarning: {
    label: 'Warnings found',
    IMEIS_LESS_THAN_PREVIOUS_IMEI_COUNTS: {
      label: 'IMEI less than previous IMEI counts',
    },
    MISSING_INVOICE_OR_TRACKING_NUMBER: {
      label: 'Missing invoice or tracking number',
    },
  },
  imeiImportUpdate: {
    label: 'Changes found',
    VALID: {
      label: 'IMEIs Added',
    },
  },

  pricingImportError: {
    label: 'Errors found (will be skipped)',
    INVALID_SKU: {
      label: 'Invalid SKU',
    },
    LOW_PRICE_MORE_THAN_LIST_PRICE: {
      label: 'Low Price is more than List Price',
    },
  },
  pricingImportWarning: {
    label: 'Warnings found',
    WILL_OVERWRITE_UNPUBLISHED_PRICE: {
      label: 'Overwrite to the unpublished draft',
    },
  },
  pricingImportUpdate: {
    label: 'Changes found',
    PRICE_UPDATED: {
      label: 'Pricing Updated',
      id: ['importLowPrice', 'importListPrice'],
    },
  },
  pricing: {
    label: 'Review Pricing Import Changes',
    importUrlKey: 'pricingImportAccept',
    keyGroup: {
      errors: 'pricingImportError',
      warnings: 'pricingImportWarning',
      updates: 'pricingImportUpdate',
    },
  },

  customerImportError: {
    label: 'Errors found (will be skipped)',
    INVALID_SALES_REP: {
      label: 'Missing/Invalid SalesRep Id',
    },
    INVALID_EMAIL: {
      label: 'Invalid Customer Email',
    },
    DUPLICATE_EMAIL: {
      label: 'Duplicate Customer Email',
    },
    DUPLICATE_CUSTOMER_NAME: {
      label: 'Duplicate Customer Name',
    },
    DUPLICATE_PHONEX_CUSTOMER_NUMBER: {
      label: 'Duplicate Phonex Customer #',
    },
    DUPLICATE_REF_CUSTOMER_NUMBER: {
      label: 'Duplicate Ref Customer #',
    },
    CUSTOMER_NOT_FOUND: {
      label: 'Customer Not Found',
    },
    MISSING_CUSTOMER_NAME: {
      label: 'Missing Customer Name',
    },
    MISSING_PHONEX_CUSTOMER_NUMBER: {
      label: 'Missing Customer #',
    },
    MISSING_REF_CUSTOMER_NUMBER: {
      label: 'Missing Ref Customer #',
    },
    MISSING_REQUIRED_ADDITIONAL_FIELD: {
      label: 'Missing one of the required additional field columns',
    },
    MISSING_REQUIRED_ADDITIONAL_FIELD_VALUE: {
      label: 'Missing one of the required additional fields',
    },
    CUSTOMER_NUMBER_ALREADY_EXIST: {
      label: 'Customer Number Already Exist',
    },
    CUSTOMER_NAME_ALREADY_EXIST: {
      label: 'Customer Name Already Exist',
    },
    REF_CUSTOMER_NUMBER_ALREADY_EXIST: {
      label: 'Ref Customer # Already Exist',
    },
    FIELD_EXCEEDS_LENGTH: {
      label: 'Customer Fields exceed Length',
    },
  },
  customerImportWarning: {
    STATUS_CHANGE: {
      label: 'Customer Status Change',
      id: ['active'],
    },
    CUSTOMER_NAME_ALREADY_EXIST: {
      label: 'Duplicate Customer Name',
    },
    DUPLICATE_CUSTOMER_NAME: {
      label: 'Duplicate Customer Name',
    },
    PHONEX_CUSTOMER_NUMBER: {
      label: 'Do not enter Phonex Customer # while adding Customers',
    },
  },
  customerImportUpdate: {
    UPDATED: {
      label: 'Updated',
    },
    NEW: {
      label: 'New Customer',
    },
  },
  customer: {
    label: 'Review Customer Import Changes',
    getColDef: customerImportReviewCols,
    importUrlKey: 'customerImportAccept',
    keyGroup: {
      errors: 'customerImportError',
      warnings: 'customerImportWarning',
      updates: 'customerImportUpdate',
    },
  },

  addressImportError: {
    label: 'Errors found (will be skipped)',
    ADDRESS_NOT_FOUND: {
      label: 'Invalid Address Id',
    },
    CUSTOMER_NOT_FOUND: {
      label: 'Customer not found',
    },
    DUPLICATE_ADDRESS_CODE: {
      label: 'Duplicated Address code',
    },
    DUPLICATE_ADDRESS_ID: {
      label: 'Duplicated Address #',
    },
    MISSING_ADDRESS_CODE: {
      label: 'Missing Address Code',
    },
    MISSING_ADDRESS_1: {
      label: 'Missing Address 1',
    },
    MISSING_ADDRESS_2: {
      label: 'Missing Address 2',
    },
    MISSING_COUNTRY: {
      label: 'Missing Country',
    },
    FIELD_EXCEEDS_LENGTH: {
      label: 'Address Fields exceed Length',
    },
    MISSING_REQUIRED_ADDITIONAL_FIELD: {
      label: 'Missing Required Additional Field',
    },
  },
  addressImportWarning: {
    ADDRESS_MARKED_INACTIVE: {
      label: 'Address will be marked as inactive',
    },
    ADDRESS_TYPE_NOT_SET: {
      label: 'Address type not set',
    },
  },
  addressImportUpdate: {
    UPDATED: {
      label: 'Updated',
    },
    NEW: {
      label: 'New Address',
    },
  },
  address: {
    label: 'Review Address Import Changes',
    getColDef: addressImportReviewCols,
    importUrlKey: 'customerAddressImportAccept',
    keyGroup: {
      errors: 'addressImportError',
      warnings: 'addressImportWarning',
      updates: 'addressImportUpdate',
    },
  },

  userImportError: {
    label: 'Errors found (will be skipped)',
    DUPLICATE_EMAIL: {
      label: 'Duplicate User email',
    },
    DUPLICATE_USER_NUMBER: {
      label: 'Duplicate User Number',
    },
    MISSING_EMAIL: {
      label: 'Missing User Email',
    },
    MISSING_FIRST_NAME: {
      label: 'Missing First Name',
    },
    MISSING_ROLE: {
      label: 'Missing First Name',
    },
    // TODO: no point of this error => it should be missing required additional field
    MISSING_REQUIRED_FIELD: {
      label: 'Missing a Required Field',
    },
    MISSING_REQUIRED_ADDITIONAL_FIELD: {
      label: 'Missing a Required Additional Field',
    },
    MISSING_PHONEX_CUSTOMER_NUMBER: {
      label: 'Missing Phonex Customer #',
    },
    CUSTOMER_NOT_FOUND: {
      label: 'Missing Customer',
    },
    USER_NOT_FOUND: {
      label: 'Missing User',
    },
    INVALID_EMAIL: {
      label: 'Invalid User Email',
    },
    INVALID_ROLE: {
      label: 'Invalid User Role',
    },
    INVALID_FIELD_VALUE: {
      label: 'Invalid Field Value',
    },
    FIELD_EXCEEDS_LENGTH: {
      label: 'Field Exceeds length',
    },
  },
  userImportWarning: {
    STATUS_CHANGE: {
      label: 'Status Changed',
    },
    USER_DEACTIVATED: {
      label: 'Users Changed to Deactivated',
    },
    USER_ACTIVATED: {
      label: 'Users Changed to Active',
    },
  },
  userImportUpdate: {
    UPDATED: {
      label: 'Users with Changes',
    },
    NEW: {
      label: 'New Users Added',
    },
    CUSTOMER_WITH_USER_CHANGES: {
      label: 'Customers with User Changes',
    },
  },
  user: {
    label: 'Review User Import Changes',
    getColDef: userImportReviewCols,
    importUrlKey: 'customerUserImportAccept',
    keyGroup: {
      errors: 'userImportError',
      warnings: 'userImportWarning',
      updates: 'userImportUpdate',
    },
  },
};

interface IGetImportReviewColDef {
  itemGroupGetter: any;
  settings: any;
  screen: any;
  ImportDescription: any;
  warehouseValueGetter: any;
}
export const getImportReviewColDef = ({
  itemGroupGetter,
  settings,
  screen,
  ImportDescription,
  warehouseValueGetter,
}: IGetImportReviewColDef) => {
  return (ImportHighlighter: any) => {
    switch (screen) {
      case 'inventory':
        return [
          {
            headerName: 'Row',
            headerClass: 'px-inventory-review-header',
            children: [
              {
                valueGetter: itemGroupGetter,
                rowGroup: true,
                sortable: true,
                hide: true,
              },
              {
                headerName: '',
                field: 'rowNumber',
                maxWidth: 150,
              },
            ],
          },
          {
            headerName: 'Product Information',
            headerClass: 'px-inventory-review-header',
            children: [
              {
                headerName: 'Description',
                cellRendererFramework: ImportDescription,
                cellRendererParams: {
                  settingsData: settings,
                },
                colId: 'px-description-row',
                wrapText: true,
                minWidth: 300,
                maxWidth: 400,
              },
              {
                headerName: 'Category',
                field: 'fields.category',
              },
              {
                headerName: 'Warehouse',
                valueGetter: warehouseValueGetter,
                cellRendererFramework: ImportHighlighter,
                minWidth: 125,
                maxWidth: 200,
              },
              {
                headerName: 'SKU',
                field: 'itemNumber',
                cellRendererFramework: ImportHighlighter,
              },
              {
                headerName: 'Grade',
                field: 'fields.grade',
                cellRendererFramework: ImportHighlighter,
              },
            ],
          },
          {
            headerName: 'Quantity and Cost',
            headerClass: 'px-inventory-review-header',
            children: [
              {
                headerName: 'Cost',
                field: 'cost',
                valueParser: 'value || --',
                cellRendererFramework: ImportHighlighter,
                maxWidth: 150,
              },
              {
                headerName: 'Increment',
                field: 'incrementSize',
                valueParser: 'value || --',
                cellRendererFramework: ImportHighlighter,
              },
              {
                headerName: 'On Hand',
                field: 'quantityOnHand',
                valueParser: 'value || --',
                cellRendererFramework: ImportHighlighter,
              },
              {
                headerName: 'Allocated',
                field: 'quantityAllocated',
                valueParser: 'value || --',
                cellRendererFramework: ImportHighlighter,
              },
              {
                headerName: 'Available',
                field: 'quantityAvailable',
                valueParser: 'value || --',
                cellRendererFramework: ImportHighlighter,
              },
              {
                headerName: 'Reserved',
                field: 'quantityReserved',
                valueParser: 'value || --',
                cellRendererFramework: ImportHighlighter,
              },
            ],
          },
        ];

      default:
        break;
    }
  };
};

export const logOutTheUser = async (message: string, severity: any, messageType?: any) => {
  try {
    await Auth.signOut();
    const config = JSON.parse(localStorage.getItem('PxConfig') || '{}');
    localStorage.clear();
    window.indexedDB.deleteDatabase('d2');
    localStorage.setItem(
      'customLoginLoadMessage',
      JSON.stringify({ message, severity, messageType })
    );
    document.location.href = config?.business_tenant_site || '/';
  } catch (error: any) {
    console.error('error signing out: ', error);
  }
};

export const tableColWidth: any = {
  order: {
    buyerId: {
      minWidth: 200,
    },
    createDate: {
      minWidth: 100,
    },
    updateDate: {
      minWidth: 100,
    },
    warehouseCode: {
      minWidth: 90,
    },
    // daysOnhold: 30,
    fulfillmentStatus: 180,
    // margin: 20,
    // marginPercent: 20,
    // orderTotal: 20,
    paymentStatus: 166,
    phonexOrderNumber: {
      minWidth: 140,
    },
    refOrderNumber: {
      minWidth: 140,
    },
    // updateDate: 20,
    // warehouseCode: 20,
  },
};

export const getDateFormat = (date: string): string => {
  // find the format
  const dateFormatsSupported = [
    'Mo-dd-yyyy',
    'MM-dd-yyyy',
    'MMM-dd-yyyy',
    'MMMM-dd-yyyy',
    'MMMMM-dd-yyyy',
    'Mo/dd/yyyy',
    'MM/dd/yyyy',
    'MMM/dd/yyyy',
    'MMMM/dd/yyyy',
    'MMMMM/dd/yyyy',
    'Mo dd yyyy',
    'MM dd yyyy',
    'MMM dd yyyy',
    'MMMM dd yyyy',
    'MMMMM dd yyyy',
    'Mo.dd.yyyy',
    'MM.dd.yyyy',
    'MMM.dd.yyyy',
    'MMMM.dd.yyyy',
    'MMMMM.dd.yyyy',
    'MM/dd',
    'MM.dd',
    'MM dd',
    'MMMM/dd',
    'MMMM.dd',
    'MMMM dd',

    'yyyy-dd-Mo',
    'yyyy-dd-MM',
    'yyyy-dd-MMM',
    'yyyy-dd-MMMM',
    'yyyy-dd-MMMMM',
    'yyyy/dd/Mo',
    'yyyy/dd/MM',
    'yyyy/dd/MMM',
    'yyyy/dd/MMMM',
    'yyyy/dd/MMMMM',
    'yyyy dd Mo',
    'yyyy dd MM',
    'yyyy dd MMM',
    'yyyy dd MMMM',
    'yyyy dd MMMMM',
    'yyyy.dd.Mo',
    'yyyy.dd.MM',
    'yyyy.dd.MMM',
    'yyyy.dd.MMMM',
    'yyyy.dd.MMMMM',

    'dd-Mo-yyyy',
    'dd-MM-yyyy',
    'dd-MMM-yyyy',
    'dd-MMMM-yyyy',
    'dd-MMMMM-yyyy',
    'dd/Mo/yyyy',
    'dd/MM/yyyy',
    'dd/MMM/yyyy',
    'dd/MMMM/yyyy',
    'dd/MMMMM/yyyy',
    'dd Mo yyyy',
    'dd MM yyyy',
    'dd MMM yyyy',
    'dd MMMM yyyy',
    'dd MMMMM yyyy',
    'dd.Mo.yyyy',
    'dd.MM.yyyy',
    'dd.MMM.yyyy',
    'dd.MMMM.yyyy',
    'dd.MMMMM.yyyy',
    'dd/MMMM',
    'dd.MMMM',
    'dd MMMM',
  ];

  for (let i = 0; i < dateFormatsSupported.length; i++) {
    const parsedDate = parse(date, dateFormatsSupported[i], new Date());
    if (isValid(parsedDate)) {
      return dateFormatsSupported[i];
    }
  }
  return '';
};

export const formatISODate = (date: string) => {
  return date
    .split(' ')
    .filter((_: any, idx: number) => {
      return idx < 4;
    })
    .join(' ');
};

export const toCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/['"]/g, '')
    .replace(/\W+/g, ' ')
    .replace(/ (.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/ /g, '');
};

export const generateAddressParts = (state: string, nextState: string) => {
  return state ? state + (nextState ? ', ' : '') : '';
};

export const checkNullOrUndefined = (val: any) => {
  return val === null || val === undefined;
};

export const isEmptyOrSpaces = (str: any) => {
  return str === null || str === ' ' || str === undefined;
};

export const generateOfferClearingOffer = (status: string, offer: any, counter: any) => {
  return status === 'COUNTERED' ? counter : offer;
};

export const orderQuantityValidationRule = (val: number, upperlimit: number, incrSize: number) => {
  let error = '';
  if (val === 0) {
    error = 'Must be 0 or more';
  } else if (val > upperlimit) {
    error = `Must be ${upperlimit} or less`;
  } else if (val % incrSize !== 0) {
    error = `Must match increment size`;
  }
  return error;
};
export const orderPriceValidationRule = (val: number, lowerlimit: number, upperlimit: number) => {
  let error = '';
  if (val < lowerlimit) {
    error = `Must be ${lowerlimit} or more`;
  } else if (val > upperlimit) {
    error = `Must be ${upperlimit} or less`;
  } else if (val === 0) {
    error = 'Must be more than 0';
  }
  return error;
};

export type DataFormattingType =
  | 'id'
  | 'date'
  | 'percent'
  | 'currency'
  | 'currency-with-no-0'
  | 'integer'
  | 'days'
  | 'string'
  | 'number';

export const dataFormatting = (
  type: DataFormattingType,
  value: any,
  isZeroShownForCurrency?: boolean
) => {
  if (specialFilters[value]) value = specialFilters[value];
  switch (type) {
    case 'id':
      return value || value === 0 ? `${value}` : '--';
    case 'date':
      return dateFormatting(value);
    case 'percent':
      return percentFormatting(value);
    case 'currency':
      return currencyFormatter(value, 2, isZeroShownForCurrency);
    case 'currency-with-no-0':
      return currencyFormatter(value, 0, isZeroShownForCurrency);
    case 'integer':
      return integerFormatting(value);
    case 'number':
      return value
        ? value.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2 })
        : '--';
    case 'days':
      return daysFormatting(value);
    default:
      return value ? specialSymbols[value] || value : '--';
  }
};

const dateFormatting = (value: any) => {
  return value ? formatISODate(parseISO(value).toString()).slice(3) : '--';
};

const percentFormatting = (value: any) => {
  if (value === Infinity) return specialSymbols[value];
  return +value ? `${(+value).toFixed(1)}%` : value && value !== 'NaN' ? value : '--';
};

const integerFormatting = (value: any) => {
  return `${
    value ? value.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 0 }) : '--'
  }`;
};

const daysFormatting = (value: any) => {
  if (checkNullOrUndefined(value)) return '--';
  if (typeof value === 'number') {
    // const daysHrsArr = `${value}`.split('.');
    const days = Math.trunc(value);
    const hoursMinFraction = (value - days) * 24;
    const hrs = Math.trunc(hoursMinFraction);
    // const min = Math.trunc((hoursMinFraction - hrs) * 60);
    if (!days && !hrs && !value) {
      return 'Just now';
    }
    return `${days}d ${hrs}h`;
  }

  const diffDate = intervalToDuration({
    start: new Date(value),
    end: new Date(),
  });

  if (
    !diffDate ||
    (!diffDate?.years &&
      !diffDate?.months &&
      !diffDate?.days &&
      !diffDate?.hours &&
      !diffDate?.minutes)
  ) {
    return 'Just now';
  }

  return `${diffDate.days ? `${diffDate.days}d` : ''}${
    diffDate.hours ? ` ${diffDate.hours}h ` : ''
  }${diffDate.minutes ? ` ${diffDate.minutes}m ` : ''}`;
};

const currencyFormatter = (value: any, minimumFractionDigits: number, isZeroShown?: boolean) => {
  if ((!isZeroShown || (isZeroShown && value !== 0)) && !value) return '--';
  if (value === Infinity) return specialSymbols[value];
  const localeCurrency = localStorage.getItem('PxCurrencySymbol');
  let currencySymbol = '$';
  if (localeCurrency) {
    currencySymbol = localeCurrency;
  }

  if (typeof value === 'string') {
    return `${currencySymbol}${value}`;
  }
  const val = +(+value).toFixed(2);
  return val >= 0
    ? `${currencySymbol}${val.toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits,
      })}`
    : `-${currencySymbol}${Math.abs(val).toLocaleString('en', {
        useGrouping: true,
        minimumFractionDigits,
      })}`;
};

export const stocklistRowGroupDescriptionGetter = (pricingSettings: any) => {
  return ({ data }: any) => {
    // if (!data.offers) return 'Totals and Averages';
    const category = data.category;
    if (!Array.isArray(pricingSettings.groupDescription[category])) return;
    const label = pricingSettings.groupDescription[category]
      .map((val: any) => {
        const labels = pricingSettings[`${val}Labels`]
          ? pricingSettings[`${val}Labels`][data[val]]
          : data[val];
        return labels;
      })
      .join(pricingSettings.groupDescriptionDelimiter);

    return label;
  };
};

interface IStocklistRowSubgroupDescriptionGetter {
  settings: any;
  item: any;
}

export const stocklistRowDescriptionGetter = ({
  settings,
  item,
}: IStocklistRowSubgroupDescriptionGetter): { sku: string; description: string } => {
  const res = {
    description:
      settings?.groupItemDescription[item?.category]
        .map((val: any) => {
          return item[val];
        })
        .join(settings?.groupItemDescriptionDelimiter) || '',
    sku: `${settings?.itemDescriptionRules?.itemNumberDescriptionPrefix || ''}${item.itemNumber}${
      settings?.itemDescriptionRules?.itemNumberDescriptionSuffix || ''
    }`,
  };
  return res;
};

export const specialSymbols: any = {
  [Infinity]: '∞',
};

export const formatBuyerName = (buyerName: string) => {
  return buyerName.substr(0, 32) + (buyerName.length > 32 ? '...' : '');
};

// >> Data filtering >>

const specialFilters: any = {
  orders: {
    multipleSearch: true,
    multipleSearchCol: 'phonexOrderNumber',
    multipleSearchPrefix: 'Order',
    multipleSearchRegex: '({prefix}[0-9]+[\\s]*)+', // PXSTKL0000232 PXSTKL0000231 PXSTKL0000230
    phonexOrderNumber: 'string',
    createDate: 'date',
    paymentStatus: 'label',
    fulfillmentStatus: 'label',
    refOrderNumber: 'string',
    updateDate: 'date',
    buyerId: 'label',
    orderTotal: 'currency',
  },
  ordersAdd: {
    multipleSearch: true,
    multipleSearchCol: 'itemNumber',
    multipleSearchPrefix: 'SKU',
    multipleSearchRegex: /^[0-9|\s]+$/, // 1011767 1011849
    description: 'string',
    category: 'string',
    listPrice: 'currency',
    lowPrice: 'currency',
  },
  offerClearing: {
    multipleSearch: true,
    multipleSearchCol: 'itemNumber',
    multipleSearchPrefix: 'SKU',
    multipleSearchRegex: /^[0-9|\s]+$/,
    stocklistDescription: 'generated',
    stocklistRowDescription: 'generated',
  },
  pricing: {
    multipleSearch: true,
    multipleSearchCol: 'itemNumber',
    multipleSearchPrefix: 'SKU',
    multipleSearchRegex: /^[0-9|\s]+$/,
    stocklistDescription: 'generated',
    stocklistRowDescription: 'generated',
  },
  channel: {
    multipleSearch: true,
    multipleSearchCol: 'itemNumber',
    multipleSearchPrefix: 'SKU',
    multipleSearchRegex: /^[0-9|\s]+$/,
    description: 'string',
    itemNumber: 'string',
    itemNumberWarehouse: 'string',
    lastUpdatedDate: 'date',
    channel: 'string',
    warehouse: 'label',
    category: 'string',
    defaultChannel: 'generated',
  },
  inventory: {
    multipleSearch: true,
    multipleSearchCol: 'itemNumber',
    multipleSearchPrefix: 'SKU',
    multipleSearchRegex: /^[0-9|\s]+$/,
    description: 'string',
    itemNumber: 'string',
    itemNumberWarehouse: 'string',
    lastUpdatedDate: 'date',
    channel: 'label',
    warehouse: 'string',
    category: 'string',
  },
  preorder: {
    multipleSearch: true,
    multipleSearchCol: 'itemNumber',
    multipleSearchPrefix: 'SKU',
    multipleSearchRegex: /^[0-9|\s]+$/,
    phonexItemDescription: 'string',
    itemNumber: 'string',
    itemNumberWarehouse: 'string',
    lastUpdatedDate: 'date',
    channel: 'label',
    warehouse: 'string',
    category: 'string',
  },
  preorderBuyer: {
    multipleSearch: true,
    multipleSearchCol: 'itemNumber',
    multipleSearchPrefix: 'SKU',
    multipleSearchRegex: /^[0-9|\s]+$/,
    phonexItemDescription: 'string',
    warehouse: 'string',
    itemNumber: 'string',
  },
  funding: {
    availableFund: 'number',
    awardedInPeriod: 'number',
    endingBalance: 'number',
    fulfilledForAwardedInPeriod: 'number',
    fulfilledPercentage: 'number',
    newFund: 'number',
    openOrderAmount: 'number',
    shippedOrderAmount: 'number',
    startingBalance: 'number',
    buyerName: 'string',
    buyerNumber: 'string',
    updateDate: 'date',
  },
  itemScheduling: {
    multipleSearch: true,
    multipleSearchCol: 'itemNumber',
    multipleSearchPrefix: 'SKU',
    multipleSearchRegex: /^[0-9|\s]+$/,
    itemDescription: 'string',
    groupName: 'string',
    itemNumber: /[\s,]/,
    itemNumberWarehouse: 'string',
    lastUpdatedDate: 'date',
    warehouse: 'string',
    category: 'string',
  },
  poClearing: {
    multipleSearch: true,
    multipleSearchCol: 'itemNumber',
    multipleSearchPrefix: 'SKU',
    multipleSearchRegex: /^[0-9|\s]+$/,
    itemDescription: 'string',
    displayGroupName: 'string',
    itemNumber: /[\s,]/,
    itemNumberWarehouse: 'string',
    lastUpdatedDate: 'date',
    warehouse: 'string',
    color: 'string',
    // category: 'string',
  },
};

export const searchOptionPlaceHolders: any = {
  itemNumber: 'Search Item # (paste cells from spreadsheet)',
  others: 'Search Item Description',
};

const searchOptionLabels: any = {
  phonexOrderNumber: 'Order Number',
  id: 'PhoneX Customer #',
  buyerName: 'Customer Name',
  country: 'Country',
  createDate: 'Created Date',
  email: 'Email',
  phone: 'Phone',
  salesRepEmail: 'Sales Rep Email',
  salesRepName: 'Sales Rep Name',
  state: 'State',
  updateDate: 'Update Date',
  users: 'Users',
  whatsApp: 'Whatsapp',
  itemNumber: 'Item #',
};

export const getSearchOptions = (view: IScreenNames) => {
  if (!specialFilters[view]) return [];
  return [
    { option: 'others', label: 'Others' },
    ...Object.keys(specialFilters[view])
      .filter((val) => !!searchOptionLabels[val])
      .map((val) => {
        return { label: searchOptionLabels[val], option: val };
      }),
  ];
};

export const generateLabelKey = (fieldName: string) => {
  return `${fieldName}Labels`;
};

interface IConstructLabel {
  fieldName: any;
  settings: any;
  id?: any;
}

const constructLabel = ({ fieldName, settings, id = '' }: IConstructLabel) => {
  const label = generateLabelKey(fieldName);
  const labelValid = settings[label] && settings[label][id];
  let returnLabel = labelValid ? settings[label][id] : fieldName;
  if (fieldName === 'buyerId') {
    returnLabel = returnLabel.buyerName;
  }
  return returnLabel;
};

interface IGenerateConstructedLabel {
  specialFilterName: any;
  settings: any;
  item: any;
}
const generateConstructedLabel = ({
  specialFilterName,
  item,
  settings,
}: IGenerateConstructedLabel) => {
  switch (specialFilterName) {
    case 'stocklistDescription':
      return stocklistRowGroupDescriptionGetter(settings)({
        data: item,
      });
    case 'stocklistRowDescription':
      return Object.values(
        stocklistRowDescriptionGetter({
          settings,
          item,
        })
      ).join(' ');
    case 'defaultChannel':
      return defaultChannelValueDisplayMap[item.channel];
    default:
      return '';
  }
};
const removeTwoSimultaneousSpaces = (data: string): string => {
  let newStr = '';
  for (let index = 0; index < data.length; index++) {
    if (data[index] === ' ' && data[index + 1] === ' ') {
      index++;
    }
    newStr += data[index];
  }

  return newStr;
};
interface ISearchEngine {
  searchTerm: string;
  pieces?: Array<string>;
  item?: any;
  view: string;
  settings: any;
}

// search engine
const searchEngine = ({ searchTerm, pieces, item, view, settings }: ISearchEngine): boolean => {
  let res: boolean = false;
  if (Array.isArray(pieces) && pieces.length) {
    // search SKUs
    return pieces.includes(item[specialFilters[view].multipleSearchCol]);
  } else {
    // search Term
    if (specialFilters[view]) {
      Object.keys(specialFilters[view]).forEach((val) => {
        if (
          res ||
          (!item[val] && specialFilters[view][val] !== 'generated') ||
          val.includes('multiple')
        ) {
          return;
        }
        try {
          // only support single nested items => must be an array
          if (val.includes('.')) {
          }
          let searchStr = '';
          // look for types
          switch (specialFilters[view][val]) {
            case 'string':
              searchStr =
                val === 'description' ? removeTwoSimultaneousSpaces(item[val]) : item[val];
              break;
            case 'date':
              searchStr = dataFormatting('date', item[val]);
              res = searchStr.includes(dataFormatting('date', new Date(searchTerm).toISOString()));
              return;
            case 'label':
              searchStr = constructLabel({
                fieldName: val,
                id: item[val],
                settings: settings,
              });
              break;
            case 'currency':
              searchStr = dataFormatting('currency', item[val]);
              break;
            case 'generated':
              searchStr = generateConstructedLabel({ specialFilterName: val, item, settings });
              break;
            case 'number':
              break;

            default:
              break;
          }

          res = searchStr.toLowerCase().trim().includes(searchTerm);
        } catch (error: any) {
          // console.log('unable to search through: ', val, 'error:', error.message);
        }
      });
    }
  }
  return res;
};

interface ISearchItems {
  searchTermState: string;
  view: string;
  itemsList: Array<any>;
  settings: any;
}

// only search feature
export const searchItems = ({ searchTermState, view, itemsList, settings }: ISearchItems) => {
  let pieces: any = null;
  let searchTerm: string = searchTermState || '';
  // checking if the search term starts with SKU:

  if (
    searchTerm?.length &&
    specialFilters[view] &&
    specialFilters[view]?.multipleSearch &&
    searchTerm.match(new RegExp(`^(${specialFilters[view].multipleSearchPrefix})[:]*`, 'i'))
  ) {
    searchTerm = searchTerm.split(':')[1].trim();
  }

  if (searchTerm?.length && specialFilters[view]?.multipleSearch) {
    if (
      view === 'orders' &&
      !!searchTerm.match(
        new RegExp(
          formatString(specialFilters[view].multipleSearchRegex, {
            prefix: 'PXSTKL',
          }),
          'i'
        )
      )
    ) {
      pieces = searchTerm
        .trim()
        .split(' ')
        .map((val) => {
          return val.trim();
        });
    } else if (!!searchTerm.match(new RegExp(specialFilters[view]?.multipleSearchRegex))) {
      // dividing search term
      // TODO: - Make / dynamic to come from config
      pieces = removeTwoSimultaneousSpaces(searchTerm.trim())
        .split(' ')
        .map((val) => {
          return val.trim();
        });
    }
  }

  // set of all array keys
  // const offersKeySet = new Set<string>();
  const searchedItems: any[] = [];
  // all the items
  itemsList.forEach((item: any) => {
    // is a match for the search term
    let matchSearchTerm = false;
    if (!searchTerm || !searchTerm.length) {
      matchSearchTerm = true;
    }
    if (searchTerm) {
      matchSearchTerm = searchEngine({
        searchTerm: searchTerm.trim().toLowerCase(),
        view,
        pieces,
        item,
        settings,
      });
    }

    if (matchSearchTerm) {
      searchedItems.push(item);
    }
  });

  // if (Array.isArray(pieces) && pieces.length > 0) {
  //   return { itemProperties: [...pieces] };
  // }

  return searchedItems;
};

const getUpdatedFilterCount = (currentCount: number | undefined, incrementBy1: boolean) => {
  return (currentCount || 0) + (incrementBy1 ? 1 : 0);
  // switch (type) {
  //   case 'filtered':
  //     return count + 1;
  //   case 'absolute':
  //   case 'search':
  // }
};

/**
 * Function to filter items on a flat JSON Array<items>
 * @param itemsList Array<any> : Redux stockState
 * @param searchTerm string | null : String from search field
 * @param chipsInUse Object<chipsInUse> : { category: ['Hearables', 'Tablet', 'Phones', 'Wearables', 'Accessories'], outOfStock: false }
 * @param outOfStockField string : Name of field on DB, like stocklist_quantity_available
 * @param settings Object : Contain the settings definition used on the chips
 * @param specialFiltersState Object : Contain the name and functions of the special filters
 * @returns Promise(Object<items, menu>)
 */

export const advanceFilterEngine: IAdvanceFilterEngine = ({
  advanceFilters = {},
  searchTermState,
  filtersApplied,
  outOfStockField,
  itemsList,
  settings,
  view,
}) => {
  return new Promise((res) => {
    if (!Array.isArray(itemsList)) itemsList = [];
    // getting chips in use [category, outOfStocks, new]
    const filterAttr: Array<keyof IFiltersApplied> = Object.keys(filtersApplied);
    // results
    const results: IAdvanceFilterEngineRes = {
      items: [],
      filterCounts: {
        absolute: {
          buyerFilter: {},
          itemFilter: {},
        },
        filtered: {
          buyerFilter: {},
          itemFilter: {},
        },
        searchedAbsolute: {
          buyerFilter: {},
          itemFilter: {},
        },
        includeOutOfStock: filtersApplied.outOfStock,
        itemSearched: 0,
      },
      itemProperties: {}, // List of properties from DB table
      tabStats: {}, // hold the tab stats of the
    };

    let pieces: any = null;
    let searchTerm: string = searchTermState || '';
    // checking if the search term starts with SKU:

    if (
      searchTerm?.length &&
      specialFilters[view] &&
      specialFilters[view]?.multipleSearch &&
      searchTerm.match(new RegExp(`^(${specialFilters[view].multipleSearchPrefix})[:]*`, 'i'))
    ) {
      searchTerm = searchTerm.split(':')[1].trim();
    }

    if (searchTerm?.length && specialFilters[view]?.multipleSearch) {
      if (
        view === 'orders' &&
        !!searchTerm.match(
          new RegExp(
            formatString(specialFilters[view].multipleSearchRegex, {
              prefix: 'PXSTKL',
            }),
            'i'
          )
        )
      ) {
        pieces = searchTerm
          .trim()
          .split(' ')
          .map((val) => {
            return val.trim();
          });
      } else if (!!searchTerm.match(new RegExp(specialFilters[view]?.multipleSearchRegex))) {
        // dividing search term
        // TODO: - Make / dynamic to come from config
        pieces = removeTwoSimultaneousSpaces(searchTerm.trim())
          .split(' ')
          .map((val) => {
            return val.trim();
          });
      }
    }

    // set of all array keys
    const offersKeySet = new Set<string>();
    // all the items
    itemsList.forEach((item: any) => {
      if (Object.keys(results.filterCounts.filtered).length <= 2) {
        results.itemProperties = Object.keys(item);
        results.itemProperties.forEach((key: string) => {
          results.filterCounts.filtered[key] = {};
          results.filterCounts.absolute[key] = {};
          results.filterCounts.searchedAbsolute[key] = {};
        });
      }
      // is a match for the search term
      let matchSearchTerm = false;
      if (!searchTerm || !searchTerm.length) {
        matchSearchTerm = true;
      }
      if (searchTerm) {
        matchSearchTerm = searchEngine({
          searchTerm: searchTerm.trim().toLowerCase(),
          view,
          pieces,
          item,
          settings,
        });
      }

      // item match to chip filters currently selected
      let itemMatchChips = false;
      // only validate if the current item satisfy search criteria
      if (matchSearchTerm) {
        itemMatchChips = true;
        const count = filterAttr.length;
        for (let i = 0; i < count; i++) {
          const chipName: keyof IFiltersApplied = filterAttr[i];
          // if chipName is out of stock, can be changed dynamically
          if (chipName === 'outOfStock') {
            if (filtersApplied['outOfStock'] && item[outOfStockField] > 0) {
              itemMatchChips = true;
            } else if (!filtersApplied['outOfStock'] && item[outOfStockField] <= 0) {
              itemMatchChips = false;
            }
          } else {
            // priortize the advance or custom filters
            if (advanceFilters.hasOwnProperty(chipName)) {
              itemMatchChips = advanceFilters[chipName](item, filtersApplied);
            } else {
              // check for the predefined chip as buyer filter
              // pre defined filters
              if (
                typeof filtersApplied[chipName] !== 'boolean' &&
                (!filtersApplied[chipName] || !filtersApplied[chipName][item[chipName]])
              ) {
                itemMatchChips = false;
              }
            }
          }
          if (!itemMatchChips) {
            break;
          }
        }
      }
      // If item survives filtering, add it to final list
      if (matchSearchTerm && itemMatchChips) {
        // check the tab category of the item
        if (view) {
          const tabCatVal = getTabValue(view, item);
          tabCatVal.forEach((tabCat) => {
            results.tabStats[tabCat] = results.tabStats[tabCat] ? results.tabStats[tabCat] + 1 : 1;
          });
        }
        results.items.push({ ...item });
      }
      // Get counts for Menu
      results.itemProperties.forEach((key: string) => {
        let fieldName = item[key];
        // setting offers filter
        if (key === 'offers') {
          // document unique buyer ids
          // menu contains all the unique values, menu filter contains counts of the
          item[key].forEach((obj: any) => {
            const buyerId = obj.buyerId;
            results.filterCounts.absolute.buyerFilter[buyerId] = getUpdatedFilterCount(
              results.filterCounts.absolute.buyerFilter[buyerId],
              true
            );
            results.filterCounts.filtered.buyerFilter[buyerId] = getUpdatedFilterCount(
              results.filterCounts.filtered.buyerFilter[buyerId],
              matchSearchTerm && itemMatchChips
            );
            results.filterCounts.searchedAbsolute.buyerFilter[buyerId] = getUpdatedFilterCount(
              results.filterCounts.searchedAbsolute.buyerFilter[buyerId],
              matchSearchTerm
            );
          });
        } else {
          if (checkNullOrUndefined(item[key]) || item[key] === '') return;
          results.filterCounts.absolute[key][fieldName] = getUpdatedFilterCount(
            results.filterCounts.absolute[key][fieldName],
            true
          );
          results.filterCounts.filtered[key][fieldName] = getUpdatedFilterCount(
            results.filterCounts.filtered[key][fieldName],
            matchSearchTerm && itemMatchChips
          );
          results.filterCounts.searchedAbsolute[key][fieldName] = getUpdatedFilterCount(
            results.filterCounts.searchedAbsolute[key][fieldName],
            matchSearchTerm
          );
        }
        if (view === 'preorder') {
          if (key === 'buyerNumber') {
            results.filterCounts.absolute.buyerFilter[fieldName] = getUpdatedFilterCount(
              results.filterCounts.absolute.buyerFilter[fieldName],
              true
            );
            results.filterCounts.filtered.buyerFilter[fieldName] = getUpdatedFilterCount(
              results.filterCounts.filtered.buyerFilter[fieldName],
              matchSearchTerm && itemMatchChips
            );
            results.filterCounts.searchedAbsolute.buyerFilter[fieldName] = getUpdatedFilterCount(
              results.filterCounts.searchedAbsolute.buyerFilter[fieldName],
              matchSearchTerm
            );
          } else if (key === 'itemNumberWarehouse') {
            results.filterCounts.absolute.itemFilter[fieldName] = getUpdatedFilterCount(
              results.filterCounts.absolute.itemFilter[fieldName],
              true
            );
            results.filterCounts.filtered.itemFilter[fieldName] = getUpdatedFilterCount(
              results.filterCounts.filtered.itemFilter[fieldName],
              matchSearchTerm && itemMatchChips
            );
            results.filterCounts.searchedAbsolute.itemFilter[fieldName] = getUpdatedFilterCount(
              results.filterCounts.searchedAbsolute.itemFilter[fieldName],
              matchSearchTerm
            );
          }
        }
      });
    });
    // push array key set into item properties
    if (offersKeySet.size > 0) {
      results.itemProperties.push(...Array.from(offersKeySet));
    }

    if (results?.filterCounts.filtered) {
      // Clear zero results
      Object.keys(results.filterCounts.filtered).forEach((k: string) => {
        const tmp: any = {};
        for (let prop in results.filterCounts.filtered[k]) {
          if (results.filterCounts.filtered[k][prop] > 0) {
            tmp[prop] = results.filterCounts.filtered[k][prop];
          }
        }
        results.filterCounts.filtered[k] = { ...tmp };
      });
    }

    if (pieces?.length) {
      results.searchTerm = `${specialFilters[view].multipleSearchPrefix}: ${searchTerm}`;
    }
    res(results);
  });
};

interface ISearchItemsV1 {
  searchState?: {
    searchText: string | null;
    searchOption: string;
  };
  view: string;
  itemsList: Array<any>;
  settings: any;
}

const getSearchPieces = ({ searchState, view }: any) => {
  let pieces: any = null;
  let searchTerm: string = searchState?.searchText || '';
  const searchOption: string = searchState?.searchOption || '';
  // checking if the search option have , or space
  // assuming everything is multi search
  if (
    searchOption &&
    searchTerm?.length &&
    specialFilters[view] &&
    specialFilters[view][searchOption] &&
    typeof specialFilters[view][searchOption] !== 'string'
  ) {
    // dividing search term
    pieces = removeTwoSimultaneousSpaces(searchTerm.trim())
      .split(new RegExp(specialFilters[view][searchOption] || /[\s,]/gm))
      .map((val) => {
        return val.trim();
      })
      .filter((val) => !!val);
  }

  return pieces;
};

// only search feature
export const searchItemsV1 = ({ searchState, view, itemsList, settings }: ISearchItemsV1) => {
  const pieces = getSearchPieces({ searchState, view });
  // set of all array keys
  const searchedItems: any[] = [];
  // all the items
  itemsList.forEach((item: any) => {
    // is a match for the search term
    let matchSearchTerm = false;
    if (!searchState?.searchText?.length) matchSearchTerm = true;
    if (searchState?.searchText)
      matchSearchTerm = searchEngineV1({
        searchTerm: searchState.searchText.trim().toLowerCase(),
        searchOption: searchState.searchOption,
        view,
        pieces,
        item,
        settings,
      });

    if (matchSearchTerm) searchedItems.push(item);
  });

  return searchedItems;
};

interface ISearchEngineV1 {
  searchOption: string;
  searchTerm: string;
  pieces?: Array<string>;
  item?: any;
  view: string;
  settings: any;
}

// search engine
const searchEngineV1 = ({
  searchTerm,
  searchOption,
  pieces,
  item,
  view,
  settings,
}: ISearchEngineV1): boolean => {
  let res: boolean = false;
  if (Array.isArray(pieces) && pieces.length) {
    // search SKUs
    // return !!pieces.find((val) => `${item[searchOption]}`.toLowerCase().includes(val));
    return !!pieces.includes(`${item[searchOption]}`);
  } else {
    // general search
    Object.keys(specialFilters[view]).forEach((val) => {
      if (
        res ||
        (!item[val] && specialFilters[view][val] !== 'generated') ||
        val.includes('multiple')
      ) {
        return;
      }
      try {
        // only support single nested items => must be an array
        if (val.includes('.')) {
        }
        let searchStr = '';
        // look for types
        switch (specialFilters[view][val]) {
          case 'string':
            searchStr =
              val === 'description' ? removeTwoSimultaneousSpaces(item[val]) : `${item[val]}`;
            break;
          case 'date':
            searchStr = dataFormatting('date', item[val]);
            res = searchStr.includes(dataFormatting('date', new Date(searchTerm).toISOString()));
            return;
          case 'label':
            searchStr = constructLabel({
              fieldName: val,
              id: item[val],
              settings: settings,
            });
            break;
          case 'currency':
            searchStr = dataFormatting('currency', item[val]);
            break;
          case 'generated':
            searchStr = generateConstructedLabel({ specialFilterName: val, item, settings });
            break;
          case 'number':
            break;
          case 'array':
            searchStr = item[val].map((itemVal: string) => {
              return itemVal.toLowerCase();
            });
            break;
          case 'exactString':
            searchStr = item[val];
            break;
          default:
            break;
        }

        switch (specialFilters[view][val]) {
          case 'array':
            res = searchStr.includes(searchTerm);
            break;
          case 'exactString':
            res = searchStr === searchTerm;
            break;
          default:
            res = searchStr.toLowerCase().trim().includes(searchTerm);
            break;
        }

        // if (res) {
        //   console.log(searchStr, searchTerm, 'search', val);
        // }
      } catch (error: any) {
        // console.log('unable to search through: ', val, 'error:', error.message);
      }
    });
  }
  return res;
};

export const advanceFilterEngineV1: IAdvanceFilterEngineV1 = ({
  advanceFilters = {},
  searchState,
  filtersApplied,
  outOfStockField,
  itemsList,
  settings,
  view,
}) => {
  return new Promise((res) => {
    if (!Array.isArray(itemsList)) itemsList = [];
    // getting chips in use [category, outOfStocks, new]
    const filterAttr: Array<keyof IFiltersApplied> = Object.keys(filtersApplied);
    // results
    const results: IAdvanceFilterEngineRes = {
      items: [],
      filterCounts: {
        absolute: {
          buyerFilter: {},
        },
        filtered: {
          buyerFilter: {},
        },
        searchedAbsolute: {
          buyerFilter: {},
        },
        includeOutOfStock: filtersApplied.outOfStock,
        itemSearched: 0,
      },
      itemProperties: [], // List of properties from DB table
      tabStats: {}, // hold the tab stats of the
    };

    const pieces = getSearchPieces({ searchState, view });
    let searchTerm: string = searchState?.searchText || '';

    // set of all array keys
    const offersKeySet = new Set<string>();
    // all the items
    itemsList.forEach((item: any) => {
      if (results.itemProperties.length <= Object.keys(item).length) {
        const itemProps = new Set([...Object.keys(item), ...results.itemProperties]);
        results.itemProperties = Array.from(itemProps);
      }
      // is a match for the search term
      let matchSearchTerm = !searchTerm || !searchTerm.length ? true : false;

      if (searchState && searchState.searchText) {
        matchSearchTerm = searchEngineV1({
          searchTerm: searchTerm.trim().toLowerCase(),
          searchOption: searchState.searchOption,
          settings,
          pieces,
          view,
          item,
        });
      }
      // item match to chip filters currently selected
      let itemMatchChips = false;
      // only validate if the current item satisfy search criteria
      if (matchSearchTerm) {
        itemMatchChips = true;
        const count = filterAttr.length;
        for (let i = 0; i < count; i++) {
          const chipName: keyof IFiltersApplied = filterAttr[i];
          // if chipName is out of stock, can be changed dynamically
          if (chipName === 'outOfStock') {
            if (filtersApplied['outOfStock'] && item[outOfStockField] > 0) {
              itemMatchChips = true;
            } else if (!filtersApplied['outOfStock'] && item[outOfStockField] <= 0) {
              itemMatchChips = false;
            }
          } else {
            // priortize the advance or custom filters
            if (advanceFilters.hasOwnProperty(chipName)) {
              itemMatchChips = advanceFilters[chipName](item, filtersApplied);
            } else {
              // check for the predefined chip as buyer filter
              // TODO: pre defined filters
              const fieldData = item[chipName] || '(Blanks)';
              if (
                typeof filtersApplied[chipName] !== 'boolean' &&
                (!filtersApplied[chipName] || !filtersApplied[chipName][fieldData])
              ) {
                itemMatchChips = false;
              }
            }
          }
          if (!itemMatchChips) {
            break;
          }
        }
      }

      // If item survives filtering, add it to final list
      if (matchSearchTerm && itemMatchChips) {
        // check the tab category of the item
        if (view) {
          const tabCatVal = getTabValue(view, item);
          tabCatVal.forEach((tabCat) => {
            results.tabStats[tabCat] = results.tabStats[tabCat] ? results.tabStats[tabCat] + 1 : 1;
          });
        }
        results.items.push({ ...item });
      }

      // Get counts for Menu
      results.itemProperties.forEach((key: string) => {
        let fieldName = item[key] || '(Blanks)';
        // setting offers filter
        if (key === 'offers') {
          // document unique buyer ids
          // menu contains all the unique values, ßmenu filter contains counts of the
          item[key].forEach((obj: any) => {
            const buyerId = obj.buyerId;
            results.filterCounts.absolute.buyerFilter[buyerId] = getUpdatedFilterCount(
              results.filterCounts.absolute.buyerFilter[buyerId],
              true
            );
            results.filterCounts.filtered.buyerFilter[buyerId] = getUpdatedFilterCount(
              results.filterCounts.filtered.buyerFilter[buyerId],
              matchSearchTerm && itemMatchChips
            );
            results.filterCounts.searchedAbsolute.buyerFilter[buyerId] = getUpdatedFilterCount(
              results.filterCounts.searchedAbsolute.buyerFilter[buyerId],
              matchSearchTerm
            );
          });
        } else {
          // if (checkNullOrUndefined(item[key]) || item[key] === '') return;
          if (!results.filterCounts.filtered[key]) {
            results.filterCounts.absolute[key] = {};
            results.filterCounts.filtered[key] = {};
            results.filterCounts.searchedAbsolute[key] = {};
          }
          results.filterCounts.absolute[key][fieldName] = getUpdatedFilterCount(
            results.filterCounts.absolute[key][fieldName],
            true
          );
          results.filterCounts.filtered[key][fieldName] = getUpdatedFilterCount(
            results.filterCounts.filtered[key][fieldName],
            matchSearchTerm && itemMatchChips
          );
          results.filterCounts.searchedAbsolute[key][fieldName] = getUpdatedFilterCount(
            results.filterCounts.searchedAbsolute[key][fieldName],
            matchSearchTerm
          );
        }
      });
    });
    // push array key set into item properties
    if (offersKeySet.size > 0) {
      results.itemProperties.push(...Array.from(offersKeySet));
    }

    if (results?.filterCounts.filtered) {
      // Clear zero results
      Object.keys(results.filterCounts.filtered).forEach((k: string) => {
        const tmp: any = {};
        for (let prop in results.filterCounts.filtered[k]) {
          if (results.filterCounts.filtered[k][prop] > 0) {
            tmp[prop] = results.filterCounts.filtered[k][prop];
          }
        }
        results.filterCounts.filtered[k] = { ...tmp };
      });
    }

    if (pieces?.length) {
      results.searchTerm = `${specialFilters[view].multipleSearchPrefix}: ${searchTerm}`;
    }
    res(results);
  });
};

// advance filter implementation
export const itemsAvailabilityFilter: IAdvanceFilterFn = (item, filtersApplied) => {
  if (checkNullOrUndefined(filtersApplied?.availability?.qty) || !filtersApplied.availability.type)
    return true;
  switch (filtersApplied.availability.type) {
    case 'above':
      return filtersApplied.availability.qty < item.currentAvailableQuantity;
    case 'below':
      return filtersApplied.availability.qty > item.currentAvailableQuantity;
    case 'equal':
      return filtersApplied.availability.qty === item.currentAvailableQuantity;
  }
  return false;
};

export const addToStockFilter: IAdvanceFilterFn = (item, filtersApplied) => {
  if (!filtersApplied?.addToStock?.qty || !filtersApplied.addToStock.type) return true;
  const addToStockFilter: IAddedToStockFilterStategy = filtersApplied.addToStock;
  const percent = +addToStockFilter.qty / 100;
  let addedPercent = 0;
  if (
    item.periodBeginningOnHand +
      item.addedInPeriod +
      item.soldInPeriod +
      item.periodEndingOnHand ===
    0
  )
    return false;

  if (item.periodBeginningOnHand + item.addedInPeriod !== 0) {
    addedPercent = item.addedInPeriod / (item.periodBeginningOnHand + item.addedInPeriod);
  }
  switch (addToStockFilter.type) {
    case 'atLeast':
      return percent <= addedPercent;
    case 'noMoreThan':
      return percent >= addedPercent;
  }

  return false;
};

export const daysToSellFilter = ({ dayToSellRange, item }: any) => {
  if (
    !dayToSellRange ||
    (checkNullOrUndefined(dayToSellRange.from) && checkNullOrUndefined(dayToSellRange.to)) ||
    !item
  )
    return true;
  const estDaysToSell = +item.estDaysToSell || 0;
  if (dayToSellRange.from <= estDaysToSell && dayToSellRange.to >= estDaysToSell) return true;

  return false;
};

// advance filter data => all the static chip filter data labels
export const advanceChipFilterDataState = (
  settings: any,
  buyerFilterLabels?: any,
  itemFilterLabels?: any
) => {
  const res: any = {
    attributeLabels: {
      availability: 'Availability',
      daysToSell: 'Days To Sell',
      addToStock: 'Added Stock',
      ...settings.attributeLabels,
    },
    topSearchLevelForAllTenants: [...settings.topSearchLevelForAllTenants],
    [generateLabelKey('availability')]: {
      chipLabel: `Availability {type} {qty}`,
    },
    [generateLabelKey('addToStock')]: {
      // more options range
      chipLabel: `Added Stock {symbol} {qty}%`,
    },
    [generateLabelKey('daysToSell')]: {
      // more options range
      chipLabel: `Days to Sell {from} - {to}`,
    },
  };

  if (buyerFilterLabels) {
    res.attributeLabels.offerRange = 'Offer $ Range';
    res.attributeLabels.topOffers = 'Top Offers';
    res.attributeLabels.buyerFilter = 'Customers';
    res.attributeLabels.itemFilter = 'Items';
    res.topSearchLevelForAllTenants.push('buyerFilter');
    // res.topSearchLevelForAllTenants.push('buyerFilter');
    res.buyerFilterLabels = buyerFilterLabels;
    res.itemFilterLabels = itemFilterLabels;
    res.offerRangeLabels = {
      // more options range
      chipLabel: `{start}%—{end}% {priceType} ${dataFormatting('currency', ' {priceSelected}')}`,
    };

    res.topOffersLabels = {
      // more options range
      chipLabel: `Top Offers {qty}`,
    };
  }
  return res;
};

// offer range is

// detail grid height
export const detailGridRowHeight = 50;

export const getOfferClearingRowHeight = (params: any) => {
  if (params.node.isRowPinned()) return 32;

  if (!params.data) return (params.node.key.length * 14 * 17) / 300;

  const isDetailRow = params.node.detail;
  if (
    !isDetailRow &&
    !!params.data.agGridSubGroupVal?.description?.length &&
    !!params.data.agGridSubGroupVal?.sku?.length
  ) {
    const description = Math.ceil(params.data.agGridSubGroupVal.description.length * 12);
    const skuLen = Math.ceil(params.data.agGridSubGroupVal.sku.length * 12);
    let noOfLines = Math.ceil(skuLen / 120) + Math.ceil(description / 120);
    const height = noOfLines * 24 + (noOfLines === 1 ? 8 : 0);
    return height;
  }
  // for all rows that are not detail rows, return nothing
  if (!isDetailRow) {
    return params.node.rowHeight + 20;
  }

  // otherwise return height based on number of rows in detail grid
  const detailPanelHeight = ((params.data?.offers?.length || 0) + 0.4) * detailGridRowHeight || 0;
  return detailPanelHeight + 10;
};

export const getPricingRowHeight = (params: any) => {
  if (params.node.isRowPinned()) return 32;

  if (params.node.group) return (params.node.key.length * 14 * 18) / 413 + 8;
  return 60;
};

// sanitize
// eslint-disable-next-line no-useless-escape
const illegalRe = /[\/\?<>\\:\*\|":®©℗]/g;
// eslint-disable-next-line no-control-regex
const controlRe = /[\x00-\x1f\x80-\x9f]/g;

export function sanitize(input: string, replacement: string = '_') {
  const sanitized = input.replace(illegalRe, replacement).replace(controlRe, replacement);
  return sanitized;
}

// << Data filtering <<

export const getI18n = () => {
  return new Promise((resolve: any) => {
    const getI18nByLang = (data: any) => {
      const localeFromConfig = localStorage.getItem('Px-localeFromConfig');
      const browserLocale = navigator.languages ? navigator.languages : [navigator.language];
      let chosenLocale: any = null;
      if (localeFromConfig && data.hasOwnProperty(localeFromConfig)) {
        // If user chose a locale from config, use it
        chosenLocale = localeFromConfig;
      } else {
        // Or try using the locale from browser
        Object.keys(data).forEach((i18nLang: string) => {
          if (!chosenLocale) {
            browserLocale.forEach((l: string) => {
              if (!chosenLocale && i18nLang.indexOf(l) > -1) {
                chosenLocale = i18nLang;
              }
            });
          }
        });
      }
      const localI18n: any = localStorage.getItem('PxConfig');
      chosenLocale = JSON.parse(localI18n)?.language;
      console.log('chosenLocale ' + chosenLocale);
      if (chosenLocale && !localeFromConfig) {
        localStorage.setItem('Px-localeFromConfig', chosenLocale);
      }

      const currencySymbol = localStorage.getItem('PxCurrencySymbol');
      if (!currencySymbol && data[chosenLocale]?.currency?.i18n_value) {
        localStorage.setItem('PxCurrencySymbol', data[chosenLocale]?.currency?.i18n_value);
      }
      if (chosenLocale) {
        document.documentElement.lang = chosenLocale;
        return data[chosenLocale];
      }
      return {};
    };

    // Try loading i18n from localStorage
    const localI18n = localStorage.getItem('PxI18n');
    if (localI18n) {
      const i18n = getI18nByLang(JSON.parse(localI18n));
      resolve(i18n);
    } else {
      console.log('rocess.env.REACT_APP_STAGE ' + process.env.REACT_APP_STAGE);
      let publicDataEndpoint =
        'https://l2dqqbs9r2.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-i18n';
      if (process.env.REACT_APP_STAGE === 'test') {
        publicDataEndpoint =
          'https://s4z4motqq3.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-i18n';
      }
      if (process.env.REACT_APP_STAGE === 'prod') {
        publicDataEndpoint =
          'https://qkexusfqqf.execute-api.us-east-1.amazonaws.com/Integration/px-api-gateway/saas-i18n';
      }
      axios.get(publicDataEndpoint).then(async (res: any) => {
        if (res.data.status === 200) {
          const remoteI18n = Object.assign({}, res.data.results);
          let i18n: any = {};
          if (Object.keys(remoteI18n).length) {
            // Only store i18n if it had content
            localStorage.setItem('PxI18n', JSON.stringify(remoteI18n));
            i18n = getI18nByLang(remoteI18n);
          }
          resolve(i18n);
        } else {
          resolve({});
        }
      });
    }
  });
};

export const getContracts = () => {
  return new Promise(async (resolve: any, reject: any) => {
    const path = `/px-api-gateway/module-contracts/tenant-contracts`;
    API.get(apiName, path, {})
      .then(async (res: any) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

export const signContracts = (contratIds: string) => {
  return new Promise(async (resolve: any, reject: any) => {
    const path = `/px-api-gateway/module-contracts/sign-contracts`;
    console.log('contratIds', contratIds);
    API.post(apiName, path, { body: { contract_id: contratIds } })
      .then(async (res: any) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};
