import { ClickAwayListener, MenuList, Popper } from '@material-ui/core';
import React from 'react';

function PopOverMenu(props: any) {
  const { popOverAnchorElement, isMenuOpen, closeMenu, content } = props;
  return (
    <Popper
      open={!!isMenuOpen}
      placement="bottom-start"
      className="px-popper-menu px-accounts-menu"
      disablePortal={true}
      keepMounted={false}
      transition
      anchorEl={popOverAnchorElement}
    >
      <ClickAwayListener onClickAway={closeMenu}>
        <MenuList>{content}</MenuList>
      </ClickAwayListener>
    </Popper>
  );
}

export default PopOverMenu;
