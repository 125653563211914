import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { format } from 'date-fns';
import { endOfDay, startOfDay } from 'date-fns/esm';
import parse from 'date-fns/parse';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import useDataService from '../../../hooks/useDataService';
import { eMessageType } from '../../../types/IMessageType';
import { getDateFormat } from '../../../_lib/lib';
import { PrimaryButton, TertiaryButton } from '../../AtomComponents';
import DateField from '../../DateField/DateField';
import { IOfferTimeRange } from '../../../types/IItemSetup';
import { convertSecondsToTime, dateFormats } from '../../PreOrders/Utils';
import './DateRangeSelector.scss';
interface IDateRangeSelector {
  customOptionObj: any;
  clearFilter: any;
  saveFilter: any;
  closeDialog: any;
  applyButtonLabel?: any;
  clearButtonLabel?: any;
  disableStartBefore?: string;
  disableBothDates?: {
    endDate?: { after?: string };
  };
  setLoadingDialog: any;
  overLappingShippingDates: any;
  daysToFinalize: number;
  offeringTimeRange: IOfferTimeRange;
}

interface IStartEndDate {
  startDate: string;
  endDate: string;
}
interface IFilterOptions {
  offerDates: IStartEndDate | null;
  shippingDates: IStartEndDate | null;
}

interface IShowNoDate {
  offerStart: boolean;
  offerEnd: boolean;
  shippingStart: boolean;
  shippingEnd: boolean;
}

const commonDateFormat = 'MM/DD/yyyy';

function DateRangeSelector({
  customOptionObj,
  clearFilter,
  saveFilter,
  closeDialog,
  disableStartBefore,
  applyButtonLabel = 'SAVE',
  clearButtonLabel = 'CANCEL',
  disableBothDates,
  setLoadingDialog,
  overLappingShippingDates,
  daysToFinalize,
  offeringTimeRange,
}: IDateRangeSelector) {
  // initial filter set
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({
    offerDates: null,
    shippingDates: null,
  });
  const [filterDates, setFilterDates] = useState<any>({
    negotiatingDates: null,
    finalizingDates: null,
  });
  const [showNoDate, setShowNoDate] = useState<IShowNoDate>({
    offerStart: true,
    offerEnd: true,
    shippingStart: true,
    shippingEnd: true,
  });
  const { openSnackBar } = useDataService();
  useEffect(() => {
    if (customOptionObj) {
      try {
        const offerStartDate = customOptionObj.offerDates.startDate
          ? moment(customOptionObj.offerDates.startDate)
          : '';
        const offerEndDate = customOptionObj.offerDates.endDate
          ? moment(customOptionObj.offerDates.endDate)
          : '';
        const shippingStartDate = customOptionObj.shippingDates.startDate
          ? moment(customOptionObj.shippingDates.startDate)
          : '';
        const shippingEndDate = customOptionObj.shippingDates.endDate
          ? moment(customOptionObj.shippingDates.endDate)
          : '';

        if (offerStartDate && offerEndDate && shippingStartDate && shippingEndDate)
          // set disabled dates
          setDisabledDates({
            offerDates: {
              before: offerStartDate.format(commonDateFormat),
              after: offerEndDate.format(commonDateFormat),
            },
            shippingDates: {
              before: shippingStartDate.format(commonDateFormat),
              after: shippingEndDate.format(commonDateFormat),
            },
          });

        // set rangeDates
        setFilterOptions({
          offerDates: {
            startDate: offerStartDate ? offerStartDate.format(commonDateFormat) : '',
            endDate: offerEndDate ? offerEndDate.format(commonDateFormat) : '',
          },
          shippingDates: {
            startDate: shippingStartDate ? shippingStartDate.format(commonDateFormat) : '',
            endDate: shippingEndDate ? shippingEndDate.format(commonDateFormat) : '',
          },
        });
      } catch (error: any) {
        console.error(error);
        setFilterOptions({
          ...filterOptions,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let negotiatingStartDate, negotiatingEndDate, finalizingStartDate, finalizingEndDate;
    if (!showNoDate.offerEnd) {
      negotiatingStartDate = moment(filterOptions.offerDates?.endDate, commonDateFormat).add(
        offeringTimeRange?.offerEndTimeInSeconds + 1,
        'seconds'
      );
    }
    if (!showNoDate.shippingStart) {
      finalizingStartDate = moment(filterOptions.shippingDates?.startDate, commonDateFormat)
        .subtract(daysToFinalize + 1, 'days')
        .add(offeringTimeRange?.offerEndTimeInSeconds + 1, 'seconds');

      negotiatingEndDate = moment(finalizingStartDate).subtract(1, 'second');
      finalizingEndDate = moment(filterOptions.shippingDates?.startDate, commonDateFormat).subtract(
        1,
        'second'
      );
    }
    setFilterDates({
      negotiatingDates: { startDate: negotiatingStartDate, endDate: negotiatingEndDate },
      finalizingDates: { startDate: finalizingStartDate, endDate: finalizingEndDate },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const setOfferStartDate = (val: any) => {
    let endDate: any;
    const newStartDate = moment(val, commonDateFormat);
    if (showNoDate.offerStart) setShowNoDate({ ...showNoDate, offerStart: false });
    if (
      filterOptions?.offerDates?.endDate &&
      moment(filterOptions?.offerDates?.endDate, commonDateFormat).isBefore(newStartDate)
    ) {
      // endDate = format(addDays(new Date(val), 1), commonDateFormat);
      endDate = newStartDate.add(1, 'day').format(commonDateFormat);
    }
    setFilterOptions({
      ...filterOptions,
      offerDates: { startDate: val, endDate: endDate ?? filterOptions?.offerDates?.endDate },
    });
  };

  const setOfferEndDate = (val: any) => {
    const shippingStartFromSelected = moment(val, commonDateFormat).add(daysToFinalize + 1, 'days');

    let startDate: any;
    const newEndDate = moment(val, commonDateFormat);
    // const endDatePlusOne = moment(val, commonDateFormat).add(1, 'day');
    if (showNoDate.offerEnd) setShowNoDate({ ...showNoDate, offerEnd: false });
    if (
      filterOptions?.shippingDates?.startDate &&
      moment(filterOptions?.shippingDates?.startDate, commonDateFormat).isSameOrBefore(
        shippingStartFromSelected
      )
    ) {
      startDate = newEndDate.add(daysToFinalize + 2, 'day').format(commonDateFormat);
    }
    setFilterOptions({
      ...filterOptions,
      offerDates: { startDate: filterOptions?.offerDates?.startDate || '', endDate: val },
      shippingDates: {
        startDate: startDate ?? filterOptions?.shippingDates?.startDate,
        endDate: filterOptions?.shippingDates?.endDate || '',
      },
    });
    if (!getDateFormat(val)) return;
    let disableAfter = disabledDates.shippingDates.before;

    if (
      overLappingShippingDates &&
      (moment(disabledDates.shippingDates.before, commonDateFormat).isSameOrBefore(
        shippingStartFromSelected
      ) ||
        moment(shippingStartFromSelected).isSameOrAfter(overLappingShippingDates))
    ) {
      disableAfter = newEndDate.add(daysToFinalize + 2, 'day').format(commonDateFormat);
    } else if (
      overLappingShippingDates &&
      moment(disabledDates.shippingDates.before, commonDateFormat).isAfter(
        shippingStartFromSelected
      ) &&
      moment(shippingStartFromSelected).isSameOrBefore(overLappingShippingDates)
    ) {
      disableAfter = moment(overLappingShippingDates).format(commonDateFormat);
    }

    const shippingDates = { ...disabledDates.shippingDates };
    const offerDates = { ...disabledDates.offerDates };
    offerDates.after = val;
    shippingDates.before = startDate
      ? startDate
      : overLappingShippingDates
      ? disableAfter
      : newEndDate.add(daysToFinalize + 2, 'day').format(commonDateFormat);
    setDisabledDates({
      offerDates,
      shippingDates,
    });
  };

  const setShippingStartDate = (val: any) => {
    let endDate: any;
    const newStartDate = moment(val, commonDateFormat);
    if (showNoDate.shippingStart) setShowNoDate({ ...showNoDate, shippingStart: false });
    if (
      filterOptions?.shippingDates?.endDate &&
      moment(filterOptions?.shippingDates?.endDate, commonDateFormat).isSameOrBefore(newStartDate)
    ) {
      endDate = newStartDate.add(1, 'day').format(commonDateFormat);
      // endDate = format(addDays(new Date(val), 1), commonDateFormat);
    }
    setFilterOptions({
      ...filterOptions,
      shippingDates: { startDate: val, endDate: endDate ?? filterOptions?.shippingDates?.endDate },
    });
    if (!getDateFormat(val)) return;
    const shippingDates = { ...disabledDates.shippingDates };
    // shippingDates.before = val;
    setDisabledDates({
      ...disabledDates,
      shippingDates,
    });
  };

  const setShippingEndDate = (val: any) => {
    setFilterOptions({
      ...filterOptions,
      shippingDates: {
        startDate: filterOptions?.shippingDates?.startDate || '',
        endDate: val,
      },
    });
    if (showNoDate.shippingEnd) setShowNoDate({ ...showNoDate, shippingEnd: false });
  };

  const [disabledDates, setDisabledDates] = useState<any>({
    offerDates: {
      before: moment().format(commonDateFormat),
      after: moment().format(commonDateFormat),
    },
    shippingDates: {},
  });
  const offerStartDateRef = useRef<any>();
  const offerEndDateRef = useRef<any>();
  const shippingStartDateRef = useRef<any>();
  const shippingEndDateRef = useRef<any>();

  const showInvalidDatesError = () => {
    // show error
    if (offerStartDateRef?.current?.showError) {
      offerStartDateRef.current.showError(`Invalid Dates`);
    }

    if (offerEndDateRef?.current?.showError) {
      offerEndDateRef.current.showError(`Invalid Dates`);
    }
    if (shippingStartDateRef?.current?.showError) {
      shippingStartDateRef.current.showError(`Invalid Dates`);
    }
    if (shippingEndDateRef?.current?.showError) {
      shippingEndDateRef.current.showError(`Invalid Dates`);
    }
  };
  const handleApply = async () => {
    const allDates: any = {
      offerDates: {
        startDate: '',
        endDate: '',
      },
      shippingDates: {
        startDate: '',
        endDate: '',
      },
    };
    const offerDates = filterOptions?.offerDates,
      shippingDates = filterOptions?.shippingDates;
    const offerStartDateFormat = getDateFormat(offerDates?.endDate || '');
    const offerEndDateFormat = getDateFormat(offerDates?.startDate || '');
    const shippingStartDateFormat = getDateFormat(shippingDates?.startDate || '');
    const shippingEndDateFormat = getDateFormat(shippingDates?.endDate || '');

    if (
      !offerStartDateFormat ||
      !offerEndDateFormat ||
      !shippingStartDateFormat ||
      !shippingEndDateFormat
    )
      return showInvalidDatesError();

    allDates.offerDates.startDate = startOfDay(
      parse(offerDates?.startDate ?? '', offerStartDateFormat, new Date())
    );
    allDates.offerDates.endDate = endOfDay(
      parse(offerDates?.endDate ?? '', offerEndDateFormat, new Date())
    );
    allDates.shippingDates.startDate = startOfDay(
      parse(shippingDates?.startDate ?? '', shippingStartDateFormat, new Date())
    );
    allDates.shippingDates.endDate = endOfDay(
      parse(shippingDates?.endDate ?? '', shippingEndDateFormat, new Date())
    );

    const updatedFilter: any = {
      offerStartDate: format(allDates.offerDates.startDate, dateFormats.yearMonthDate),
      offerEndDate: format(allDates.offerDates.endDate, dateFormats.yearMonthDate),
      shippingStartDate: format(allDates.shippingDates.startDate, dateFormats.yearMonthDate),
      shippingEndDate: format(allDates.shippingDates.endDate, dateFormats.yearMonthDate),
      negotiatingStartDate: filterDates.negotiatingDates?.startDate.format('yyyy-MM-DD HH:mm:ss'),
      negotiatingEndDate: filterDates.negotiatingDates?.endDate.format('yyyy-MM-DD HH:mm:ss'),
      finalizingStartDate: filterDates.finalizingDates?.startDate.format('yyyy-MM-DD HH:mm:ss'),
      finalizingEndDate: filterDates.finalizingDates?.endDate.format('yyyy-MM-DD HH:mm:ss'),
    };

    // handle filter
    setLoadingDialog(true);
    const res = await saveFilter(updatedFilter);
    if (res) closeDialog();
    else openSnackBar('Could Not Make Changes', eMessageType.error);
    setLoadingDialog(false);
  };
  const handleClear = () => {
    closeDialog();
  };

  return (
    <Dialog
      onClose={() => closeDialog()}
      className="px-create-dates-dialog"
      open={!!customOptionObj}>
      <DialogTitle>
        <span className="px-chips-filter-title">Create New Dates</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          <div className="margin-bottom-2 text-faded" style={{ fontSize: '12px' }}>
            Finalizing days is currently set to{' '}
            {daysToFinalize === 1 ? `${daysToFinalize} Day` : `${daysToFinalize} Days`}. You must
            leave a {1 + daysToFinalize}-day gap minimum between the Offer end date and Shipping
            start date for Negotiating and Finalizing
          </div>
          <div className="grid-x small-12  margin-bottom-2 px-create-date-filter-selector-container">
            <div className="cell small-2 create-dates-labels">
              Offering (
              {!showNoDate.offerEnd && filterDates.negotiatingDates.startDate
                ? moment(filterDates.negotiatingDates?.startDate).diff(
                    moment(filterOptions.offerDates?.startDate, commonDateFormat),
                    'days'
                  )
                : '--'}
              D):
            </div>
            <DateField
              textfieldProps={{
                variant: 'outlined',
                label:
                  !showNoDate.offerStart &&
                  `Start Taking Offers (${convertSecondsToTime(
                    offeringTimeRange?.offerStartTimeInSeconds ?? 0,
                    'hh:mm a'
                  )})`,
                placeholder: showNoDate.offerStart
                  ? `Start Taking Offers (${convertSecondsToTime(
                      offeringTimeRange?.offerStartTimeInSeconds ?? 0,
                      'hh:mm a'
                    )})`
                  : '',
                // disabled: ,
                InputLabelProps: { shrink: true },
              }}
              disabled={{
                before: disabledDates?.offerDates?.before,
                after: !showNoDate.offerEnd
                  ? moment(disabledDates?.offerDates?.after, commonDateFormat)
                  : undefined,
              }}
              date={filterOptions?.offerDates?.startDate}
              setDate={setOfferStartDate}
              ref={offerStartDateRef}
              showNoDate={showNoDate.offerStart}
            />
            to
            <DateField
              textfieldProps={{
                variant: 'outlined',
                label:
                  !showNoDate.offerEnd &&
                  `End Taking Offers (${convertSecondsToTime(
                    offeringTimeRange?.offerEndTimeInSeconds ?? 0,
                    'hh:mm a'
                  )})`,
                placeholder: showNoDate.offerEnd
                  ? `End Taking Offers (${convertSecondsToTime(
                      offeringTimeRange?.offerEndTimeInSeconds ?? 0,
                      'hh:mm a'
                    )})`
                  : '',
                disabled: showNoDate.offerStart,
                InputLabelProps: { shrink: true },
              }}
              disabled={{
                before: moment(filterOptions?.offerDates?.startDate, commonDateFormat),
                after: !showNoDate.shippingStart
                  ? moment(filterOptions?.shippingDates?.startDate, commonDateFormat).subtract(
                      daysToFinalize + 2,
                      'day'
                    )
                  : undefined,
              }}
              date={filterOptions?.offerDates?.endDate ?? ''}
              setDate={setOfferEndDate}
              ref={offerEndDateRef}
              showNoDate={showNoDate.offerEnd}
            />
          </div>
          <div className="grid-x small-12 px-create-date-filter-selector-container">
            <div className="cell small-2 create-dates-labels">
              Negotiating (
              {filterDates.negotiatingDates?.endDate
                ? moment(filterDates.negotiatingDates?.endDate).diff(
                    moment(filterOptions.offerDates?.endDate, commonDateFormat),
                    'days'
                  )
                : '--'}
              D):
            </div>
            <div className="margin-left-2">
              {filterDates.negotiatingDates?.startDate
                ? filterDates.negotiatingDates?.startDate.format('MM/DD/yyyy (hh:mm a)')
                : '--'}{' '}
              &nbsp; &nbsp; to &nbsp; &nbsp;
              {filterDates.negotiatingDates?.endDate
                ? filterDates.negotiatingDates?.endDate.format('MM/DD/yyyy (hh:mm a)')
                : '--'}
            </div>
          </div>
          <div className="grid-x small-12 px-create-date-filter-selector-container margin-top-2">
            <div className="cell small-2 create-dates-labels">
              Finalizing (
              {filterDates.finalizingDates?.endDate
                ? moment(filterDates.finalizingDates?.endDate).diff(
                    moment(filterDates.finalizingDates?.startDate, commonDateFormat),
                    'days'
                  )
                : '--'}
              D):
            </div>
            <div className="margin-left-2">
              {filterDates.finalizingDates?.startDate
                ? filterDates.finalizingDates?.startDate.format('MM/DD/yyyy (hh:mm a)')
                : '--'}{' '}
              &nbsp; &nbsp; to &nbsp; &nbsp;
              {filterDates.finalizingDates?.endDate
                ? filterDates.finalizingDates?.endDate.format('MM/DD/yyyy (hh:mm a)')
                : '--'}
            </div>
          </div>
          <div className="grid-x small-12 margin-top-2 px-create-date-filter-selector-container">
            <div className="cell small-2 create-dates-labels">
              Shipping (
              {!showNoDate.shippingEnd
                ? moment(filterOptions.shippingDates?.endDate, commonDateFormat).diff(
                    moment(filterOptions.shippingDates?.startDate, commonDateFormat),
                    'days'
                  )
                : '--'}
              D):
            </div>
            <DateField
              textfieldProps={{
                variant: 'outlined',
                label: !showNoDate.shippingStart && `Start Shipping (12:00 am)`,
                placeholder: showNoDate.shippingStart ? `Start Shipping (12:00 am)` : '',
                disabled: showNoDate.offerEnd,
                InputLabelProps: { shrink: true },
              }}
              disabled={{
                before: disabledDates?.shippingDates.before ?? moment(),
                after: !showNoDate.shippingEnd
                  ? moment(filterOptions.shippingDates?.endDate, commonDateFormat).subtract(
                      1,
                      'day'
                    )
                  : undefined,
              }}
              date={filterOptions?.shippingDates?.startDate ?? ''}
              setDate={setShippingStartDate}
              ref={shippingStartDateRef}
              showNoDate={showNoDate.shippingStart}
            />
            to
            <DateField
              textfieldProps={{
                variant: 'outlined',
                label: !showNoDate.shippingEnd && `End Shipping (11:59 pm)`,
                placeholder: showNoDate.shippingEnd ? `End Shipping (11:59 pm)` : '',
                disabled: showNoDate.shippingStart,
                InputLabelProps: { shrink: true },
              }}
              disabled={{
                before: moment(filterOptions?.shippingDates?.startDate, commonDateFormat).add(
                  1,
                  'day'
                ),
              }}
              date={filterOptions?.shippingDates?.endDate}
              setDate={setShippingEndDate}
              ref={shippingEndDateRef}
              showNoDate={showNoDate.shippingEnd}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>{clearButtonLabel}</TertiaryButton>
        <PrimaryButton
          onClick={handleApply}
          disabled={Object.keys(showNoDate).some(
            (item: string) => showNoDate[item as keyof IShowNoDate]
          )}>
          {applyButtonLabel}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default DateRangeSelector;
