import { InputAdornment, makeStyles, TextFieldProps, Tooltip } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import React, { forwardRef, useState, useEffect, useImperativeHandle, Dispatch } from 'react';
import './TimeField.scss';
import ErrorIcon from '@material-ui/icons/Error';
import { TextField } from '../../AtomComponents';
import TimeDialog from './TimeDialog';
import moment from 'moment-timezone';

interface ITimeField {
  textfieldProps?: TextFieldProps;
  time: string;
  setTime: Dispatch<string>;
  timeFormat?: string;
}

const tooltipStyle = makeStyles({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'red',
    height: '5px',
  },
});

const TimeField = forwardRef(
  ({ textfieldProps, time, setTime, timeFormat }: ITimeField, ref: any) => {
    const tooltipClass = tooltipStyle();
    const [currentTime, setCurrentTime] = useState<string>(time);
    const [timeError, setTimeError] = useState<string>('');
    const [openCalendarState, setOpenCalendarState] = useState<boolean>(false);
    const commonTimeFormat = timeFormat ? timeFormat : 'hh:mm a';

    useEffect(() => {
      setCurrentTime(time);
    }, [time]);

    const onChange = (e: any) => {
      setCurrentTime(e.target.value);
      setTime(e.target.value);
    };
    const validateSelectedDate = (e: any) => {
      isCorrectDate();
    };
    const changeDateFromCalendar = (calendarDate: Date) => {
      if (moment(calendarDate).isValid()) {
        setTimeError('');
        setCurrentTime(moment(calendarDate).format(commonTimeFormat));
        setTime(moment(calendarDate).format(commonTimeFormat));
      }
    };
    const toggleCalendar = () => {
      if (textfieldProps?.disabled) {
        return;
      }
      setOpenCalendarState(!openCalendarState);
    };

    const isCorrectDate = () => {
      // find the format
      const timeFormatsSupported = [
        'H:m',
        'H:m a',
        'H:m A',
        'H:ma',
        'H:mA',
        'H:mm',
        'H:mm a',
        'H:mm A',
        'H:mma',
        'H:mmA',

        'HH:m',
        'HH:m a',
        'HH:m A',
        'HH:ma',
        'HH:mA',
        'HH:mm',
        'HH:mm a',
        'HH:mm A',
        'HH:mma',
        'HH:mmA',

        'h:m',
        'h:m a',
        'h:m A',
        'h:ma',
        'h:mA',
        'h:mm',
        'h:mm a',
        'h:mm A',
        'h:mma',
        'h:mmA',

        'hh:m',
        'hh:m a',
        'hh:m A',
        'hh:ma',
        'hh:mA',
        'hh:mm',
        'hh:mm a',
        'hh:mm A',
        'h:mma',
        'h:mmA',
      ];
      for (let i = 0; i < timeFormatsSupported.length; i++) {
        const trimedTimeString = time.trim();
        const isTimeValid = moment(trimedTimeString, timeFormatsSupported[i], true).isValid();
        if (isTimeValid) {
          setTimeError('');
          const timeSelected = moment(trimedTimeString, timeFormatsSupported[i]);
          setCurrentTime(timeSelected.format(commonTimeFormat));
          return true;
        }
      }
      setTimeError('Invalid Time! please enter the time in the format: HH:MM or HH:MM A');
      return false;
    };

    useImperativeHandle(
      ref,
      () => ({
        isCorrectDate: isCorrectDate,
        removeError: () => {
          onFocus();
        },
        showError: (error: string) => {
          setTimeError(error);
        },
      }),
      // eslint-disable-next-line
      [time]
    );

    const onFocus = () => {
      setTimeError('');
    };
    const startAdornment = !!timeError ? (
      <Tooltip classes={tooltipClass} disableFocusListener disableTouchListener title={timeError}>
        <ErrorIcon className="errorMessage" color="error" />
      </Tooltip>
    ) : (
      <></>
    );

    return (
      <div className="px-timefield-container">
        {openCalendarState && (
          <TimeDialog
            onChange={changeDateFromCalendar}
            dateVal={currentTime}
            openDialog={openCalendarState}
            closeDialog={toggleCalendar}
            timeFormat={commonTimeFormat}
          />
        )}
        <TextField
          onChange={onChange}
          onFocus={onFocus}
          {...textfieldProps}
          error={!!timeError}
          helperText={''}
          value={currentTime}
          onBlur={validateSelectedDate}
          InputProps={{
            startAdornment,
            endAdornment: (
              <InputAdornment className="pointer-mouse" position="end" onClick={toggleCalendar}>
                <AccessTimeIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
);

export default TimeField;
