import React from 'react';
import { IconButton, MenuItem } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import TertiaryButton from '../AtomComponents/TertiaryButton';
import useDialog from '../../hooks/useDialog';
import PopOverMenu from '../PopOverMenu/PopOverMenu';
import { ICellRendererParams } from 'ag-grid-community';

interface IDeleteItem extends ICellRendererParams {
  deleteItem: any;
  params: ICellRendererParams;
}

const DeleteItem = (props: IDeleteItem) => {
  const [
    isDeleteAllDialogOpen,
    openDeleteAllDialog,
    closeDeleteAllDialog,
    deleteAllDialogElement,
  ] = useDialog();

  const openPopOverMenu = (e: any) => {
    openDeleteAllDialog(e);
  };

  const handleClick = async (itemElectId: number) => {
    closeDeleteAllDialog();
    const res = await props.deleteItem(itemElectId);
    if (res) return true;
    return false;
  };

  if (!props.params.data) return '';
  if (props.params.data.status === 'DRAFT' || props.params.data.status === 'SCHEDULED') {
    return (
      <>
        <IconButton className="px-itemsetup-icon-btn" aria-label="delete">
          <CancelIcon onClick={openPopOverMenu} />
        </IconButton>
        <PopOverMenu
          popOverAnchorElement={deleteAllDialogElement}
          isMenuOpen={isDeleteAllDialogOpen}
          closeMenu={closeDeleteAllDialog}
          content={[
            <MenuItem key="deleteItem">
              <TertiaryButton onClick={() => handleClick(props.params.data.itemElectId)}>
                {'DELETE'}
              </TertiaryButton>
            </MenuItem>,
          ]}
        />
      </>
    );
  }
  return '';
};

export default DeleteItem;
