import { useState } from 'react';
import React from 'react';
import { IFilterSaveParams } from '../../types/IFilterEngine';
import DateRangeAnalyticsSelector from '../../components/PreOrders/DateRangeAnalyticsSelector';
import DateSelectorComponent from '../../components/PreOrders/DateSelectorComponent';
import { ILastDateRangeSelector } from '../../types/IOfferClearing';

export const LastDateRangeSelector = (props: ILastDateRangeSelector) => {
  const [dateRangeDialog, setDateRangeDialog] = useState<any>(false);

  const closeDateRangeSelector = () => setDateRangeDialog(false);
  const clearDateRangeSelector = () => {
    setDateRangeDialog(false);
  };
  const openDateRangeSelector = () => {
    setDateRangeDialog({
      attribute: 'date',
      title: 'Date Select',
    });
  };

  const updateCustomDate = ({ selectedFilters }: IFilterSaveParams) => {
    if (selectedFilters.startDate && selectedFilters.endDate) {
      props.dateRangeState.setDateRange({
        startDate: selectedFilters.startDate,
        endDate: selectedFilters.endDate,
      });
    }
    closeDateRangeSelector();
  };
  //#endregion << DateRangeSelector manager <<

  return (
    <div>
      <div className="cell small-12">
        <DateRangeAnalyticsSelector
          dateRangeDialog={dateRangeDialog}
          startDate={props.dateRangeState.dateRange.startDate}
          endDate={props.dateRangeState.dateRange.endDate}
          updateCustomDate={updateCustomDate}
          clearDateRangeSelector={clearDateRangeSelector}
          closeDateRangeSelector={closeDateRangeSelector}
          disableCustom={true}
        />
        <div className="margin-top-2 margin-bottom-2">
          <DateSelectorComponent
            dateRange={props.dateRangeState.dateRange}
            openDateRangeSelector={openDateRangeSelector}
            type={'clearing'}
          />
        </div>
      </div>
    </div>
  );
};
