import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { PrimaryButton, TertiaryButton } from '../../AtomComponents';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface ITimeDialog {
  onChange: (calenderDate: Date) => void;
  dateVal: string;
  openDialog: boolean;
  closeDialog: () => void;
  timeFormat: string;
}

function TimeDialog({ onChange, dateVal, openDialog, closeDialog, timeFormat }: ITimeDialog) {
  const [selectedDate, handleDateChange] = useState<any>(moment(dateVal, timeFormat));
  const changeDateValue = (date: MaterialUiPickersDate) => {
    handleDateChange(date);
  };

  const saveSelectedDates = (e: any) => {
    onChange(selectedDate);
    closeDialog();
  };

  return (
    <Dialog className="px-time-dialog" open={openDialog} onClose={closeDialog}>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            variant="static"
            openTo="hours"
            value={selectedDate}
            onChange={changeDateValue}
            okLabel={'done'}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <>
          <TertiaryButton onClick={closeDialog}>CANCEL</TertiaryButton>
          <PrimaryButton onClick={saveSelectedDates}>OK</PrimaryButton>
        </>
      </DialogActions>
    </Dialog>
  );
}

export default TimeDialog;
