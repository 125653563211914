import { ePricingCategory } from '../../types/IPricing';
import {
  categorizePricing,
  checkNullOrUndefined,
  stocklistRowDescriptionGetter,
} from '../../_lib/lib';
import * as types from '../actions/actionTypes';
const initState = {
  items: null,
  isLoaded: false,
  filters: {},
};

export default function pricingReducer(state: any = initState, action: any) {
  switch (action.type) {
    case types.PRICING_SET: {
      const newState = {
        ...state,
        lastPublishDate: action.payload?.lastPublishDate,
        draftPendingApproval: action.payload?.draftPendingApproval,
        items: action.payload?.items?.map((val: any, index: number) => {
          const { description, sku } = stocklistRowDescriptionGetter({
            settings: state.settings,
            item: val,
          });

          return {
            ...val,
            index,
            agGridSubGroupVal: { description, sku },
          };
        }),
        stats: {
          ...state?.stats,
          absoluteChanges: 0,
        },
        isLoaded: state.settings ? true : false,
      };
      return newState;
    }
    case types.PRICING_UPDATE: {
      if (!action.payload.itemNumberWarehouse || !state.items[action.payload.index]) {
        return state;
      }

      let absoluteChanges = state.stats.absoluteChanges || 0;
      const items = [...state.items];
      if (items[action.payload.index].itemNumberWarehouse === action.payload.itemNumberWarehouse) {
        const oldTab = categorizePricing(items[action.payload.index]);
        items[action.payload.index] = {
          ...items[action.payload.index],
          [action.payload.column]: action.payload.value,
        };
        if (
          items[action.payload.index].draftListPrice &&
          items[action.payload.index].draftLowPrice
        ) {
          if (items[action.payload.index].draftSource !== 'MANUAL') {
            items[action.payload.index].draftSource = 'MANUAL';
          }
          if (
            items[action.payload.index].draftListPrice !== items[action.payload.index].listPrice &&
            items[action.payload.index].draftLowPrice !== items[action.payload.index].lowPrice
          ) {
            items[action.payload.index].draftExists = true;
          }
        }
        const newTab = categorizePricing(items[action.payload.index]);

        if (newTab === ePricingCategory.manual && oldTab !== ePricingCategory.manual) {
          absoluteChanges += 1;
        }

        if (newTab !== ePricingCategory.manual && oldTab === ePricingCategory.manual) {
          absoluteChanges -= 1;
        }
      }

      return {
        ...state,
        items,
        stats: {
          ...state.stats,
          absoluteChanges,
        },
      };
    }
    case types.PRICING_COUNT_UPDATE: {
      console.log({ ...state.items[action.payload.data.index] });

      if (
        action.payload.newTab === action.payload.oldTab ||
        !action.payload.newTab ||
        !action.payload.oldTab ||
        checkNullOrUndefined(action.payload.data?.index) ||
        !state.items[action.payload.data.index]
      ) {
        return state;
      }
      let changesCount = state.stats.absoluteChanges || 0;
      if (action.payload.oldTab === 'manual' || action.payload.oldTab === 'import') {
        changesCount -= 1;
      }

      if (action.payload.newTab === 'manual' || action.payload.newTab === 'import') {
        changesCount += 1;
      }

      const statsObj = {
        ...state.stats,
        [action.payload.oldTab]: (state.stats[action.payload.oldTab] || 1) - 1,
        [action.payload.newTab]: (state.stats[action.payload.newTab] || 0) + 1,
        absoluteChanges: changesCount,
      };

      // update the state of current item
      const items = [...state.items];

      items[action.payload.data.index] = {
        ...items[action.payload.data.index],
        ...action.payload.data,
      };

      console.log(
        items[action.payload.data.index],
        items[action.payload.data.index] === state.items[action.payload.data.index]
      );

      // console.log(statsObj, 'types.PRICING_COUNT_UPDATE', action.payload.newTab, action.payload.oldTab)
      return {
        ...state,
        items,
        stats: statsObj,
      };
    }
    case types.PRICING_PUBLISH: {
      const statsObj = {
        ...state.stats,
        manual: 0,
        import: 0,
      };
      const newState = state.items.map((val: any) => {
        const price = { ...val };
        if (val.draftPrice) {
          price.listPrice = val.draftPrice.listPrice;
          price.lowPrice = val.draftPrice.lowPrice;
        }
        return price;
      });
      return {
        ...state,
        items: newState,
        stats: statsObj,
      };
    }
    case types.PRICING_SETTINGS_SET:
      return {
        ...state,
        settings: action.payload,
        isLoaded: state.items ? true : false,
      };

    case types.PRICING_LOADING_CHANGE: {
      return {
        ...state,
        isLoaded: action.payload,
      };
    }
    case types.PRICING_STATS_UPDATE: {
      if (!Object.keys(action.payload).length) {
        return {
          ...state,
          stats: {
            absoluteChanges: state?.absoluteChanges,
          },
        };
      }
      return {
        ...state,
        stats: {
          // ...state.stats,
          ...action.payload,
          absoluteChanges:
            state.stats?.absoluteChanges ||
            (action.payload?.import || 0) +
              (state.stats?.import || 0) +
              (action.payload?.manual || 0) +
              (state.stats?.manual || 0),
        },
      };
    }
    default:
      return state;
  }
}
