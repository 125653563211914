import {
  FirstDataRenderedEvent,
  GridOptions,
  RowGroupOpenedEvent,
  RowNode,
  RowSelectedEvent,
} from 'ag-grid-community';
import { isEmpty } from 'lodash';

// Helper Functions and Components
import {
  checkDetailRows,
  checkStateFromDetail,
  checkStateFromMaster,
  LoadingComponent,
  NoRowsMatchedComponent,
  rowOpenOrCloseMemorizer,
} from '../TableCellComponents';
import { getClosedColumnDefinitions } from './ClosedColDef';
import { getOpenColumnDefinitions } from './OpenColDef';
import { getPendingColumnDefinitions } from './PendingColsDef';

// Types
import { awards, tabIndexStates, TableProperties, viewByStates } from '../../../types/IPreOrders';

// let scrolledBy = 'parent';

export const getTableProperties = ({
  updateHandler,
  onGridReady,
  onDetailGridReady,
  setUserLocalStates,
  setRowDisplayedCount,
  canManagePoOrder,
}: TableProperties) => {
  // Column Definitions for Open Table
  const {
    buyerOpenColsDef,
    buyerOpenDetailsColsDef,
    itemOpenColsDef,
    itemOpenDetailsColsDef,
  } = getOpenColumnDefinitions({ updateHandler, canManagePoOrder });

  // Column Definitions for Closed Table
  const {
    buyerClosedColsDef,
    buyerClosedDetailsColsDef,
    itemClosedColsDef,
    itemClosedDetailsColsDef,
  } = getClosedColumnDefinitions();

  // Column Definitions for Pending Table
  const {
    buyerPendingColsDef,
    buyerPendingDetailsColsDef,
    itemPendingColsDef,
    itemPendingDetailsColsDef,
  } = getPendingColumnDefinitions({ updateHandler, canManagePoOrder });

  const gridOptionsBuyerOpen: GridOptions = {
    //suppress Unwanted Behaviours
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    suppressHorizontalScroll: true,
    // Master Options
    onGridReady,
    masterDetail: true,
    columnDefs: buyerOpenColsDef,
    defaultColDef: {
      flex: 1,
      cellClass: 'cell-vertical-align-text-left px-po-break-word',
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      sortable: true,
    },
    rowSelection: 'multiple',
    rowBuffer: 10,
    rowStyle: { background: '#eef5ff' },
    immutableData: true,
    getRowNodeId: (data: awards) => data.buyerNumber,
    valueCache: true,
    // Scrolling Options
    debounceVerticalScrollbar: true,
    alwaysShowVerticalScroll: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
    // Grid Events
    onFirstDataRendered: (params: FirstDataRenderedEvent) =>
      setRowDisplayedCount(params.api.getDisplayedRowCount()),
    onRowGroupOpened: (params: RowGroupOpenedEvent) => {
      setRowDisplayedCount(params.api.getDisplayedRowCount());
      params.expanded && params.data.subGrid.length >= 20 && params.api.showLoadingOverlay();
      rowOpenOrCloseMemorizer(setUserLocalStates, tabIndexStates.Open, viewByStates.buyer, params);
      const childIds = params.node.data.childIdsSelected ?? [];
      checkDetailRows(params.node, childIds, viewByStates.buyer);
    },
    onRowSelected: (params: RowSelectedEvent) => {
      if (!isEmpty(params.api.getRowNode(params.node.id)))
        checkStateFromMaster(params, setUserLocalStates, viewByStates.buyer);
    },
    // Detail Options
    detailRowAutoHeight: true,
    detailCellRendererParams: (params: any) => ({
      detailGridOptions: {
        //suppress Unwanted Behaviours
        suppressContextMenu: true,
        suppressRowClickSelection: true,
        suppressCellSelection: true,
        enableCellTextSelection: true,
        suppressMovableColumns: true,
        // Row and Column Options
        columnDefs: buyerOpenDetailsColsDef,
        defaultColDef: {
          flex: 1,
          autoHeight: true,
          wrapText: true,
          cellClass: 'cell-vertical-align-text-left-detail-grid px-po-break-word',
          menuTabs: [],
          sortable: true,
        },
        domLayout: 'autoHeight',
        rowSelection: 'multiple',
        rowBuffer: 10,
        getRowNodeId: (data: awards) => `detail_${data.buyerNumber}_${data.itemElectId}`,
        // Grid Events
        onGridReady: onDetailGridReady,
        onFirstDataRendered: () => params.api.hideOverlay(),
        onRowSelected: (params2: RowSelectedEvent) => {
          let selectedNodes = params2.api.getSelectedNodes().map((node: RowNode) => node.id);
          checkStateFromDetail(
            params.node.parent,
            selectedNodes,
            params2.node,
            setUserLocalStates,
            viewByStates.buyer
          );
        },
        // // detail grid scroll
        // onBodyScroll: (e: BodyScrollEvent) => {
        //   if (e.direction === 'horizontal') {
        //     if (scrolledBy !== 'parent') {
        //       const scrollbars = document.querySelectorAll('.ag-body-horizontal-scroll-viewport');
        //       if (scrollbars.length) {
        //         const tablebdyScroll = scrollbars.length - 1;
        //         // const upperLimit = e.left + 10,
        //         //   lowerLimit = e.left - 10 < 0 ? 0 : e.left - 10;
        //         // scrollbars.forEach((val, index) => {
        //         //   // if (val.scrollLeft <= lowerLimit && val.scrollLeft >= upperLimit)
        //         //   console.log(val.scrollLeft, lowerLimit, upperLimit, val);

        //         //   val.scrollTo(
        //         //     e.left,
        //         //     index === tablebdyScroll ? scrollbars[tablebdyScroll].scrollTop : 0
        //         //   );
        //         // });

        //         scrollbars[tablebdyScroll].scrollTo(e.left, scrollbars[tablebdyScroll].scrollTop);
        //       } else {
        //         document.querySelector('.ag-center-cols-viewport')?.scrollTo(e.left, 0);
        //       }
        //     }

        //     setTimeout(() => {
        //       scrolledBy = 'child';
        //     }, 10);
        //   }
        // },
      },
      getDetailRowData: (params2: any) => params2.successCallback(params2.data.subGrid),
    }),
    // scroll
    // onBodyScroll: (e: BodyScrollEvent) => {
    //   if (e.direction === 'horizontal') {
    //     console.log(e);
    //     if (scrolledBy !== 'child') {
    //       document
    //         .querySelectorAll('.ag-details-row .ag-body-horizontal-scroll-viewport')
    //         .forEach((val) => {
    //           val.scrollTo(e.left, 0);
    //         });
    //     }
    //     setTimeout(() => {
    //       scrolledBy = 'parent';
    //     }, 10);
    //   }
    // },
  };

  const gridOptionsItemOpen: GridOptions = {
    //suppress Unwanted Behaviours
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    suppressHorizontalScroll: true,
    // Master Options
    onGridReady,
    masterDetail: true,
    columnDefs: itemOpenColsDef,
    defaultColDef: {
      flex: 1,
      cellClass: 'cell-vertical-align-text-left px-po-break-word',
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      sortable: true,
    },
    rowSelection: 'multiple',
    rowBuffer: 10,
    rowStyle: { background: '#eef5ff' },
    immutableData: true,
    getRowNodeId: (data: awards) => `${data.itemElectId}`,
    // Scrolling Options
    debounceVerticalScrollbar: true,
    alwaysShowVerticalScroll: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
    // Grid Events
    onRowGroupOpened: (params: RowGroupOpenedEvent) => {
      setRowDisplayedCount(params.api.getDisplayedRowCount());
      params.expanded && params.data.subGrid.length >= 20 && params.api.showLoadingOverlay();
      rowOpenOrCloseMemorizer(setUserLocalStates, tabIndexStates.Open, viewByStates.item, params);
      const childIds = params.node.data.childIdsSelected ?? [];
      checkDetailRows(params.node, childIds, viewByStates.buyer);
    },
    onRowSelected: (params: RowSelectedEvent) => {
      if (!isEmpty(params.api.getRowNode(params.node.id)))
        checkStateFromMaster(params, setUserLocalStates, viewByStates.item);
    },
    onFirstDataRendered: (params: FirstDataRenderedEvent) =>
      setRowDisplayedCount(params.api.getDisplayedRowCount()),
    // Detail Options
    detailRowAutoHeight: true,
    detailCellRendererParams: (params: any) => ({
      detailGridOptions: {
        //suppress Unwanted Behaviours
        suppressContextMenu: true,
        suppressRowClickSelection: true,
        suppressCellSelection: true,
        enableCellTextSelection: true,
        suppressMovableColumns: true,
        // Row and Column Options
        columnDefs: itemOpenDetailsColsDef,
        defaultColDef: {
          flex: 1,
          autoHeight: true,
          wrapText: true,
          cellClass: 'cell-vertical-align-text-left-detail-grid px-po-break-word',
          menuTabs: [],
          sortable: true,
        },
        rowClass: 'px-po-item-subgrid',
        rowSelection: 'multiple',
        domLayout: 'autoHeight',
        getRowNodeId: (data: awards) => `detail_${data.itemElectId}_${data.buyerNumber}`,
        // Grid Events
        onGridReady: onDetailGridReady,
        onFirstDataRendered: () => params.api.hideOverlay(),
        onRowSelected: (params2: RowSelectedEvent) => {
          let selectedNodes = params2.api.getSelectedNodes().map((node: RowNode) => node.id);
          checkStateFromDetail(
            params.node.parent,
            selectedNodes,
            params2.node,
            setUserLocalStates,
            viewByStates.item
          );
        },
      },
      getDetailRowData: (params: any) => params.successCallback(params.data.subGrid),
    }),
  };

  const gridOptionsBuyerClosed: GridOptions = {
    //suppress Unwanted Behaviours
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    suppressHorizontalScroll: true,
    // Master Options
    onGridReady,
    masterDetail: true,
    columnDefs: buyerClosedColsDef,
    defaultColDef: {
      flex: 1,
      cellClass: 'cell-vertical-align-text-left px-po-break-word',
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      sortable: true,
    },
    rowStyle: { background: '#eef5ff' },
    rowBuffer: 10,
    getRowNodeId: (data: awards) => data.buyerNumber,
    // Scrolling Options
    debounceVerticalScrollbar: true,
    alwaysShowVerticalScroll: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
    // Grid Events
    onRowGroupOpened: (params: RowGroupOpenedEvent) => {
      params.expanded && params.data.subGrid.length >= 20 && params.api.showLoadingOverlay();
      rowOpenOrCloseMemorizer(
        setUserLocalStates,
        tabIndexStates.Closed,
        viewByStates.buyer,
        params
      );
    },
    // Detail Options
    detailRowAutoHeight: true,
    detailCellRendererParams: (params: any) => ({
      detailGridOptions: {
        //suppress Unwanted Behaviours
        suppressContextMenu: true,
        suppressRowClickSelection: true,
        suppressCellSelection: true,
        enableCellTextSelection: true,
        suppressMovableColumns: true,
        // Row and Column Options
        columnDefs: buyerClosedDetailsColsDef,
        defaultColDef: {
          flex: 1,
          autoHeight: true,
          wrapText: true,
          cellClass: 'cell-vertical-align-text-left-detail-grid px-po-break-word',
          menuTabs: [],
          sortable: true,
        },
        domLayout: 'autoHeight',
        // Grid Events
        onFirstDataRendered: () => params.api.hideOverlay(),
        getRowNodeId: (data: awards) => `detail_${data.buyerNumber}_${data.itemElectId}`,
      },
      getDetailRowData: (params: any) => params.successCallback(params.data.subGrid),
    }),
  };

  const gridOptionsItemClosed: GridOptions = {
    // Suppress Unwanted Behaviours
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    suppressHorizontalScroll: true,
    // Master Options
    onGridReady,
    masterDetail: true,
    columnDefs: itemClosedColsDef,
    defaultColDef: {
      flex: 1,
      cellClass: 'cell-vertical-align-text-left px-po-break-word',
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      sortable: true,
    },
    rowStyle: { background: '#eef5ff' },
    rowBuffer: 10,
    getRowNodeId: (data: awards) => `${data.itemElectId}`,
    // Scrolling Options
    debounceVerticalScrollbar: true,
    alwaysShowVerticalScroll: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
    // Grid Events
    onRowGroupOpened: (params: RowGroupOpenedEvent) => {
      params.expanded && params.data.subGrid?.length >= 20 && params.api.showLoadingOverlay();
      rowOpenOrCloseMemorizer(setUserLocalStates, tabIndexStates.Closed, viewByStates.item, params);
    },
    // Detail Options
    detailRowAutoHeight: true,
    detailCellRendererParams: (params: any) => ({
      detailGridOptions: {
        // Suppress Unwanted Behaviours
        suppressContextMenu: true,
        suppressRowClickSelection: true,
        suppressCellSelection: true,
        enableCellTextSelection: true,
        suppressMovableColumns: true,
        // Row and Column Options
        columnDefs: itemClosedDetailsColsDef,
        defaultColDef: {
          flex: 1,
          autoHeight: true,
          wrapText: true,
          cellClass: 'cell-vertical-align-text-left-detail-grid px-po-break-word',
          menuTabs: [],
          sortable: true,
        },
        domLayout: 'autoHeight',
        // Grid Events
        onFirstDataRendered: () => params.api.hideOverlay(),
        getRowNodeId: (data: awards) => `detail_${data.itemElectId}_${data.buyerNumber}`,
      },
      getDetailRowData: (params: any) => params.successCallback(params.data.subGrid),
    }),
  };

  const gridOptionsBuyerPending: GridOptions = {
    // Suppress Unwanted Behaviours
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    suppressHorizontalScroll: true,
    // Master Options
    onGridReady,
    masterDetail: true,
    columnDefs: buyerPendingColsDef,
    defaultColDef: {
      flex: 1,
      cellClass: 'cell-vertical-align-text-left px-po-break-word',
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      sortable: true,
    },
    rowStyle: { background: '#eef5ff' },
    rowBuffer: 10,
    getRowHeight: () => 52,
    immutableData: true,
    getRowNodeId: (data: awards) => data.buyerNumber,
    // Scrolling and Loading Options
    debounceVerticalScrollbar: true,
    alwaysShowVerticalScroll: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
    // Grid Events
    onRowGroupOpened: (params: RowGroupOpenedEvent) => {
      params.expanded && params.data.subGrid?.length >= 20 && params.api.showLoadingOverlay();
      rowOpenOrCloseMemorizer(
        setUserLocalStates,
        tabIndexStates.Pending,
        viewByStates.buyer,
        params
      );
    },
    // Detail Options
    detailRowAutoHeight: true,
    detailCellRendererParams: (params: any) => ({
      detailGridOptions: {
        // Suppress Unwanted Behaviours
        suppressContextMenu: true,
        suppressRowClickSelection: true,
        suppressCellSelection: true,
        enableCellTextSelection: true,
        suppressMovableColumns: true,
        // Row and Column Options
        columnDefs: buyerPendingDetailsColsDef,
        defaultColDef: {
          flex: 1,
          autoHeight: true,
          wrapText: true,
          cellClass: 'cell-vertical-align-text-left-detail-grid px-po-break-word',
          menuTabs: [],
          sortable: true,
        },
        domLayout: 'autoHeight',
        // Grid Events
        onFirstDataRendered: () => params.api.hideOverlay(),
        getRowNodeId: (data: awards) => `detail_${data.buyerNumber}_${data.itemElectId}`,
      },
      getDetailRowData: (params: any) => params.successCallback(params.data.subGrid),
    }),
  };

  const gridOptionsItemPending: GridOptions = {
    // Suppress Unwanted Behaviours
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    suppressHorizontalScroll: true,
    // Master Options
    onGridReady,
    masterDetail: true,
    columnDefs: itemPendingColsDef,
    defaultColDef: {
      flex: 1,
      cellClass: 'cell-vertical-align-text-left px-po-break-word',
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      sortable: true,
    },
    rowStyle: { background: '#eef5ff' },
    rowBuffer: 10,
    immutableData: true,
    getRowNodeId: (data: awards) => `${data.itemElectId}`,
    // Scrolling and Loading Options
    debounceVerticalScrollbar: true,
    alwaysShowVerticalScroll: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
    // Grid Events
    onRowGroupOpened: (params: RowGroupOpenedEvent) => {
      params.expanded && params.data.subGrid?.length >= 20 && params.api.showLoadingOverlay();
      rowOpenOrCloseMemorizer(
        setUserLocalStates,
        tabIndexStates.Pending,
        viewByStates.item,
        params
      );
    },
    // Detail Options
    detailRowAutoHeight: true,
    detailCellRendererParams: (params: any) => ({
      detailGridOptions: {
        // Suppress Unwanted Behaviours
        suppressContextMenu: true,
        suppressRowClickSelection: true,
        suppressCellSelection: true,
        enableCellTextSelection: true,
        suppressMovableColumns: true,
        // Row and Column Options
        columnDefs: itemPendingDetailsColsDef,
        defaultColDef: {
          flex: 1,
          autoHeight: true,
          wrapText: true,
          cellClass: 'cell-vertical-align-text-left-detail-grid px-po-break-word',
          menuTabs: [],
          sortable: true,
        },
        domLayout: 'autoHeight',
        // Grid Events
        onFirstDataRendered: () => params.api.hideOverlay(),
        getRowNodeId: (data: awards) => `detail_${data.itemElectId}_${data.buyerNumber}`,
      },
      getDetailRowData: (params: any) => params.successCallback(params.data.subGrid),
    }),
  };

  return {
    gridOptionsBuyerOpen,
    gridOptionsItemOpen,
    gridOptionsBuyerClosed,
    gridOptionsItemClosed,
    gridOptionsBuyerPending,
    gridOptionsItemPending,
  };
};
