import React from 'react';
import SideMenu from '../components/SideMenu/SideMenu';
import { connect } from 'react-redux';

function DashboardLayout(props: any) {
  console.log(props);
  const [autoCloseState, setAutoCloseState] = React.useState(props.isMenuOpened || 0);
  return (
    <>
      <div className="px-v-dashboard grid-x">
        {props.userState.userType === 'ADMIN' && (
          <SideMenu
            userType={props.userState.userType}
            autoCloseState={autoCloseState}
            setAutoCloseState={setAutoCloseState}
            showItemScheduling={props.userState.po_offers_enabled}
          />
        )}
        <main className={`cell auto ${autoCloseState > 0 ? 'px-main-padding' : 'px-home-padding'}`}>
          {props.children}
        </main>
      </div>
    </>
  );
}
function mapStateToProps(state: any) {
  return {
    userState: state.userState,
  };
}

export default connect(mapStateToProps)(DashboardLayout);
