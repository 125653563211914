import { SvgIcon } from '@material-ui/core';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

interface IParam extends ICellRendererParams {
  openPricingAvgGrid?: any;
}

const PricingStats = (params: ICellRendererParams) => {
  const value = params.valueFormatted;
  if (params.node.isRowPinned()) return `${value}`;

  const onClick = (e: React.MouseEvent) => {
    e.persist();
    const res = {
      event: e,
      data: params.data,
      column: params.columnApi,
    };

    if (!res.data) {
      res.data = {
        ...params.node.allLeafChildren[0].data,
        isGroup: true,
      };
    }
    const param: IParam = params;
    param.openPricingAvgGrid(res);
  };
  // 1334971 1638787

  const isGroup = params.node.group;
  let showMenu = !!params.data?.salesTotalDemand;
  if (isGroup) {
    showMenu =
      !!params.node?.childrenAfterFilter?.length &&
      params.node?.childrenAfterFilter[0]?.data?.salesTotalDemand >= 0;
  }
  return (
    <div className={`px-stats-show-icon-container ${isGroup && 'group-asp-tab'}`}>
      {showMenu && (
        <SvgIcon className="pointer-mouse padding-0" onClick={onClick} color="primary">
          <svg
            className="MuiSvgIcon-root jss178 padding-0"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true">
            <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"></path>
          </svg>
        </SvgIcon>
      )}
    </div>
  );
};

export default PricingStats;
