import React, { useEffect } from 'react';

import Logo from '../Logo/Logo';
import { PrimaryIconButton, TertiaryButton } from '../AtomComponents';

import { Link } from 'react-router-dom';

import { Menu, MenuItem } from '@material-ui/core';

export const TopMenuTenant = (props: any) => {
  const { openMenu, logoutMenu, closeMenu, openLogoutDialog } = props;

  useEffect(() => {
    const header = document.getElementsByTagName('header');
    header[0].classList.remove('header-buyer');
  }, []);

  return (
    <>
      <h1 className="show-for-sr text-center">
        {props.configState.tenantShortName || 'ACME'} SASS
      </h1>
      <nav className="px-c-TopMenu grid-cols-12">
        <Link
          to="/"
          onClick={() => (window.location.href = props.configState?.business_tenant_site || '/')}>
          <Logo />
        </Link>
        <ul>
          {props.userState?.isSignedIn && (
            <>
              <li>
                <TertiaryButton onClick={openMenu}>My Account</TertiaryButton>
              </li>
              {props.userState?.firstName && props.userState?.lastName && (
                <li>
                  <PrimaryIconButton onClick={openMenu} className="px-user-initials">
                    {props.userState?.firstName[0]?.toUpperCase()}
                    {props.userState?.lastName[0]?.toUpperCase()}
                  </PrimaryIconButton>
                </li>
              )}
            </>
          )}
        </ul>
      </nav>
      <Menu
        id="simple-menu"
        anchorEl={logoutMenu}
        keepMounted={false}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={!!logoutMenu}
        onClose={closeMenu}>
        <MenuItem
          component="a"
          href={props.configState.business_tenant_site}
          onClick={() => {
            window.location.href = props.configState.business_tenant_site || '/';
          }}>
          Return to main site
        </MenuItem>
        <MenuItem onClick={openLogoutDialog}>
          {' '}
          <span className="link">Logout</span>
        </MenuItem>
      </Menu>
    </>
  );
};
