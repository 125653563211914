import * as types from './actionTypes';

export function inventorySet(inventory: any) {
    return { type: types.INVENTORY_SET, inventory };
}

export function inventoryUpdate(index: number, inventory: any) {
    return {
        type: types.INVENTORY_UPDATE,
        payload: {
            index, inventory
        }
    }
}

export function inventorySettingsSet(settings: any) {
    return { type: types.INVENTORY_SETTINGS_SET, settings };
}
