import { makeStyles, Tooltip } from '@material-ui/core';
import { Info, Warning, Error } from '@material-ui/icons';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { TextField } from '../AtomComponents';

const tooltipStyleError = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'red',
    height: '5px',
    paddingTop: 0,
  },
}));
const tooltipStyleMsg = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'black',
    height: '5px',
    paddingTop: 0,
  },
}));
interface IOfferTextInput {
  name: string;
  label: string;
  onCheck: (
    val: string,
    name: string,
    setVal: any,
    endAdornmentHandler: any,
    setErrMsg: any
  ) => void;
  onClick?: () => {};
  value: string;
  margin?: any;
  variant?: any;
  size?: any;
  inputProps?: any;
  InputProps?: any;
  incrementSize?: number;
}
const OfferTextInput = forwardRef(
  (
    {
      name,
      label,
      onCheck,
      onClick,
      value,
      margin,
      variant,
      size,
      inputProps,
      InputProps,
      incrementSize,
    }: IOfferTextInput,
    ref
  ) => {
    const [val, setVal] = useState<any>(value);
    const [errMsg, setErrMsg] = useState<String>('');
    const [endAdornment, setEndAdornment] = useState<JSX.Element>(<></>);
    const incrementMsg = `Increments of ${incrementSize}`;

    useEffect(() => {
      if (errMsg !== incrementMsg && errMsg !== '') setEndAdornment(getAdornment('error'));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errMsg]);

    const tooltipClassMsg = tooltipStyleMsg();
    const tooltipClassError = tooltipStyleError();

    useEffect(() => {
      if (name === 'quantity') {
        if (incrementSize && incrementSize > 1)
          setEndAdornment(
            <Tooltip
              classes={tooltipClassMsg}
              disableFocusListener
              disableTouchListener
              title={incrementMsg}>
              <Info className="px-message-info" />
            </Tooltip>
          );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    useImperativeHandle(ref, () => {
      return {
        getForcastErrorMessage(errorMessage: string) {
          setErrMsg(errorMessage);
          endAdornmentHandler('error');
        },
      };
    });

    const getAdornment = (endAdornmentStatus: string, extraMsg?: string) => {
      switch (endAdornmentStatus) {
        case 'roundOff': {
          return (
            <Tooltip
              classes={tooltipClassMsg}
              disableFocusListener
              disableTouchListener
              title={extraMsg ? extraMsg : incrementMsg}>
              <Warning className="px-message-warning" />
            </Tooltip>
          );
        }
        case 'error': {
          return (
            <Tooltip
              classes={tooltipClassError}
              disableFocusListener
              disableTouchListener
              title={errMsg}>
              <Error className="errorMessage" color="error" />
            </Tooltip>
          );
        }
        case 'increment': {
          return (
            <Tooltip
              classes={tooltipClassMsg}
              disableFocusListener
              disableTouchListener
              title={incrementMsg}>
              <Info className="px-message-info" />
            </Tooltip>
          );
        }
        case 'default': {
          return <></>;
        }
        default:
          return <></>;
      }
    };

    const endAdornmentHandler = (status: string, extraMsg?: string) =>
      setEndAdornment(getAdornment(status, extraMsg));

    const onBlurHandler = () => {
      onCheck(val, name, setVal, endAdornmentHandler, setErrMsg);
    };

    return (
      <TextField
        name={name}
        label={label}
        onChange={(e) => {
          if (e.target.value === '.') {
          } else setVal(e.target.value);
        }}
        onBlur={onBlurHandler}
        value={val ?? '--'}
        margin={margin ?? undefined}
        error={!!errMsg}
        variant={variant ?? 'standard'}
        size={size ?? undefined}
        onClick={onClick ?? undefined}
        inputProps={inputProps ?? undefined}
        InputProps={{ ...InputProps, endAdornment } ?? undefined}
      />
    );
  }
);
export default OfferTextInput;
