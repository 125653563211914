import React, { useState } from 'react';
import { DialogActions, FormControlLabel, RadioGroup, Tab, Tabs } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { IFilterDialog, INewFilterTabType } from '../../../types/IFilterEngine';
import TextField from '../../AtomComponents/TextField';
import useDataService from '../../../hooks/useDataService';
import { eMessageType } from '../../../types/IMessageType';
import { PrimaryButton, PrimaryRadio, TertiaryButton } from '../../AtomComponents';

export default function FilterNew({
  attribute,
  filterState,
  openDialog,
  closeDialog,
  getLabel,
}: IFilterDialog) {
  const { openSnackBar } = useDataService();
  const [optionsState] = useState<any>(filterState?.presetFilters || []);
  const [selectedOption, setSelectedOption] = useState<{
    title: string;
    attribute: string;
  }>();
  const [searchTermState, setSearchTermState] = useState<any>('');

  const [tabState, setTabState] = useState<INewFilterTabType>(filterState.newFilterTab || 'preset');
  const handleTabChange = (e: any, tab: INewFilterTabType) => setTabState(tab);

  const openSelectedDialog = () => {
    if (selectedOption?.title && selectedOption?.attribute) {
      openDialog({ title: selectedOption?.title, attribute: selectedOption?.attribute });
    } else {
      openSnackBar('Please select filter to add', eMessageType.warning);
    }
  };

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      aria-labelledby="customized-dialog-title"
      className="px-chips-filter-dialog"
      open={!!filterState}>
      <DialogTitle>
        <span className="px-chips-filter-title">Add New Filter</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          {Array.isArray(filterState?.customFilters) && filterState.customFilters.length ? (
            <Tabs
              className="margin-bottom-1 cell small-12"
              value={tabState}
              onChange={handleTabChange}>
              <Tab label="Device Filters" value="preset" />
              <Tab label="Advance Filters" value="custom" />
            </Tabs>
          ) : null}

          {tabState === 'preset' && (
            <>
              <div
                className="cell small-12 margin-bottom-1"
                style={{ display: optionsState.length > 9 ? 'block' : 'none' }}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  className="vertical-middle"
                  aria-describedby="standard-weight-helper-text"
                  placeholder="Search"
                  value={searchTermState}
                  onChange={(e: any) => {
                    setSearchTermState(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    'aria-label': 'search',
                  }}
                />
              </div>
              <div className="grid-x cell small-12 margin-bottom-2">
                {optionsState.length ? (
                  <RadioGroup value={selectedOption?.attribute || ''}>
                    {optionsState.sort().map((val: any) => {
                      let label = getLabel(attribute, val);
                      if (
                        searchTermState.length &&
                        label.toLowerCase().indexOf(searchTermState.toLowerCase()) === -1
                      ) {
                        return null;
                      }

                      const dataVal = { title: label, attribute: val };
                      return (
                        <FormControlLabel
                          label={label.toUpperCase()}
                          value={val}
                          className="margin-formControl px-new-filter-radio cell small-12 px-margin-vertical-8px"
                          key={`filter_category_${val}`}
                          onClick={() => setSelectedOption(dataVal)}
                          control={
                            <PrimaryRadio value={val} className="padding-0" />
                          }></FormControlLabel>
                      );
                    })}
                  </RadioGroup>
                ) : null}
              </div>
            </>
          )}
          {tabState === 'custom' && Array.isArray(filterState?.customFilters) && (
            <RadioGroup value={selectedOption?.attribute || ''}>
              {filterState.customFilters.sort().map((val: any) => {
                let label = getLabel('attribute', val);
                // Search among selected filters
                if (
                  searchTermState.length &&
                  label.toLowerCase().indexOf(searchTermState.toLowerCase()) === -1
                ) {
                  return null;
                }
                // Or return the button for filter option
                const dataVal = { title: label, attribute: val };
                return (
                  <FormControlLabel
                    label={label.toUpperCase()}
                    value={val}
                    className="margin-formControl px-new-filter-radio cell small-12 px-margin-vertical-8px"
                    key={`filter_category_${val}`}
                    onClick={() => setSelectedOption(dataVal)}
                    control={<PrimaryRadio value={val} className="padding-0" />}></FormControlLabel>
                );
              })}
            </RadioGroup>
          )}
        </div>
      </DialogContent>
      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={() => closeDialog({ attribute })}>Cancel</TertiaryButton>
        <PrimaryButton onClick={openSelectedDialog}>Next</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
