import React, { useEffect, useState } from 'react';

import Link from '@material-ui/core/Link';
import { Menu as MenuIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import Logo from '../Logo/Logo';
import { TopMenuBuyerNavItems } from './TopMenuBuyerNavItems';

export const TopMenuBuyer = (props: any) => {
  const { openLogoutDialog, configState, userState } = props;
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean | null>(null);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  const toggleMobileViewMenu = () => {
    if (!isMobileView) return;
    setOpenMobileMenu((prev: boolean | null) => !!!prev);
  };

  useEffect(() => {
    const header = document.getElementsByTagName('header');
    header[0].classList.add('header-buyer');
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 750) setIsMobileView(true);
      else setIsMobileView(false);
    });
    return () => window.removeEventListener('resize', () => {});
  }, []);

  useEffect(() => {
    const header = document.getElementsByTagName('header')[0];
    if (!header) return;
    if (!isMobileView) {
      header.classList.remove('auto-height-mobile');
      return;
    }

    if (openMobileMenu) header.classList.add('auto-height-mobile');
    else if (openMobileMenu === false) header.classList.remove('auto-height-mobile');
  }, [isMobileView, openMobileMenu]);

  return (
    <>
      <nav className={`po-topnav margin-bottom-1`}>
        <div className="po-topnav-container1">
          <Link
            onClick={() => (window.location.href = props.configState?.business_buyer_site || '/')}
            href={`${props.configState?.business_buyer_site || '/'}`}
            component="button"
            className="po-tenant-logo-link">
            <Logo />
          </Link>
        </div>
        {isMobileView && (
          <IconButton onClick={toggleMobileViewMenu} className="po-topnav-menu-icon" disableRipple>
            <MenuIcon fontSize="large" />
          </IconButton>
        )}
        {!isMobileView && (
          <div className="po-topnav-container2">
            <TopMenuBuyerNavItems
              groupClassName="po-topnav-container2"
              menuAnchorPosition={{ vertical: 'bottom', horizontal: 'right' }}
              menuTransformOrigin={{ vertical: 'top', horizontal: 'right' }}
              configState={configState}
              openLogoutDialog={openLogoutDialog}
              showPoOffers={userState.po_offers_enabled}
            />
          </div>
        )}
      </nav>
      {isMobileView && (
        <>
          <div
            className={`po-topnav-mobile-container2 ${
              isMobileView && openMobileMenu ? 'po-topnav-mobile-container2-show' : ''
            }`}>
            <TopMenuBuyerNavItems
              groupClassName="po-topnav-mobile-container2"
              menuAnchorPosition={{ vertical: 'bottom', horizontal: 'right' }}
              menuTransformOrigin={{ vertical: 'top', horizontal: 'left' }}
              configState={configState}
              openLogoutDialog={openLogoutDialog}
              showPoOffers={userState.po_offers_enabled}
            />
          </div>
        </>
      )}
    </>
  );
};
