import {
  Box,
  Chip,
  MenuItem,
  IconButton,
  Popper,
  ClickAwayListener,
  Button,
  MenuList,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React, { useState, useRef } from 'react';
import { PrimaryButton, SecondaryButton, TertiaryButton, TextField } from '../AtomComponents';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import { poOffersStatusChip, poOffersStatusLabelMap } from '../../types/enumToLabelsMap';
import { IPooffersItems, offerStatusValues } from '../../types/IPoOffers';
import OfferTextInput from './OfferTextInput';
import { isEmpty } from 'lodash';
import { formatDateByMonthDay, reofferRule } from '../PreOrders/Utils';
import { dataFormatting } from '../../_lib/lib';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
interface IReOfferItem {
  item: IPooffersItems;
  deleteItem: (item: IPooffersItems, offerId: number[]) => {};
  updateItem: (item: IPooffersItems) => {};
}

const ReOfferItem = (props: IReOfferItem) => {
  const reOfferRef = useRef<any>();

  const { item, deleteItem, updateItem } = props;
  const {
    itemDescription,
    itemNumber,
    offerQuantity,
    offerPrice,
    isTakeAll,
    offerStatus,
    reofferRequestQuantity,
    reofferRequestPrice,
    incrementSize,
    itemStatus,
    durationLeft,
    shippingEndDate,
    shippingStartDate,
  } = item;

  let isFinalized: boolean = itemStatus === 'FINALIZING' ? true : false;

  const [reOfferEdit, setReOfferEdit] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isTakeAllValue, setIsTakeAllValue] = useState<number>(isTakeAll);

  const [updatedValues, setUpdatedValues] = useState<any>({});

  const toggleReOfferEdit = () => {
    setIsTakeAllValue(isTakeAll);
    setUpdatedValues({});
    setReOfferEdit((prev: boolean) => !prev);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = async () => {
    if (isEmpty(updatedValues)) {
      if (reofferRule(offerStatus)) {
        const res = await updateItem({
          ...item,
          offerQuantity: reofferRequestQuantity,
          offerPrice: reofferRequestPrice,
          offerStatus: offerStatusValues.reofferAwaiting,
          isTakeAll: isTakeAllValue,
        });
        if (typeof res === 'string' && res.includes('Available Forecast') && reOfferRef.current) {
          reOfferRef.current.getForcastErrorMessage(res);
        }
      } else {
        const res = await updateItem({
          ...item,
          offerStatus: offerStatusValues.reofferAwaiting,
          isTakeAll: isTakeAllValue,
        });
        if (typeof res === 'string' && res.includes('Available Forecast') && reOfferRef.current) {
          reOfferRef.current.getForcastErrorMessage(res);
        }
      }
    } else {
      const errorMessage = await updateItem({
        ...item,
        ...updatedValues,
        isTakeAll: isTakeAllValue,
        offerStatus: offerStatusValues.reofferAwaiting,
      });
      if (
        typeof errorMessage === 'string' &&
        errorMessage.includes('Available Forecast') &&
        reOfferRef.current
      ) {
        reOfferRef.current.getForcastErrorMessage(errorMessage);
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const validateAndSave = (
    val: any,
    name: string,
    setVal: any,
    endAdornmentHandler: any,
    setErrMsg: any
  ) => {
    endAdornmentHandler('default');
    setErrMsg('');

    let updatedVal;

    if (name === 'quantity') {
      if (typeof val === 'number') val = val.toString();
      updatedVal = parseInt(val);

      if (isNaN(val)) {
        setErrMsg('Value must be a number');
        if (reofferRule(offerStatus)) {
          updatedVal = parseInt(reofferRequestQuantity);
          setVal(reofferRequestQuantity);
        } else {
          updatedVal = parseInt(offerQuantity);
          setVal(offerQuantity);
        }
        endAdornmentHandler('error');
      } else if (val <= 0 || Math.floor(val) === 0) {
        setErrMsg('Value must be > 0');
        if (reofferRule(offerStatus)) {
          updatedVal = parseInt(reofferRequestQuantity);
          setVal(reofferRequestQuantity);
        } else {
          updatedVal = parseInt(offerQuantity);
          setVal(offerQuantity);
        }
        endAdornmentHandler('error');
      } else if (val.includes('.')) {
        let newValue = Math.floor(val);
        updatedVal = newValue;
        setVal(newValue);
        endAdornmentHandler('roundOff', 'Value should not be a decimal');
      } else if (incrementSize > 1) {
        if (val % incrementSize === 0) {
          endAdornmentHandler('increment');
        } else {
          let newValue = Math.floor(val / incrementSize) * incrementSize;
          if (newValue === 0) newValue = incrementSize;
          updatedVal = newValue;
          setVal(newValue);
          endAdornmentHandler('roundOff');
        }
      }
    } else if (name === 'price') {
      const regex = new RegExp('^[0-9]\\d*(((,\\d{3}))*(\\.\\d{0,2})?)$');

      if (typeof val === 'string')
        updatedVal = Number(parseFloat(val.replace(/,/g, '')).toFixed(2));
      else updatedVal = Number(parseFloat(val).toFixed(2));
      setVal(updatedVal.toFixed(2).toString());
      if (val <= 0 || Math.floor(val) === 0) {
        setErrMsg('Value must be > 0');
        if (reofferRule(offerStatus)) {
          updatedVal = Number(parseFloat(reofferRequestPrice).toFixed(2));
          setVal(reofferRequestPrice);
        } else {
          updatedVal = Number(parseFloat(offerPrice).toFixed(2));
          setVal(offerPrice);
        }
        endAdornmentHandler('error');
      } else if (!regex.test(val)) {
        setErrMsg('Must be a valid price');
        if (reofferRule(offerStatus)) {
          updatedVal = Number(parseFloat(reofferRequestPrice).toFixed(2));
          setVal(reofferRequestPrice);
        } else {
          updatedVal = Number(parseFloat(offerPrice).toFixed(2));
          setVal(offerPrice);
        }
        endAdornmentHandler('error');
      }
    }

    setUpdatedValues({
      ...updatedValues,
      [name === 'quantity' ? 'offerQuantity' : 'offerPrice']: updatedVal,
    });
    return;
  };

  return (
    <>
      <Box className="cell small-12 px-po-card-body hover-bg">
        <div className="grid-x">
          <div className="cell small-4 px-po-offers-card-desc">
            <h6 className="bold padding-bottom-1">{itemDescription}</h6>
            <p className="margin-0">Item #: {itemNumber}</p>
            <div className="cell auto shrink margin-right-2 faded_1 px-shipping-icon">
              <LocalShippingOutlinedIcon className="vertical-middle px-margin-right-5" />
              <span className="px-shipping">
                {formatDateByMonthDay(shippingStartDate, shippingEndDate) || '- -'}
              </span>
              <span className=" padding-left-2 faded_1">
                {!isFinalized ? (
                  <>
                    <AccessAlarmOutlinedIcon className="vertical-middle px-margin-right-5" />{' '}
                    {`${durationLeft} left`}
                  </>
                ) : (
                  <>
                    <PanToolOutlinedIcon className="vertical-middle px-margin-right-5" />
                    {'Current Offer Stands'}
                  </>
                )}
              </span>
            </div>
          </div>
          <div className="grid-x cell small-8">
            <div className="cell small-3 px-po-offers-card-data margin-left-1">
              <span className="faded_1">Status</span>
              <Chip
                label={poOffersStatusLabelMap[offerStatus]}
                className={`${poOffersStatusChip[offerStatus]} ${
                  !isFinalized ? 'px-filter-on' : 'px-filter-off'
                }`}
                size="small"
              />
            </div>
            {!reOfferEdit ? (
              <>
                <div className="cell small-2 px-po-offers-card-data">
                  <span className="faded_1 margin-bottom-1">Your Offer</span>
                  <span>
                    {/* {offerQuantity} @ ${offerPrice} */}
                    {offerQuantity} @ {dataFormatting('currency', offerPrice)}
                  </span>
                </div>
                <div className="cell small-2 px-po-offers-card-data">
                  {reofferRule(offerStatus) ? (
                    <>
                      <span className="faded_1 margin-bottom-1">Re-Offer Request</span>
                      <span>
                        {/* {reofferRequestQuantity ?? '--'} @ ${reofferRequestPrice ?? ' --'} */}
                        {reofferRequestQuantity ?? '--'} @{' '}
                        {reofferRequestPrice
                          ? dataFormatting('currency', reofferRequestPrice)
                          : ' --'}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="cell small-1 margin-right-2 margin-left-1 px-po-offers-card-data">
                  <span className="faded_1 margin-bottom-1">Take All</span>
                  <span>{isTakeAll === 1 ? 'YES' : ' NO'}</span>
                </div>
                <div className="cell small-2 px-po-offers-card-data">
                  {reofferRule(offerStatus) ? (
                    <PrimaryButton
                      size="small"
                      onClick={toggleReOfferEdit}
                      className="reofferClass"
                      disabled={isFinalized}
                      style={
                        isFinalized
                          ? { backgroundColor: 'RGB(var(--px_color_text_muted))', color: '#ffffff' }
                          : {}
                      }>
                      RE-OFFER
                    </PrimaryButton>
                  ) : (
                    <TertiaryButton
                      size="small"
                      disabled={isFinalized}
                      onClick={toggleReOfferEdit}
                      className="padding-1">
                      RE-OFFER
                    </TertiaryButton>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                {!isFinalized ? (
                  <div
                    className={`grid-x cell auto text-center align-middle align-center margin-left-1 margin-bottom-2`}>
                    <IconButton className={'padding-0'} aria-describedby={id} onClick={handleClick}>
                      <CancelIcon color="primary" />
                    </IconButton>
                    <Popper
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      placement="bottom-start"
                      style={{ zIndex: 5, position: 'absolute' }}
                      disablePortal={true}
                      modifiers={{
                        flip: {
                          enabled: false,
                        },
                      }}>
                      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <div>
                          <Button
                            color="primary"
                            className="padding-1"
                            onClick={() => {
                              deleteItem(item, [item.offerId]);
                              setAnchorEl(null);
                            }}>
                            Delete
                          </Button>
                        </div>
                      </ClickAwayListener>
                    </Popper>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <div className="cell small-2 px-po-offers-card-data">
                  {/* <TextField
                    name="quantity"
                    label="Quantity"
                    onChange={() => {}}
                    value={
                      offerStatus === IOffersTab.reoffer
                        ? reofferRequestQuantity ?? '--'
                        : offerQuantity
                    }
                    margin="normal"
                    variant="outlined"
                    size="small"
                  /> */}
                  <OfferTextInput
                    name="quantity"
                    label="Quantity"
                    onCheck={validateAndSave}
                    value={
                      reofferRule(offerStatus) ? reofferRequestQuantity ?? '--' : offerQuantity
                    }
                    margin="normal"
                    variant="outlined"
                    size="small"
                    incrementSize={incrementSize}
                    ref={reOfferRef}
                  />
                </div>
                <div className="cell small-2 px-po-offers-card-data">
                  {/* <TextField
                    name="price"
                    label="Price"
                    onChange={() => {}}
                    value={
                      offerStatus === IOffersTab.reoffer ? reofferRequestPrice ?? '--' : offerPrice
                    }
                    InputProps={{
                      startAdornment: '$',
                    }}
                    margin="normal"
                    variant="outlined"
                    size="small"
                  /> */}
                  <OfferTextInput
                    name="price"
                    label="Price"
                    onCheck={validateAndSave}
                    value={
                      reofferRule(offerStatus) ? reofferRequestPrice.toFixed(2) ?? '--' : offerPrice
                    }
                    InputProps={{ startAdornment: '$' }}
                    margin="normal"
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="px-po-offers-card-data">
                  <TextField
                    name="Take All"
                    label="Take All"
                    value={isTakeAllValue === 1 ? 'YES' : 'NO'}
                    className="take-all-textField"
                    inputProps={{ readOnly: true }}
                    onClick={handleClick}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: !open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />,
                    }}
                  />
                  <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    style={{ zIndex: 5, position: 'absolute', width: '90px', cursor: 'pointer' }}
                    disablePortal={true}
                    modifiers={{
                      flip: {
                        enabled: false,
                      },
                    }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList>
                        <MenuItem
                          key={1}
                          value={1}
                          onClick={() => {
                            handleClose();
                            setIsTakeAllValue(1);
                          }}>
                          YES
                        </MenuItem>
                        <MenuItem
                          key={0}
                          value={0}
                          onClick={() => {
                            handleClose();
                            setIsTakeAllValue(0);
                          }}>
                          NO
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Popper>
                </div>
                <div className="cell small-3 padding-top-1 text-right">
                  <SecondaryButton size="small" className="margin-right-1" onClick={handleUpdate}>
                    OFFER
                  </SecondaryButton>
                  <TertiaryButton size="small" onClick={toggleReOfferEdit}>
                    CANCEL
                  </TertiaryButton>
                </div>
              </>
            )}
          </div>
        </div>
      </Box>
    </>
  );
};

export default ReOfferItem;
