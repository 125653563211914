import * as types from '../actions/actionTypes';
import { logOutTheUser } from '../../_lib/lib';

export const preFilterMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === types.USER_SET) {
    if (action.user.userType === 'ADMIN' || action.user.userType === 'BUYER') {
      next(action);
    } else {
      console.log('here');
      logOutTheUser(
        'You have logged into wrong website. Kindly login into {website}',
        'error',
        'template'
      );
    }
  } else {
    next(action);
  }
};
