import { Chip } from '@material-ui/core';
import { Cancel, Create } from '@material-ui/icons';
import React from 'react';
import { IFilterDialogOpenFn } from '../../types/IFilterEngine';
import { TertiaryButton } from '../AtomComponents';

interface IFilterChip {
  label: string;
  attribute: string;
  isFilterOn?: boolean;
  isRequiredFilter: boolean;
  openDialog: IFilterDialogOpenFn;
  deleteFilter?: IFilterDialogOpenFn;
}

function FilterChip({
  attribute,
  label,
  isRequiredFilter,
  isFilterOn,
  openDialog,
  deleteFilter,
}: IFilterChip) {
  const onDeleteFilter = () => {
    if (isRequiredFilter) {
      openDialog({ title: label, attribute });
    } else {
      if (deleteFilter) deleteFilter({ title: label, attribute });
    }
  };

  if (attribute === 'outOfStock') {
    return (
      <Chip
        label={label}
        id="chip_outOfStock"
        color="primary"
        variant="outlined"
        className={`px-reset-font-weights px-chips-filter-toggle px-pointer margin-bottom-1 ${
          isFilterOn ? 'px-filter-on' : false
        }`}
        onClick={() => openDialog({ title: label, attribute })}
      />
    );
  }
  return (
    <TertiaryButton
      disableRipple
      disableElevation
      disableFocusRipple
      data-id={attribute}
      onClick={() => openDialog({ title: label, attribute, from: 'filterChip' })}
      className="px-reset-font-weights px-no-bg no-padding px-pointer px-chips-filter-toggle margin-bottom-1">
      <Chip
        color="primary"
        variant="outlined"
        label={label}
        deleteIcon={!isRequiredFilter ? <Cancel /> : <Create />}
        className={`no-padding ${isFilterOn ? '' : 'px-filter-on'}`}
        onDelete={onDeleteFilter}
      />
    </TertiaryButton>
  );
}

export default FilterChip;
