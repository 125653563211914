import { TextField as InputField, TextFieldProps } from '@material-ui/core';
import React from 'react';
// interface ITextField {
//   children: any;
//   InputProps: any;
//   [props: string]: TextFieldProps;
// }

function TextField({ children, ...props }: TextFieldProps) {
  return (
    <InputField color="primary" variant="outlined" {...props}>
      {children}
    </InputField>
  );
}

export default TextField;
