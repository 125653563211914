import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';

// Custom Components
import GridInput from '../../GridInput/GridInput';

// Helper Functions and Components
import { dateFormats, getAggregatedSumOfFields, getLatestDate, manipulateDates } from '../Utils';
import {
  fullyAwarded,
  setDateEditable,
  unAllocated,
  recommendedCal,
  itemsBadge,
  customSorting,
} from '../TableCellComponents';

// Types
import { awards, ColumnDefinitions, tabIndexStates, viewByStates } from '../../../types/IPreOrders';

export const getOpenColumnDefinitions = ({
  updateHandler,
  canManagePoOrder,
}: ColumnDefinitions) => {
  const buyerOpenColsDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Buyers',
      field: 'buyerName',
      cellRenderer: 'agGroupCellRenderer',
      headerClass: 'px-po-grid-header-align',
      cellClass: (params: CellClassParams) =>
        `cell-vertical-align-text-left-detail-grid px-po-break-word ${
          canManagePoOrder ? '' : 'disable-field'
        } `,
      // ${
      //   !isEmpty(params.data.availableFundConfirmedTimeStamp) ? '' : 'disable-checkbox'
      // }`,
      cellRendererParams: {
        checkbox: true,
      },
      minWidth: 250,
    },
    // {
    //   headerName: '',
    //   field: 'isFundingRequired',
    //   headerClass: 'px-po-grid-second',
    //   cellRendererParams: (params: ICellRendererParams) => ({
    //     updateHandler,
    //     isCheckBox: true,
    //     // disabled: !params.data.availableFundConfirmedTimeStamp,
    //     disable: false,
    //     checkBoxLabel: 'Funding Required',
    //   }),
    //   cellRendererFramework: GridInput,
    //   cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
    //   minWidth: 100,
    // },
    {
      headerName: 'Items',
      field: 'items',
      valueGetter: (params: ValueGetterParams) => params.data.subGrid.length,
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Starting Fulfilled',
      field: 'quantityFulfilled',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'New Fulfilled',
      field: 'quantityReadyToFulfill',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Ending Fulfilled',
      field: 'endingFulfilled',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        }),
      minWidth: 100,
    },
    {
      headerName: '% Ending Fulfilled',
      field: 'percentageEndingFulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedAwarded = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: 'awardedQuantity',
        });
        if (aggregatedAwarded === 0) return (0).toFixed(2);
        const aggregatedEndingFulfilled = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        });
        return ((aggregatedEndingFulfilled / aggregatedAwarded) * 100).toFixed(2);
      },
      minWidth: 100,
      comparator: customSorting,
    },
    // {
    //   headerName: 'Starting Funds',
    //   field: 'startingFund',
    //   valueFormatter: (params: ValueFormatterParams) =>
    //     dataFormatting('currency', params.value, true),
    //   cellStyle: (params: CellClassParams) => remainingCellRules(params),
    //   comparator: customSorting,
    //   minWidth: 100,
    // },
    // {
    //   headerName: 'Ending Funds',
    //   field: 'endingFund',
    //   valueGetter: (params: ValueGetterParams) => {
    //     const endingFunds = getAggregatedSumOfFields({
    //       dataSet: params.data.subGrid,
    //       middlewareFn: (item: awards) => item.awardedPrice * item.quantityReadyToFulfill,
    //     });
    //     return dataFormatting('currency', params.data.startingFund - endingFunds, true);
    //   },
    //   cellStyle: (params: CellClassParams) => remainingCellRules(params),
    //   comparator: customSorting,
    //   minWidth: 100,
    // },
    // {
    //   headerName: 'Est Funding Need',
    //   valueGetter: (params: ValueGetterParams) =>
    //     getAggregatedSumOfFields({
    //       dataSet: params.data.subGrid,
    //       middlewareFn: (item: awards) => item.awardedPrice * item.quantityReadyToFulfill,
    //     }),
    //   valueFormatter: (params: ValueFormatterParams) =>
    //     dataFormatting('currency-with-no-0', params.value, true),
    //   minWidth: 100,
    // },
    {
      headerName: 'Last Shipped Date',
      field: 'shippingEndDate',
      valueGetter: (params: ValueGetterParams) => {
        return getLatestDate({
          dataSet: params.data.subGrid,
          formatTo: dateFormats.monthIn3Letters,
        });
      },
      comparator: customSorting,
      minWidth: 150,
    },
  ];

  const buyerOpenDetailsColsDef: Array<ColDef | ColGroupDef> = [
    {
      cellRendererFramework: itemsBadge,
      cellRendererParams: { viewByState: viewByStates.buyer },
      cellClass: 'buyerItemBadge',
      minWidth: 25,
      maxWidth: 25,
      pinned: 'left',
    },
    {
      headerName: 'Item',
      field: 'phonexItemDescription',
      checkboxSelection: true,
      cellClass: (params: CellClassParams) =>
        `px-po-grid-longtext cell-vertical-align-text-left-detail-grid px-po-break-word ${
          canManagePoOrder ? '' : 'disable-field'
        } `,
      // ${
      //   !isEmpty(params.data.availableFundConfirmedTimeStamp) ? '' : 'disable-checkbox'
      // }`,
      minWidth: 300,
      pinned: 'left',
    },
    {
      headerName: 'Warehouse',
      field: 'warehouse',
      minWidth: 90,
    },
    {
      headerName: 'SKU',
      field: 'itemNumber',
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      minWidth: 100,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ?? 0;
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        tabIndexState: tabIndexStates.Open,
        updateHandler,
      }),
      cellRendererFramework: GridInput,
    },
    {
      headerName: 'Price',
      field: 'awardedPrice',
      minWidth: 100,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? Number(params.value).toFixed(2) : 0;
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        startAdornment: {
          symbol: '$',
        },
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        tabIndexState: tabIndexStates.Open,
        updateHandler,
      }),
      cellRendererFramework: GridInput,
    },
    {
      headerName: 'Take All?',
      field: 'isTakeAll',
      minWidth: 120,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? 'Y' : 'N';
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        takeAll: true,
        tabIndexState: tabIndexStates.Open,
        updateHandler,
      }),
      cellRendererFramework: GridInput,
    },
    {
      headerName: 'Take % Limit',
      field: 'takeAllLimitPercentage',
      minWidth: 120,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ?? 0;
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        updateHandler,
        tabIndexState: tabIndexStates.Open,
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        disabled: params.data.isTakeAll === false,
      }),
      cellRendererFramework: GridInput,
    },
    {
      headerName: 'Starting Fulfilled',
      field: 'quantityFulfilled',
      minWidth: 120,
    },
    {
      headerName: 'Starting Available',
      field: 'quantityAvailable',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ?? 0;
      },
      minWidth: 100,
    },
    {
      headerName: 'New Fulfilled',
      field: 'quantityReadyToFulfill',
      minWidth: 100,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ?? 0;
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        tabIndexState: tabIndexStates.Open,
        updateHandler,
        recommendedCal,
      }),
      cellRendererFramework: GridInput,
    },
    {
      headerName: 'Ending Fulfilled',
      field: 'endingFulfilledDetail',
      valueGetter: (params: ValueGetterParams) =>
        params.data.quantityReadyToFulfill + params.data.quantityFulfilled,
      minWidth: 120,
    },
    {
      headerName: 'Target % of Ending Fulfilled',
      field: 'targetPercentageEndingFulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedAwarded: number = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: 'awardedQuantity',
        });
        if (aggregatedAwarded === 0) return (0).toFixed(2);
        return ((params.data.awardedQuantity / aggregatedAwarded) * 100).toFixed(2);
      },
      resizable: true,
      comparator: customSorting,
      minWidth: 120,
    },
    {
      headerName: 'Actual % of Ending Fulfilled',
      field: 'actual_per_ending_fulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedEndingFulfilled: number = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        });
        if (aggregatedEndingFulfilled === 0) return (0).toFixed(2);
        const endingFulfilled = params.data.quantityFulfilled + params.data.quantityReadyToFulfill;
        return ((endingFulfilled / aggregatedEndingFulfilled) * 100).toFixed(2);
      },
      resizable: true,
      comparator: customSorting,
      minWidth: 120,
    },
    {
      headerName: 'Shipping Start Date',
      field: 'shippingStartDate',
      valueFormatter: (params: ValueFormatterParams) =>
        manipulateDates({
          date: params.value,
          manipulate: { days: 0 },
          currentFormat: dateFormats.yearMonthDate,
          formatTo: dateFormats.monthIn3Letters,
        }),
      resizable: true,
      comparator: customSorting,
      minWidth: 140,
    },
    {
      headerName: 'Shipping End Date',
      field: 'shippingEndDate',
      minWidth: 160,
      valueFormatter: (params: ValueFormatterParams) =>
        manipulateDates({
          date: params.value,
          manipulate: { days: 0 },
          currentFormat: dateFormats.yearMonthDate,
          formatTo: dateFormats.monthIn3Letters,
        }),
      cellRendererParams: (params: ICellRendererParams) => ({
        updateHandler,
        disabled: {
          before: manipulateDates({
            date: new Date().toString(),
            manipulate: { days: 1 },
            currentFormat: dateFormats.timestamp,
            formatTo: dateFormats.yearMonthDate,
          }),
        },
      }),
      cellRendererFramework: setDateEditable,
      comparator: customSorting,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
    },
  ];

  const itemOpenColsDef: Array<ColDef | ColGroupDef> = [
    {
      cellRendererFramework: itemsBadge,
      cellRendererParams: { viewByState: viewByStates.item },
      minWidth: 25,
      maxWidth: 25,
    },
    {
      headerName: 'Item',
      field: 'phonexItemDescription',
      cellClass: (params: CellClassParams) =>
        `cell-vertical-align-text-left-detail-grid px-po-break-word ${
          canManagePoOrder ? '' : 'disable-field'
        }`,
      // ${
      //   !isEmpty(params.data.availableFundConfirmedTimeStamp) ? '' : 'disable-checkbox'
      // }`,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: { checkbox: true },
      minWidth: 350,
    },
    {
      headerName: 'Warehouse',
      field: 'warehouse',
      minWidth: 120,
    },
    {
      headerName: 'SKU',
      field: 'itemNumber',
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Starting Fulfilled',
      field: 'quantityFulfilled',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Starting Available',
      field: 'quantityAvailable',
      valueFormatter: (params: ValueFormatterParams) => params.value ?? 0,
      minWidth: 100,
    },
    {
      headerName: 'New Fulfilled',
      field: 'quantityReadyToFulfill',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: params.column.getColId(),
        }),
      minWidth: 100,
    },
    {
      headerName: 'Ending Fulfilled',
      field: 'endingFulfilled',
      valueGetter: (params: ValueGetterParams) =>
        getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        }),
      minWidth: 100,
    },
    {
      headerName: 'Ending Available',
      field: 'endingAvailable',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedNewFulfilled = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: 'quantityReadyToFulfill',
        });
        return params.data.quantityAvailable - aggregatedNewFulfilled;
      },
      cellStyle: unAllocated,
      minWidth: 100,
    },
    {
      headerName: '% Ending Fulfilled',
      field: 'percentageEndingFulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedAwarded = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: 'awardedQuantity',
        });
        if (aggregatedAwarded === 0) return (0).toFixed(2);
        const aggregatedEndingFulfilled = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        });
        return ((aggregatedEndingFulfilled / aggregatedAwarded) * 100).toFixed(2);
      },
      comparator: customSorting,
      minWidth: 100,
    },
    {
      headerName: 'Shipping Start Date',
      field: 'shippingStartDate',
      valueFormatter: (params: ValueFormatterParams) =>
        manipulateDates({
          date: params.value,
          manipulate: { days: 0 },
          currentFormat: dateFormats.yearMonthDate,
          formatTo: dateFormats.monthIn3Letters,
        }),
      comparator: customSorting,
      minWidth: 150,
    },
    {
      headerName: 'Shipping End Date',
      field: 'shippingEndDate',
      valueFormatter: (params: ValueFormatterParams) =>
        manipulateDates({
          date: params.value,
          manipulate: { days: 0 },
          currentFormat: dateFormats.yearMonthDate,
          formatTo: dateFormats.monthIn3Letters,
        }),
      cellRendererParams: (params: ICellRendererParams) => ({
        updateHandler,
        disabled: {
          before: manipulateDates({
            date: new Date().toString(),
            manipulate: { days: 1 },
            currentFormat: dateFormats.timestamp,
            formatTo: dateFormats.yearMonthDate,
          }),
        },
      }),
      cellRendererFramework: setDateEditable,
      minWidth: 150,
      comparator: customSorting,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
    },
  ];

  const itemOpenDetailsColsDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Customer',
      field: 'buyerName',
      checkboxSelection: true,
      cellClass: (params: CellClassParams) =>
        `cell-vertical-align-text-left-detail-grid px-po-break-word ${
          canManagePoOrder ? '' : 'disable-field'
        }`,
      // ${
      //   !isEmpty(params.data.availableFundConfirmedTimeStamp) ? '' : 'disable-checkbox'
      // }`,
      minWidth: 150,
      pinned: 'left',
    },
    // {
    //   headerName: 'Funding Required',
    //   field: 'isFundingRequired',
    //   cellRendererFramework: GridInput,
    //   cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
    //   cellRendererParams: (params: ICellRendererParams) => ({
    //     updateHandler,
    //     isCheckBox: true,
    //     // disabled: !params.data.availableFundConfirmedTimeStamp,
    //     disabled: false,
    //     checkBoxLabel: ' ',
    //     disablePointerAction: true,
    //   }),
    //   minWidth: 100,
    // },
    {
      headerName: 'Fully Awarded',
      field: 'fullyAwarded',
      cellRendererFramework: fullyAwarded,
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      minWidth: 100,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ?? 0;
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        tabIndexState: tabIndexStates.Open,
        updateHandler,
      }),
      cellRendererFramework: GridInput,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
    },
    {
      headerName: 'Price',
      field: 'awardedPrice',
      minWidth: 100,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? Number(params.value).toFixed(2) : 0;
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        startAdornment: {
          symbol: '$',
        },
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        tabIndexState: tabIndexStates.Open,
        updateHandler,
      }),
      cellRendererFramework: GridInput,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
    },
    {
      headerName: 'Take All?',
      field: 'isTakeAll',
      minWidth: 100,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? 'Y' : 'N';
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        tabIndexState: tabIndexStates.Open,
        takeAll: true,
        updateHandler,
      }),
      cellRendererFramework: GridInput,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
    },
    {
      headerName: 'Take % Limit',
      field: 'takeAllLimitPercentage',
      minWidth: 100,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ?? 0;
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        updateHandler,
        tabIndexState: tabIndexStates.Open,
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        disabled: params.data.isTakeAll === false,
      }),
      cellRendererFramework: GridInput,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
    },
    {
      headerName: 'Starting Fulfilled',
      field: 'quantityFulfilled',
      minWidth: 120,
    },
    {
      headerName: 'New Fulfilled',
      field: 'quantityReadyToFulfill',
      minWidth: 120,
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ?? 0;
      },
      cellRendererParams: (params: ICellRendererParams) => ({
        // textOnly: !params.data.availableFundConfirmedTimeStamp,
        tabIndexState: tabIndexStates.Open,
        updateHandler,
        recommendedCal,
      }),
      cellRendererFramework: GridInput,
      cellClass: () => `${canManagePoOrder ? '' : 'disable-field'}`,
    },
    {
      headerName: 'Ending Fulfilled',
      field: 'endingFulfilledDetail',
      valueGetter: (params: ValueGetterParams) =>
        params.data.quantityReadyToFulfill + params.data.quantityFulfilled,
      minWidth: 100,
    },
    // {
    //   headerName: 'Starting Funds',
    //   field: 'startingFund',
    //   valueFormatter: (params: ValueFormatterParams) =>
    //     dataFormatting('currency', params.value, true),
    //   cellStyle: (params: CellClassParams) => remainingCellRules(params),
    //   minWidth: 100,
    //   comparator: customSorting,
    // },
    // {
    //   headerName: 'Ending Funds',
    //   field: 'endingFund',
    //   valueGetter: (params: ValueGetterParams) => {
    //     const endingFunds = getAggregatedSumOfFields({
    //       dataSet: params.data.subGridByBuyer,
    //       middlewareFn: (item: awards) => item.awardedPrice * item.quantityReadyToFulfill,
    //     });
    //     return dataFormatting('currency', params.data.startingFund - endingFunds, true);
    //   },
    //   cellStyle: (params: CellClassParams) => remainingCellRules(params),
    //   minWidth: 100,
    //   comparator: customSorting,
    // },
    {
      headerName: 'Target % of Ending Fulfilled',
      field: 'targetPercentageEndingFulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedAwarded: number = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          field: 'awardedQuantity',
        });
        if (aggregatedAwarded === 0) return (0).toFixed(2);
        return ((params.data.awardedQuantity / aggregatedAwarded) * 100).toFixed(2);
      },
      comparator: customSorting,
      minWidth: 130,
    },
    {
      headerName: 'Actual % of Ending Fulfilled',
      field: 'actual_per_ending_fulfilled',
      valueGetter: (params: ValueGetterParams) => {
        const aggregatedEndingFulfilled: number = getAggregatedSumOfFields({
          dataSet: params.data.subGrid,
          middlewareFn: (item: awards) => item.quantityReadyToFulfill + item.quantityFulfilled,
        });
        if (aggregatedEndingFulfilled === 0) return (0).toFixed(2);
        const endingFulfilled = params.data.quantityFulfilled + params.data.quantityReadyToFulfill;
        return ((endingFulfilled / aggregatedEndingFulfilled) * 100).toFixed(2);
      },
      minWidth: 130,
      comparator: customSorting,
    },
  ];

  return { buyerOpenColsDef, buyerOpenDetailsColsDef, itemOpenColsDef, itemOpenDetailsColsDef };
};
