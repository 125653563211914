import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ArrowBackIos, Close } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import React, { useContext, useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { I18nContext } from '../../../I18n';
import { IFilterCount, IFilterDialog, IFilterOptions } from '../../../types/IFilterEngine';
import { checkNullOrUndefined } from '../../../_lib/lib';
import {
  PrimaryButton,
  PrimaryCheckbox,
  PrimaryIconButton,
  TertiaryButton,
  TextField,
} from '../../AtomComponents';

export default function FilterAttribute({
  presetOptionObj,
  title,
  attribute,
  filterState,
  clearFilter,
  saveFilter,
  closeDialog,
  openDialog,
}: IFilterDialog) {
  const I18n = useContext(I18nContext);

  const [searchTermState, setSearchTermState] = useState<any>('');
  const debounce = useDebounce(searchTermState, 600);

  const [filterOptions, setFilterOptions] = useState<IFilterOptions>();
  const [totalAndCheckedOptions, setTotalAndCheckedOptions] = useState<IFilterCount>({
    total: 0,
    checked: 0,
  });

  const [searchOptions, setSearchOptions] = useState<Array<string>>();

  useEffect(() => {
    if (!presetOptionObj || !filterState?.filterLabel) return;
    const totalAndChecked = {
      total: Object.keys(presetOptionObj).length,
      checked: 0,
    };
    const updatedOptionsObj: any = {};
    Object.keys(presetOptionObj).forEach((val) => {
      if (presetOptionObj[val]) totalAndChecked.checked++;
      if (filterState.filterLabel[val]) updatedOptionsObj[val] = presetOptionObj[val];
    });
    if (totalAndChecked.total === totalAndChecked.checked) {
      // check off select all
      totalAndChecked.checked = 0;
      // select none
      Object.keys(updatedOptionsObj).forEach((val) => {
        updatedOptionsObj[val] = false;
      });
    }
    setSearchOptions(Object.keys(updatedOptionsObj));
    setFilterOptions(updatedOptionsObj);
    setTotalAndCheckedOptions(totalAndChecked);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetOptionObj, filterState?.filterLabel]);

  useEffect(() => {
    if (filterOptions) {
      setSearchOptions(
        Object.keys(filterOptions).filter((val) => {
          const label = filterState?.filterLabel[val];
          return label && filterState?.filterLabel[val]?.indexOf(debounce) > -1;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const handleChange = (e: any) => {
    if (!filterOptions || checkNullOrUndefined(filterOptions[e.target.name])) {
      return;
    }
    const finalVal = !filterOptions[e.target.name];
    setFilterOptions({
      ...filterOptions,
      [e.target.name]: finalVal,
    });
    setTotalAndCheckedOptions({
      total: totalAndCheckedOptions.total,
      checked: totalAndCheckedOptions.checked + (finalVal ? +1 : -1),
    });
  };

  const handleSearch = (e: any) => setSearchTermState(e.target.value);

  const handleSave = () => {
    if (filterOptions) {
      saveFilter({
        title: title,
        attribute: attribute,
        selectedFilters: filterOptions,
        checkedFilters: totalAndCheckedOptions.checked,
      });
    } else {
      handleClear();
    }
  };

  const handleClear = () => {
    clearFilter({
      title: title,
      attribute: attribute,
    });
  };

  const clearSearchField = () => {
    setSearchTermState('');
  };

  const handleSelectAll = (e: any) => {
    if (filterOptions) {
      const updatedFilterOptions: IFilterOptions = {};
      const updatedFiterCount: IFilterCount = {
        total: totalAndCheckedOptions.total,
        checked: 0,
      };
      Object.keys(filterOptions).forEach((key) => {
        if (filterState?.filterLabel[key].indexOf(debounce) === -1) {
          updatedFilterOptions[key] = false;
          return;
        }
        updatedFilterOptions[key] = e.target.checked;
        if (e.target.checked) {
          updatedFiterCount.checked++;
        }
      });

      setTotalAndCheckedOptions(updatedFiterCount);
      setFilterOptions(updatedFilterOptions);
    }
  };

  return (
    <Dialog
      data-id={attribute}
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!presetOptionObj}>
      <DialogTitle className="px-one-line-data padding-horizontal-1">
        {!filterState.isApplied && (
          <PrimaryIconButton
            className="margin-0 padding-left-1"
            onClick={() => openDialog({ title: 'New Filter', attribute: '+ NEW' })}>
            <ArrowBackIos />
          </PrimaryIconButton>
        )}
        <span className="px-chips-filter-title">{title.split(':')[0].toLocaleUpperCase('en')}</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          <div
            className={`cell small-12 margin-bottom-1 ${
              filterOptions && Object.keys(filterOptions).length < 9 ? 'px-display-none' : ''
            }`}>
            <TextField
              size="small"
              className="vertical-middle"
              aria-describedby="standard-weight-helper-text"
              placeholder="Search"
              value={searchTermState}
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {debounce ? (
                      <Close onClick={clearSearchField} className="pointer-mouse" />
                    ) : (
                      <></>
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                'aria-label': 'search',
              }}
            />
          </div>
          <FormControlLabel
            className="cell small-12"
            control={
              <PrimaryCheckbox
                checked={totalAndCheckedOptions.checked === searchOptions?.length}
                indeterminate={
                  totalAndCheckedOptions.checked !== 0 &&
                  totalAndCheckedOptions.checked !== searchOptions?.length
                }
                disabled={totalAndCheckedOptions.total <= 0}
                onClick={handleSelectAll}
                onChange={handleSelectAll}
                name="selectAll"
              />
            }
            label={`(${I18n?.selectAll?.i18n_value || 'Select All'})`}
          />
          <div className="cell small-12">
            {filterOptions && searchOptions && searchOptions.length > 0 ? (
              <>
                {searchOptions.sort().map((val: any) => {
                  return (
                    <div className="cell small-12" key={`filter_${attribute}_${val}`}>
                      <FormControlLabel
                        control={
                          <PrimaryCheckbox
                            checked={filterOptions[val]}
                            onChange={handleChange}
                            data-id={val}
                            name={val}
                          />
                        }
                        label={filterState?.filterLabel[val]}
                      />
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>Clear</TertiaryButton>
        <PrimaryButton
          onClick={handleSave}
          disabled={Object.keys(filterOptions ?? {}).every(
            (item) => filterOptions && !filterOptions[item]
          )}>
          APPLY
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}
