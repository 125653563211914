import React from 'react';
import { dataFormatting } from '../../_lib/lib';

interface IOrderTotals {
  inventorySettings?: any;
  order: any;
  borderBottom?: boolean;
  containerClass?: string;
  metaDataClass?: string;
  dataClass?: string;
  isOrderDraft?: boolean;
  extraProps?: any;
}

function OrderTotals({
  inventorySettings,
  order,
  borderBottom,
  containerClass,
  metaDataClass,
  dataClass,
  isOrderDraft,
  extraProps,
}: IOrderTotals) {
  return (
    <div className="cell grid-x small-12">
      <div
        className={`cell grid-x align-middle ${
          extraProps?.minimumSpace ? 'small-offset-5 small-7' : 'small-12'
        }`}>
        <div className={`cell small-12 grid-x`}>
          <div className={`grid-x cell small-12 ${containerClass}`}>
            <div
              className={`cell ${
                metaDataClass ? metaDataClass : 'auto text-right px-bottom-subtotal-meta-data'
              }`}>
              {inventorySettings
                ? `Subtotal for Warehouse ${
                    inventorySettings?.warehouseLabels && order?.warehouse
                      ? inventorySettings.warehouseLabels[order.warehouse]
                      : order?.warehouse
                  } (${
                    !isOrderDraft
                      ? order?.lineItems
                        ? Object.keys(order?.lineItems).length
                        : 0
                      : order.noOfItems
                  } items)`
                : `Items (${
                    !isOrderDraft
                      ? order?.lineItems
                        ? Object.keys(order?.lineItems).length
                        : 0
                      : order.noOfItems
                  })`}
              :
            </div>
            <div
              data-id="subtotal"
              data-value={order?.subtotal}
              className={`cell ${
                dataClass ? dataClass : ' small-4 px-bottom-subtotal-data'
              } text-right`}>
              {dataFormatting('currency', order?.subtotal)}
            </div>
          </div>
          <div className={`grid-x cell small-12 ${containerClass}`}>
            <div
              className={`cell ${
                metaDataClass ? metaDataClass : 'auto text-right px-bottom-subtotal-meta-data'
              }`}>
              Fees:
            </div>
            <div
              data-id="fees"
              data-value={order?.paymentFee}
              className={`cell ${
                dataClass ? dataClass : ' small-4 px-bottom-subtotal-data'
              } text-right`}>
              {dataFormatting('currency', order?.paymentFee)}
            </div>
          </div>
          <div className={`grid-x cell small-12 ${containerClass}`}>
            <div
              className={`cell ${
                metaDataClass ? metaDataClass : 'auto text-right px-bottom-subtotal-meta-data'
              }`}>
              Freight:
            </div>
            <div
              data-id="freight"
              data-value={order?.freight}
              className={`cell ${
                dataClass ? dataClass : ' small-4 px-bottom-subtotal-data'
              } text-right`}>
              {dataFormatting('currency', order?.freight)}
            </div>
          </div>
          <div
            className={`grid-x cell small-12 ${
              containerClass ? containerClass + ' px-bold-fonts' : ''
            }`}>
            <div
              className={`cell ${
                metaDataClass ? metaDataClass : 'auto text-right px-bottom-subtotal-meta-data'
              }`}>
              Order Total:
            </div>
            <div
              data-id="orderTotal"
              data-value={order?.total}
              className={`cell ${
                dataClass ? dataClass : ' small-4 px-bottom-subtotal-data'
              } text-right`}>
              {dataFormatting('currency', order?.total)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderTotals;
