import React, { useEffect, useRef, useState } from 'react';
import { PrimaryButton, TertiaryButton } from '../../AtomComponents';
import Dialog from '../../Dialog/Dialog';
import TimeField from './TimeField';
import './DraftTimeSelectorDialog.scss';
import { convertSecondsToTime, getSecondsFromStartOfToday } from '../../PreOrders/Utils';
import { IDraftTimeSelectorDialog, IOfferTimeRange } from '../../../types/IItemSetup';

export const DraftTimeSelectorDialog = ({
  closeDialog,
  isDialogOpen,
  title,
  confirmHandler,
  offerTimeRange,
  setLoadingDialog,
}: IDraftTimeSelectorDialog) => {
  const [offerStartTime, setOfferStartTime] = useState<string>('');
  const [offerEndAndNegotiatingTime, setOfferEndAndNegotiatingTime] = useState<string>('');
  const offerStartTimeRef = useRef<any>();
  const offerEndAndNegotiatingTimeRef = useRef<any>();

  useEffect(() => {
    if (offerTimeRange) {
      const startTime = convertSecondsToTime(offerTimeRange.offerStartTimeInSeconds, 'hh:mm a');
      const endTime = convertSecondsToTime(offerTimeRange.offerEndTimeInSeconds, 'hh:mm a');
      setOfferStartTime(startTime);
      setOfferEndAndNegotiatingTime(endTime);
    }
  }, [offerTimeRange]);

  const handleApply = async () => {
    setLoadingDialog(true);
    const selectedOfferTime: IOfferTimeRange = {
      offerStartTimeInSeconds: 0,
      offerEndTimeInSeconds: 0,
      daysToFinalize: 0,
    };
    const offerStart = offerStartTime;
    const offerEnd = offerEndAndNegotiatingTime;
    selectedOfferTime.offerStartTimeInSeconds = getSecondsFromStartOfToday(offerStart, 'hh:mm a');
    selectedOfferTime.offerEndTimeInSeconds = getSecondsFromStartOfToday(offerEnd, 'hh:mm a');
    const res = await confirmHandler(selectedOfferTime);
    if (res) closeDialog();
    setLoadingDialog(false);
  };

  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title={title}
      content={
        <div className="grid-x">
          <div className="margin-bottom-3 text-faded" style={{ fontSize: '12px' }}>
            Select the start and end times that will be used for your current draft items when
            scheduling
          </div>
          <div className="grid-x cell small-12 px-time-select-filter-selector-container margin-bottom-3">
            <div className="cell small-4 offer-times-labels">Offering Start</div>
            <TimeField
              textfieldProps={{
                variant: 'outlined',
                label: 'Start Time',
                InputLabelProps: { shrink: true },
              }}
              time={offerStartTime}
              setTime={setOfferStartTime}
              ref={offerStartTimeRef}
            />
          </div>
          <div className="grid-x cell small-12 px-time-select-filter-selector-container">
            <div className="cell small-4 offer-times-labels">Offering and Negotiating End</div>
            <TimeField
              textfieldProps={{
                variant: 'outlined',
                label: 'End Time',
                InputLabelProps: { shrink: true },
              }}
              time={offerEndAndNegotiatingTime}
              setTime={setOfferEndAndNegotiatingTime}
              ref={offerEndAndNegotiatingTimeRef}
            />
          </div>
        </div>
      }
      actions={
        <>
          <TertiaryButton onClick={closeDialog}>CANCEL</TertiaryButton>
          <PrimaryButton onClick={handleApply}>APPLY</PrimaryButton>
        </>
      }
      label="Time Selector"
      className="px-offer-times-dialog"
    />
  );
};
