import { CellClassParams, GridApi, ValueFormatterParams } from 'ag-grid-community';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty } from 'lodash';
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { IFundingGrid } from '../../types/IFunding';
import { checkNullOrUndefined, dataFormatting, searchItems } from '../../_lib/lib';
import {
  LoadingComponent,
  NoRowsMatchedComponent,
  remainingCellRules,
} from '../PreOrders/TableCellComponents';

const FundingGrid = forwardRef(({ searchText, fundingpreorderState }: IFundingGrid, ref: any) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const debounce = useDebounce(searchText, 900);
  useImperativeHandle(ref, () => {
    return {
      showLoadingDialog() {
        gridApi?.showLoadingOverlay();
      },
      hideLoadingDialog() {
        gridApi?.hideOverlay();
      },
    };
  });
  useEffect(() => {
    gridApi?.setRowData(fundingpreorderState.funds);
  }, [gridApi, fundingpreorderState.funds]);

  useEffect(() => {
    if (isEmpty(fundingpreorderState.funds)) {
      return;
    }
    onStartSearchAgGrid({}, debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const onStartSearchAgGrid = async (e?: any, val?: string) => {
    if (checkNullOrUndefined(val)) return;
    if (e?.preventDefault) e.preventDefault();
    // search through the users
    // gridApi?.setQuickFilter(val);
    const searchedCustomers = searchItems({
      searchTermState: debounce,
      view: 'funding',
      itemsList: fundingpreorderState?.funds,
      settings: {},
    });
    if (gridApi?.setRowData) gridApi?.setRowData(searchedCustomers);
  };

  const colDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Buyer',
      field: 'buyerName',
      colId: 'buyer',
      cellClass: 'buyerClass',
      minWidth: 250,
    },
    {
      headerName: 'Buyer Id',
      field: 'buyerNumber',
      colId: 'buyerNumber',
      cellClass: 'buyerClass',
      minWidth: 150,
    },
    {
      headerName: 'Funding Required',
      field: 'isFundingRequired',
      minWidth: 100,
    },
    {
      headerName: 'Starting Balance',
      field: 'startingBalance',
      colId: 'startingBalance',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
      cellStyle: (params: CellClassParams) => remainingCellRules(params),
      minWidth: 170,
    },
    {
      headerName: 'Shipped Order Amount',
      field: 'shippedOrderAmount',
      colId: 'shippedOrderAmount',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
      cellStyle: (params: CellClassParams) => remainingCellRules(params),
      minWidth: 170,
    },
    {
      headerName: 'New Funds',
      field: 'newFund',
      colId: 'NewFunds',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
      cellStyle: (params: CellClassParams) => remainingCellRules(params),
      minWidth: 170,
    },
    {
      headerName: 'Ending Balance',
      field: 'endingBalance',
      colId: 'endingBalance',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
      cellStyle: (params: CellClassParams) => remainingCellRules(params),
      enableValue: true,
      minWidth: 170,
    },
    {
      headerName: 'Open Orders',
      field: 'openOrderAmount',
      colId: 'openOrder',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
      cellStyle: (params: CellClassParams) => remainingCellRules(params),
      enableValue: true,
      minWidth: 170,
    },
    {
      headerName: 'Available Funds',
      field: 'availableFund',
      colId: 'availableFunds',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
      cellStyle: (params: CellClassParams) => remainingCellRules(params),
      enableValue: true,
      minWidth: 170,
    },
    {
      headerName: 'Awarded In Period',
      field: 'awardedInPeriod',
      colId: 'awardedInPeriod',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
      cellStyle: (params: CellClassParams) => remainingCellRules(params),
      enableValue: true,
      minWidth: 170,
    },
    {
      headerName: 'Fulfilled In Period',
      field: 'fulfilledForAwardedInPeriod',
      colId: 'fulfilledInPeriod',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.value, true),
      cellStyle: (params: CellClassParams) => remainingCellRules(params),
      enableValue: true,
      minWidth: 170,
    },
    {
      headerName: 'Fulfilled Percentage',
      field: 'fulfilledPercentage',
      colId: 'fulfilledPercentage',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ?? 0;
      },
      enableValue: true,
      minWidth: 170,
    },
    // {
    //   headerName: 'Updated',
    //   field: 'updateDate',
    //   colId: 'updated',
    //   enableValue: true,
    //   minWidth: 170,
    // },
  ];
  const gridOptions: GridOptions = {
    columnDefs: colDef,
    suppressRowClickSelection: true,
    rowHeight: 84,
    rowBuffer: 5,
    debounceVerticalScrollbar: true,
    suppressMovableColumns: true,
    suppressContextMenu: true,
    enableCellTextSelection: true,
    suppressHorizontalScroll: true,
    rowStyle: {
      background: '#eef5ff',
    },
    suppressCellSelection: true,
    defaultColDef: {
      flex: 1,
      cellClass: 'cell-vertical-align-text-left',
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      sortable: true,
    },
    valueCache: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
  };

  const onGridReady = async (params: any) => {
    setGridApi(params.api);
  };
  return (
    <div className="ag-theme-alpine px-min-width-funding-grid">
      <AgGridReact onGridReady={onGridReady} gridOptions={gridOptions} />
    </div>
  );
});
function mapStateToProps(state: any) {
  return {
    fundingpreorderState: state.fundingpreorderState,
  };
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(FundingGrid);
