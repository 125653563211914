import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

interface IActionButtonProps {
  label: string;
  handler: () => void;
}

export const SnackBarWithActionProps = forwardRef((props: any, ref: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [actionButtonProps, setActionButtonProps] = useState<IActionButtonProps | null>(null);

  const handleOpen = (message: string, actionProps: IActionButtonProps) => {
    console.log(message, actionProps);
    setIsOpen(true);
    setMessage(message);
    setActionButtonProps(actionProps);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Snackbar
      className="po-snackBar-alert"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={300000}
      onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity="info"
        style={{ display: 'flex', flexDirection: 'row' }}>
        {message}
        {actionButtonProps && (
          <Button className="snackButton variant" size="small" onClick={actionButtonProps?.handler}>
            {actionButtonProps?.label}
          </Button>
        )}
      </Alert>
    </Snackbar>
  );
});
