import * as types from '../actions/actionTypes';

export default function offerReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.OFFERS_SET:
      return { ...state, offersList: action.offersList };

    default:
      return state;
  }
}
