import { Badge, Tab, Tabs } from '@material-ui/core';
import { CheckCircleOutline, Error, ErrorOutline, Warning } from '@material-ui/icons';
import { ColDef, ColGroupDef, GridApi, GridOptions, ValueGetterParams } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
// ag grid
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import React, { useEffect, useMemo, useState } from 'react';
import useDataService from '../../hooks/useDataService';
import { isEmptyOrSpaces, messages } from '../../_lib/lib';
import PrimaryButton from '../AtomComponents/PrimaryButton';
import TertiaryButton from '../AtomComponents/TertiaryButton';
import { eMessageType } from '../../types/IMessageType';
import '../../views/PreOrders/PreOrders.scss';
import './ImportReview.scss';
import { LoadingComponent, NoRowsMatchedComponent } from '../PreOrders/TableCellComponents';
import { dateFormats, manipulateDates } from '../PreOrders/Utils';
LicenseManager.setLicenseKey(
  'CompanyName=Phonex Holdings Inc,LicensedApplication=PhoneX SaaS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=3,AssetReference=AG-012160,ExpiryDate=1_December_2021_[v2]_MTYzODMxNjgwMDAwMA==f1ae693cebbed365cb2597d53a5021d2'
);

interface IImportReview {
  importData: any;
  settings: any;
  screen: string;
  closeImport: () => void;
  saveImport: (draftId?: any) => Promise<boolean>;
  keyGroup: any;
  fileName: string;
}

enum eImportTabs {
  errors = 'errors',
  warnings = 'warnings',
  changes = 'changes',
}

let itemGroupGetterKey: any = '';

function ImportReview({
  importData,
  settings,
  closeImport,
  keyGroup,
  saveImport,
  screen,
  fileName,
}: IImportReview) {
  const [tab, setTab] = useState<eImportTabs>(eImportTabs.errors);
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [tabStats, setTabStats] = useState<any>({});
  const [tableData, setTableData] = useState<any>({});
  const { openSnackBar } = useDataService();
  const { validErrorLength, validWarningLength, validChangesLength } = useMemo(() => {
    let validErrorLength: Number = 0;
    let validWarningLength: Number = 0;
    let validChangesLength: Number = 0;
    Object.keys(importData['errors']).forEach(
      (key: any) => (validErrorLength += importData.errors[key]?.length)
    );
    Object.keys(importData['warnings']).forEach(
      (key: any) => (validWarningLength += importData.warnings[key].length)
    );
    Object.keys(importData['changes']).forEach(
      (key: any) => (validChangesLength += importData.changes[key].length)
    );
    return {
      validErrorLength,
      validWarningLength,
      validChangesLength,
    };
  }, [importData]);
  // useEffect(() => {
  //   if (settings) {
  //     settingsData = settings;
  //   }
  // }, [settings]);

  const saveImportData = async () => {
    // show loading
    if (gridAPI) gridAPI.showLoadingOverlay();
    try {
      const res = await saveImport(importData.changes);
      if (res) {
        closeImport();
        openSnackBar(`Awards saved successfully`, 'success');
      }
    } catch (error: any) {
      openSnackBar(`Error while saving awards`, 'error');
    }
    if (gridAPI) gridAPI.hideOverlay();
  };

  useEffect(() => {
    if (Object.keys(tabStats).length <= 0 && importData && keyGroup) {
      if (validErrorLength > 0) {
        itemGroupGetterKey = keyGroup.errors;
      } else if (validWarningLength > 0) {
        itemGroupGetterKey = keyGroup.warnings;
      } else {
        itemGroupGetterKey = keyGroup.changes;
      }
      const updatedTabStats: any = {};
      const updatedTableData: any = {};

      Object.keys(importData).forEach((val) => {
        let tableStatsData: any = {};
        updatedTableData[val] = [];
        Object.keys(importData[val]).forEach((resVal: any) => {
          const importItemCategory = resVal;
          if (Array.isArray(importData[val][resVal])) {
            importData[val][resVal].forEach((errorVal: any) => {
              tableStatsData[errorVal] = true;
              updatedTableData[val].push({
                ...errorVal,
                importItemCategory,
              });
            });
          }
        });
        updatedTabStats[val] = Object.keys(tableStatsData).length;
      });

      if (!updatedTabStats.changes) {
        openSnackBar('No changes found in import file', eMessageType.error);
      }
      setTableData(updatedTableData);
      setTabStats(updatedTabStats);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabStats, importData, keyGroup]);

  useEffect(() => {
    if (gridAPI && tableData[tab] && tab) {
      gridAPI.showLoadingOverlay();
      setTimeout(() => {
        gridAPI.setRowData(tableData[tab]);
        if (tableData[tab].length <= 0) {
          gridAPI.showNoRowsOverlay();
        }
      }, 200);
    }
  }, [tableData, gridAPI, tab]);

  useEffect(() => {
    if (validErrorLength > 0) {
      setTab(eImportTabs.errors);
    } else if (validWarningLength > 0) {
      setTab(eImportTabs.warnings);
    } else {
      setTab(eImportTabs.changes);
    }
  }, [validErrorLength, validWarningLength, validChangesLength]);

  const handleTabChange = (e: any, tabVal: eImportTabs) => {
    switch (tabVal) {
      case eImportTabs.errors: {
        itemGroupGetterKey = keyGroup.errors;
        break;
      }
      case eImportTabs.warnings: {
        itemGroupGetterKey = keyGroup.warnings;
        break;
      }
      case eImportTabs.changes: {
        itemGroupGetterKey = keyGroup.changes;
        break;
      }
    }
    setTab(tabVal);
  };

  const onGridReady = (gridOptions: GridOptions) => {
    if (gridOptions.api) {
      setGridAPI(gridOptions.api);
    }
  };

  const itemGroupGetter = (params: ValueGetterParams) => {
    return messages[itemGroupGetterKey][params.data.importItemCategory]?.label;
  };

  const expandIcon = () => {
    const key = itemGroupGetterKey.toLowerCase();
    if (key.includes('error')) {
      return '<svg class="MuiSvgIcon-root px-review-icon margin-right-1 px-color-error" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>';
    }

    if (key.includes('warning')) {
      return '<svg class="MuiSvgIcon-root px-review-icon margin-right-1 px-color-warning" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg>';
    }

    if (key.includes('change')) {
      return '<svg class="MuiSvgIcon-root px-review-icon margin-right-1 px-color-change" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>';
    }

    return '<svg class="MuiSvgIcon-root px-review-icon margin-right-1 px-color-change" onclick="(e)=>{e.preventDefault();}" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>';
  };

  const collapseIcons = () => {
    const key = itemGroupGetterKey.toLowerCase();
    if (key.includes('error')) {
      return '<svg class="MuiSvgIcon-root px-collapsed-icon margin-right-1 px-color-error" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>';
    }

    if (key.includes('warning')) {
      return '<svg class="MuiSvgIcon-root px-collapsed-icon margin-right-1 px-color-warning" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg>';
    }

    if (key.includes('change')) {
      return '<svg class="MuiSvgIcon-root px-collapsed-icon margin-right-1 px-color-change" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>';
    }

    return '<svg class="MuiSvgIcon-root px-collapsed-icon margin-right-1 px-change" onclick="(e)=>{e.preventDefault();}" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>';
  };

  let columnDefs: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Row',
      headerClass: 'px-import-review-header',
      children: [
        {
          valueGetter: itemGroupGetter,
          rowGroup: true,
          hide: true,
        },
        {
          headerName: '',
          field: 'rowNumber',
          cellStyle: { marginLeft: '5px' },
          minWidth: 50,
        },
      ],
    },
    {
      headerName: 'Product Information',
      headerClass: 'px-import-review-header',
      children: [
        {
          headerName: 'Description',
          field: 'dbItemDescription',
          cellClass: 'po-import-longtext',
          // cellRendererFramework: ImportDescription,
          // cellRendererParams: {
          //   settingsData: settings,
          // },
          colId: 'Description',
          wrapText: true,
          minWidth: 350,
        },
        {
          headerName: 'Buyer',
          field: 'dbBuyerName',
          cellClass: 'po-import-longtext',
          wrapText: true,
          valueGetter: (params: ValueGetterParams) => {
            return params?.data?.dbBuyerName ?? '--';
          },
          minWidth: 300,
        },
        {
          headerName: 'Buyer#',
          field: 'BuyerNumber',
          valueGetter: (params: ValueGetterParams) => {
            if (!params.data || isEmptyOrSpaces(params.data.buyerNumber)) return '--';
            return params?.data?.buyerNumber;
          },
          minWidth: 150,
        },
        {
          headerName: 'Warehouse',
          field: 'warehouse',
          colId: 'warehouse',
          valueGetter: (params: ValueGetterParams) => {
            if (!params.data || isEmptyOrSpaces(params.data.warehouse)) return '--';
            return params?.data?.warehouse;
          },
          minWidth: 125,
        },
        {
          headerName: 'SKU',
          field: 'SKU',
          colId: 'SKU',
          valueGetter: (params: ValueGetterParams) => {
            if (!params.data || isEmptyOrSpaces(params.data.itemNumber)) return '--';
            return params?.data?.itemNumber;
          },
          minWidth: 125,
        },
        {
          headerName: 'Increment Size',
          field: 'dbIncrementSize',
          colId: 'Increment_Size',
          valueGetter: (params: ValueGetterParams) => {
            if (!params.data || isEmptyOrSpaces(params.data.dbIncrementSize)) return '--';
            return params?.data?.dbIncrementSize;
          },
          minWidth: 125,
        },
        {
          headerName: 'Awarded Quantity',
          field: 'Awarded_Quantity',
          colId: 'awardedQuantity',
          valueGetter: (params: ValueGetterParams) => {
            if (!params.data || isEmptyOrSpaces(params.data.awardedQuantity)) return '--';
            return params?.data?.awardedQuantity;
          },
          minWidth: 125,
        },
        {
          headerName: 'Awarded Price',
          field: 'Awarded_Price',
          colId: 'awardedPrice',
          valueGetter: (params: ValueGetterParams) => {
            if (!params.data || isEmptyOrSpaces(params.data.awardedPrice)) return '--';
            return params?.data?.awardedPrice;
          },
          minWidth: 125,
        },
      ],
    },
    {
      headerName: 'Quantity and Cost',
      headerClass: 'px-import-review-header',
      children: [
        {
          headerName: 'Take All?',
          field: 'isTakeAll',
          colId: 'isTakeAll',
          valueGetter: (params: ValueGetterParams) => {
            if (!params.data || isEmptyOrSpaces(params.data.isTakeAll)) return '--';
            return params?.data?.isTakeAll;
          },
          minWidth: 125,
        },
        {
          headerName: 'Take % Limit',
          field: 'takeAllLimitPercentage',
          colId: 'takeLimit',
          valueGetter: (params: ValueGetterParams) => {
            if (!params.data || isEmptyOrSpaces(params.data.takeAllLimitPercentage)) return '--';
            return params?.data?.takeAllLimitPercentage;
          },
          minWidth: 125,
        },
        {
          headerName: 'Shipping Start Date',
          field: 'shippingStartDate',
          colId: 'shippingStartDate',
          wrapText: true,
          valueGetter: (params: ValueGetterParams) => {
            if (
              !params.data ||
              isEmptyOrSpaces(params.data.shippingStartDate) ||
              params.data.shippingStartDate === 'Invalid date'
            )
              return '--';
            const formattedDate = manipulateDates({
              date: params.data.shippingStartDate,
              formatTo: dateFormats.monthIn3Letters,
              currentFormat: dateFormats.yearMonthDate,
              manipulate: { days: 0 },
            });
            return formattedDate;
          },
          minWidth: 150,
        },
        {
          headerName: 'Shipping End Date',
          field: 'shippingEndDate',
          colId: 'shippingEndDate',
          wrapText: true,
          valueGetter: (params: ValueGetterParams) => {
            if (
              !params.data ||
              isEmptyOrSpaces(params.data.shippingEndDate) ||
              params.data.shippingEndDate === 'Invalid date'
            )
              return '--';
            const formattedDate = manipulateDates({
              date: params.data.shippingEndDate,
              formatTo: dateFormats.monthIn3Letters,
              currentFormat: dateFormats.yearMonthDate,
              manipulate: { days: 0 },
            });
            return formattedDate;
          },
          minWidth: 150,
        },
      ],
    },
  ];

  let gridOptions: GridOptions = {
    headerHeight: 32,
    groupHeaderHeight: 32,
    defaultColDef: {
      flex: 1,
      minWidth: 80,
      sortable: true,
      resizable: false,
      filter: false,
      autoHeight: true,
      menuTabs: [],
    },
    suppressMovableColumns: true,
    groupDefaultExpanded: -1,
    columnDefs: columnDefs,
    icons: {
      groupExpanded: expandIcon,
      groupContracted: collapseIcons,
    },
    enableCellTextSelection: true,
    groupUseEntireRow: true,
    suppressContextMenu: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
  };

  return (
    <div className="grid-x px-po-review margin-left-1 margin-right-1">
      <div className="cell small-6">
        <h3 data-id="reviewPoAwards">Review PO Award Changes</h3>
        <div className="px-po-meta-data-container" data-id="fileName">
          <CheckCircleOutline className="px-review-icon margin-right-1 px-color-success" />
          <div className="px-po-meta-data">
            <span className="px-bold-fonts px-color-success-variant">File uploaded</span>
            {fileName}
          </div>
        </div>
      </div>
      <div className="cell small-6 text-right">
        <TertiaryButton data-id="cancelChanges" className="margin-right-1" onClick={closeImport}>
          CANCEL CHANGES
        </TertiaryButton>
        <PrimaryButton data-id="saveChanges" disabled={!tabStats.changes} onClick={saveImportData}>
          SAVE CHANGES
        </PrimaryButton>
      </div>
      <div className="grid-x small-12 margin-top-1">
        {validErrorLength > 0 && (
          <div className="small-4">
            <div className="px-po-meta-data-container" data-id="errorDesc">
              <ErrorOutline className="px-review-icon margin-right-1 px-color-error" />
              <div className="px-po-meta-data">
                <span className="px-bold-fonts">
                  {`
                   ${validErrorLength}
                   ${messages[keyGroup.errors]?.label || 'Error'}
                   (will be skipped)
                  `}
                </span>
                {Object.keys(importData.errors).map((val) => {
                  return (
                    importData.errors[val].length > 0 && (
                      <span key={val} className="px-import-meta-data-vertical-margin">
                        {importData.errors[val].length}{' '}
                        {messages[keyGroup.errors][val]?.label || ''}
                      </span>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {validWarningLength > 0 && (
          <div className="small-4">
            <div className="px-po-meta-data-container" data-id="warningDesc">
              <Warning className="px-review-icon margin-right-1 px-color-warning" />
              <div className="px-po-meta-data">
                <span className="px-bold-fonts">
                  {`
                   ${validWarningLength} ${messages[keyGroup.warnings]?.label || 'Warning'}
                   `}
                </span>
                {Object.keys(importData.warnings).map((val) => {
                  return (
                    importData.warnings[val].length > 0 && (
                      <span key={val} className="px-import-meta-data-vertical-margin">
                        {importData.warnings[val].length}{' '}
                        {messages[keyGroup.warnings][val]?.label || ''}
                      </span>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {validChangesLength > 0 && (
          <div className="small-4">
            <div className="px-po-meta-data-container" data-id="changesDesc">
              <Error className="px-review-icon margin-right-1 px-color-change" />
              <div className="px-po-meta-data">
                <span className="px-bold-fonts">
                  {`
                   ${validChangesLength} ${messages[keyGroup.changes]?.label || 'Changes'}
                   `}
                </span>
                {Object.keys(importData.changes).map((val) => {
                  return (
                    importData.changes[val].length > 0 && (
                      <span key={val} className="px-import-meta-data-vertical-margin">
                        {importData.changes[val].length}{' '}
                        {messages[keyGroup.changes][val]?.label || ''}
                      </span>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* tabs here */}
      <Tabs
        value={tab}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleTabChange}
        className="margin-top-1 px-po-tabs"
        aria-label="po tab change">
        <Tab
          className="px-error-tab px-bold-fonts"
          data-id="errorTab"
          label={
            <Badge
              badgeContent={validErrorLength || 0}
              style={{
                padding: '4px',
              }}
              max={Infinity}
              invisible={false}>
              Errors
            </Badge>
          }
          value={eImportTabs.errors}
          key="Errors"
        />
        <Tab
          className="px-warning-tab px-bold-fonts"
          data-id="warningTab"
          label={
            <Badge
              badgeContent={validWarningLength || 0}
              style={{
                padding: '4px',
              }}
              max={Infinity}
              invisible={false}>
              Warnings
            </Badge>
          }
          value={eImportTabs.warnings}
          key="Warnings"
        />
        <Tab
          className="px-change-tab px-bold-fonts"
          data-id="changesTab"
          label={
            <Badge
              badgeContent={validChangesLength || 0}
              style={{
                padding: '4px',
              }}
              max={Infinity}
              invisible={false}>
              Changes
            </Badge>
          }
          value={eImportTabs.changes}
          key="Changes"
        />
      </Tabs>
      {/* grid here */}
      <div
        className="ag-theme-alpine margin-top-1 import-review"
        style={{ height: '100vh', width: '100vw' }}>
        <AgGridReact gridOptions={gridOptions} onGridReady={onGridReady} />
      </div>
    </div>
  );
}

export default ImportReview;
