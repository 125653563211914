import * as types from '../actions/actionTypes';

export default function preorderReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.BUYER_AWARDS_SET: {
      return { ...state, awardsOpenObj: action.awards };
    }
    case types.BUYER_AWARDS_CLOSED_SET: {
      return {
        ...state,
        awardsClosedObj: action.awardsClosed,
      };
    }
    default:
      return state;
  }
}
