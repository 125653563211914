import React from 'react';

import { Button } from '@material-ui/core';

import Dialog from '../Dialog/Dialog';
import { PrimaryButton } from '../AtomComponents';

import { IConfirmDialogProps } from '../../types/IPreOrders';

const ConfirmDialog = ({
  isDialogOpen,
  title,
  confirmDialogContent,
  confirmActionProps,
  disabled = false,
  backdropHandler,
  className,
}: IConfirmDialogProps) => {
  const { confirmHandler, confirmLabel, rejectHandler, rejectLabel } = confirmActionProps;
  const dialogContent = confirmDialogContent();
  return (
    <>
      <Dialog
        isDialogOpen={isDialogOpen}
        closeDialog={rejectHandler}
        title={title}
        content={dialogContent}
        actions={
          <>
            <Button onClick={rejectHandler}>{rejectLabel}</Button>
            <PrimaryButton onClick={confirmHandler} disabled={disabled}>
              {confirmLabel}
            </PrimaryButton>
          </>
        }
        label="Confirm Funding"
        className={className ? `px-po-import-dialog ${className}` : 'px-po-import-dialog'}
        onBackdropClick={backdropHandler}
      />
    </>
  );
};
export { ConfirmDialog };
