export enum IOffersTab {
  awarded = 'AWARDED',
  reoffer = 'REOFFER_REQUEST',
  awaitingReview = 'AWAITING_REVIEW',
}

export const offerStatusValues = {
  awarded: 'AWARDED',
  reoffer: 'REOFFER_REQUEST',
  awaitingReview: 'AWAITING_REVIEW',
  reofferAwaiting: 'REOFFER_AWAITING_REVIEW',
  rejected: 'REJECTED',
};

export interface offerImportValue {
  dbIncrementSize: number;
  dbItemDescription: string;
  itemNumber: string;
  itemNumberWarehouse: string;
  quantityForecast: number;
  rowNumber: number;
  status: string;
  warehouse: string;
}
interface offerImportErrors {
  'ITEM#_INVALID': offerImportValue[];
  WAREHOUSE_INVALID: offerImportValue[];
  ITEM_WAREHOUSE_DOESNOT_EXIST: offerImportValue[];
  DUPLICATE_ITEMS: offerImportValue[];
}
interface offerImportWarnings {
  FORECAST_QUANTITY_NOT_A_MULTIPLE_OF_INCREMENT_SIZE: offerImportValue[];
  FORECAST_QUANTITY_INVALID: offerImportValue[];
}
export interface OfferImportChanges {
  newOffers: offerImportValue[];
  updatedOffers: offerImportValue[];
}
export interface IImportOffers {
  errors: offerImportErrors;
  warnings: offerImportWarnings;
  changes: OfferImportChanges;
  fileName: string;
}

export interface IPooffersItems {
  offerId: number;
  itemElectId: number;
  itemNumber: string | number;
  warehouse: string | number;
  itemNumberWarehouse: string | number;
  itemStatus: string;
  durationLeft: string;
  itemDescription: string | number;
  incrementSize: number;
  color: string | number;
  offerStatus: string;
  isTakeAll: any;
  forecastQuantity: number;
  offerQuantity: any;
  offerPrice: any;
  awardedQuantity: number;
  awardedPrice: number;
  reofferRequestPrice: any;
  reofferRequestQuantity: string;
  offerStartDate: any;
  offerEndDate: any;
  shippingStartDate: any;
  shippingEndDate: any;
  quantityAvailable: any;
}

export interface ITableData {
  changes: IPooffersItems[];
  errors: IPooffersItems[];
  fileName: IPooffersItems[];
  warnings: IPooffersItems[];
}
