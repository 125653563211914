import { FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import { TertiaryButton, TextField } from '../AtomComponents';
import Dialog from '../Dialog/Dialog';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { dataFormatting } from '../../_lib/lib';
import { IPoClearingTabs } from '../../types/IPoClearing';
import './BulkUpdateOffers.scss';
import { IBulkData, IOffers } from '../../types/IOfferClearing';

interface IBulkUpdateOffersPropTypes {
  title?: string;
  open?: boolean;
  handleClose: any;
  bulkUpdateData: IBulkData;
  updateOfferCall: (updatedOffers: any, refetchFromRemote?: boolean) => Promise<any>;
  setLoadingDialog: (type: boolean) => {};
}
enum bulkUpdateOptions {
  select = 'select',
  award = 'award',
  reoffer = 'reoffer',
  reject = 'reject',
}
interface IBulkPlaceholder {
  award: string;
  reoffer: string;
  reject: string;
}
interface IBulkButton extends IBulkPlaceholder {
  select: string;
}
interface ILeftDisplayLabels {
  availableQuantity: string;
  selectedQuantity: string;
  difference: string;
}
interface IRightDisplayLabels {
  offerRange: string;
  avgPrice: string;
  skus: string;
  buyers: string;
}

const bulkUpdationOptionPlaceHolder: IBulkButton = {
  select: '- select action -',
  award: 'Award',
  reoffer: 'Re-Offer Request',
  reject: 'Reject',
};

const bulkUpdateActionButton: IBulkPlaceholder = {
  award: 'AWARD',
  reoffer: 'RE-OFFER REQUEST',
  reject: 'REJECT',
};

const leftDisplayLabels: ILeftDisplayLabels = {
  availableQuantity: 'Available Quantity',
  selectedQuantity: 'Selected Quantity',
  difference: 'Difference',
};

const rightDisplayLabels: IRightDisplayLabels = {
  offerRange: `Offer ${dataFormatting('currency', ' Range')}`,
  avgPrice: 'Avg Price',
  skus: 'SKUs',
  buyers: 'Buyers',
};

const BulkUpdatesOffers = ({
  title,
  open,
  handleClose,
  bulkUpdateData,
  updateOfferCall,
  setLoadingDialog,
}: IBulkUpdateOffersPropTypes) => {
  const [selectVal, setSelectVal] = useState<bulkUpdateOptions>(bulkUpdateOptions.select);
  const [reofferPrice, setReOfferPrice] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [negativeDiff] = useState<boolean>(bulkUpdateData.difference < 0);
  const handleChange = (e: ChangeEvent<{ value: any }>) => {
    setSelectVal(e.target.value);
  };

  const validateEnteredprice = () => {
    setError('');
    let updatedReOfferPrice;
    const regex = new RegExp('^[0-9]\\d*(((,\\d{3}))*(\\.\\d{0,2})?)$');
    if (!!reofferPrice && reofferPrice <= bulkUpdateData.highestOfferPrice)
      return setError('Re-Offer Request Price must be above the highest offer');
    if (isNaN(+reofferPrice)) {
      return setError('Value must be numeric');
    }
    if (!regex.test(reofferPrice)) {
      return setError('Must be a valid price');
    }
    if (typeof reofferPrice === 'string')
      updatedReOfferPrice = +parseFloat(reofferPrice.replace(/,/g, '')).toFixed(2);
    else updatedReOfferPrice = +parseFloat(reofferPrice).toFixed(2);
    setReOfferPrice(updatedReOfferPrice.toFixed(2).toString());
  };

  const updateOffers = async () => {
    const selectedOffers = bulkUpdateData.offers;
    let updatedOffers: {
      offerAction: string;
      status: string;
      isTakeAll: boolean;
      awardedPrice: number | null;
      awardedQuantity: number | null;
      buyerId: number;
      buyerName: string;
      buyerNumber: string;
      errorHandlers: any;
      itemElectId: number;
      offerId: number;
      offerPrice: number;
      offerQuantity: number;
      quantityAvailable: number;
      reofferRequestPrice: number | null;
      reofferRequestQuantity: number | null;
    }[] = [];
    setLoadingDialog(true);
    switch (selectVal) {
      case bulkUpdateOptions.award:
        updatedOffers = selectedOffers.map((offer: IOffers) => ({
          ...offer,
          awardedPrice: offer.offerPrice,
          awardedQuantity: offer.offerQuantity,
          offerAction: IPoClearingTabs.awarded,
          status: IPoClearingTabs.awarded,
          isTakeAll: offer.isTakeAll === 0 ? false : true,
        }));
        break;
      case bulkUpdateOptions.reject:
        updatedOffers = selectedOffers.map((offer: IOffers) => ({
          ...offer,
          offerAction: 'REJECTED',
          status: 'REJECTED',
          isTakeAll: offer.isTakeAll === 0 ? false : true,
        }));
        break;
      case bulkUpdateOptions.reoffer:
        updatedOffers = selectedOffers.map((offer: IOffers) => ({
          ...offer,
          offerAction: IPoClearingTabs.reofferedRequest,
          status: IPoClearingTabs.reofferedRequest,
          isTakeAll: offer.isTakeAll === 0 ? false : true,
          reofferRequestPrice: +reofferPrice,
          reofferRequestQuantity: offer.offerQuantity,
        }));
        break;
    }
    const res = await updateOfferCall(updatedOffers, true);
    setLoadingDialog(false);
    if (res) handleClose();
  };

  const renderActions = (val: string) => {
    if (val === bulkUpdateOptions.award && (negativeDiff || bulkUpdateData.isAwardDisabled))
      return 'Award (must have enough Available)';
    else if (val === bulkUpdateOptions.reoffer && bulkUpdateData.reOfferDisabledText)
      return `Re-Offer Request (${bulkUpdateData.reOfferDisabledText})`;
    else return bulkUpdationOptionPlaceHolder[val as keyof IBulkButton];
  };

  return (
    <Dialog
      isDialogOpen={open}
      closeDialog={handleClose}
      title={title}
      fullWidth
      maxWidth="sm"
      content={
        <>
          <div className="grid-x margin-top-2">
            <div className="cell small-6 ">
              {Object.keys(leftDisplayLabels).map((val: string) => {
                const isnegDiff = val === 'difference' && negativeDiff ? 'error-color' : '';
                return (
                  <div key={val} className="grid-x cell small-12">
                    <div
                      className={`cell small-6 margin-bottom-1 px-text-description ${isnegDiff}`}>
                      {leftDisplayLabels[val as keyof ILeftDisplayLabels]} :
                    </div>
                    <div className={`cell small-6 margin-bottom-1 ${isnegDiff}`}>
                      {bulkUpdateData && bulkUpdateData[val as keyof IBulkData]}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="cell small-6">
              <div className="grid-x cell small-12">
                {Object.keys(rightDisplayLabels).map((val: string) => {
                  return (
                    <div key={val} className="grid-x cell small-12 margin-bottom-1">
                      <div className="cell small-6 px-text-description">
                        {rightDisplayLabels[val as keyof IRightDisplayLabels]}:
                      </div>
                      <div className="cell small-6">
                        {bulkUpdateData && bulkUpdateData[val as keyof IBulkData]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <FormControl variant="outlined" className="margin-top-1">
            <InputLabel id="select-outlined-label">Bulk Action</InputLabel>
            <Select
              labelId="select-outlined-label"
              id="select-outlined"
              value={selectVal}
              onChange={handleChange}
              label="Bulk Action"
              style={{ width: '328px' }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              renderValue={(selectedValue: any) =>
                selectedValue === bulkUpdateOptions.select ? (
                  <span className="px-text-description">
                    {bulkUpdationOptionPlaceHolder[selectedValue as keyof IBulkButton]}
                  </span>
                ) : (
                  bulkUpdationOptionPlaceHolder[selectedValue as keyof IBulkButton]
                )
              }>
              {Object.keys(bulkUpdateOptions).map((val: string) => {
                return (
                  <MenuItem
                    key={val}
                    value={val}
                    style={{ display: val === bulkUpdateOptions.select ? 'none' : '' }}
                    disabled={
                      (val === bulkUpdateOptions.reoffer && !!bulkUpdateData.reOfferDisabledText) ||
                      (val === bulkUpdateOptions.award &&
                        (negativeDiff || bulkUpdateData.isAwardDisabled))
                    }>
                    <p className="text-primary">{renderActions(val)}</p>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {selectVal === bulkUpdateOptions.reoffer && (
            <div className="margin-top-2">
              <TextField
                label="Bulk Re-Offer Request Price"
                variant="outlined"
                value={reofferPrice}
                style={{ width: '328px' }}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  setError('');
                  setReOfferPrice(e.target.value);
                }}
                onBlur={validateEnteredprice}
                error={!!error}
                helperText={error}
                InputProps={{
                  style: { height: 42 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </>
      }
      actions={
        <>
          <TertiaryButton style={{ color: 'black' }} onClick={handleClose}>
            Cancel
          </TertiaryButton>
          {bulkUpdateActionButton[selectVal as keyof IBulkPlaceholder] ? (
            <TertiaryButton
              autoFocus
              disabled={selectVal === bulkUpdateOptions.reoffer && (reofferPrice === '' || !!error)}
              onClick={updateOffers}>
              {bulkUpdateActionButton[selectVal as keyof IBulkPlaceholder]}
            </TertiaryButton>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

export default BulkUpdatesOffers;
