import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import { IFilterDialog, IItemsAvailabilityFilterStategy } from '../../../types/IFilterEngine';
import { PrimaryButton, PrimaryIconButton, TertiaryButton, TextField } from '../../AtomComponents';
import { ArrowBack } from '@material-ui/icons';
import { dataFormatting } from '../../../_lib/lib';
const FilterItemsAvailability = ({
  customOptionObj,
  title,
  attribute,
  filterState,
  clearFilter,
  saveFilter,
  closeDialog,
  getLabel,
  openDialog,
}: IFilterDialog) => {
  const [filterOptions, setFilterOptions] = useState<IItemsAvailabilityFilterStategy>({
    type: 'above',
    qty: 0,
  });
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    if (customOptionObj) {
      const updatedObj = {
        ...customOptionObj,
      };
      if (updatedObj.qty === Infinity) updatedObj.qty = dataFormatting('number', updatedObj.qty);
      if (updatedObj.qty === 0) updatedObj.from = '0';
      setFilterOptions(updatedObj);
    }
  }, [customOptionObj]);

  const handleSave = () => {
    const errObj: any = {};
    let qty = +filterOptions.qty;
    if (!filterOptions.qty) {
      qty = 1;
    } else {
      const stringQty = `${filterOptions.qty}`;
      if (stringQty.includes('.') || stringQty.includes('e'))
        errObj.qty = 'Required Valid Whole Number';
    }

    if (isNaN(qty)) errObj.qty = 'Required Valid Whole Number';
    if (qty < 1) errObj.qty = `Must be a whole number 1 or more.`;
    setErrorObj(errObj);
    if (Object.keys(errObj).length > 0) return;
    saveFilter({
      title: title,
      attribute: attribute,
      selectedFilters: {
        qty: qty,
        type: filterOptions.type,
      },
    });
  };

  const handleClear = () => {
    clearFilter({
      title: title,
      attribute: attribute,
    });
  };

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!customOptionObj}>
      <DialogTitle>
        {!filterState.isApplied && (
          <PrimaryIconButton
            onClick={() =>
              openDialog({ title: 'New Filter', attribute: '+ NEW', from: attribute })
            }>
            <ArrowBack />
          </PrimaryIconButton>
        )}
        <span className="px-chips-filter-title">{getLabel('attribute', attribute)}</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          <div className="cell small-12 margin-bottom-1">Show Items where Availability is...</div>
          <TextField
            select
            value={filterOptions.type || ''}
            error={!!errorObj.type}
            helperText={errorObj.type || ''}
            className="cell small-12 margin-vertical-1"
            onChange={(e) =>
              setFilterOptions({
                ...filterOptions,
                type: e.target.value as 'above' | 'below',
              })
            }
            placeholder="Select either percent or cost">
            {[
              {
                id: 'above',
                label: `Above`,
              },
              {
                id: 'below',
                label: `Below`,
              },
            ].map((option: any, index: number) => (
              <MenuItem value={option.id} key={index}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Available Quantity"
            placeholder="1"
            className="cell small-12 margin-vertical-1"
            value={filterOptions.qty || ''}
            error={!!errorObj.qty}
            helperText={errorObj.qty || ''}
            onChange={(e) =>
              setFilterOptions({
                ...filterOptions,
                qty: e.target.value,
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>Clear</TertiaryButton>
        <PrimaryButton onClick={handleSave}>APPLY</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default FilterItemsAvailability;
