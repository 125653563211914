import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import { dataFormatting } from '../../../_lib/lib';
import { CustomCellRendererBuyer } from '../TableCellComponents';
import { dateFormats, manipulateDates } from '../Utils';

export const ColsDef = () => {
  const buyerOpenColsDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Days Remaining',
      field: 'daysRemaining',
      minWidth: 100,
    },
    {
      headerName: 'Item',
      field: 'phonexItemDescription',
      cellRenderer: CustomCellRendererBuyer,
      minWidth: 300,
    },
    {
      headerName: 'Warehouse',
      field: 'warehouse',
      minWidth: 100,
    },
    {
      headerName: 'SKU',
      field: 'itemNumber',
      minWidth: 150,
    },
    {
      headerName: 'Price',
      field: 'awardedPrice',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.data.awardedPrice),
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      minWidth: 100,
    },
    {
      headerName: 'Take All?',
      field: 'isTakeAll',
      valueFormatter: (params: ValueFormatterParams) => (params.data.isTakeAll ? 'Y' : 'N'),
      minWidth: 100,
    },
    {
      headerName: 'Fulfilled to Date',
      field: 'fulfilledToDateQuantity',
      valueFormatter: (params: ValueFormatterParams) => params.data.fulfilledToDateQuantity ?? '0',
      minWidth: 100,
    },
    {
      headerName: '% Fulfilled to Date',
      field: 'fulfilledToDatePercentage',
      valueFormatter: (params: ValueFormatterParams) => params.value ?? '0',
      minWidth: 100,
    },
    {
      headerName: '$ Fulfilled to Date',
      field: 'fulfilledToDatePrice',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.data.fulfilledToDatePrice, true),
      minWidth: 150,
    },
    {
      headerName: '$ Pending Fulfillment',
      field: 'pendingFulfillmentPrice',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.data.pendingFulfillmentPrice, true),
      minWidth: 150,
    },
    {
      headerName: 'Order Numbers',
      field: 'orderNumbers',
      cellRenderer: CustomCellRendererBuyer,
      minWidth: 150,
    },
    {
      headerName: 'Last Shipped Date',
      field: 'lastShippedDate',
      valueFormatter: (params: ValueFormatterParams) =>
        params.data.lastShippedDate
          ? manipulateDates({
              date: params.data.lastShippedDate,
              manipulate: { days: 0 },
              formatTo: dateFormats.monthIn3Letters,
            })
          : '--',
      minWidth: 150,
    },
  ];

  const buyerClosedColsDef: Array<ColDef | ColGroupDef> = [
    {
      headerName: 'Date Closed',
      field: 'closedDate',
      valueFormatter: (params: ValueFormatterParams) =>
        params.data.closedDate
          ? manipulateDates({
              date: params.data.closedDate,
              manipulate: { days: 0 },
              currentFormat: dateFormats.yearMonthDate,
              formatTo: dateFormats.monthIn3Letters,
            })
          : '--',
      minWidth: 120,
      colId: 'closedDate',
    },
    {
      headerName: 'Item',
      field: 'phonexItemDescription',
      cellRenderer: CustomCellRendererBuyer,
      minWidth: 300,
      colId: 'phonexItemDescription',
    },
    {
      headerName: 'Warehouse',
      field: 'warehouse',
      minWidth: 80,
    },
    {
      headerName: 'SKU',
      field: 'itemNumber',
      minWidth: 80,
    },
    {
      headerName: 'Price',
      field: 'awardedPrice',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.data.awardedPrice),
      minWidth: 100,
    },
    {
      headerName: 'Awarded',
      field: 'awardedQuantity',
      minWidth: 100,
    },
    {
      headerName: 'Take All?',
      field: 'isTakeAll',
      valueFormatter: (params: ValueFormatterParams) => (params.data.isTakeAll ? 'Y' : 'N'),
      minWidth: 100,
    },
    {
      headerName: 'Fulfilled to Date',
      field: 'fulfilledToDateQuantity',
      valueFormatter: (params: ValueFormatterParams) => params.data.fulfilledToDateQuantity ?? '0',
      minWidth: 100,
    },
    {
      headerName: '% Fulfilled to Date',
      field: 'fulfilledToDatePercentage',
      valueFormatter: (params: ValueFormatterParams) =>
        params.data.fulfilledToDatePercentage ?? '0',
      minWidth: 100,
    },
    {
      headerName: '$ Fulfilled to Date',
      field: 'fulfilledToDatePrice',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.data.fulfilledToDatePrice, true),
      minWidth: 150,
    },
    {
      headerName: '$ Not Fulfilled',
      field: 'pendingFulfillmentPrice',
      valueFormatter: (params: ValueFormatterParams) =>
        dataFormatting('currency', params.data.pendingFulfillmentPrice, true),
      minWidth: 150,
    },
    {
      headerName: 'Order Numbers',
      field: 'orderNumbers',
      cellRenderer: CustomCellRendererBuyer,
      minWidth: 150,
    },
    {
      headerName: 'Last Shipped Date',
      field: 'lastShippedDate',
      valueFormatter: (params: ValueFormatterParams) =>
        params.data.lastShippedDate
          ? manipulateDates({
              date: params.data.lastShippedDate,
              manipulate: { days: 0 },
              currentFormat: dateFormats.yearMonthDate,
              formatTo: dateFormats.monthIn3Letters,
            })
          : '--',
      minWidth: 120,
    },
  ];

  return { buyerOpenColsDef, buyerClosedColsDef };
};
