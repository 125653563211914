export const READY_TO_CHECKOUT = 'READY_TO_CHECKOUT';
export const AWAITING_TENANT_REVIEW = 'AWAITING_TENANT_REVIEW';
export const AWAITING_BUYER_REVIEW = 'AWAITING_BUYER_REVIEW';
export const OUT_OF_STOCK = 'OUT_OF_STOCK';

export const PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE';
export const BUY_AT_LIST = 'BUY_AT_LIST';
export const ACCEPTED = 'ACCEPTED';
export const EXPIRED = 'EXPIRED';
export const NOT_ACCEPTED = 'NOT_ACCEPTED';
export const PRICE_DROPPED = 'PRICE_DROPPED';
export const COUNTERED = 'COUNTERED';

export const offerStatusChipMap: any = {
  COUNTERED: 'countered',
  PRICE_DROPPED: 'pricedrop',
  NOT_ACCEPTED: 'rejected',
  PENDING_ACCEPTANCE: 'offered',
  ACCEPTED: 'accepted',
  EXPIRED: 'expired',
  BUY_AT_LIST: 'buyatlist',
};

export const offerStatusFilterChipMap: any = {
  COUNTERED: 'Countered Offers',
  PRICE_DROPPED: 'Price Drops',
  NOT_ACCEPTED: 'Rejected Offers',
  PENDING_ACCEPTANCE: 'Offered',
  ACCEPTED: 'Accepted Offers',
  EXPIRED: 'EXpired Offers',
  BUY_AT_LIST: 'At List Price',
};

export const offerStatusLabelMap: any = {
  PENDING_ACCEPTANCE: 'Offered',
  NOT_ACCEPTED: 'Rejected',
  ACCEPTED: 'Accepted',
  COUNTERED: 'Countered',
  PRICE_DROPPED: 'Price Drop',
  EXPIRED: 'Expired',
  BUY_AT_LIST: 'Buy At List',
};

export const poOffersStatusChip: any = {
  AWARDED: 'awarded',
  AWAITING_REVIEW: 'awaitingReview',
  REOFFER_AWAITING_REVIEW: 'awaitingReview',
  REOFFER_REQUEST: 'reOffer',
  REJECTED: 'rejected',
};

export const poOffersStatusLabelMap: any = {
  AWARDED: 'Awarded',
  AWAITING_REVIEW: 'Awaiting Review',
  REOFFER_AWAITING_REVIEW: 'Awaiting Review',
  REOFFER_REQUEST: 'Re-Offer Request',
  REJECTED: 'Rejected',
};
// export const offerStatusExtraInfoMap: any = {
//   PENDING_ACCEPTANCE: '',
//   NOT_ACCEPTED: 'Your offer of ${1} was Rejected',
//   ACCEPTED:
//     'Your offer of ${1} was accepted. New Price is valid for 1 order with minimum quantity of ${2}, expires on ${3}',
//   COUNTERED:
//     'Your offer of ${1} was countered. Seller Counter of ${2} is valid for 1 order with minimum quantity of ${3}, expires on ${4}',
//   PRICE_DROPPED:
//     'The list price was lowered and is now lower than your offer. You can checkout now at this new list price',
//   EXPIRED: 'Your offer of ${1} expired',
//   BUY_AT_LIST: '',
// };
