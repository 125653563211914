import React from 'react';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton } from '../../components/AtomComponents';
import { contractsSet } from '../../redux/actions/contractsActions';
import { bindActionCreators } from 'redux';
import { signContracts } from '../../_lib/lib';

import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GetAppIcon from '@material-ui/icons/GetApp';

import './Contracts.scss';

interface IDialogPropTypes {
  isDialogOpen: any;
  closeDialog: any;
  title?: any;
  onScrollContract?: any;
  content: React.ReactNode | any;
  actions?: React.ReactNode | string;
  label?: any;
  className?: string;
  [rest: string]: any;
}

const Dialog = ({
  isDialogOpen,
  closeDialog,
  title,
  onScrollContract,
  content,
  actions,
  label,
  className,
  ...rest
}: IDialogPropTypes) => {
  const ref: any = React.createRef();

  React.useEffect(() => {
    setTimeout(() => {
      if (ref.current.clientHeight <= ref.current.scrollHeight) {
        onScrollContract.setIsScrolledtate(true);
      }
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiDialog
      color="primary"
      open={isDialogOpen}
      onClose={closeDialog}
      aria-labelledby={title || ''}
      aria-describedby={label || ''}
      className={className}
      // style={{ zIndex: 9999 }}
      {...rest}>
      <DialogTitle className="px-contracts-popup">{title || ''}</DialogTitle>
      <DialogContent
        ref={ref}
        dangerouslySetInnerHTML={{ __html: content }}
        onScroll={(e: any) => {
          onScrollContract.onScrollDialog(
            e,
            onScrollContract.isScrolledState,
            onScrollContract.setIsScrolledtate
          );
        }}></DialogContent>
      {actions && <DialogActions className="margin-1">{actions}</DialogActions>}
    </MuiDialog>
  );
};

export function Contracts(props: any) {
  const [popupState, setPopupState] = React.useState<string>('');
  const [signedState, setSigenedState] = React.useState<Array<string>>([]);
  const [isScrolledState, setIsScrolledtate] = React.useState<Boolean>(false);

  React.useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '');
    document.title = String(config.siteTitle) + ' - Contracts';
  }, []);

  React.useEffect(() => {
    const signed: Array<string> = [];
    Object.keys(props.contractsState).map((i: any) => {
      if (props.contractsState[i].status === 'CONFIRMED') {
        signed.push(i);
      }
      return true;
    });
    setSigenedState([...signed]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sign = (policyId: any) => {
    signContracts(props.contractsState[policyId].contract_id).then(() => {
      const copyObj = Object.assign({}, props.contractsState);
      copyObj[policyId].status = 'CONFIRMED';
      contractsSet(copyObj);
      setSigenedState([...signedState, props.contractsState[policyId].policyTitle]);
      setPopupState('');
    });
  };

  const signAll = () => {
    if (Object.keys(props.contractsState).length < signedState.length) {
      alert('You need to read and agree with all Policies to continue.');
    } else {
      const allIds: Array<string> = [];
      Object.keys(props.contractsState).forEach((k: string) => {
        if (props.contractsState[k].status === 'CONFIRMED') {
          allIds.push(props.contractsState[k].contract_id);
        }
      });
      signContracts(allIds.join('|')).then(() => {
        document.location.href = '/';
      });
    }
  };

  const ContractsList = () => {
    return (
      <>
        <div className="grid-x px-contracts margin-bottom-3">
          <div className="cell small-12">
            <h1>Policy Review</h1>
            <p className="cell small-12 margin-bottom-3">
              Before we enable your account, we ask that you please review and accept our posted
              policies.
            </p>
          </div>
          <div className="cell small-12 margin-bottom-3">
            <div className="grid-x">
              {Object.keys(props.contractsState).map((k: string) => {
                return (
                  <div className="cell small-12" key={props.contractsState[k].policy_title}>
                    <div
                      className="px-contracts-policy link"
                      onClick={() => {
                        setPopupState(props.contractsState[k].policy_id);
                      }}>
                      <span>{props.contractsState[k].policy_title}</span>
                      <span>
                        {props.contractsState[k].status === 'PENDING' ? (
                          <RadioButtonUncheckedIcon />
                        ) : (
                          <CheckCircleIcon />
                        )}
                      </span>
                      <span>
                        {props.contractsState[k].status === 'PENDING' && (
                          <>Policy review required</>
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="cell small-12 margin-bottom-3">
            {props.contractsState && (
              <PrimaryButton
                disabled={signedState.length < Object.keys(props.contractsState).length}
                onClick={signAll}>
                Done
              </PrimaryButton>
            )}
          </div>
        </div>
      </>
    );
  };

  const getContent = (policy_id: string) => {
    if (props.contractsState && props.contractsState.hasOwnProperty(policy_id)) {
      return props.contractsState[policy_id].content;
    }
    return 'Missing.';
  };

  const GoContactOwner = () => {
    return (
      <>
        <div className="grid-x">
          <h1 className="cell small-12 margin-bottom-3">Contracts</h1>
          <h4 className="cell small-12 margin-bottom-3">Policy Review</h4>
          <p className="cell small-12 margin-bottom-1">There are changes in the policies.</p>
          <p className="cell small-12 margin-bottom-1">
            Please contact the Owner and request for the contracts to be reviewed and accepted.
          </p>
          <p className="cell small-12 margin-bottom-1">
            After that your account will be operational as before.
          </p>
        </div>
      </>
    );
  };

  const print = (innerHTML: string) => {
    // TODO: need to change this
    const mywindow: any = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + document.title + '</h1>');
    mywindow.document.write(innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
  };

  const onScrollDialog = (e: any, isScrolledState: any, setIsScrolledtate: any) => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setIsScrolledtate(true);
    }
  };

  return (
    <>
      {/* {props.userState && props.userState.email_id === props.userSate.owner_email_id ? <ContractsList /> : <GoContactOwner /> } */}
      {props.userState &&
      props.userState.username ===
        props.contractsState[Object.keys(props.contractsState)[0]].tenant_owner_email ? (
        <ContractsList />
      ) : (
        <GoContactOwner />
      )}

      {popupState.length > 0 && (
        <Dialog
          isDialogOpen={true}
          id="px-contract-dialog"
          className="px-contracts-popup"
          closeDialog={() => {
            setPopupState('');
            setIsScrolledtate(false);
          }}
          onScrollContract={{ onScrollDialog, isScrolledState, setIsScrolledtate }}
          maxWidth="md"
          title={props.contractsState[popupState].policy_title}
          content={getContent(popupState)}
          actions={
            <>
              <SecondaryButton
                className="margin-right-3"
                onClick={() => {
                  const innerHTML = document.getElementById('px-contract-dialog')?.innerHTML || '';
                  print(innerHTML);
                }}>
                <GetAppIcon />
                Print
              </SecondaryButton>
              {props.contractsState[popupState].status === 'PENDING' && (
                <PrimaryButton
                  className="text-uppercase"
                  disabled={!isScrolledState}
                  onClick={() => {
                    sign(props.contractsState[popupState].policy_id);
                  }}>
                  I agree to the Terms of Service
                </PrimaryButton>
              )}
              {props.contractsState[popupState].status !== 'PENDING' && (
                <PrimaryButton
                  className="text-uppercase"
                  onClick={() => {
                    setPopupState('');
                  }}>
                  Close
                </PrimaryButton>
              )}
            </>
          }
        />
      )}
    </>
  );
}

function mapDispatchToProps(dispatch: any) {
  return {
    contractsSet: bindActionCreators(contractsSet, dispatch),
  };
}

function mapStateToProps(state: any) {
  return {
    userState: state.userState,
    contractsState: state.contractsState?.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
