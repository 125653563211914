import * as types from '../actions/actionTypes';

export default function filterReducer(state: any = {}, action: any) {
  // console.log('filterReducer', state);
  switch (action.type) {
    case types.FILTER_SET:
      return { ...state, filterData: Object.assign({}, action.filter) };
    default:
      return state;
  }
}
