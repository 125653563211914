import React from 'react';
// import { ICellRendererParams } from 'ag-grid-community';

const ImportHighlighter = ({ additionalFieldVal, ...params }: any) => {
  if (!params?.data) return null;
  const attributesUpdated = params.data.attributesUpdated;
  let label =
    params.colDef.field && params.data[params.colDef.field]
      ? params.data[params.colDef.field]
      : params.value;
  if (
    params.colDef.field &&
    attributesUpdated &&
    Array.isArray(attributesUpdated) &&
    attributesUpdated.indexOf(params.colDef.field) > -1
  ) {
    label = <span className="px-inventory-highlight">{label || '--'}</span>;
  }
  if (
    params.data?.importItemCategory === 'MISSING_REQUIRED_ADDITIONAL_FIELD' &&
    additionalFieldVal
  ) {
    label = <span className="px-inventory-highlight">{label || '--'}</span>;
  }
  return <div>{label || '--'}</div>;
};

export default ImportHighlighter;
