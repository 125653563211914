import loaderimage  from '../LoadingDialog/loaderimage.svg'
import React from 'react'
import Dialog from '../Dialog/Dialog'

interface ILoadingDialog {
  isDialogOpen: boolean;
}

function LoadingDialog({
  isDialogOpen
}: ILoadingDialog) {
  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={() => {}}
      content={<img src={loaderimage} alt="Loading" />}
      actions={<></>}
      disableBackdropClick
      disableEscapeKeyDown
      // PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
    />
  );
}

export default LoadingDialog
