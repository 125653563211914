import * as types from '../actions/actionTypes';
export const setOfferClearingStats = (statsObj: any) => {
  return {
    type: types.PO_OFFER_CLEARING_STATS_SET,
    payload: statsObj,
  };
};

export const updateOfferClearingStats = (oldTab: string, newTab: string) => {
  return {
    type: types.PO_OFFER_CLEARING_STATS_UPDATE,
    payload: {
      oldTab,
      newTab,
    },
  };
};

export const poOfferClearingSet = (vals: any) => ({
  type: types.PO_OFFER_CLEARING_SET,
  payload: vals,
});

export const poOfferClearingUpdate = (values: any) => ({
  type: types.PO_OFFER_CLEARING_OFFER_UPDATE,
  payload:{
      values
    },
});
