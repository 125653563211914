import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';

export interface IFileWellProps {
  handleDrop: any;
  acceptedFiles?: any;
  filesLimit?: number;
  required?: boolean;
  error?: boolean;
  name?: string;
  helperText?: string;
  className?: string;
  customError?: string;
  resetError?:any
}

const FileWell = ({
  handleDrop,
  acceptedFiles,
  filesLimit,
  required,
  name,
  helperText,
  error,
  className,
  customError,
  resetError,
}: IFileWellProps) => {
  const handleChange = (files: any) => {
    handleDrop(files);
    if(resetError){
      resetError();
    }
  };

  return (
    <>
      <DropzoneArea
        filesLimit={filesLimit}
        acceptedFiles={acceptedFiles}
        onChange={handleChange.bind(this)}
        dropzoneClass={`${className} ${error ? 'Mui-error' : ''}`}
        dropzoneText={helperText || 'Drag and drop a file here or click'}
        data-id="uploadBox"
        showFileNames={true}
        inputProps={{
          required: !!required,
          name: name || 'uploadBox',
        }}
        getDropRejectMessage={(rejectedFile)=> `File ${rejectedFile.name} was rejected. ${customError ?? 'File type not supported'}`}
      />
    </>
  );
};

export default FileWell;
