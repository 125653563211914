import { combineReducers } from 'redux';
import userReducer from './userReducer';
import configReducer from './configReducer';
import contractsReducer from './contractsReducer';
import filterReducer from './filterReducer';
import stockGroupReducer from './stockGroupReducer';
import stockCustomReducer from './stockCustomReducer';
import pricingReducer from './pricingReducer';
import inventoryReducer from './inventoryReducer';
import urlReducer from './urlReducer';
import flagsReducer from './flagsReducer';
import customerReducer from './customerReducer';
import messageReducer from './messageReducer';
import sideMenuReducer from './sideMenuReducer';
import stockReducer from './stockReducer';
import offerClearingReducer from './offerClearingReducer';
import preorderReducer from './preorderReducer';
import fundingpreorderReducer from './fundingPreorderReducer';
import preorderBuyerReducer from './preorderBuyerReducer';
import itemSchedulingReducer from './itemSchedulingReducer';
import offerReducer from './offerReducer';
import poClearingReducer from './poClearingReducer';

const rootReducer = combineReducers({
  userState: userReducer,
  configState: configReducer,
  contractsState: contractsReducer,
  filterState: filterReducer,
  stockGroupState: stockGroupReducer,
  stockCustomState: stockCustomReducer,
  pricingState: pricingReducer,
  offerClearingState: offerClearingReducer,
  inventoryState: inventoryReducer,
  urlState: urlReducer,
  flagsState: flagsReducer,
  customerState: customerReducer,
  messageState: messageReducer,
  sideMenuState: sideMenuReducer,
  stockState: stockReducer,
  preorderState: preorderReducer,
  preorderBuyerState: preorderBuyerReducer,
  fundingpreorderState: fundingpreorderReducer,
  itemSchedulingState: itemSchedulingReducer,
  offerState: offerReducer,
  poClearingState: poClearingReducer,
});

export const InitialState = () => {
  const PxConfig = localStorage.getItem('PxConfig');
  let config = PxConfig && JSON.parse(PxConfig);
  config = config && { ...config, isLoaded: true };
  const initState = {
    userState: null,
    configState: config || { isLoaded: false },
    contractsState: null,
    filterState: { filterData: null },
    stockGroupState: { isLoaded: false, results: null },
    stockState: { isLoaded: false, results: null },
    stockCustomState: {},
    offerClearingState: {
      results: [],
      isLoaded: false,
      stats: {},
    },
    inventoryState: null,
    urlState: { url: '', preventWindowClose: null, preventRouteChange: null, preventLogout: null },
    flagsState: { isHyla: window.location.href.indexOf('hyla') > -1 },
    sideMenuState: {},
    preorderState: {
      awards: null,
      awardsClosed: null,
      awardsPending: null,
      uniqueBuyers: null,
      uniqueItems: null,
      buyerFilterLabel: null,
      itemFilterLabel: null,
    },
    preorderBuyerState: { awards: null, awardsClosed: null },
    fundingpreorderState: { funds: null },
    itemSchedulingState: { itemsList: null, uniqueCategories: null, uniqueWarehouses: null },
    offerState: { offersList: null },
    poClearingState: {
      offersList: null,
      uniqueBuyers: null,
      buyerFilterLabel: null,
      inventoryMetricsForAllGroups: null,
      salesMetricsForAllGroups: null,
      uniqueCategories: null,
      uniqueWarehouses: null,
    },
  };
  console.log('initState', initState);
  return initState;
};

export default rootReducer;
