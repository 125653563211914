import { awards } from '../../types/IPreOrders';
import * as types from './actionTypes';

export function awardsSet(awards: awards[] | null) {
  return { type: types.AWARDS_SET, awards };
}

export function awardsClosedSet(awardsClosed: awards[] | null) {
  return { type: types.AWARDS_CLOSED_SET, awardsClosed };
}

export const awardsPendingSet = (awardsPending: awards[] | null) => {
  return { type: types.AWARDS_PENDING_SET, awardsPending };
};

export const updateAwardsOpen = (status: number, values: any, field?: string) => {
  return {
    type: types.UPDATE_AWARDS,
    payload: {
      status,
      values,
      field,
    },
  };
};
