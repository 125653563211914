import {
  CircularProgress,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Error, Info, Warning } from '@material-ui/icons';
import { ICellRendererParams } from 'ag-grid-community';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { SecondaryButton } from '../../components/AtomComponents';
import { IPooffersItems } from '../../types/IPoOffers';
import { awardedRule } from '../../components/PreOrders/Utils';
import './CustomInputButton.scss';
import { IItemSchedulingTab } from '../../types/IItemSetup';

const tooltipStyleError = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'red',
    height: '5px',
    paddingTop: 0,
  },
}));
const tooltipStyleMsg = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'black',
    height: '5px',
    paddingTop: 0,
  },
}));

interface ICustomInputButton extends ICellRendererParams {
  updateOffer: (dataValue: IPooffersItems[]) => Promise<any>;
  offerItem: IPooffersItems;
  onSendPrice: (inputValue: string, data: IPooffersItems, value: string) => Promise<any>;
  parentNode: any;
  canEdit: boolean;
}

export const CustomInputButton = (params: ICustomInputButton) => {
  const [inputValue, setInputValue] = React.useState<string>('');
  const [errorMsg, setErrorMsg] = React.useState<String>('');
  const [endAdornment, setEndAdornment] = React.useState<JSX.Element>(<></>);
  const incrementMsg = `Increments of ${params.data.incrementSize}`;
  const isFinalizing = params.parentNode.data.itemStatus === IItemSchedulingTab.finalizing;
  const [loading, setLoading] = useState<boolean>(false);
  const [isQuantityError, setIsQuantityError] = useState<boolean>(false);

  React.useEffect(() => {
    if (errorMsg !== params.data.incrementMsg && errorMsg !== '')
      setEndAdornment(getAdornment('error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMsg]);

  const tooltipClassMsg = tooltipStyleMsg();
  const tooltipClassError = tooltipStyleError();

  useEffect(() => {
    params.data.setAwardDisable = setIsQuantityError;
    // eslint-disable-next-line
  }, []);

  const getAdornment = (endAdornmentStatus: string, extraMsg?: string) => {
    switch (endAdornmentStatus) {
      case 'roundOff': {
        return (
          <Tooltip
            classes={tooltipClassMsg}
            disableFocusListener
            disableTouchListener
            title={extraMsg ? extraMsg : incrementMsg}>
            <Warning className="px-message-warning" />
          </Tooltip>
        );
      }
      case 'error': {
        return (
          <Tooltip
            classes={tooltipClassError}
            disableFocusListener
            disableTouchListener
            title={errorMsg}>
            <Error className="errorMessage" color="error" />
          </Tooltip>
        );
      }
      case 'increment': {
        return (
          <Tooltip
            classes={tooltipClassMsg}
            disableFocusListener
            disableTouchListener
            title={incrementMsg}>
            <Info className="px-message-info" />
          </Tooltip>
        );
      }
      case 'default': {
        return <></>;
      }
      default:
        return <></>;
    }
  };

  const setRowLevelData = (updatedOffer: any) => {
    //set currentRowData and checkbox
    params.node.setData({
      ...updatedOffer[0],
    });
    params.node.setSelected(false);
    const parentNode = params.parentNode;

    //setting item level data and triggering aggFunc of group level data
    if (awardedRule(updatedOffer[0].status)) {
      const offers = parentNode.data.offers.map((offer: any) =>
        offer.offerId === updatedOffer[0].offerId ? updatedOffer[0] : offer
      );

      parentNode.setData({
        ...parentNode.data,
        awardedQuantity: parentNode.data.awardedQuantity + updatedOffer[0].awardedQuantity,
        awardedAveragePrice: parentNode.data.awardedAveragePrice + updatedOffer[0].awardedPrice,
        quantityAvailable: parentNode.data.quantityAvailable - updatedOffer[0].awardedQuantity,
        offers,
      });
      parentNode.parent.gridApi.refreshClientSideRowModel('aggregate');
    }

    //disable item level checkbox if no offer selectable
    let isAllUnselectable: boolean = false;
    isAllUnselectable =
      params.node.parent?.allLeafChildren?.some((node: any) => {
        return node.selectable;
      }) ?? false;
    if (!isAllUnselectable) {
      parentNode.setRowSelectable(false);
    }

    //disable group level checkbox if no item selectable
    let isParentNodeSelectAble = false;
    isParentNodeSelectAble = parentNode.parent.allLeafChildren?.some((node: any) => {
      return node.selectable;
    });
    if (!isParentNodeSelectAble) {
      parentNode.parent.setRowSelectable(false);
    }

    //refresh current row data and detail grid header
    const childNode = params?.node?.parent?.allLeafChildren ?? [];
    params.api.refreshCells({
      rowNodes: parentNode.data.quantityAvailable === 0 ? [...childNode] : [params.node],
      columns: ['checkboxSelection', 'status', 'awardedPrice', 'awardedQuantity'],
      force: true,
      suppressFlash: true,
    });
    params.api.refreshHeader();
  };

  const endAdornmentHandler = (status: string, extraMsg?: string) =>
    setEndAdornment(getAdornment(status, extraMsg));

  const onBlurHandler = () => {
    endAdornmentHandler('default');
    setErrorMsg('');
    let updatedValue;

    const regex = new RegExp('^[0-9]\\d*(((,\\d{3}))*(\\.\\d{0,2})?)$');
    if (!inputValue) {
      setInputValue('');
      return;
    }
    if (+inputValue <= 0) {
      setErrorMsg('Value must be > 0');
      endAdornmentHandler('error');
      return;
    }
    if (!regex.test(inputValue)) {
      setErrorMsg('Must be a valid price');
      endAdornmentHandler('error');
      return;
    }
    if (inputValue <= params.data.offerPrice) {
      setErrorMsg('Re-Offer Request Price must be above the highest offer');
      endAdornmentHandler('error');
      return;
    }
    if (typeof inputValue === 'string')
      updatedValue = +parseFloat(inputValue.replace(/,/g, '')).toFixed(2);
    else updatedValue = +parseFloat(inputValue).toFixed(2);
    setInputValue(updatedValue.toFixed(2).toString());
  };

  const onAwardOffer = async (e: any) => {
    const loadingTimeOut = setTimeout(() => {
      setLoading(true);
    }, 300);
    e.preventDefault();
    const res = await params.onSendPrice(inputValue, params.data, 'award');
    if (res) {
      setRowLevelData(res);
    }
    setLoading(false);
    clearTimeout(loadingTimeOut);
  };

  const onReOffer = async (e: any) => {
    const loadingTimeOut = setTimeout(() => {
      setLoading(true);
    }, 300);
    e.preventDefault();
    const res = await params.onSendPrice(inputValue, params.data, 'reoffer');
    if (res) {
      setRowLevelData(res);
    }
    setLoading(false);
    clearTimeout(loadingTimeOut);
  };

  const onRejectOffer = async (e: any) => {
    const loadingTimeOut = setTimeout(() => {
      setLoading(true);
    }, 300);
    e.preventDefault();
    const selectedOffer = params.data;
    const dataValue = {
      ...selectedOffer,
      status: 'REJECTED',
      offerAction: 'REJECTED',
      isTakeAll: params.data.isTakeAll === 0 ? false : true,
    };
    const res = await params.updateOffer([dataValue]);
    if (res) {
      setRowLevelData(res);
    }
    setLoading(false);
    clearTimeout(loadingTimeOut);
  };

  let showAwardRejectReoffer: ReactNode = <div></div>;
  showAwardRejectReoffer =
    inputValue === '' ? (
      <div>
        <SecondaryButton
          size="small"
          style={{ marginRight: '20px' }}
          disabled={
            !params.canEdit ||
            errorMsg !== '' ||
            loading ||
            isQuantityError ||
            params.parentNode.data.quantityAvailable === 0
          }
          onClick={onAwardOffer}>
          {loading ? <CircularProgress size={20} /> : 'AWARD'}
        </SecondaryButton>
        <SecondaryButton
          size="small"
          disabled={!params.canEdit || errorMsg !== '' || loading}
          onClick={onRejectOffer}>
          {loading ? <CircularProgress size={20} /> : 'REJECT'}
        </SecondaryButton>
      </div>
    ) : (
      <SecondaryButton
        size="small"
        disabled={
          !params.canEdit ||
          errorMsg !== '' ||
          loading ||
          isQuantityError ||
          params.parentNode.data.quantityAvailable === 0
        }
        style={{ marginRight: '20px', transform: 'none' }}
        onClick={onReOffer}>
        {loading ? <CircularProgress size={20} /> : 'RE-OFFER REQUEST'}
      </SecondaryButton>
    );

  return (
    <div className="clearing-input">
      <TextField
        size="small"
        name="price"
        variant="outlined"
        value={isFinalizing ? params.data.offerPrice.toFixed(2) : inputValue}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setErrorMsg('');
          endAdornmentHandler('default');
          setInputValue(e.target.value);
        }}
        onBlur={onBlurHandler}
        margin="normal"
        error={!!errorMsg}
        disabled={!params.canEdit || isFinalizing || params.parentNode.data.quantityAvailable === 0}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <h6 style={{ marginTop: 8 }}>$</h6>
            </InputAdornment>
          ),
          endAdornment: endAdornment,
        }}
      />
      {showAwardRejectReoffer}
    </div>
  );
};
