import { InputAdornment, MenuItem, Tooltip } from '@material-ui/core';
import { Close, HelpOutline, Search as SearchIcon } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { IScreenNames, IStartSearch } from '../../types/IFilterEngine';
import { getSearchOptions, searchOptionPlaceHolders } from '../../_lib/lib';
import TextField from '../AtomComponents/TextField';
import './SearchFieldV1.scss';

interface ISearch {
  searchTerm?: string;
  startSearch: (params: IStartSearch) => void;
  tooltipSettings?: {
    heading?: string;
    display?: boolean;
  };
  placeholder?: string;
  view: IScreenNames;
}

const SearchFieldV1 = ({
  startSearch,
  searchTerm,
  tooltipSettings,
  placeholder,
  view,
}: ISearch) => {
  const [searchText, setSearchText] = useState<any>();
  const [searchOption, setSearchOption] = useState<string>('itemNumber');
  const [searchOptions, setSearchOptions] = useState<Array<{ label: string; option: string }>>();
  const debounce = useDebounce(searchText, 500);

  useEffect(() => {
    startSearch({
      searchText,
      searchOption,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, searchOption]);

  useEffect(() => {
    if (!!searchOptions?.length) return;
    const options = [...getSearchOptions(view)];
    setSearchOption(options[0]?.option || '');
    setTimeout(() => {
      setSearchOptions(options);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  useEffect(() => {
    if (searchTerm !== searchText) setSearchText(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const clearSearchField = () => {
    setSearchText('');
    startSearch({ searchOption: searchOption, searchText: '' });
  };

  const onChangeSearchText = (e: any) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };

  const onChangeSearchOption = (e: any) => {
    setSearchOption(e.target.value);
    setSearchText('');
  };

  const tip = (
    <div className="px-tooltip px-text-description">
      <div className="px-tooltip-heading">
        <HelpOutline /> Tips on Using Search
      </div>
      <div className="px-tooltip-description">
        <span className="px-tooltip-font-large">
          {tooltipSettings?.heading || 'Match on list of SKUs from a spreadsheet'}
        </span>
        <span>Select a range of cells in a spreadsheet,</span>
        <span>then copy and paste into the search input</span>
      </div>
    </div>
  );

  return (
    <div className="px-search-block">
      <div className="px-search-bar">
        <SearchIcon />
        {searchOptions?.length ? (
          <TextField
            select
            size="small"
            className="px-search-option"
            value={searchOption}
            onChange={onChangeSearchOption}>
            {searchOptions.map((val: { label: string; option: string }) => {
              return (
                <MenuItem key={val.option} value={val.option}>
                  {val.label}
                </MenuItem>
              );
            })}
          </TextField>
        ) : (
          ''
        )}
        <TextField
          value={searchText || ''}
          onChange={onChangeSearchText}
          className="px-search-text"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {debounce ? <Close onClick={clearSearchField} className="pointer-mouse" /> : <></>}
              </InputAdornment>
            ),
          }}
          aria-describedby="Search item"
          placeholder={
            searchOptionPlaceHolders[searchOption] || placeholder || 'Search Item Description'
          }
          size="small"
        />
      </div>
      {!tooltipSettings || !!tooltipSettings.display ? (
        <div className="px-search-q margin-left-1">
          <Tooltip
            id="search-tooltip"
            title={tip}
            defaultChecked={true}
            TransitionProps={{ timeout: 0 }}
            interactive>
            <div>
              <HelpOutline />
            </div>
          </Tooltip>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SearchFieldV1;
