import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import { IAddedToStockFilterStategy, IFilterDialog } from '../../../types/IFilterEngine';
import { PrimaryButton, PrimaryIconButton, TertiaryButton, TextField } from '../../AtomComponents';
import { ArrowBack } from '@material-ui/icons';

const FilterAddedToStock = ({
  customOptionObj,
  title,
  attribute,
  filterState,
  clearFilter,
  saveFilter,
  closeDialog,
  openDialog,
  getLabel,
}: IFilterDialog) => {
  const [filterOptions, setFilterOptions] = useState<IAddedToStockFilterStategy>({
    qty: 0,
    type: 'atLeast',
  });
  const [errorObj, setErrorObj] = useState<any>({});

  useEffect(() => {
    if (customOptionObj) {
      const updatedObj = {
        ...customOptionObj,
      };
      if (updatedObj.qty === 0) updatedObj.from = '0';
      setFilterOptions(updatedObj);
    }
  }, [customOptionObj]);

  const handleChange = (e: any) => {
    if (isNaN(+e.target.value)) return;
    setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    const errObj: any = {};
    const qty = +(filterOptions?.qty || 50);
    if (isNaN(qty)) errObj.qty = 'Required Valid Number';
    if (qty < 0) errObj.qty = 'Must be a number between 0 and 100';
    if (qty > 100) errObj.qty = 'Must be a number between 0 and 100';
    if (!filterOptions.type) errObj.type = 'Please select valid option';
    setErrorObj(errObj);
    if (Object.keys(errObj).length > 0) return;
    let symbol = '';
    switch (filterOptions.type) {
      case 'atLeast':
        symbol = '>=';
        break;
      case 'noMoreThan':
        symbol = '<=';
        break;
    }
    saveFilter({
      title: title,
      attribute: attribute,
      selectedFilters: {
        qty: qty,
        type: filterOptions.type,
        symbol: symbol,
      },
    });
  };

  const handleClear = () => {
    clearFilter({
      title: title,
      attribute: attribute,
    });
  };

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!customOptionObj}>
      <DialogTitle>
        {!filterState.isApplied && (
          <PrimaryIconButton
            onClick={() =>
              openDialog({ title: 'New Filter', attribute: '+ NEW', from: attribute })
            }>
            <ArrowBack />
          </PrimaryIconButton>
        )}
        <span className="px-chips-filter-title">{getLabel('attribute', attribute)}</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          <div className="cell small-12 margin-bottom-1">Show Items where Stock Added is...</div>
          <TextField
            select
            name="type"
            className="cell small-12 margin-vertical-1"
            value={filterOptions.type || ''}
            error={!!errorObj.type}
            helperText={errorObj.type || ''}
            onChange={(e) =>
              setFilterOptions({
                ...filterOptions,
                type: e.target.value as 'atLeast' | 'noMoreThan',
              })
            }>
            {[
              {
                id: 'atLeast',
                label: 'At Least',
              },
              {
                id: 'noMoreThan',
                label: 'No More Than',
              },
            ].map((option: any, index: number) => (
              <MenuItem value={option.id} key={index}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="% of Available in Period"
            name="qty"
            placeholder="50"
            className="cell small-12 margin-vertical-1"
            value={filterOptions.qty || ''}
            error={!!errorObj.qty}
            helperText={errorObj.qty || ''}
            onChange={handleChange}
            InputProps={{
              endAdornment: '%',
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className="cell small-12">
            <h6 className="bold">Note: Will exclude stock added today</h6>
          </div>
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>Clear</TertiaryButton>
        <PrimaryButton onClick={handleSave}>APPLY</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default FilterAddedToStock;
