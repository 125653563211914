import React, { useEffect, useState } from 'react';
import { PrimaryButton, TertiaryButton } from '../../AtomComponents';
import Dialog from '../../Dialog/Dialog';
import { TextField } from '@material-ui/core';
import './DraftFinalizeDateDialog.scss';

export const DraftFinalizeDateDialog = ({
  closeDialog,
  isDialogOpen,
  title,
  confirmHandler,
  offerTimeRange,
  setLoadingDialog,
  isDaysFinalize,
}: any) => {
  const [datesValue, setDatesValue] = useState<number>();
  const [error, setError] = useState('');

  useEffect(() => {
    setDatesValue(offerTimeRange?.daysToFinalize);
  }, [offerTimeRange]);

  const onChangeHandler = (event: any) => {
    setDatesValue(event.target.value);
    if (event.target.value.toString() === '') return setError('');
    const num = event.target.value;
    if (typeof num !== 'number' && !(num > 0 && num < 32)) {
      setError('Enter a number between 1 and 31');
    } else {
      setError('');
    }
  };
  const onApplyHandler = () => {
    confirmHandler(datesValue);
  };
  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      closeDialog={closeDialog}
      title={title}
      content={
        <div className="grid-x">
          <div className="margin-bottom-3">
            <div className="text-faded" style={{ fontSize: '12px' }}>
              Select the number of days that will be scheduled for Finalizing PO Offers
            </div>
            {isDaysFinalize ? (
              <div className="draft-note">
                NOTE: Days value can only be updated before Draft items have dates applied
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="grid-x cell small-12 days-textfield">
            <div className="cell small-4 margin-top-1">Days for Finalize</div>
            <TextField
              label="Days"
              value={datesValue}
              onChange={onChangeHandler}
              variant="outlined"
              className="cell small-6"
              helperText={error}
              error={!!error}
              disabled={isDaysFinalize}
            />
          </div>
        </div>
      }
      actions={
        <>
          {isDaysFinalize ? (
            <PrimaryButton onClick={closeDialog}>Continue</PrimaryButton>
          ) : (
            <>
              <TertiaryButton onClick={closeDialog}>CANCEL</TertiaryButton>
              <PrimaryButton
                onClick={onApplyHandler}
                disabled={
                  !!error ||
                  offerTimeRange?.daysToFinalize.toString() === datesValue?.toString() ||
                  datesValue?.toString() === ''
                }>
                APPLY
              </PrimaryButton>
            </>
          )}
        </>
      }
      label="Finalize Date Selector"
    />
  );
};
