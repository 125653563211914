import { FormControlLabel, RadioGroup } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { PrimaryButton, PrimaryRadio, TertiaryButton, TextField } from '../AtomComponents';
import Dialog from '../Dialog/Dialog';
import { Search } from '@material-ui/icons';
import useDebounce from '../../hooks/useDebounce';

interface IBuyerDialog {
  title?: string;
  open: boolean;
  handleClose: any;
  buyerFilterLabel: any;
  importBuyerOffers: any;
}
const BuyerDialog = ({
  title,
  open,
  handleClose,
  buyerFilterLabel,
  importBuyerOffers,
}: IBuyerDialog) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [buyer, setBuyer] = useState<string>('');
  const [buyerList, setBuyerList] = useState<Array<string>>([]);
  const debounce = useDebounce(searchTerm, 800);

  useEffect(() => {
    if (buyerFilterLabel) {
      setBuyerList(buyerFilterLabel);
    }
  }, [buyerFilterLabel]);

  useEffect(() => {
    if (buyerFilterLabel && buyerFilterLabel.length > 0) {
      // filter out all the un desirable items from option state
      setBuyerList(
        buyerFilterLabel.filter((val: any) => {
          return (
            `${val.buyerName.toLowerCase()} - ${val.buyerNumber}`.indexOf(debounce.toLowerCase()) >
            -1
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = buyerFilterLabel.find((val: any) => val.buyerId.toString() === e.target.value);
    setBuyer(value.buyerId);
  };

  return (
    <Dialog
      isDialogOpen={open}
      closeDialog={handleClose}
      title={title}
      fullWidth
      maxWidth="sm"
      content={
        <>
          <TextField
            size="small"
            className="vertical-middle margin-top-1"
            aria-describedby="standard-weight-helper-text"
            placeholder="Search"
            value={searchTerm}
            fullWidth
            onChange={({ target: { value } }) => {
              setSearchTerm(value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              'aria-label': 'search',
            }}
          />
          <div className="margin-top-1">
            <RadioGroup
              aria-label="Buyer Select"
              name="buyer"
              value={buyer.toString()}
              onChange={onValueChange}>
              {buyerList.map((val: any) => {
                return (
                  <FormControlLabel
                    key={val.buyerId}
                    value={val.buyerId.toString()}
                    control={<PrimaryRadio name={val.buyerId.toString()} />}
                    label={`${val.buyerName} - ${val.buyerNumber}`}
                  />
                );
              })}
            </RadioGroup>
            <h5>{!buyerList.length ? 'No Matching Results' : ''}</h5>
          </div>
        </>
      }
      actions={
        <>
          <TertiaryButton onClick={handleClose}>Cancel</TertiaryButton>
          <PrimaryButton
            disabled={!buyer}
            onClick={() => {
              importBuyerOffers(buyer.toString());
            }}>
            SELECT BUYER
          </PrimaryButton>
        </>
      }
    />
  );
};

export default BuyerDialog;
