import { GridOptions } from 'ag-grid-community';
import { LoadingComponent, NoRowsMatchedComponent } from '../TableCellComponents';

export const getTableProperties = (onGridReady: (params: GridOptions) => void) => {
  const gridOptions: GridOptions = {
    //suppress Unwanted Behaviours
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    suppressMovableColumns: true,
    suppressCellSelection: true,
    enableCellTextSelection: true,
    suppressHorizontalScroll: true,
    applyColumnDefOrder: true,
    // Master Options
    onGridReady,
    defaultColDef: {
      flex: 1,
      cellClass: 'cell-vertical-align-text-left px-po-break-word border-bottom',
      wrapText: true,
      autoHeight: true,
      menuTabs: [],
      sortable: true,
      headerClass: 'eliminate-sort-order',
    },
    rowStyle: { background: '#eef5ff' },
    rowBuffer: 10,
    getRowNodeId: (data: any) => `${data.itemElectId}_${data.itemNumberWarehouse}`,
    // Scrolling Options
    debounceVerticalScrollbar: true,
    alwaysShowVerticalScroll: true,
    loadingOverlayComponentFramework: LoadingComponent,
    noRowsOverlayComponentFramework: NoRowsMatchedComponent,
  };

  return { gridOptions };
};
