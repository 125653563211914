import React from 'react';
import { IDialogControl } from '../types/IDialogControl';
const useDialog = (): IDialogControl => {
  // Customer User Add/Edit Dialog
  const [dialog, setDialog] = React.useState(null);
  const isDialog = Boolean(dialog);

  const openDialog: any = (e: any) => {
    if (e?.currentTarget) {
      setDialog(e.currentTarget);
    } else {
      setDialog(e);
    }
  };

  const closeDialog: any = (e: any) => {
    setDialog(null);
  };
  return [isDialog, openDialog, closeDialog, dialog];
};

export default useDialog;
