import { Card, CardContent } from '@material-ui/core';
import React from 'react';
import { TertiaryButton, WavingHandIcon } from '../AtomComponents';
import AwardsAndAwaitingItem from './AwardsAndAwaitingItem';
import ReOfferItem from './ReOfferItem';
import './PoOffersBox.scss';
import { dataFormatting } from '../../_lib/lib';
import { awaitingRule, awardedRule, reofferRule } from '../PreOrders/Utils';
import { IPooffersItems } from '../../types/IPoOffers';

interface IPoofferBox {
  currentTab: string;
  deleteItem: (item: IPooffersItems, offerId: number[]) => {};
  exportOfferSheet: () => {};
  importOfferSheet: () => void;
  offersList: any;
  updateItem: (item: IPooffersItems) => {};
}

const PoOffersBox = (props: IPoofferBox) => {
  const {
    currentTab,
    exportOfferSheet,
    importOfferSheet,
    offersList,
    deleteItem,
    updateItem,
  } = props;

  if (Object.keys(offersList).length === 0) {
    let heading = '';
    if (awardedRule(currentTab)) heading = 'PO Awards';
    if (reofferRule(currentTab)) heading = 'PO Re-Offer Requests';
    if (awaitingRule(currentTab)) heading = 'PO Offers Awaiting Review';

    return (
      <div className="cell margin-top-2 small-12 margin-bottom-2 grid-x">
        <WavingHandIcon className="margin-left-1 margin-right-2 faded_2" fontSize="inherit" />
        <div className="cell small-9">
          <p className="no-awards-heading-text">
            {`You will see your ${heading} here (nothing yet)`}
          </p>
          <p>
            Use{' '}
            <TertiaryButton className="no-highlight-on-hover" onClick={exportOfferSheet}>
              EXPORT OFFER SHEET
            </TertiaryButton>{' '}
            to download a spreadsheet of the items currently open for PO Offers.
          </p>
          <p>
            To place PO offers, fill in your PO offers on the downloaded spreadsheet and use{' '}
            <TertiaryButton className="no-highlight-on-hover" onClick={importOfferSheet}>
              IMPORT OFFER SHEET
            </TertiaryButton>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="grid-x cell small-12 px-po-cards">
      {Object.keys(offersList).map((warehouseCode: string) => {
        return (
          <Card
            id={`warehouse-${warehouseCode}`}
            key={`${currentTab}-warehouse-${warehouseCode}`}
            className="cell small-12 margin-bottom-1 px-po-offers-card">
            <CardContent>
              <div className="grid-x cell small-12 px-po-card-header">
                <h5 className="cell small-12 px-po-offers-card-title faded_1 bold margin-left-1">
                  Warehouse {warehouseCode}
                </h5>
                <span className="grid-x cell small-12 text-right margin-bottom-2">
                  <span className="cell small-9 faded_1 px-po-offers-card-subtotal">
                    Subtotal for Warehouse {warehouseCode} :{' '}
                    <span className="totalVal">
                      {dataFormatting('currency', offersList[warehouseCode].subTotal)}
                    </span>
                  </span>
                  <span className="cell small-3 text-center px-large-align-right"></span>
                </span>
              </div>
              <div className="grid-x">
                {reofferRule(currentTab)
                  ? offersList[warehouseCode].items.map((item: IPooffersItems, idx: number) => {
                      return (
                        <ReOfferItem
                          item={item}
                          key={`${currentTab}-${item.offerId}`}
                          deleteItem={deleteItem}
                          updateItem={updateItem}
                        />
                      );
                    })
                  : offersList[warehouseCode].items.map((item: IPooffersItems, idx: number) => {
                      return (
                        <AwardsAndAwaitingItem
                          item={item}
                          key={`${currentTab}-${item.offerId}`}
                          currentTab={currentTab}
                          deleteItem={deleteItem}
                        />
                      );
                    })}
              </div>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default PoOffersBox;
