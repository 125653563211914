import * as types from '../actions/actionTypes';

export default function stockReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.STOCK_DUMMY_SET:
      const newStock = {
        ...state,
        results: [...action.stock],
        isLoaded: true,
        isComplete: action.isComplete,
      };
      console.log('Redux store', newStock);
      return newStock;
    default:
      return state;
  }
}
