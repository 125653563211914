import { IconButton, Popper } from '@material-ui/core';
import React, { useState } from 'react';
import '../../components/ItemSetup/ShippingWindow.scss';

interface IPopover {
  content: JSX.Element;
  header: JSX.Element;
  iconsNeeded: JSX.Element;
  infoDetails: JSX.Element;
}

export const PopoverWrapper = (props: IPopover) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = async (event: any) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick} onBlur={handleClose}>
        {props.iconsNeeded}
      </IconButton>
      {props.content ?? ''}
      <Popper
        className="popper-root"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ zIndex: 3 }}
        modifiers={{
          offset: {
            enabled: true,
            offset: '0 , 5',
          },
          flip: {
            enabled: false,
          },
        }}>
        <div className="padding-1">
          <div className="popover-title margin-bottom-1">
            <div style={{ cursor: 'pointer' }} onBlur={handleClose} className="padding-0">
              {props.iconsNeeded}
            </div>
            <span>{props.header}</span>
          </div>
          {props.infoDetails}
        </div>
      </Popper>
    </div>
  );
};
