import { cloneDeep, isEmpty } from 'lodash';
import * as types from '../actions/actionTypes';

const getFilterSpecificData = (data: any) => {
  const uniqueCategories = new Set();
  const uniqueWarehouses = new Set();

  if (isEmpty(data?.items))
    return {
      uniqueCategories: [],
      uniqueWarehouses: [],
    };

  data.items.forEach((itemList: any) => {
    uniqueCategories.add(itemList.category);
    uniqueWarehouses.add(itemList.warehouse);
  });

  return {
    uniqueCategories,
    uniqueWarehouses,
  };
};

export default function itemSchedulingReducer(state: any = {}, action: any) {
  switch (action.type) {
    case types.ITEMSLIST_SET:
      const { uniqueCategories, uniqueWarehouses } = getFilterSpecificData(action.itemsList);
      return { ...state, itemsList: action.itemsList, uniqueCategories, uniqueWarehouses };
    case types.ITEMLIST_UPDATE: {
      const values = action.payload.values;
      const items = cloneDeep(state.itemsList.items);
      if (!items) {
        return { ...state };
      }
      values?.forEach((value: any) => {
        const currentItemIndex = items.findIndex(
          (item: any) =>
            item.itemElectId === value.itemElectId && item.itemNumber === value.itemNumber
        );

        let currentItem = items[currentItemIndex];
        currentItem = {
          ...currentItem,
          ...value,
        };
        items[currentItemIndex] = currentItem;
      });
      const itemsList = cloneDeep(state.itemsList);
      itemsList.items = items;
      return { ...state, itemsList };
    }
    case types.ITEMLIST_DELETE: {
      const itemElectId = action.payload.itemElectId;
      const items = cloneDeep(state.itemsList.items);
      if (!items) {
        return { ...state };
      }
      const newItems = items.filter((item: any) => item.itemElectId !== itemElectId);
      const itemsList = cloneDeep(state.itemsList);
      itemsList.items = newItems;
      return { ...state, itemsList };
    }
    default:
      return state;
  }
}
