import { IObject } from './IObject';

export interface IProductInformation {
  description: string;
  category: string;
  warehouse: string;
  sku: string;
  grade: string;
  preOrderReserve: number;
}

export interface IDefaultChannel {
  id: string;
  title: string;
}

export interface IProductQuantity {
  totalAvailable: number;
  auction: number;
  stockList: number;
  unassigned: number;
  defaultChannel: IDefaultChannel;
}

export interface IChannel {
  id: number | string;
  productInformation: IProductInformation;
  productQuantity: IProductQuantity;
}

export enum IChannelTab {
  all = "ALL",
  stocklist = "STOCKLIST",
  auction = "AUCTION",
  notAssign = "UNASSIGNED",
  preOrder = "PREORDER",
}

export type IEditChannel = IObject<any>;

export function generateInitialEditChannelData() {
  return {
    description: '',
    itemNumberWarehouseList: [],
    defaultChannel: 'Stock List',
    selectedIndexes: [],
    totalQuantityAvailable: [],
    title: 'Update Default Channel',
  };
}

export function generateInitialAssignSalesFormData() {
  return {
    totalQuantityAvailable: 0,
    description: '',
    itemNumberWarehouse: '',
    defaultChannel: 'Stock List',
    title: 'Assign to Sales Channel',
    selectedIndex: 0,
  };
}

// dependency: populating additional channels list (channels page)
export const defaultChannelValueDisplayMap: any = {
  STOCKLIST: 'Stock List',
  AUCTION: 'Auction',
  UNASSIGNED: 'Not Assigned',
  PREORDER: 'Purchase Order',
};

export const defaultChannelValueAPIMap: any = {
  'Stock List': 'STOCKLIST',
  Auction: 'AUCTION',
  'Not Assigned': 'UNASSIGNED',
  'Purchase Order': 'PREORDER',
};

// dependency: table column mapping; tab mapping; 
export const defaultChannelValueQuantityMap: any = {
  'Stock List': 'stockListQuantityAvailable',
  Auction: 'auctionQuantityAvailable',
  'Not Assigned': 'unassignedQuantityAvailable',
  'Purchase Order': 'preOrderQuantityAvailable',
};
