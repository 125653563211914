import * as types from './actionTypes';

export function itemSchedulingSet(itemsList: any) {
  return { type: types.ITEMSLIST_SET, itemsList };
}

export const updateItemsDraft = ( values: any) => {
  // console.log("val",values)
  return {
    type: types.ITEMLIST_UPDATE,
    payload: {
      values,
    },
  };
};

export const deleteItemDraftScheduled = (itemElectId:number) => {
  return {
    type: types.ITEMLIST_DELETE,
    payload: {
      itemElectId
    }
  }
}
