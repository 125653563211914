import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// URL Template
// import Login from './components/Login/Login';
// import ResetPassword from './components/Login/ResetPassword';
// ALL
import TopMenu from './components/TopMenu/TopMenu';
//ERROR HANDLER
// import useDataService from './hooks/useDataService';
// LAYOUTS
import DashboardLayout from './layouts/layout-dashboard';
import * as configActions from './redux/actions/configActions';
import * as contractsActions from './redux/actions/contractsActions';
import * as customerActions from './redux/actions/customerActions';
import * as filterActions from './redux/actions/filterActions';
import * as inventoryActions from './redux/actions/inventoryActions';
import * as pricingActions from './redux/actions/pricingActions';
import * as stockActions from './redux/actions/stockActions';
import * as stockGroupActions from './redux/actions/stockGroupActions';
import * as userActions from './redux/actions/userActions';
import Theme from './Theme';
// VIEWS
import Contracts from './views/Contracts/Contracts';
// import Homepage from './views/Homepage/Homepage';
import PreOrders from './views/PreOrders/PreOrders';
import PreOrdersBuyer from './views/PreOrders/PreOrdersBuyer';
import { getConfig, getToken, mapPathToRedirecting } from './_lib/lib';
import CreateOrders from './views/CreateOrders/CreateOrders';
import Funding from './views/Funding/Funding';
import useDataService from './hooks/useDataService';
import apiToUrlMap from './ApiMapping';
import loaderimage from './components/LoadingDialog/loaderimage.svg';
import ItemSetup from './views/ItemSetup/ItemSetup';
import PoOffers from './views/PoOffers/PoOffers';
import PoClearing from './views/PoClearing/PoClearing';

// let called = 1;

const PrivateRoute = ({
  children,
  redux: {
    props: {
      userState,
      authorities,
      stockGroupState,
      customerActions,
      contractsActions,
      contractsState,
      pricingActions,
      inventoryActions,
      stockGroupActions,
    },
  },
  ...rest
}: any) => {
  const location = useLocation();

  // const fetchContractState = async () => {
  //   try {
  //     const resp = await fetchUrl('get', apiToUrlMap.getCustomerSettings, {});
  //     if (resp && resp.hasOwnProperty('results') && Object.keys(resp.results).length) {
  //       contractsActions.contractsSet(resp.results);
  //     } else {
  //       contractsActions.contractsSet('noContracts');
  //     }
  //   } catch (error) {
  //     contractsActions.contractsSet('noContracts');
  //   }
  // };

  React.useEffect(() => {
    //   if (!contractsState) {
    //     getContracts()
    //       .then((resp: any) => {
    //         if (resp && resp.hasOwnProperty('results') && Object.keys(resp.results).length) {
    //           contractsActions.contractsSet(resp.results);
    //         } else {
    //           contractsActions.contractsSet('noContracts');
    //         }
    //       })
    //       .catch(() => {
    contractsActions.contractsSet('noContracts');

    //         // contractsActions.contractsSet('error');
    //       });
    //   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!contractsState ? (
        <div className="grid-x margin-vertical-2 margin-horizontal-2">{loading}</div>
      ) : contractsState === 'error' ? (
        <div className="grid-x margin-vertical-2 margin-horizontal-2">Something went wrong</div>
      ) : contractsState === 'noContracts' ? (
        <Route
          {...rest}
          render={({ location }) =>
            userState.isSignedIn ? (
              <DashboardLayout isMenuOpened={rest.isMenuOpened}>{children}</DashboardLayout>
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            )
          }
        />
      ) : // If, yes, if it did find contracts, but you are not in the contracts page
      typeof contractsState === 'object' && location.pathname.indexOf('contracts') === -1 ? (
        <Route
          {...rest}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: '/contracts',
                state: { from: location },
              }}
            />
          )}
        />
      ) : (
        // Or finally just open contracts page
        <Route
          {...rest}
          render={({ location }) =>
            userState.isSignedIn ? (
              <DashboardLayout isMenuOpened={rest.isMenuOpened}>
                <Contracts />
              </DashboardLayout>
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};

const PxRouter = (props: any) => {
  const { userState, sideMenuState } = props.props;
  const defaultVal: any =
    Object.keys(sideMenuState).find((menu: any) => sideMenuState[menu] === 'show') ?? '';

  // useEffect(() => {
  //   if (
  //     props.props.sideMenuState?.Fulfillment === 'hide' &&
  //     document.location.href.indexOf('fulfillment') > -1
  //   )
  //     <Redirect to="/funding" />;
  //   if (
  //     props.props.sideMenuState?.Funding === 'hide' &&
  //     document.location.href.indexOf('funding') > -1
  //   )
  //     <Redirect to="/fulfillment" />;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Theme redux={props}>
      <Router>
        <header className="cell small-12">
          <TopMenu />
        </header>
        <div className="cell small-12">
          <Switch>
            {/* <Route path="/" exact={true}>
              <PreOrders />
            </Route> */}
            <PrivateRoute path={'/fulfillment'} redux={props} isMenuOpened={1}>
              {sideMenuState.Fulfillment === 'hide' && <Redirect to="/" />}
              {userState?.userType === 'ADMIN' ? <PreOrders /> : <PreOrdersBuyer />}
            </PrivateRoute>
            {/* <Route path="/" exact={true}>
              <PreOrders />
            </Route> */}
            {/* <PrivateRoute path="/" redux={props} isMenuOpened={1}>
              <PreOrders />
            </PrivateRoute> */}
            <PrivateRoute path="/preorders/create" exact={true} redux={props} isMenuOpened={1}>
              {(userState?.userType === 'BUYER' || sideMenuState.Fulfillment === 'hide') && (
                <Redirect to="/" />
              )}
              <CreateOrders />
            </PrivateRoute>
            <PrivateRoute path="/funding" redux={props} isMenuOpened={1}>
              {(userState?.userType === 'BUYER' || sideMenuState.Funding === 'hide') && (
                <Redirect to="/" />
              )}
              <Funding />
            </PrivateRoute>
            <PrivateRoute path="/po-offer-clearing" exact={true} redux={props} isMenuOpened={1}>
              {(userState?.userType === 'BUYER' ||
                !userState?.po_offers_enabled ||
                sideMenuState.OfferClearing === 'hide') && <Redirect to="/" />}
              <PoClearing />
            </PrivateRoute>
            <PrivateRoute path="/item-scheduling" exact={true} redux={props} isMenuOpened={1}>
              {(userState?.userType === 'BUYER' ||
                !userState?.po_offers_enabled ||
                sideMenuState.ItemScheduling === 'hide') && <Redirect to="/" />}
              <ItemSetup />
            </PrivateRoute>
            <PrivateRoute path="/po-offers" exact={true} redux={props} isMenuOpened={0}>
              {(userState?.userType === 'ADMIN' ||
                !userState?.po_offers_enabled ||
                sideMenuState.Fulfillment === 'hide') && <Redirect to="/" />}
              <PoOffers />
            </PrivateRoute>
            <PrivateRoute path="/contracts" redux={props} isMenuOpened={1}>
              <Contracts />
            </PrivateRoute>
            {/* <ResetPasswordRoute path="/reset-password" redux={props} /> */}
            <Redirect from="/" to={mapPathToRedirecting[defaultVal]} />
          </Switch>
        </div>
      </Router>
    </Theme>
  );
};

const loading = (
  <div className="cell small-12 loading-class">
    <img src={loaderimage} alt="Loading" />
  </div>
);

function PhonexRouter(props: any) {
  //  >> code of auth starts
  const { fetchUrl } = useDataService();

  interface IAuthInfoResponse {
    first_name: string;
    last_name: string;
    email: string;
    authorities: string;
    user_type: string;
    buyer_id?: string;
    po_offers_enabled?: boolean;
  }

  const getAuthInfo = async () => {
    try {
      const res: IAuthInfoResponse = await fetchUrl('post', apiToUrlMap.authInfo, {});
      props.userActions.setUserAndFetchAuthorities({
        firstName: res.first_name.trim(),
        lastName: res.last_name.trim(),
        email: res.email,
        authorities: res.authorities,
        userType: res.user_type,
        // userType: 'BUYER',
        buyerId: res.buyer_id ?? '',
        po_offers_enabled: res.po_offers_enabled,
      });
    } catch (error) {
      console.error('error while dummy auth call', error);
    }
  };

  useEffect(() => {
    const clearLocalStorageBasedOnToken = () => {
      const newJWTToken = getToken();
      const oldJWTToken = localStorage.getItem('jwtToken');
      if (oldJWTToken === null || newJWTToken !== oldJWTToken) {
        localStorage.removeItem('PO-Open-Selected-State');
        localStorage.setItem('jwtToken', newJWTToken ?? '');
      }
    };

    async function fetchConfigAndUser() {
      try {
        const remoteConfig = await getConfig();
        props.configActions.configSet(remoteConfig);

        // clear selectedState on new Login
        clearLocalStorageBasedOnToken();

        // TODO: Update it
        getAuthInfo();
      } catch (error: any) {
        console.error(error);
        // props.userActions.userStateSet({ isSignedIn: false });
      }
    }

    fetchConfigAndUser();
    // pxBearerToken
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // >>code of auth ends

  return (
    <div className="grid-x">
      {props.configState.isLoaded &&
      props.userState &&
      Object.keys(props.sideMenuState).length > 0 ? (
        <PxRouter props={props} />
      ) : (
        loading
      )}
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    userState: state.userState,
    configState: state.configState,
    contractsState: state.contractsState?.data,
    filterState: state.filterState,
    stockGroupState: state.stockGroupState,
    stockState: state.stockState,
    authorities: state.userState?.authorities,
    sideMenuState: state.sideMenuState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    configActions: bindActionCreators(configActions, dispatch),
    contractsActions: bindActionCreators(contractsActions, dispatch),
    stockActions: bindActionCreators(stockActions, dispatch),
    pricingActions: bindActionCreators(pricingActions, dispatch),
    inventoryActions: bindActionCreators(inventoryActions, dispatch),
    stockGroupActions: bindActionCreators(stockGroupActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhonexRouter);
