import React, { useEffect, useRef, useState } from 'react';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import { SvgIcon, Tabs, Tab, Badge } from '@material-ui/core';
import { TertiaryButton, SecondaryButton, WavingHandIcon } from '../../components/AtomComponents';

import './PoOffers.scss';
import PoOffersBox from '../../components/PoOffers/PoOffersBox';
import ImportPopup from '../../components/ImportPopup/ImportPopup';

import useDataService from '../../hooks/useDataService';
import apiToUrlMap from '../../ApiMapping';
import { awaitingRule, awardedRule, formatDateByMonthDay } from '../../components/PreOrders/Utils';
import { eMessageType } from '../../types/IMessageType';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import PoOffersItemImport from '../../components/PoOffers/PoOffersItemImport';
import { cloneDeep, isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import * as offersActions from '../../redux/actions/offersActions';
import { connect } from 'react-redux';
import {
  IImportOffers,
  IOffersTab,
  IPooffersItems,
  OfferImportChanges,
} from '../../types/IPoOffers';
import { ConfirmDialog } from '../../components/Funding/ConfirmDialog';
import { IConfirmDialogProps, initialConfirmDialogProps } from '../../types/IPreOrders';
import WarningIcon from '@material-ui/icons/Warning';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

interface ISummary {
  consolidatedOfferStartDate: string;
  consolidatedOfferEndDate: string;
  consolidatedShippingStartDate: string;
  consolidatedShippingEndDate: string;
  durationLeft: string;
}

function PoOffers({ offerState, offersActions }: any) {
  const { offersList } = offerState;

  //loading state
  const [loadingDialog, setLoadingDialog] = useState<boolean>(false);

  //notification state
  const [isReofferDialog, setIsReofferDialog] = useState<boolean>(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState<IConfirmDialogProps>(
    initialConfirmDialogProps
  );
  const [isVisited, setIsVisited] = useState<boolean>(false);

  //offers
  const [summary, setSummary] = useState<ISummary>();

  //use data service
  const { fileUpload, fetchUrl, exportData, openSnackBar } = useDataService();

  // Tab Maintenance
  const [tabState, setTabState] = React.useState<IOffersTab>(IOffersTab.awarded);

  // Import Items
  const [importDialog, setImportDialog] = useState<boolean>(false);
  const [currentFileName, setCurrentFileName] = useState<string>('');
  const [importData, setImportData] = useState<IImportOffers | null>();
  const pxImportRef: any = useRef();
  const [filteredGroupItems, setfilteredGroupItems] = useState({
    [IOffersTab.awarded]: {},
    [IOffersTab.reoffer]: {},
    [IOffersTab.awaitingReview]: {},
  });
  const [badgeCount, setBadgeCount] = useState({
    [IOffersTab.awarded]: 0,
    [IOffersTab.reoffer]: 0,
    [IOffersTab.awaitingReview]: 0,
  });
  const [offerList, setOfferList] = useState<any>();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [offsetMarginBottom, setOffsetMarginBottom] = useState(0);

  const getDimensions = (ele: any) => {
    const { height, top: topFromViewPort } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop + 60; // Adding 45px header height as the offsetTop only returns height relative to positioned parents container
    const offsetBottom = offsetTop + height + 12;

    return {
      height,
      offsetTop,
      offsetBottom,
      topFromViewPort,
    };
  };

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('PxConfig') || '{}');
    if (config.siteTitle) document.title = String(config.siteTitle) + ' | PO Offers';
  }, []);

  const getPOoffers = async () => {
    try {
      setLoadingDialog(true);
      const res = await fetchUrl('get', apiToUrlMap.getPOoffers);
      offersActions.offersSet(res);
      if (!isVisited) {
        setIsReofferDialog(res.isReofferFound);
      }
      setLoadingDialog(false);
    } catch (error: any) {
      openSnackBar('Something went wrong. Try again.', eMessageType.error);
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  useEffect(() => {
    const cachedRef = headerRef.current,
      observer = new IntersectionObserver(
        ([e]) => {
          e.target.classList.toggle('box-shadow-3', e.intersectionRatio < 1);
          e.target.classList.toggle('px-box', e.intersectionRatio < 1);
        },
        {
          threshold: [1],
        }
      );

    observer.observe(cachedRef!);
    const getPOSummary = async () => {
      try {
        const res = await fetchUrl('get', apiToUrlMap.getSummary);
        setSummary(res);
      } catch (error: any) {
        openSnackBar('Something went wrong. Try again.', eMessageType.error);
      }
    };
    getPOoffers();
    getPOSummary();

    // unmount
    return function () {
      observer.unobserve(cachedRef!);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hash = () => {
    setTimeout(() => {
      const el = document.getElementById(window.location.hash.replace('#', ''));
      if (el) {
        window.scrollTo({
          top: getDimensions(el).offsetTop - getDimensions(headerRef.current).height - 20,
          left: 0,
        });
      }
    }, 0);
  };

  useEffect(() => {
    if (!offerList) return;
    const { height: headerHeight } = getDimensions(headerRef.current);
    const linksArray = Object.keys(offerList);
    if (linksArray.length > 0) setOffsetMarginBottom(window.innerHeight - headerHeight - 14);
    else setOffsetMarginBottom(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState, offerList]);

  useEffect(() => {
    const handleScroll = () => {
      if (offersList) {
        if (headerRef.current) {
          const { height: headerHeight } = getDimensions(headerRef.current);
          const warehouseBoxes = document.querySelectorAll('[id^=warehouse-]');
          const warehouseLinks = document.querySelectorAll('a[href*=warehouse-]');
          const scrollPosition = Math.ceil(window.scrollY + headerHeight + 20);
          warehouseBoxes.forEach((ele: any, index: number) => {
            const { offsetBottom, offsetTop } = getDimensions(ele);
            warehouseLinks[index].classList.toggle(
              'active',
              scrollPosition >= offsetTop && scrollPosition < offsetBottom
            );
          });
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offersList]);

  const filterOffersIntoDifferentTabs = (offers: IPooffersItems[]) => {
    let awardsTabItems: any = {},
      reofferTabItems: any = {},
      awaitingReviewItems: any = {};
    let awardsTabBagdeCount = 0,
      reofferTabBadgeCount = 0,
      awaitingReviewBadgeCount = 0;
    offers.forEach((offer: any) => {
      let currentTabItems: any = {};
      if (awardedRule(offer.offerStatus)) {
        currentTabItems = awardsTabItems;
        awardsTabBagdeCount++;
      } else if (awaitingRule(offer.offerStatus)) {
        currentTabItems = awaitingReviewItems;
        awaitingReviewBadgeCount++;
      } else {
        currentTabItems = reofferTabItems;
        reofferTabBadgeCount++;
      }
      if (!currentTabItems[offer.warehouse]) {
        currentTabItems[offer.warehouse] = {};
        currentTabItems[offer.warehouse].items = [];
        currentTabItems[offer.warehouse].subTotal = 0;
      }
      currentTabItems[offer.warehouse].items.push({ ...offer });
      currentTabItems[offer.warehouse].subTotal += offer.offerPrice * offer.offerQuantity;
    });
    return {
      groupedItems: {
        [IOffersTab.awarded]: awardsTabItems,
        [IOffersTab.reoffer]: reofferTabItems,
        [IOffersTab.awaitingReview]: awaitingReviewItems,
      },
      badgeCount: {
        [IOffersTab.awarded]: awardsTabBagdeCount,
        [IOffersTab.reoffer]: reofferTabBadgeCount,
        [IOffersTab.awaitingReview]: awaitingReviewBadgeCount,
      },
    };
  };

  useEffect(() => {
    if (!offersList) return;
    const offers = offersList.offers;
    const { groupedItems, badgeCount } = filterOffersIntoDifferentTabs(offers);
    setfilteredGroupItems(groupedItems);
    setBadgeCount(badgeCount);
  }, [offersList]);

  useEffect(() => {
    setOfferList(filteredGroupItems[tabState]);
  }, [tabState, filteredGroupItems]);

  const NotificationReofferDialogContent = () => {
    return (
      <h6 className="notification-content">
        Based on market conditions we recommend you re-offer on these items.
        <br />
        <br />
        If you do not re-offer and your current offer is the highest you may still be awarded.
        <br />
        <br />
        Rejected offers will not be awarded unless you re-offer at a higher price.
      </h6>
    );
  };

  useEffect(() => {
    setConfirmDialogProps({
      isDialogOpen: isReofferDialog,
      title: 'You Have Re-Offer Requests',
      confirmDialogContent: NotificationReofferDialogContent,
      confirmActionProps: {
        confirmLabel: 'Re-Offer Requests',
        confirmHandler: () => {
          setIsReofferDialog(false);
          setIsVisited(true);
          setTabState(IOffersTab.reoffer);
        },
      },
      backdropHandler: () => {
        setIsReofferDialog(false);
        setIsVisited(true);
      },
    });
  }, [isReofferDialog]);

  const handleDelete = (item: IPooffersItems, isUpdate?: boolean) => {
    const currentTabState = cloneDeep(offerList);
    const currentWarehouseItems = currentTabState[item.warehouse];
    const offers = cloneDeep(currentWarehouseItems.items);
    if (!offers) {
      return;
    }
    const newOffers = offers.filter((offers: IPooffersItems) => offers.offerId !== item.offerId);
    const currentTabStateSubTotal = currentTabState[item.warehouse].subTotal;
    currentTabState[item.warehouse] = {
      items: newOffers,
      subTotal: currentTabStateSubTotal - item.offerQuantity * item.offerPrice,
    };
    if (currentTabState[item.warehouse].items.length === 0) {
      delete currentTabState[item.warehouse];
    }
    if (!isUpdate) {
      setfilteredGroupItems((prev: any) => ({ ...prev, [tabState]: currentTabState }));
      setBadgeCount((prev) => ({ ...prev, [tabState]: prev[tabState] - 1 }));
    }
    return currentTabState;
  };

  const deleteItem = async (item: IPooffersItems, offerId: number[]) => {
    try {
      await fetchUrl('delete', apiToUrlMap.getPOoffers, { data: { offerIds: offerId } });
      handleDelete(item);
      openSnackBar('Item is deleted sucessfully', eMessageType.success);
      return true;
    } catch (error: any) {
      if (error.message.toUpperCase().includes('FINALIZING')) {
        openSnackBar(error.message, eMessageType.error);
        return false;
      }
      openSnackBar('Could not delete item. Try again', eMessageType.error);
      return false;
    }
  };

  const handleUpdate = (item: IPooffersItems) => {
    const currentTabState = cloneDeep(offerList);
    const currentWarehouseItems = currentTabState[item.warehouse];
    const offers = cloneDeep(currentWarehouseItems.items);
    if (!offers) {
      return;
    }
    const currentItemIndex = offers.findIndex(
      (offer: IPooffersItems) => offer.offerId === item.offerId
    );
    let currentItem = offers[currentItemIndex];
    currentItem = {
      ...currentItem,
      ...item,
    };
    offers[currentItemIndex] = currentItem;
    const newOffers = handleDelete(item, true);
    setfilteredGroupItems((prev: any) => {
      const awaitingReview = cloneDeep(prev[IOffersTab.awaitingReview]);
      if (awaitingReview[item.warehouse]) {
        awaitingReview[item.warehouse].items.push(currentItem);
        awaitingReview[item.warehouse].subTotal +=
          currentItem.offerQuantity * currentItem.offerPrice;
      } else {
        awaitingReview[item.warehouse] = {};
        awaitingReview[item.warehouse].items = [currentItem];
        awaitingReview[item.warehouse].subTotal =
          currentItem.offerQuantity * currentItem.offerPrice;
      }
      return {
        ...prev,
        [IOffersTab.awaitingReview]: awaitingReview,
        [IOffersTab.reoffer]: newOffers,
      };
    });
    setBadgeCount((prev) => ({
      ...prev,
      [IOffersTab.reoffer]: prev[IOffersTab.reoffer] - 1,
      [IOffersTab.awaitingReview]: prev[IOffersTab.awaitingReview] + 1,
    }));
  };

  const updateItem = async (item: IPooffersItems) => {
    try {
      const res = await fetchUrl('patch', apiToUrlMap.getPOoffers, { body: item }, true);
      handleUpdate(res);
      openSnackBar('Item is updated sucessfully', eMessageType.success);
    } catch (error: any) {
      if (error.message.includes('Available Forecast')) {
        openSnackBar(error.message, eMessageType.error);
        return error.message;
      }
      if (error.message.toUpperCase().includes('FINALIZING')) {
        openSnackBar(error.message, eMessageType.error);
        return false;
      }
      openSnackBar('Something went wrong. Try again.', eMessageType.error);
      return false;
    }
  };
  const handleTabChange = (e: any, tabVal: IOffersTab) => {
    setTabState(tabVal);
  };

  const exportOfferSheet = async () => {
    setLoadingDialog(true);
    try {
      let today = new Date();
      let fileName = `PO Offer Sheet Export-${today.getFullYear()}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getDate()}.xlsx`;
      await exportData({
        url: apiToUrlMap.exportOffer,
        fileName,
      });
      openSnackBar('File Exported', eMessageType.success);
    } catch (error: any) {
      openSnackBar('Something has gone wrong. Try again', eMessageType.error);
    }
    setLoadingDialog(false);
  };

  // Import Dialog Manager
  const openImportDialog = () => setImportDialog(true);
  const closeImportDialog = () => setImportDialog(false);

  //import draft items functions
  const handleUpload = async (file: File) => {
    try {
      const res = await fileUpload(file, apiToUrlMap.importOffer);
      const dataToImport: any = cloneDeep(res);
      setImportData(dataToImport);
      setCurrentFileName(dataToImport.fileName);
      closeImportDialog();
    } catch (error: any) {
      pxImportRef?.current?.setImportError(error.message);
    }
  };

  const reviewImportStateHandler = () => setImportData(null);

  const handleSave = async (changes: OfferImportChanges) => {
    try {
      const offers = [...changes.newOffers, ...changes.updatedOffers];
      await fetchUrl('PUT', apiToUrlMap.getPOoffers, { body: { offers } });
      getPOoffers();
      return true;
    } catch (error) {
      return false;
    }
  };

  const setTabToAwaitingReview = () => {
    setTabState(IOffersTab.awaitingReview);
  };

  if (importData) {
    return (
      <PoOffersItemImport
        importData={importData}
        fileName={currentFileName!}
        keyGroup={{
          errors: 'poOfferItemError',
          changes: 'poOfferItemChange',
          warnings: 'poOfferItemWarning',
        }}
        closeImport={reviewImportStateHandler}
        saveImport={handleSave}
        screen="poOfferChanges"
        setPoOffersTab={setTabToAwaitingReview}
      />
    );
  }

  return (
    <main
      className="grid-x px-po-offers"
      style={{
        maxWidth: '1240px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: offsetMarginBottom,
      }}>
      <ConfirmDialog
        isDialogOpen={confirmDialogProps?.isDialogOpen}
        title={confirmDialogProps?.title}
        confirmDialogContent={confirmDialogProps?.confirmDialogContent}
        confirmActionProps={confirmDialogProps?.confirmActionProps}
        backdropHandler={confirmDialogProps?.backdropHandler}
        className="notification-dialog"
      />
      <LoadingDialog isDialogOpen={loadingDialog} />
      <ImportPopup
        title="Import Offers"
        desc=""
        ref={pxImportRef}
        open={importDialog}
        handleClose={closeImportDialog}
        handleUpload={handleUpload}
        customError="File format must be .XLSX"
      />
      <div className="cell small-12 medium-shrink">
        <h2>PO Offers</h2>
      </div>
      <div className="cell small-12 medium-auto text-right">
        <TertiaryButton className="inline-block px-margin-right-20" onClick={exportOfferSheet}>
          <GetAppIcon />
          &nbsp; EXPORT OFFER SHEET
        </TertiaryButton>
        <SecondaryButton className="inline-block" onClick={openImportDialog}>
          <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
            </svg>
          </SvgIcon>
          &nbsp; IMPORT OFFER SHEET
        </SecondaryButton>
      </div>
      <div className="grid-x cell small-12 margin-bottom-1">
        <div className="cell small-2 shrink margin-right-2">
          <AccessAlarmOutlinedIcon className="vertical-middle px-margin-right-5 faded_2" />
          {summary?.durationLeft ? `${summary?.durationLeft} left` : '--'}
        </div>
        <div className="cell small-3 shrink margin-right-2">
          <span className="faded_2"> Offers Dates: </span>
          <WavingHandIcon className="vertical-middle px-margin-right-5 faded_2" />{' '}
          {formatDateByMonthDay(
            summary?.consolidatedOfferStartDate,
            summary?.consolidatedOfferEndDate
          ) || '- -'}
        </div>
        <div className="cell auto shrink margin-right-2 ">
          <span className="faded_2">Ship Dates: </span>
          <LocalShippingOutlinedIcon className="vertical-middle px-margin-right-5 faded_2" />{' '}
          {formatDateByMonthDay(
            summary?.consolidatedShippingStartDate,
            summary?.consolidatedShippingEndDate
          ) || '- -'}
        </div>
      </div>
      <nav ref={headerRef} className="go-to-top grid-x cell small-12 padding-right-3">
        <div className="cell small-12 margin-top-1">
          <Tabs
            value={tabState}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleTabChange}
            className="margin-top-1 px-inventory-tabs"
            variant="fullWidth"
            aria-label="inventory tab change">
            <Tab
              className="px-po-awards-tab px-bold-fonts"
              label={
                <Badge
                  badgeContent={badgeCount[IOffersTab.awarded]}
                  style={{
                    padding: '4px',
                  }}
                  max={Infinity}
                  invisible={false}>
                  AWARDS
                </Badge>
              }
              value={IOffersTab.awarded}
              key={IOffersTab.awarded}
            />
            <Tab
              className="px-po-reoffer-tab px-bold-fonts"
              label={
                <Badge
                  badgeContent={badgeCount[IOffersTab.reoffer]}
                  style={{
                    padding: '4px',
                  }}
                  max={Infinity}
                  invisible={false}>
                  RE-OFFER REQUESTS
                </Badge>
              }
              value={IOffersTab.reoffer}
              key={IOffersTab.reoffer}
            />
            <Tab
              className="px-po-awaiting-tab px-bold-fonts"
              label={
                <Badge
                  badgeContent={badgeCount[IOffersTab.awaitingReview]}
                  style={{
                    padding: '4px',
                  }}
                  max={Infinity}
                  invisible={false}>
                  OFFERS AWAITING REVIEW
                </Badge>
              }
              value={IOffersTab.awaitingReview}
              key={IOffersTab.awaitingReview}
            />
          </Tabs>
        </div>
        {!isEmpty(offersList) && (
          <div className="cell margin-vertical-1 small-12">
            {tabState === IOffersTab.reoffer &&
              (badgeCount[tabState] !== 0 ? (
                <div className="grid-x margin-top-1">
                  <WarningIcon className="cell margin-horizontal-1 px-warning-icon" />
                  <p className="cell small-10 faded_1">
                    BASED ON MARKET CONDITIONS WE RECOMMEND YOU RE-OFFER ON THESE ITEMS. IF YOU DO
                    NOT RE-OFFER AND YOUR CURRENT OFFER IS THE HIGHEST YOU MAY STILL BE AWARDED.
                    REJECTED OFFERS WILL NOT BE AWARDED UNLESS YOU RE-OFFER AT A HIGHER PRICE.
                  </p>
                </div>
              ) : (
                ''
              ))}
            {Object.keys(offerList).length > 1 ? (
              <div className="margin-vertical-1  margin-left-1">
                You have {tabState === IOffersTab.awarded ? 'awards' : 'offers'} across multiple
                warehouses:
              </div>
            ) : (
              ''
            )}
            {Object.keys(offerList).length > 1 ? (
              <div className="px-po-warehouses margin-left-1 margin-bottom-2">
                {Object.keys(offerList).map((warehouseCode: string) => {
                  return (
                    <Link
                      to={`#warehouse-${warehouseCode}`}
                      className="px-warehouse-link"
                      onClick={hash}
                      key={`${tabState}-${warehouseCode}`}>
                      WAREHOUSE {warehouseCode}
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div className="px-po-warehouses margin-left-1 margin-bottom-2">
                {Object.keys(offerList).map((warehouseCode: string) => {
                  return (
                    <Link
                      to={`#warehouse-${warehouseCode}`}
                      onClick={hash}
                      key={`${tabState}-${warehouseCode}`}></Link>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </nav>
      {offerList && (
        <PoOffersBox
          currentTab={tabState}
          exportOfferSheet={exportOfferSheet}
          importOfferSheet={openImportDialog}
          offersList={offerList}
          deleteItem={deleteItem}
          updateItem={updateItem}
        />
      )}
    </main>
  );
}
const mapStateToProps = (state: any) => ({
  offerState: state.offerState,
});

const mapDispatchToProps = (dispatch: any) => ({
  offersActions: bindActionCreators(offersActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoOffers);
