import { ICellRendererParams } from 'ag-grid-community';
import { IObject } from './IObject';
import { IPooffersItems } from './IPoOffers';

export type IOfferClearingTabs = 'all' | 'offered' | 'countered' | 'accepted';

// internal types
export enum eOfferClearingTabs {
  all = 'all',
  offered = 'offered',
  countered = 'countered',
  accepted = 'accepted',
}

export enum eOfferClearingActions {
  accept = 'accept',
  reject = 'reject',
  counter = 'counter',
}

// types values getting from server
export const offerClearingStatusValues = {
  offered: 'PENDING_ACCEPTANCE',
  countered: 'COUNTERED',
  accepted: 'ACCEPTED',
  rejected: 'NOT_ACCEPTED',
};

// types getting from server
export const offerClearingStatus: IObject<string> = {
  PENDING_ACCEPTANCE: 'Offered',
  NOT_ACCEPTED: 'Rejected',
  COUNTERED: 'Countered',
  ACCEPTED: 'Accepted',
  BOOKED: 'Booked',
  ALL: 'All',
};

export type offerClearingStatusKeys =
  | 'PENDING_ACCEPTANCE'
  | 'NOT_ACCEPTED'
  | 'COUNTERED'
  | 'ACCEPTED'
  | 'BOOKED';
//

export enum eOfferStates {
  readyToCheckout = 'ready_to_checkout',
  awaitingTenantReview = 'awaiting_tenant_review',
  awaitingBuyerReview = 'awaiting_buyer_review',
  outOfStock = 'out_of_stock',
}

export interface IOffers {
  awardedPrice: number | null;
  awardedQuantity: number | null;
  buyerId: number;
  buyerName: string;
  buyerNumber: string;
  errorHandlers: any;
  isTakeAll: number;
  itemElectId: number;
  offerId: number;
  offerPrice: number;
  offerQuantity: number;
  quantityAvailable: number;
  reofferRequestPrice: number | null;
  reofferRequestQuantity: number | null;
  status: string;
}

export interface IClearingProps extends ICellRendererParams {
  pinned: string;
  updateOffer: (dataValue: IPooffersItems[]) => {};
  offerItem: IPooffersItems;
  onSendPrice: (inputValue: string, data: IPooffersItems, value: string) => {};
  initialIds?: any;
  detailRowSelectedHandler?: any;
}

export interface IBulkData {
  availableQuantity: number;
  avgPrice: string;
  buyers: number;
  difference: number;
  highestOfferPrice: string | number;
  reOfferDisabledText: string;
  isAwardDisabled: boolean;
  offerRange: string;
  offers: IOffers[];
  selectedQuantity: number;
  skus: number;
}

export interface IDataRange {
  endDate: string;
  startDate: string;
}

export interface ILastDateRangeSelector extends ICellRendererParams {
  dateRangeState: {
    dateRange: IDataRange;
    setDateRange: any;
  };
}

export interface IItems {
  IsShipping: boolean;
  analytics: any;
  awardedAveragePrice: number;
  awardedQuantity: number;
  bandMaterial: string;
  casingMaterial: string;
  casingSize: string;
  category: string;
  color: string;
  cpuModel: string;
  displayGroupName: string;
  grade: string;
  groupName: string;
  itemElectId: number;
  itemNumber: string;
  itemNumberWarehouse: string;
  listPrice: null | number;
  lockStatus: string;
  lowPrice: null | number;
  manufacturer: string;
  model: string;
  modelNumber: string;
  offerEndDate: string;
  offerStartDate: string;
  offers: IOffers[];
  protocol: string;
  quantityAvailable: number;
  ram: string;
  salesTotalDemand: number;
  screenSize: string;
  shippingEndDate: string;
  shippingStartDate: string;
  warehouse: string;
}
