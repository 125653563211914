import { InputAdornment, Tooltip } from '@material-ui/core';
import { Close, HelpOutline, Search as SearchIcon } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import TextField from '../AtomComponents/TextField';
import './SearchField.scss';

interface ISearch {
  tmpSearchTermState: any;
  setTmpSearchTermState: any;
  setSearchTermState: any;
  tooltipHeading?: string;
  placeholder?: string;
}

const SearchField = ({
  tmpSearchTermState,
  setTmpSearchTermState,
  setSearchTermState,
  tooltipHeading,
  placeholder,
}: ISearch) => {
  const [searchText, setSearchText] = useState<any>();
  const debounce = useDebounce(searchText, 500);

  useEffect(() => {
    setSearchTermState(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const clearSearchField = () => {
    setSearchText('');
    setTmpSearchTermState('');
  };

  const tip = (
    <div className="px-tooltip px-text-description">
      <div className="px-tooltip-heading">
        <HelpOutline /> Tips on Using Search
      </div>
      <div className="px-tooltip-description">
        <span className="px-tooltip-font-large">
          {tooltipHeading ? tooltipHeading : 'Match on list of SKUs from a spreadsheet'}
        </span>
        <span>Select a range of cells in a spreadsheet,</span>
        <span>then copy and paste into the search input</span>
      </div>
    </div>
  );

  return (
    <div className="grid-x">
      <div className="cell small-7 medium-5 large-3">
        <TextField
          data-id="searchBox"
          value={tmpSearchTermState}
          onChange={(e: any) => {
            setSearchText(e.target.value);
            setTmpSearchTermState(e.target.value);
          }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            // color="primary"
            endAdornment: (
              <InputAdornment position="end">
                {debounce ? <Close onClick={clearSearchField} className="pointer-mouse" /> : <></>}
              </InputAdornment>
            ),
          }}
          aria-describedby="Search item"
          placeholder={placeholder || 'Search Item Description'}
          size="small"
        />
      </div>
      <div className="cell small-1 px-search-q margin-left-1">
        <Tooltip
          id="search-tooltip"
          title={tip}
          defaultChecked={true}
          TransitionProps={{ timeout: 0 }}
          interactive>
          <HelpOutline />
        </Tooltip>
      </div>
    </div>
  );
};

export default SearchField;
