import { ClickAwayListener, MenuItem, MenuList, Popper, SvgIcon } from '@material-ui/core';
import { ArrowDropDown, GetApp } from '@material-ui/icons';
import React from 'react';
import useDialog from '../../hooks/useDialog';
import TertiaryButton from '../AtomComponents/TertiaryButton';

interface ImportActions {
  action: any;
  label: string;
  actionParams?: string;
  buttonParams?: Object;
}

interface IImportExportDropdown {
  importExportOptions: {
    importLabel?: string;
    importOptions?: Array<ImportActions>;
    importAction?: any;
    exportLabel?: string;
    exportOptions?: Array<ImportActions>;
    exportAction?: any;
    disabled?: {
      import?: any;
      export?: any;
    };
    actionButtonParams?: {
      import?: Object;
      export?: Object;
    };
  };
}
function ImportExportDropdown({
  importExportOptions: {
    importAction,
    importLabel,
    importOptions,
    exportLabel,
    exportOptions,
    exportAction,
    disabled,
    actionButtonParams,
  },
}: IImportExportDropdown) {
  // export state
  const [isExportMenu, openExportMenu, closeExportMenu, exportMenu] = useDialog();
  const [isImportMenu, openImportMenu, closeImportMenu, importMenu] = useDialog();

  const import_action = (e: any) => {
    if (importAction) {
      importAction(e);
    }

    if (importOptions?.length) {
      openImportMenu(e);
    }
  };

  const export_action = (e: any) => {
    if (exportAction) {
      exportAction(e);
    }

    if (exportOptions?.length) {
      openExportMenu(e);
    }
  };

  const importButtonParams = actionButtonParams?.import ? actionButtonParams.import : {};
  const exportButtonParams = actionButtonParams?.export ? actionButtonParams.export : {};

  return (
    <>
      {importLabel && (
        <TertiaryButton
          disabled={!!disabled?.import && typeof disabled?.import === 'boolean'}
          className="px-import-export-btn"
          {...importButtonParams}
          onClick={import_action}>
          <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
            </svg>
          </SvgIcon>{' '}
          {importLabel}
          {importOptions?.length ? <ArrowDropDown /> : null}
        </TertiaryButton>
      )}

      {/* currentTarget */}

      {importOptions?.length && (
        <Popper
          open={isImportMenu}
          placement="bottom-start"
          className="px-popper-menu"
          keepMounted={false}
          transition
          anchorEl={importMenu}>
          <ClickAwayListener onClickAway={closeImportMenu}>
            <MenuList>
              {importOptions.map((val: any) => {
                return (
                  <MenuItem
                    key={val.label}
                    {...val.buttonParams}
                    onClick={() => {
                      if (val.actionParams) {
                        return val.action(val.actionParams);
                      }
                      val.action();
                    }}>
                    {val.label}
                  </MenuItem>
                );
              })}
            </MenuList>
          </ClickAwayListener>
        </Popper>
      )}

      {exportLabel && (
        <TertiaryButton
          disabled={!!disabled?.export && typeof disabled?.export === 'boolean'}
          className="px-import-export-btn"
          {...exportButtonParams}
          onClick={export_action}>
          <GetApp /> {exportLabel}
          {exportOptions?.length ? <ArrowDropDown /> : null}
        </TertiaryButton>
      )}

      {exportOptions?.length && (
        <Popper
          open={isExportMenu}
          placement="bottom-start"
          className="px-popper-menu"
          keepMounted={false}
          transition
          anchorEl={exportMenu}>
          <ClickAwayListener onClickAway={closeExportMenu}>
            <MenuList>
              {exportOptions.map((val: any) => {
                return (
                  <MenuItem
                    key={val.label}
                    {...val.buttonParams}
                    onClick={() => {
                      if (val.actionParams) {
                        return val.action(val.actionParams);
                      }
                      val.action();
                    }}>
                    {val.label}
                  </MenuItem>
                );
              })}
            </MenuList>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
}

export default ImportExportDropdown;
