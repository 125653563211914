import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect, useState } from 'react';
import { I18nContext } from '../../../I18n';
import { IFilterCount, IFilterDialog, IFilterOptions } from '../../../types/IFilterEngine';
import { checkNullOrUndefined } from '../../../_lib/lib';
import { PrimaryCheckbox } from '../../AtomComponents';
import PrimaryButton from '../../AtomComponents/PrimaryButton';
import TertiaryButton from '../../AtomComponents/TertiaryButton';

function FiltersRequired({
  presetOptionObj,
  title,
  attribute,
  filterState,
  clearFilter,
  saveFilter,
  closeDialog,
  getLabel,
  getCount,
}: IFilterDialog) {
  const I18n = React.useContext(I18nContext);
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>();
  const [totalAndCheckedOptions, setTotalAndCheckedOptions] = useState<IFilterCount>({
    total: 0,
    checked: 0,
  });
  useEffect(() => {
    const totalAndChecked = {
      total: Object.keys(presetOptionObj).length,
      checked: 0,
    };
    Object.keys(presetOptionObj).forEach((val) => {
      if (presetOptionObj[val]) totalAndChecked.checked++;
    });
    const updatedOptionsObj = { ...presetOptionObj };
    if (totalAndChecked.total === totalAndChecked.checked) {
      // check off select all
      totalAndChecked.checked = 0;
      // select none
      Object.keys(updatedOptionsObj).forEach((val) => {
        updatedOptionsObj[val] = false;
      });
    }

    setFilterOptions(updatedOptionsObj);
    setTotalAndCheckedOptions(totalAndChecked);
  }, [presetOptionObj]);

  const handleChange = (e: any) => {
    if (!filterOptions || checkNullOrUndefined(filterOptions[e.target.name])) {
      return;
    }
    const finalVal = !filterOptions[e.target.name];
    setFilterOptions({
      ...filterOptions,
      [e.target.name]: finalVal,
    });
    setTotalAndCheckedOptions({
      total: totalAndCheckedOptions.total,
      checked: totalAndCheckedOptions.checked + (finalVal ? +1 : -1),
    });
  };

  const handleSave = () => {
    if (filterOptions) {
      saveFilter({
        title: title,
        attribute: attribute,
        selectedFilters: filterOptions,
        checkedFilters: totalAndCheckedOptions.checked,
      });
    } else {
      handleClear();
    }
  };

  const handleClear = () => {
    clearFilter({
      title: title,
      attribute: attribute,
    });
  };

  const handleSelectAll = (e: any) => {
    if (filterOptions) {
      const updatedFilterOptions: IFilterOptions = {};
      Object.keys(filterOptions).forEach((key) => {
        updatedFilterOptions[key] = e.target.checked;
      });
      const updatedFiterCount: IFilterCount = {
        total: totalAndCheckedOptions.total,
        checked: 0,
      };
      if (e.target.checked) {
        updatedFiterCount.checked = updatedFiterCount.total;
      }
      setTotalAndCheckedOptions(updatedFiterCount);
      setFilterOptions(updatedFilterOptions);
    }
  };

  const isTotalAndCheckedGt0 =
    totalAndCheckedOptions.checked > 0 && totalAndCheckedOptions.total > 0;

  return (
    <Dialog
      onClose={() => closeDialog({ attribute })}
      className="px-chips-filter-dialog"
      open={!!presetOptionObj}>
      <DialogTitle>
        <span className="px-chips-filter-title">{getLabel('attribute', attribute)}</span>
      </DialogTitle>

      <DialogContent>
        <div className="grid-x">
          <div className="cell small-12">
            <div className="grid-x">
              <FormControlLabel
                className="cell small-12"
                control={
                  <PrimaryCheckbox
                    checked={
                      isTotalAndCheckedGt0 &&
                      totalAndCheckedOptions.checked === totalAndCheckedOptions.total
                    }
                    indeterminate={
                      isTotalAndCheckedGt0 &&
                      totalAndCheckedOptions.checked !== totalAndCheckedOptions.total
                    }
                    disabled={totalAndCheckedOptions.total <= 0}
                    onChange={handleSelectAll}
                    name="selectAll"
                  />
                }
                label={`(${I18n?.selectAll?.i18n_value || 'Select All'})`}
              />
              {filterOptions && Object.keys(filterOptions).length > 0 ? (
                <>
                  {Object.keys(filterOptions)
                    .sort()
                    .map((val: any) => {
                      return (
                        <FormControlLabel
                          className="cell small-12"
                          key={`filter_category_${val}`}
                          control={
                            <PrimaryCheckbox
                              checked={filterOptions[val]}
                              onChange={handleChange}
                              name={val}
                            />
                          }
                          label={filterState?.filterLabel[val]}
                        />
                      );
                    })}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions className="px-chips-filter-dialog-actions">
        <TertiaryButton onClick={handleClear}>Clear</TertiaryButton>
        <PrimaryButton onClick={handleSave}>APPLY</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default FiltersRequired;
